<template>
  <b-modal
    id="modal-account-service"
    title="회원 서비스"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    contentClass="confirm border-0 fw-400"
    footer-class="p-0 border-0"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="sm"
  >
    <div class="text-center text-17">
      로그인이 필요한 서비스 입니다.<br />로그인/회원가입을 하시겠습니까?
    </div>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between btn-group flex-nowrap">
        <b-btn
          variant="outline-primary m-0 py-3 w-50 btn-block"
          @click="goto('/account/sign-up')"
        >
          회원가입
        </b-btn>
        <b-btn
          variant="primary m-0 ml-1 py-3 w-50 btn-block"
          @click="goto('/account/login')"
          >로그인</b-btn
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  methods: {
    goto(url) {
      this.$bvModal.hide("modal-account-service");
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped></style>
