var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout"},[_c('b-container',[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"py-3 py-md-5 border-right min-md-vh-100",attrs:{"cols":"12","md":"3","lg":"3","xl":"2"}},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('header',{staticClass:"px-lg-3 mb-md-5"},[_c('figure',{staticClass:"\n                  rounded-sm\n                  d-flex\n                  flex-row flex-md-column\n                  align-items-center\n                  justify-content-lg-center\n                "},[_c('div',{staticClass:"\n                    myphoto\n                    p-1\n                    border border-light-gray\n                    rounded\n                    mr-3 mr-md-0\n                    position-relative\n                  ",style:({
                    minWidth: '8.5rem',
                    minHeight: '8.5rem',
                  })},[(_vm.user.profile)?[_c('div',{staticClass:"bg-img w-100 ratio-100",style:({
                        backgroundImage: ("url(" + (_vm.s3 + _vm.user.profile) + ")"),
                      })})]:(!_vm.user.profile)?[_c('b-avatar',{style:({
                        width: '100%',
                        height: '100%',
                        minWidth: '8.5rem',
                        minHeight: '8.5rem',
                      }),attrs:{"square":"","variant":"primary","src":null}})]:_vm._e(),(_vm.user.loginType !== 'EMAIL')?[_c('div',{staticClass:"bg-img position-absolute rounded-circle p-1",class:("bg-" + (_vm.snsId(_vm.user.serviceId).variant)),style:({
                        top: '-8px',
                        right: '-8px',
                        zIndex: '3',
                      })},[_c('div',{staticClass:"bg-img",style:({
                          width: '24px',
                          minWidth: '24px',
                          paddingBottom: '24px',
                          backgroundImage: ("url(" + (_vm.snsId(_vm.user.serviceId).type) + ")"),
                        })})])]:_vm._e()],2),_c('figcaption',{staticClass:"\n                    d-flex\n                    flex-column\n                    align-items-md-center\n                    overflow-hidden\n                  "},[_c('span',{staticClass:"mt-2 mb-3 text-16 text-gray text-truncate",style:({ maxWidth: '180px' })},[(_vm.user.loginType === 'EMAIL')?[_vm._v(" "+_vm._s(_vm.user.role === "COUNSELOR" ? !_vm.user.counselInfo ? _vm.onlyId(_vm.user.serviceId) : _vm.user.counselInfo.finInfoName +'_'+ _vm.user.counselInfo.name : _vm.user.companyMeta.companyName)+" ")]:_vm._e(),(_vm.user.loginType !== 'EMAIL')?[_vm._v(" "+_vm._s(_vm.snsId(_vm.user.serviceId).id)+" ")]:_vm._e()],2),_vm._v(" "+_vm._s(_vm.$store.state.user.role)+" "+_vm._s(_vm.$store.state.user.mypageStatus)+" "),_c('b-btn',{staticClass:"px-3",attrs:{"variant":"outline-primary"},on:{"click":_vm.userTypeToggle}},[_vm._v(" "+_vm._s(_vm.userType === "bidder" ? "의뢰자로 전환하기" : "입찰자로 전환하기")+" ")])],1)])])]),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('article',[_c('header',{staticClass:"pb-3",style:({ borderBottom: '3px solid #333' })},[_c('h5',{staticClass:"d-none d-md-block"},[_vm._v("마이페이지")])]),_c('article',[(_vm.$route.path == '/mypage/history')?_c('b-row',{staticClass:"list-unstyled bg-light py-3 d-block d-md-none"},[_c('b-col',{staticClass:"mb-md-3",attrs:{"cols":"12"}},[_c('div',{staticClass:"\n                        lnb-category\n                        d-flex\n                        align-items-cneter\n                        justify-content-between\n                        px-0\n                        py-2\n                        text-17\n                      ",attrs:{"block":""}},[_c('span',[_vm._v("나의 비딩매칭 내역")]),_c('i',{staticClass:"icon icon-caret text-gray"})]),(
                        _vm.sidebarLinks[0].list && _vm.sidebarLinks[0].list.length
                      )?[_c('b-row',{staticClass:"list-unstyled bg-light py-1"},_vm._l((_vm.sidebarLinks[0].list),function(link,l){return _c('b-col',{key:l,staticClass:"my-1",attrs:{"cols":"6"}},[_c('router-link',{staticClass:"lnb-link text-14 text-nowrap px-3 px-md-0",attrs:{"to":{ query: { type: link.url } }}},[_vm._v(_vm._s(link.name)+" ("+_vm._s(link && link.count ? _vm.getLength(link.count) : 0)+") "),(_vm.getState(link.state))?_c('i',{staticClass:"icon icon-is-new icon-16 ml-1 mb-n1"}):_vm._e()])],1)}),1)]:_vm._e()],2)],1):_vm._e(),_vm._l((_vm.sidebarLinks),function(item,i){return _c('section',{key:i,staticClass:"mb-1 border-0 d-none d-md-block"},[(item.userType === null)?[_c('router-link',{staticClass:"\n                        lnb-category\n                        d-flex\n                        align-items-cneter\n                        justify-content-between\n                        px-0\n                        py-2\n                        text-17\n                      ",attrs:{"block":"","to":("/mypage/" + (item.url))}},[_c('span',[_vm._v(_vm._s(item.category)+" "),(item.state)?[(_vm.getState(item.state) === true)?_c('i',{staticClass:"icon icon-is-new icon-16 ml-1 mb-n1"}):_vm._e()]:_vm._e()],2),(item.list)?_c('i',{staticClass:"icon icon-caret text-gray"}):_vm._e()]),(
                        _vm.sidebarLinks[0].list && _vm.sidebarLinks[0].list.length
                      )?[(item.list && _vm.meta.showLnb)?_c('b-row',{staticClass:"list-unstyled bg-light py-3"},_vm._l((item.list),function(link,l){return _c('b-col',{key:l,staticClass:"mb-md-3",attrs:{"cols":"12","md":"12"}},[_c('router-link',{staticClass:"lnb-link text-14 text-nowrap px-3 px-md-0",attrs:{"to":{ query: { type: link.url } }}},[_vm._v(_vm._s(link.name)+" ("+_vm._s(link && link.count ? _vm.getLength(link.count) : 0)+") "),(_vm.getState(link.state))?_c('i',{staticClass:"icon icon-is-new icon-16 ml-1 mb-n1"}):_vm._e()])],1)}),1):_vm._e()]:_vm._e()]:[_c('router-link',{staticClass:"\n                        lnb-category\n                        d-flex\n                        align-items-cneter\n                        justify-content-between\n                        px-0\n                        py-2\n                        text-17\n                      ",attrs:{"block":"","to":("/mypage/" + (item.url))}},[_c('span',[_vm._v(_vm._s(item.category))]),(item.list)?_c('i',{staticClass:"icon icon-caret text-gray"}):_vm._e()])]],2)})],2)])])],1)],1),_c('b-col',{staticClass:"py-5",attrs:{"cols":"12","md":"9","lg":"9","xl":"10"}},[_c('router-view',{staticClass:"px-3",attrs:{"userType":_vm.userType}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }