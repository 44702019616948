import Vue from "vue";
const SendBird = require("sendbird");

// 센드버드 계정 변경 A5AE5987-13FE-4A57-A3CD-A5C4BBACF2E0 -> 25D59EE6-8859-43ED-9C16-6E5A66B38A26 - 기존
//25D59EE6-8859-43ED-9C16-6E5A66B38A26 -> y_522계정
//5082326E-F8CC-4474-B76A-D3597A240A55 -> 이도생각 계정

const sb = new SendBird({
  appId: "709DB0B8-9F91-47A1-AD15-8A5B28533758",
});

Vue.prototype.sb = sb;

export default sb;
