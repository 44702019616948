<template>
  <b-modal
    id="modal-owner-phone"
    title="휴대폰번호 입력"
    ok-title="확인"
    @ok="ok"
    cancelTitle="닫기"
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="primary m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="sm"
  >
    <div class="text-center">
      <span>소유자의 휴대폰 번호를 입력해주세요.</span>
      <b-input-group class="my-1" variant="outline" hide-prepend>
        <w-input
          class="w-100 m-4"
          type="text"
          v-model="input.realphone"
          @input.native="$numberOnly($event, 'realphone', 11)"
          placeholder="소유자 번호*"
        ></w-input>
      </b-input-group>
    </div>
  </b-modal>
</template>

<script>
// 0810 매칭하기 버튼 클릭시 나오는 모달창 현재 미완
export default {
  computed: {
  },
  data() {
    return {
      input: { realphone: null },
      submitted: false
    };
  },
  props: {
    idBidding: {
      type: Number,
    },
  },
  methods: {
    async ok() {
      
      if(!this.input.realphone){
        alert('전화번호를 입력해주세요');
        return;
      }
      
      const bool = await window.confirm(
        `입력하신 전화번호가 맞습니까 ?<br>
        <strong stlye="text-primary">${this.input.realphone}</strong>
        `,
        {
          title: "전화번호 확인",
          bodyClass: "text-center",
          okTitle: "확인",
          cancelTitle: "취소",
        }
      );

      if (bool) {
        try{
          alert('OK!');
        }catch(e){
          console.log('매칭하기(전화번호 입력)',e)
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
