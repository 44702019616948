var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3 mb-5 py-5"},[_c('b-container',[_c('header',{staticClass:"mb-3"},[_c('h2',{staticClass:"mb-2 text-28 text-lg-38"},[_vm._v("문의")]),_c('span',{staticClass:"text-14 text-gray fw-400"},[_vm._v("일반 문의, 제휴 무의 등 자유롭게 문의하세요.")])]),_c('b-form',{staticClass:"mt-5",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{style:({
          borderTop: '3px solid #333',
          borderBottom: '3px solid #333',
        })},[_c('b-row',{staticClass:"mx-0 border-bottom border-gray",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"px-0 pl-md-0 pr-md-4",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"py-2 py-md-4 text-center text-gray",style:({ backgroundColor: '#fbf8f4' })},[_vm._v(" 문의 제목 ")])]),_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"py-3"},[_c('b-form-input',{staticClass:"rounded-lg input-inquiry",attrs:{"placeholder":"문의 제목을 입력해주세요"},model:{value:(_vm.input.title),callback:function ($$v) {_vm.$set(_vm.input, "title", $$v)},expression:"input.title"}})],1)])],1),_c('b-row',{staticClass:"mx-0 border-bottom border-gray",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"px-0 pl-md-0 pr-md-4",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"py-2 py-md-4 text-center text-gray",style:({ backgroundColor: '#fbf8f4' })},[_vm._v(" 이메일 ")])]),_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"pt-3 pb-4 position-relative"},[_c('b-form-input',{staticClass:"rounded-lg input-inquiry",attrs:{"type":"email","state":_vm.input.senderEmail && _vm.input.senderEmail.length > 5
                    ? _vm.rules.serviceId
                    : null,"placeholder":"답변 받으실 이메일을 입력해주세요","required":""},model:{value:(_vm.input.senderEmail),callback:function ($$v) {_vm.$set(_vm.input, "senderEmail", $$v)},expression:"input.senderEmail"}}),(
                  !_vm.rules.serviceId &&
                    _vm.input.senderEmail &&
                    _vm.input.senderEmail.length > 5
                )?_c('small',{staticClass:"position-absolute text-danger"},[_vm._v("이메일 양식을 확인해주세요.")]):_vm._e()],1)])],1),_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","md":"2","align-self":"stretch"}},[_c('div',{staticClass:"py-2 py-md-4 text-center text-gray h-100 d-flex flex-column",style:({ backgroundColor: '#fbf8f4' })},[_c('span',{staticClass:"my-auto"},[_vm._v(" 문의 내용 ")])])]),_c('b-col',{attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"my-3"},[_c('b-textarea',{staticClass:"rounded-lg input-inquiry",attrs:{"rows":"10","placeholder":"문의 내용을 입력해주세요"},model:{value:(_vm.input.content),callback:function ($$v) {_vm.$set(_vm.input, "content", $$v)},expression:"input.content"}})],1)])],1)],1),_c('b-row',{staticClass:"mt-4 mx-md-5",attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"text-center",attrs:{"cols":"12","md":"5"}},[_vm._v(" "+_vm._s(_vm.rules.serviceId)+" "),_c('b-btn',{staticClass:"w-100 mx-auto py-4",style:({ maxWidth: '440px' }),attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v("등록")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }