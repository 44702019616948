<template>
  <b-modal
    id="modal-mortgage-check"
    title="근저당권 설정"
    ok-variant="primary m-0 ml-1 p-3"
    ok-title="확인"
    cancelTitle="닫기"
    cancelVariant="outline-primary m-0 p-3"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    hide-header-close
    contentClass="confirm border-0 fw-400"
    footerClass="w-100 d-flex align-items-center justify-content-between p-1 border-0 btn-group"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="xl"
  >
    <div class="text-center" v-if="data">
      <hr class="mt-0" />
      <b-row>
        <b-col cols="1">순위</b-col>
        <b-col cols="2" class="pl-0">등기목적</b-col>
        <b-col cols="2" class="pl-0">근저당권자</b-col>
        <b-col cols="2" class="pl-0">채권 최고액</b-col>
        <b-col cols="2" class="pl-0">대출원금</b-col>
        <b-col cols="1" class="pl-0">채무자</b-col>
        <b-col cols="1" class="pl-0">설정률</b-col>
        <b-col cols="1" class="pl-0">말소</b-col>
      </b-row>
      <b-row v-for="item in data.mortgage" :key="item.id" class="mt-2">
        <b-col cols="1">
          <b-form-input
            type="text"
            v-model="item.ranking"
            disabled
          ></b-form-input
        ></b-col>
        <b-col cols="2" class="pl-0"
          ><b-form-input type="text" v-model="item.cont" disabled></b-form-input
        ></b-col>
        <b-col cols="2" class="pl-0"
          ><b-form-input
            type="text"
            v-model="item.mortgageHolder"
            disabled
          ></b-form-input
        ></b-col>
        <b-col cols="2" class="pl-0"
          ><b-form-input
            type="text"
            v-model="item.highBondAmount"
            disabled
          ></b-form-input
        ></b-col>
        <b-col cols="2" class="pl-0"
          ><b-form-input
            type="text"
            v-model="item.bondAmount"
            disabled
          ></b-form-input
        ></b-col>
        <b-col cols="1" class="pl-0"
          ><b-form-input
            type="text"
            v-model="item.owner"
            disabled
          ></b-form-input
        ></b-col>
        <b-col cols="1" class="pl-0">
          <b-form-select v-model="item.persent" @change="changePersent(item)">
            <!-- <option :value="null" selected>100.0</option> -->
            <option
              v-for="option in options"
              :key="option"
              :value="option"
              >{{ option }}
            </option>
          </b-form-select>
        </b-col>
        <b-col cols="1" class="pl-0">
          <b-form-group class="ml-auto text-gray">
            <b-form-checkbox v-model="item.cancellation"/>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-row class="mt-3">
        <b-col cols="6">채권 최고액 합계</b-col>
        <b-col cols="6">채권 원금 합계</b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="6"
          ><b-form-input
            type="text"
            v-model="data.totalHighBondAmount"
            disabled
          ></b-form-input
        ></b-col>
        <b-col cols="6"
          ><b-form-input
            type="text"
            v-model="data.totalBondAmount"
            disabled
          ></b-form-input
        ></b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between btn-group flex-nowrap">
        <!-- <b-btn
          variant="outline-primary m-0 py-3 w-50 btn-block"
          @click="cancel()"
        >
          취소
        </b-btn> -->
        <b-btn variant="primary m-0 ml-1 py-3 w-50 btn-block" @click="ok()"
          >설정</b-btn
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    data: null, //해당 data에는 모든 api를 조회한 값이 담겨옴 (비딩 등록 직전의 데이터)
  },
  data() {
    return {
      options: [
        "100.0",
        "110.0",
        "115.0",
        "120.0",
        "125.0",
        "130.0",
        "135.0",
        "140.0",
        "145.0",
        "150.0",
      ],
    };
  },
  methods: {
    async cancel() {},
    // 근저당권 설정율 selectBox 값 변경시 실행
    changePersent(item) {
      var persent = item.persent;
      var highBondAmount = item.highBondAmount;
      var bondAmount = item.bondAmount;

      // highBondAmount = highBondAmount.replace(/,/g, "");

      var result = (highBondAmount / Math.floor(persent)) * 100;

      bondAmount = Math.floor(result);
      // .toString()
      // .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      item.bondAmount = bondAmount;

      this.getTotalBondAmount();
    },
    // 채권원금합계 계산 - changePersent메소드 내부에서 호출
    getTotalBondAmount() {
      var totalBondAmountReset = 0;

      this.data.mortgage.forEach(function (e) {
        var value = e.bondAmount;
        // var value = e.bondAmount.replaceAll(",", "");
        totalBondAmountReset += Number(value);
      });

      this.data.totalBondAmount = totalBondAmountReset;
      // .toString()
      // .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      this.data.changed = true;
    },
    emit() {
      this.$emit("setMortgage", this.data);
      this.$bvModal.hide("modal-mortgage-check");
    },
    async ok() {
      try {
        console.log("ok");
        this.emit();
      } catch (error) {}
    },
    // async cancel() {
    //   try {
    //     console.log("ok");
    //     this.$bvModal
    //       .msgBoxOk("근저당권 설정을 취소하시겠습니까?", {
    //         title: "근저당권 설정",
    //         size: "sm",
    //         buttonSize: "sm",
    //         okVariant: "danger",
    //         okTitle: "확인",
    //         footerClass: "p-2",
    //         centered: true,
    //         noCloseOnBackdrop: true,
    //         titleClass: "fw-900",
    //         footerBgVariant: "white",
    //       })
    //       .then((value) => {
    //         if (value) {
    //           this.emit();
    //         }
    //       });
    //   } catch (error) {}
    // },
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  padding: 6px 12px;
  height: 38px;
}
</style>
