<template>
  <div class="text-17 text-lg-15">
    <header class="d-flex align-items-center">
      <strong :style="{ minWidth: '111px' }">
        <!-- 의뢰자 코멘트 -->
        <span v-if="!item.relativeBidder">
          {{ `${item.relativeUser.companyMeta
                ? item.relativeUser.companyMeta.companyName
                : item.relativeUser.serviceId} - ${getText(item.type)}` 
          }}
        </span>

        <!-- 자동입찰시 -->
        <span v-if="item.relativeBidder && item.relativeBidder.auto && item.relativeBidder.relativeUser.companyMeta">
          <span v-if="item.relativeBidder.relativeUser.companyMeta.companyName2">
            {{ `${item.relativeBidder.relativeUser.companyMeta.companyName2} - ${item.relativeBidder.regProcedure}번째 ${getText(item.type)}` }}
          </span>
          <span v-else>
            {{ `${item.relativeBidder.relativeUser.companyMeta.companyName} - ${item.relativeBidder.regProcedure}번째 ${getText(item.type)}` }}
          </span>
        </span>
        <!-- 회사명 -->
        <span v-else-if="item.relativeBidder && item.relativeBidder.relativeUser.companyMeta">
          {{ `${item.relativeBidder.relativeUser.companyMeta.companyName} - ${item.relativeBidder.regProcedure}번째 ${getText(item.type)}` }}
        </span>
        <!-- 상담사 -->
        <span v-else-if="item.relativeBidder && item.relativeBidder.relativeUser.counselInfo">
          {{ `${item.relativeBidder.relativeUser.counselInfo.corpName} - ${item.relativeBidder.regProcedure}번째 ${getText(item.type)}` }}
        </span>
      </strong>
      <small>{{ toLocaleDate(item.createdDate) }}</small>
    </header>
    <section class="mt-3">
      <p class="mb-0 text-gray lh-180 fw-400">
        {{ item.content }}
      </p>
    </section>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    getText(val) {
      const variant = {
        beforeBidding: "매칭 전",
        ING: "진행중",
        MATCHED: "매칭 완료",
        APPROVAL: "승인 완료",
        AUTOGRAPH: "자서 완료",
        TRANSFERRED: "송금 완료",
        COMPLETE: "대출 완료",
        REJECT: "부결",
        END_BIDDING: "입찰 마감",
        CANCEL_PROGRESS: "진행 취소",
        CANCEL_MATCHING: "매칭 취소",
        CANCEL_BIDDER: "입찰 취소",
        CLIENT: "의뢰자",
        UPDATE_BIDDING:"의뢰자",
        UPDATE_BIDDER:"입찰", // 입찰  수정
        UPDATE_CONDICTION:"입찰",
        AUTO: "자동입찰",
        BID: "입찰",
        ERROR: "에러",
      };
      return variant[val] || variant.error;
    },
  },
};
</script>

<style lang="scss" scoped></style>
