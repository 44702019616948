<template>
    <div>
      <b-input-group class="mb-4" variant="outline" hide-prepend>
        <b-form-input
          type="text"
          v-model="value.address"
          placeholder="토지 주소*"
          readonly
          @click="openPopup()"
        ></b-form-input>
        <template #append>
          <div class="ml-3">
            <b-btn
              variant="outline-primary"
              readonly
              class="text-17 text-nowrap"
              @click="openPopup()"
              >검색</b-btn
            >
          </div>
        </template>
        </b-input-group>
        <div v-if="kakaoMaps && kakaoMaps.documents">
            <ul
                v-for="item in kakaoMaps.documents" 
                :key="item.id" 
                class="mt-2"
                style="list-style:none;padding-left:0px;"
            >
                <li>
                    <span style="text-decoration: underline; cursor:pointer">{{item.address.address_name}}</span>
                </li>
            </ul>
        </div>
        <kakao-map-popup @addressObj="addressObj"/>
    </div>
</template>

<script>
import KakaoMapPopup from "@/components/KakaoMapPopup.vue";
export default {
    components: {
        KakaoMapPopup
    },
   props: {
    value: {
        type: Object,
    },
  },
  data() {
    
    return {
      showModal: false,
      address: null,
      kakaoMap: null,
      kakaoMaps: null,
    };
  },
  methods: {
    addressObj(e){
        this.$emit('input',e);
        console.log(e);
    },
    searched(){
        alert('searched');
    },
    openPopup(){
        this.$bvModal.show('bv-modal-kakoMap');
    },
    // async search() {
    //     try {
    //         const { data } = await this.$axios.get("/test/kbPrice2");

    //         if (data.status.code === "0000") {
    //             console.log('kakaoMap');
    //             console.log(data);
    //             this.kakaoMaps = data.data;
                
    //             // this.$emit("input", {
    //             //     zipNo: data.zonecode,
    //             //     address: `${addr} ${extraAddr}`,
    //             //     sido: data.sido,
    //             //     jibunAddress:
    //             //     data.jibunAddress !== ""
    //             //         ? data.jibunAddress
    //             //         : data.autoJibunAddress,
    //             //     roadAddress:
    //             //     data.roadAddress != "" ? data.roadAddress : data.autoRoadAddress,
    //             //     addressDetailMeta: {
    //             //     bcode: data.bcode,
    //             //     bname: data.bname,
    //             //     bname1: data.bname1,
    //             //     buildingCode: data.buildingCode,
    //             //     buildingName: data.buildingName,
    //             //     bun: jibun.split("-")[0] || "",
    //             //     ji: jibun.split("-")[1] || "",
    //             //     sido: data.sido,
    //             //     sigungu: data.sigungu,
    //             //     sigunguCode: data.sigunguCode,
    //             //     },
    //             // });
    //         } else {
    //             window.alert(data.status.message);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // },
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  padding: 6px 12px;
  height: 38px;
}
</style>
