<template>
  <!-- 토지대장 -->
  <div class="">
    <div v-if="itemMeta && metaData">
      <article v-for="(item, i) in metaData" :key="i">
        <header
          class="px-4 px-md-5 d-flex align-items-center justify-content-between"
        >
          <span class="text-14 text-lg-16 text-primary">
            {{ item.title }}
          </span>
          <span class="text-dark-gray text-13 text-lg-14 fw-300">
            열람일자 : {{ item.savedDate }}
          </span>
        </header>
        <section class="mt-2 mb-3">
          <header
            class="mt-3 px-4 px-md-5 text-17 text-lg-16 text-darkest"
            v-if="item.category"
          >
            {{ item.category }}
          </header>
          <div class="py-3 px-4 px-md-5">
            <ul class="list-unstyled m-0">
              <li
                class="py-2 d-flex align-items-center justify-content-between"
                v-for="(item2, j) in item.list"
                :key="j"
                :class="
                  j === item.list.length - 1
                    ? ''
                    : 'mb-2 border-bottom border-dark-gray'
                "
              >
                <span
                  class="
                    text-gray
                    fw-300
                    text-13 text-lg-14
                    ml-0
                    mr-auto
                    text-nowrap
                  "
                >
                  {{ listName(item2.key) }}
                </span>
                <span
                  class="
                    text-darkest
                    fw-600
                    text-13 text-lg-14
                    ml-auto
                    mr-0
                    pl-3
                    text-right
                  "
                >
                  {{ item2.value || "-" }}
                </span>
              </li>
            </ul>
          </div>
          <hr class="bg-light mt-0 mb-3 pb-4 mx-n3 mx-md-0 border-0" />
        </section>
      </article>
      <article class="py-3">
        <header class="px-4 px-md-5 text-14 text-lg-16 text-primary">
          토지 정보
        </header>
        <section class="mt-2" v-for="(item, i) in plans" :key="i">
          <header
            class="mt-3 px-4 px-md-5 text-14 text-lg-15 text-darkest"
            v-if="item.category"
          >
            {{ item.category }}
          </header>
          <div class="py-3 px-4 px-md-5">
            <ul class="list-unstyled m-0">
              <li
                class="py-2 d-flex align-items-center justify-content-between"
                v-for="(item2, j) in item.list"
                :key="j"
                :class="
                  j === item.list.length - 1
                    ? ''
                    : 'mb-2 border-bottom border-dark-gray'
                "
              >
                <span
                  class="
                    text-gray
                    fw-300
                    text-13 text-lg-14
                    ml-0
                    mr-auto
                    text-nowrap
                  "
                >
                  {{ listName(item2.key) }}
                </span>
                <span
                  class="
                    text-darkest
                    fw-600
                    text-13 text-lg-14
                    ml-auto
                    mr-0
                    pl-3
                    text-right
                  "
                >
                  {{ item2.value || "-" }}
                </span>
              </li>
            </ul>
          </div>
        </section>
      </article>
      <hr class="bg-light mt-0 mb-3 pb-4 mx-n3 mx-md-0 border-0" />
      <article class="py-3">
        <header class="px-4 px-md-5 text-14 text-lg-16 text-primary">
          개별공시지가 (토지e음 참조)
        </header>
        <section class="mt-2" v-if="years && prices && priceRange">
          <header
            class="
              mt-3
              px-4 px-md-5
              text-14 text-lg-15
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <span class="text-darkest ml-0 mr-auto">
              {{ years[years.length - 1] }}년 공시지가(평)
            </span>
            <span class="ml-auto">
              <strong class="text-secondary">
                {{ Number(priceRange.list[0]).toLocaleString() }}
              </strong>
              <span>원</span>
            </span>
          </header>
          <div class="my-3 px-4 px-md-5">
            <b-row>
              <b-col cols="12">
                <b-row align-v="stretch" class="py-2">
                  <b-col
                    cols="2"
                    class="
                      d-flex
                      flex-column
                      pt-1
                      justify-content-center
                      align-items-center
                    "
                  >
                    <span
                      class="
                        text-gray-light text-12 text-lg-14
                        fw-300
                        text-nowrap
                        mb-4 mb-md-3
                        pt-md-1
                      "
                      v-for="(item, i) in priceRange.list"
                      :key="'range-' + i"
                    >
                      {{ `${Math.floor(item / 10000)}만` }}
                    </span>
                  </b-col>
                  <b-col cols="10" class="position-relative mt-3 my-md-3 pr-0">
                    <div
                      class="border-top"
                      v-for="(item, i) in priceRange.list"
                      :class="{
                        'py-3 mb-2': i !== priceRange.list.length - 1,
                      }"
                      :key="'line-' + i"
                    ></div>
                    <div
                      class="position-absolute rounded-circle bg-primary"
                      v-for="(item, i) in prices"
                      :key="'dot-' + i"
                      :style="{
                        width: '16px',
                        height: '16px',
                        bottom: `calc(${
                          (item.price / priceRange.max) * 100
                        }% - 8px)`,
                        left: `${i * 14.285}%`,
                        transform: `translateX(120%)`,
                        zIndex: '3',
                      }"
                      v-b-tooltip.hover
                      :title="`${Math.floor(item.price / 10000)}만`"
                      v-show="item.price"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" class="mt-n4 mt-md-0">
                <b-row align-h="end">
                  <b-col
                    cols="sm7"
                    v-for="(item, i) in years"
                    :key="i"
                    class="text-center"
                  >
                    <span class="text-gray-light text-12 text-lg-14 fw-300">
                      {{ `${String(item).slice(2)}년` }}
                    </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </section>
        <section v-else>
          <div class="py-3 text-light-gray text-center">
            데이터를 불러올 수 없습니다.
          </div>
        </section>
      </article>
    </div>
    <div v-else>
      <p class="m-0 py-3 text-center text-gray-light text-14 fw-300">
        토지대장 정보를 불러올 수 없습니다.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemMeta: {
      type: Object,
    },
  },
  data() {
    return {
      current: null,
    };
  },
  computed: {
    metaData() {
      const { landMeta } = this.itemMeta;
      if (landMeta) {
        const { ladfrl, possession, referLandPrices, landMetaSavedDate } =
          landMeta || {};

        const data = [
          {
            title: "토지 정보",
            savedDate: landMetaSavedDate
              ? this.toLocaleDate(landMetaSavedDate)
              : "-",

            list: [
              {
                key: "area",
                value: ladfrl
                  ? `${ladfrl.lndpclAr}${this.encdoeStr(13217)}(${this.pyUnit(
                      ladfrl.lndpclAr
                    )})`
                  : "-",
              },
              {
                key: "landCategory",
                value: ladfrl ? ladfrl.lndcgrCodeNm : "-",
              },
              {
                key: "ladUseSittnNm",
                value: referLandPrices ? referLandPrices.ladUseSittnNm : "-",
              },
              {
                key: "posesnSeCodeNm",
                value: possession ? possession.posesnSeCodeNm : "-",
              },
              {
                key: "ownshipChgDe",
                value: possession ? possession.ownshipChgDe : "-",
              },
              {
                key: "ownshipChgCauseCodeNm",
                value: possession ? possession.ownshipChgCauseCodeNm : "-",
              },
              {
                key: "roadSideCodeNm",
                value: referLandPrices ? referLandPrices.roadSideCodeNm : "-",
              },
              {
                key: "tpgrphHgCodeNm",
                value: referLandPrices ? referLandPrices.tpgrphHgCodeNm : "-",
              },
              {
                key: "tpgrphFrmCodeNm",
                value: referLandPrices ? referLandPrices.tpgrphFrmCodeNm : "-",
              },
            ],
          },
        ];
        return data;
      } else return [];
    },
    plans() {
      const { landMeta } = this.itemMeta;
      if (landMeta && landMeta.landUse) {
        const data = [
          {
            category: "<국토의 계획 및 이용에 관한 법률>",
            list: [
              {
                key: "include",
                value: landMeta.landUse.countryPlanInclusion || "-",
              },
              {
                key: "conflict",
                value: landMeta.landUse.countryPlanConflict || "-",
              },
              {
                key: "join",
                value: landMeta.landUse.countryPlanJoin || "-",
              },
            ],
          },
          {
            category: "<기타 법률>",
            list: [
              {
                key: "include",
                value: landMeta.landUse.etcInclusion || "-",
              },
              {
                key: "conflict",
                value: landMeta.landUse.etcConflict || "-",
              },
              {
                key: "join",
                value: landMeta.landUse.etcJoin || "-",
              },
            ],
          },
        ];
        return data;
      } else return [];
    },
    years() {
      const years = [
        1990,
        1995,
        2000,
        2005,
        2010,
        2015,
        Number(new Date().getFullYear()),
      ];

      return years;
    },
    prices() {
      const { landMeta } = this.itemMeta;
      if (landMeta && landMeta.indvdLandPrices) {
        const arr = Object.values(landMeta.indvdLandPrices);

        const data = arr.map((item, i) => {
          return item
            ? { year: item.split(":")[0], price: item.split(":")[1] }
            : { year: this.years[i], price: 0 };
        });
        return data;
      } else return [];
    },
    priceRange() {
      const { landMeta } = this.itemMeta;
      if (landMeta && landMeta.indvdLandPrices) {
        const arr = Object.values(landMeta.indvdLandPrices);

        const data = arr.map((item, i) => {
          return item
            ? { year: item.split(":")[0], price: item.split(":")[1] }
            : { year: this.years[i], price: 0 };
        });
        const sorted = data.sort((a, b) => {
          return a.price - b.price;
        });
        const min = sorted[0].price;
        const max = sorted[sorted.length - 1].price;
        const list = [
          max,
          (max / 5) * 4,
          (max / 5) * 3,
          (max / 5) * 2,
          (max / 5) * 1,
          (min / 5) * 3,
        ];

        return { list, max, min };
      } else return { list: [0, 0, 0, 0, 0], max: 0, min: 0 };
    },
  },

  methods: {
    toggle(idx) {
      if (this.current !== null && this.current === idx) {
        this.current = null;
      } else {
        this.current = idx;
      }
    },
    listName(key) {
      const names = {
        area: "면적",
        landCategory: "지목",
        ladUseSittnNm: "이용상황",
        posesnSeCodeNm: "소유구분",
        ownshipChgDe: "소유권변동일자",
        ownshipChgCauseCodeNm: "소유권변동원인",
        roadSideCodeNm: "도로접면",
        tpgrphHgCodeNm: "지형높이",
        tpgrphFrmCodeNm: "지형형상",

        include: "포함",
        conflict: "저촉",
        join: "접합",
      };
      return names[key];
    },
  },
};
</script>

<style lang="scss" scoped>
.col-sm7 {
  flex: 0 0 11.9% !important;
  max-width: 11.9% !important;
}
</style>
