<template>
  <div>
    <header>
      <template v-if="userType === 'client'">
        <!-- to="/matching/upload" -->
        <b-btn variant="secondary" class="rounded-0 w-sm-100" @click="upload()"
          >비딩매칭 의뢰하러 가기 <i class="icon icon-next"></i>
        </b-btn>
      </template>
      <template v-if="userType === 'bidder'">
        <b-btn variant="secondary" class="rounded-0 w-sm-100" to="/matching"
          >비딩매칭 입찰하러 가기 <i class="icon icon-next"></i>
        </b-btn>
      </template>
    </header>
    <article>
      <template v-if="!items">
        <div class="text-center">
          <b-spinner variant="primary" class="my-5" />
        </div>
      </template>
      <template v-if="items && !items.length">
        <div class="py-4 text-center bg-light">비딩매칭 내역이 없습니다.</div>
      </template>

      <template v-else-if="items && items.length">
        <matching-items :items="items" />
      </template>
    </article>
    <div class="mt-5 d-flex flex-column" v-if="items && items.length">
      <b-pagination
        @change="getItem($event)"
        :value="page.number + 1"
        :total-rows="page.totalElements"
        :per-page="page.size"
        class="mx-auto"
        first-class="d-none"
        last-class="d-none"
        hide-ellipsis
      >
        <template #prev-text>
          <i class="icon icon-prev ml-1"></i>
        </template>
        <template #next-text>
          <i class="icon icon-next ml-1"></i>
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import Items from "@/components/Items/index";

export default {
  props: {
    userType: {
      type: String,
    },
  },
  components: {
    // BiddingItems: Items.Bidding,
    MatchingItems: Items.Matching,
  },
  data() {
    return {
      allItem: null,
      page: {
        page: 1,
      },
      currentState: null,
    };
  },
  computed: {
    query() {
      return this.$route.query.type;
    },
    items() {
      if (this.allItem) {
        if (this.query === "all") {
          return this.allItem;
        } else {
          return this.allItem.filter((r) => {
            return r.progress === this.query;
          });
        }
      } else return null;
    },
  },
  methods: {
    // 0816 추가
    upload() {
      if (
        this.$store.state.user &&
        this.$store.state.user.status === "WAITING"
      ) {
        window.alert(
          "아직 승인되지 않은 계정입니다. 승인 후 이용 가능한 서비스입니다."
        );
        return;
      } else if (
        this.$store.state.user &&
        this.$store.state.user.role === "FINANCE"
      ) {
        window.alert("입찰만 가능한 계정입니다. ");
        return;
      } else {
        this.$router.push("/matching/upload2");
      }
    },

    async getItem(n = 1) {
      try {
        const { data } = await this.$axios.get(
          `/bidding-history/${this.userType}`,
          {
            params: { page: n - 1 },
          }
        );
        if (data.status.code === "0000") {
          this.allItem = data.data.content;
          this.page = data.data.page;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCuurentState() {
      try {
        const { data } = await this.$axios.get(
          `/bidding-history/${this.userType}/current-state`
        );

        if (data.status.code === "0000") {
          this.currentState = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    async userType(type) {
      await this.getItem();
      await this.getCuurentState();
    },
  },
  async mounted() {
    await this.getItem();
    await this.getCuurentState();
  },
};
</script>

<style lang="scss" scoped></style>
