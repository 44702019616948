<template>
  <div>
    <b-row align-h="center" style="height: 100vh; width: 100vw;" align-v="center">
      <b-col lg="8" class="bg_img"></b-col>
      <b-col lg="4" style="background-color: cornflowerblue; height: 100%; display: flex; align-items: center;">
        <b-container ref="temp">
          <b-form @submit.prevent.stop="login">
            <b-form-group>
              <b-form-input
                v-model="input.serviceId"
                placeholder="아이디"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-form-input
                v-model="input.password"
                type="password"
                placeholder="비밀번호"
              ></b-form-input>
            </b-form-group>
            <div class="d-flex align-items-center">
              <b-form-group class="ml-auto text-gray">
                <b-form-checkbox v-model="input.autoLogin" :value="true">
                  자동로그인
                </b-form-checkbox>
              </b-form-group>
            </div>

            <div class="mt-3">
              <b-btn variant="primary" class="w-100" type="submit" size="lg">
                <span class="text-18">로그인</span>
              </b-btn>
            </div>
          </b-form>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import $ from 'jquery';
import router from '../router';

export default {
  data() {
    return {
      input: {
        loginType: "EMAIL",
        serviceId: "",
        password: "",
        autoLogin: false,
      },
      naverUrl: null,
    };
  },
  methods: {
    async login() {
      if (!this.input.serviceId.length) {
        if (!this.input.password.length) {
          window.alert("필수값들을 모두 입력해주세요.", {
            title: "필수 값 미입력",
          });
        }
      } else {
        router.push({ path: "/dashboard" });
        /*
        try {
          const { data } = await this.$axios.post("/auth/token", this.input);
          if (data.status.code === "1003") {
            window.alert("입력하신 아이디 혹은 비밀번호가 일치하지 않습니다.", {
              title: "계정 오류",
            });
          } else if (data.status.code === "4041") {
            window.alert("가입되지 않은 계정입니다.", {
              title: "가입되지 않은 계정",
            });
          } else if (data.status.code === "0000") {
            const loginData = {
              token: `${data.data.token_type} ${data.data.access_token}`,
              autoLogin: this.input.autoLogin,
            };
            // const token = `${data.data.token_type} ${data.data.access_token}`;
            this.getMe(loginData);

            this.$router.push("/Dashboard");
          }
        } catch (error) {
          window.alert(error);
        }
        */
      }
    },
  },
  mounted() {
    $("#gnb").hide();
    $("#footer").hide();
  },
};
</script>

<style lang="scss" scoped>
.bg_img {
  background-image: url("../assets/images/loginimg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  animation: 50s linear infinite alternate-reverse rotate;
  height: 100%;
}
</style>