<template>
  <div class="">
    <aside
      class="position-fixed d-none d-lg-block"
      :style="{
        top: '50%',
        right: '0',
        height: '200px',
        zIndex: '100',
      }"
    >
      <b-btn
        variant="primary"
        class="pl-1 pr-2 h-100"
        :style="{ borderRadius: '4rem 0 0 4rem' }"
      >
        <i class="icon icon-24 ml-n1 icon-sidebar-toggler"></i>
      </b-btn>
    </aside>
    <aside
      class="position-fixed d-block d-lg-none"
      :style="{
        top: '50%',
        right: '0',
        height: '48px',
        zIndex: '100',
      }"
    >
      <b-btn
        variant="primary"
        class="py-3 px-1 w-100"
        :style="{ borderRadius: '2rem 0 0 2rem' }"
      >
        <i class="icon icon-sidebar-toggler"></i>
      </b-btn>
    </aside>
  </div>
</template>

<script>
export default {
  data() {
    return {
      floatWidth: 40,
      space: 0,
    };
  },
  methods: {
    // resizeEventListener() {
    //   const max = window.innerWidth;
    //   const container = document.getElementsByClassName("container")[0]
    //     .clientWidth;
    //   this.space = (max - container) / 2;
    // },
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     window.addEventListener("resize", this.resizeEventListener);
  //     this.resizeEventListener();
  //   });
  // },
  computed: {
    // right() {
    //   return (
    //     Math.floor(
    //       this.space < this.floatWidth ? 6 : this.space - this.floatWidth
    //     ) + "px"
    //   );
    // },
    meta() {
      return this.$route.meta;
    },
  },
};
</script>

<style lang="scss" scoped></style>
