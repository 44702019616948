<template>
  <b-modal
    id="modal-excel-upload"
    title="엑셀 업로드"
    ok-title="등록"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="primary m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="xl"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidht: '1200px', maxHeight: '1000px' }"
    >
      <div id="btn_bar">
        <template>
          <div class="upload-btn">
            <!--
            <label for="file_input">
              <span class="ti ti-upload"> 파일 업로드</span>
            </label>
            <input type="file" id="__port_scan_excel_file" @change="onChange" :key="fileKey" style="display: none;" />
            -->
            <label for="file_input">
              <span class="ti ti-upload"> 파일 업로드</span>
            </label>
            <input id="file_input" type="file" style="display: none;" @change="readFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
          </div>
        </template>
      </div>
      <div>
        <b-table v-if = "file_type === 'station_list'"
          id = "station_table"
          striped hover 
          :items="excelJsonData"
          :fields="stationFields"
          small
        >
          <template #cell(No)="row">
            <div>
              {{ row.index + 1 }}
            </div>
          </template>
          <template #cell(name)="row">
            <div>
              {{ excelJsonData[row.index].충전소명 }}
            </div>
          </template>
          <template #cell(sid)="row">
            <div>
              {{ excelJsonData[row.index].충전소ID }}
            </div>
          </template>
          <template #cell(use_time)="row">
            <div>
              {{ excelJsonData[row.index].이용시간 }}
            </div>
          </template>
          <template #cell(kind)="row">
            <div>
              {{ excelJsonData[row.index].구분코드 }}
            </div>
          </template>
          <template #cell(kind_dtl)="row">
            <div>
              {{ excelJsonData[row.index].상세구분코드 }}
            </div>
          </template>
          <template #cell(addr)="row">
            <div>
              {{ excelJsonData[row.index].주소 }}
            </div>
          </template>
          <template #cell(addr_dtl)="row">
            <div>
              {{ excelJsonData[row.index].상세주소 }}
            </div>
          </template>
          <template #cell(reg_date)="row">
            <div>
              {{ excelJsonData[row.index].등록일 }}
            </div>
          </template>
          <template #cell(bid)="row">
            <div>
              {{ excelJsonData[row.index].업체코드 }}
            </div>
          </template>
          <template #cell(bname)="row">
            <div>
              {{ excelJsonData[row.index].업체명 }}
            </div>
          </template>
          <template #cell(bcall)="row">
            <div>
              {{ excelJsonData[row.index].연락처 }}
            </div>
          </template>
          <template #cell(note)="row">
            <div>
              {{ excelJsonData[row.index].특이사항 }}
            </div>
          </template>
          <template #cell(year)="row">
            <div>
              {{ excelJsonData[row.index].설치일자 }}
            </div>
          </template>
          <template #cell(limit_yn)="row">
            <div>
              {{ excelJsonData[row.index].제한유무 }}
            </div>
          </template>
          <template #cell(limit_dtl)="row">
            <div>
              {{ excelJsonData[row.index].제한유무_상세 }}
            </div>
          </template>
          <template #cell(del_yn)="row">
            <div>
              {{ excelJsonData[row.index].삭제유무 }}
            </div>
          </template>
          <template #cell(del_dtl)="row">
            <div>
              {{ excelJsonData[row.index].삭제유무_상세 }}
            </div>
          </template>
          <template #cell(fee_id)="row">
            <div>
              {{ excelJsonData[row.index].요금제ID }}
            </div>
          </template>
          <template #cell(bis_id)="row">
            <div>
              {{ excelJsonData[row.index].bis_id }}
            </div>
          </template>
        </b-table>
        <b-table v-else-if = "file_type === 'charger_list'"
          id = "charger_table"
          striped hover 
          :items="excelJsonData"
          :fields="chargerFields"
          small
        >
        <template #cell(No)="row">
            <div>
              {{ row.index + 1 }}
            </div>
          </template>
          <template #cell(name)="row">
            <div>
              {{ excelJsonData[row.index].충전소명 }}
            </div>
          </template>
          <template #cell(sid)="row">
            <div>
              {{ excelJsonData[row.index].충전소ID }}
            </div>
          </template>
          <template #cell(use_time)="row">
            <div>
              {{ excelJsonData[row.index].이용시간 }}
            </div>
          </template>
          <template #cell(kind)="row">
            <div>
              {{ excelJsonData[row.index].구분코드 }}
            </div>
          </template>
          <template #cell(kind_dtl)="row">
            <div>
              {{ excelJsonData[row.index].상세구분코드 }}
            </div>
          </template>
          <template #cell(addr)="row">
            <div>
              {{ excelJsonData[row.index].주소 }}
            </div>
          </template>
          <template #cell(addr_dtl)="row">
            <div>
              {{ excelJsonData[row.index].상세주소 }}
            </div>
          </template>
          <template #cell(reg_date)="row">
            <div>
              {{ excelJsonData[row.index].등록일 }}
            </div>
          </template>
          <template #cell(bid)="row">
            <div>
              {{ excelJsonData[row.index].업체코드 }}
            </div>
          </template>
          <template #cell(bname)="row">
            <div>
              {{ excelJsonData[row.index].업체명 }}
            </div>
          </template>
          <template #cell(bcall)="row">
            <div>
              {{ excelJsonData[row.index].연락처 }}
            </div>
          </template>
          <template #cell(note)="row">
            <div>
              {{ excelJsonData[row.index].특이사항 }}
            </div>
          </template>
          <template #cell(year)="row">
            <div>
              {{ excelJsonData[row.index].설치일자 }}
            </div>
          </template>
          <template #cell(limit_yn)="row">
            <div>
              {{ excelJsonData[row.index].제한유무 }}
            </div>
          </template>
          <template #cell(limit_dtl)="row">
            <div>
              {{ excelJsonData[row.index].제한유무_상세 }}
            </div>
          </template>
          <template #cell(del_yn)="row">
            <div>
              {{ excelJsonData[row.index].삭제유무 }}
            </div>
          </template>
          <template #cell(del_dtl)="row">
            <div>
              {{ excelJsonData[row.index].삭제유무_상세 }}
            </div>
          </template>
          <template #cell(fee_id)="row">
            <div>
              {{ excelJsonData[row.index].요금제ID }}
            </div>
          </template>
          <template #cell(bis_id)="row">
            <div>
              {{ excelJsonData[row.index].bis_id }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import XLSX from 'xlsx';

export default {
  components: {
    
  },
  props: {
    file_type: String
  },
  data() {
    return {
      excelJsonData: [],
      stationFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "name",
          label: "충전소명"
        },
        {
          key: "sid",
          label: "충전소ID"
        },
        {
          key: "use_time",
          label: "이용시간"
        },
        {
          key: "kind",
          label: "구분코드"
        },
        {
          key: "kind_dtl",
          label: "상세구분코드"
        },
        {
          key: "addr",
          label: "주소"
        },
        {
          key: "addr_dtl",
          label: "상세주소"
        },
        {
          key: "reg_date",
          label: "등록일"
        },
        {
          key: "bid",
          label: "업체코드"
        },
        {
          key: "bname",
          label: "업체명"
        },
        {
          key: "bcall",
          label: "연락처"
        },
        {
          key: "note",
          label: "특이사항"
        },
        {
          key: "year",
          label: "설치일자"
        },
        {
          key: "limit_yn",
          label: "제한유무"
        },
        {
          key: "limit_dtl",
          label: "제한유무_상세"
        },
        {
          key: "del_yn",
          label: "삭제유무"
        },
        {
          key: "del_dtl",
          label: "삭제유무_상세"
        },
        {
          key: "fee_id",
          label: "요금제ID"
        },
        {
          key: "bis_id",
          label: "BIS ID"
        },
      ],
      chargerFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "station_name",
          label: "충전소명"
        },
        {
          key: "station_id",
          label: "충전소 ID"
        },
        {
          key: "use_time",
          label: "이용시간"
        },
        {
          key: "station_kind",
          label: "충전소 구분"
        },
        {
          key: "fee_name",
          label: "요금제명"
        },
        {
          key: "addr_name",
          label: "주소"
        },
        {
          key: "reg_date",
          label: "등록일"
        },
        {
          key: "station_status",
          label: "상태"
        },
      ],
    };
  },
  mounted() {
    // this.init()
  },
  methods: {
    readFile(event) {
      const file = event.target.files[0];
      let reader = new FileReader();
      let tmpResult = {};
      reader.onload = (e) => {
          let data = reader.result;
          let workbook = XLSX.read(data, {type: 'binary'});
          workbook.SheetNames.forEach(sheetName => {
              const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
              tmpResult = roa;
          });
          console.log(tmpResult);
          this.excelJsonData=tmpResult;
      };
      reader.readAsBinaryString(file);
    },
    async ok() {
      try {
        var add_type_url = "";

        if (this.file_type === 'station_list') {
          add_type_url = "/api/addStationList";
        } else if (this.file_type === 'charger_list') {
          add_type_url = "/api/addChargerList";
        }

        const { data } = await this.$axios.post(
          add_type_url, {
            items: excelJsonData
          }
        );

        window.alert("등록되었습니다.");
        this.$bvModal.hide("modal-excel-upload");
      } catch (error) {
        window.alert("등록을 실패했습니다.");
        this.$bvModal.hide("modal-excel-upload");
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-btn {
  position: relative;
  float: right;
  margin-left: auto;
  label {
    display: flex;
    border: 1px solid black;
    cursor: pointer;
    background-color: #ffffff;
    color: black;
    font-weight: 400;
    border-radius: 2px;
    padding: 5px;
    &:hover {
      background-color: #1c6b1f;
      color: #ffffff;
      opacity: 0.9;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
    }
  }
}
</style>
