<template>
  <b-modal
    id="modal-image"
    title="매매계약서"
    ok-title="닫기"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="primary m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="xl"
    @shown="init"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxHeight: '800px' }"
      v-if="content"
    >
      <div id="pdf-container">
        <vue-pdf-viewer class="w-100" height="1000px" :url="content" />
      </div>
    </div>
    <div class="pb-3 text-center" v-else>
      <b-spinner variant="primary"></b-spinner>
    </div>
  </b-modal>
</template>

<script>
import VuePdfViewer from "vue-instant-pdf-viewer";
export default {
  components: {
    VuePdfViewer,
  },
  props: {
    url: {
      type: String,
      default: null,
    },

    type: String,
  },
  data() {
    return {
      content: null,
    };
  },
  mounted() {
    // this.init()
  },
  methods: {
    async init() {
      this.content = this.url;
      console.log(this.content);

      if (this.PDFObject) {
        this.PDFObject.embed(this.content, "#pdf-container", {
          forceIframe: true,
        });
      }
    },
    ok() {
      this.$bvModal.hide("modal-image");
      this.$root.$emit("hb::pdf::hidden", true);
    },
  },
};
</script>

<style lang="scss" scoped></style>
