<template>
  <b-modal
    id="modal-grade2"
    title="평점 작성"
    ok-title="확인"
    @ok="ok"
    cancelTitle="취소"
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="primary m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18 mx-n1'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="sm"
  >
    <div class="text-center">
      <b-form-radio-group
        v-model="grade"
        buttons
        button-variant="outline-primary rounded-0 w-50"
      >
        <b-form-radio
          v-for="(item, i) in options"
          :value="item.value"
          :key="i"
          :style="{ marginLeft: '-1px', marginRight: '-1px' }"
        >
          {{item.text}}
        </b-form-radio>
      </b-form-radio-group>
    </div>
    <div class="my-3 text-center text-nowrap">
      <strong class="text-secondary text-24">{{ grade }}</strong>
      <span class="ml-1 mr-0 fw-400  text-17 text-light-gray">
        
      </span>
    </div>
  </b-modal>
</template>

<script>
export default {
  // computed: {
  //   idBidding() {
  //     return this.$route.meta.bidding_id;
  //   },
  // },
  props: {
    value: {
      type: String,
    },
    idBidder: {
      type: Number,
    },
    idBidding: {
      type: Number,
    },
    type: {
      type: String,
    }
  },
  watch: {
    value(n) {
      this.grade = n;
      // console.log(n);
    },
  },
  data() {
    return {
      grade: null,
      options: [
        {
          text: "상",
          value: "A",
        },
        {
          text: "중",
          value: "B",
        },
        {
          text: "하",
          value: "C",
        }
      ],
    };
  },
  methods: {
    async ok() {
      if(!this.type){ //타입에 값이 있을 때 없을 때 판별
        const mypageStatus = this.$store.state.user.mypageStatus.toLowerCase();

        //입찰자 
        if(mypageStatus === 'bidder'){  
          this.type = 'client'

        //의뢰자  
        }else if(mypageStatus === 'client'){ 
          this.type = 'bidder'
        }
      }
      try {
        const { data } = await this.$axios.put(
          `/bidding/${this.idBidding}/${this.type}/${this.idBidder}/update-grade2?grade=${this.grade}`
        );
        if (data.status.code === "0000") {
          const bool = await window.alert("평점 작성이 완료되었습니다.");
          if (bool) {
            window.location.reload();
          }
        } else {
          window.alert(data.status.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // async ok() {
    //   try {
    //     const { data } = await this.$axios.put(
    //       `/bidding/${this.idBidding}/bidder/${this.idBidder}/update-grade?grade=${this.grade}`
    //     );
    //     if (data.status.code === "0000") {
    //       const bool = await window.alert("평점 작성이 완료되었습니다.");
    //       if (bool) {
    //         window.location.reload();
    //       }
    //     } else {
    //       window.alert(data.status.message);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped></style>
