<template>
  <b-container>
    <div style="margin-top: 50px;" v-if="item">
      <span class="text-17 text-danger" v-if="item.caution">
        <font-awesome-icon icon="fa-solid fa-asterisk" /> {{item.caution}}
        <hr>
      </span>
      <h5>등기부 등본 요약 내역</h5>
      <div v-if="item.registerCountMeta" style="margin-bottom: 20px">
        <div>압류 건수 : {{ item.registerCountMeta.seizure }}</div>
        <div>가압류 건수 : {{ item.registerCountMeta.proSeizure }}</div>
        <div>
          강제 or 임의 경매 개시결정 건수 :
          {{ item.registerCountMeta.auction }}
        </div>
      </div>
      <div v-else style="margin-bottom: 20px">
        <div>
          <strong>조회 내용 없음</strong>
        </div>
      </div>
      <h5>가압류 요약 내용</h5>
      <div v-if="regsiter && regsiter.length" style="margin-bottom: 20px">
        <ul style="list-style: none; display: flex">
          <div
            class="fw-700 text-gray text-truncate"
            style="margin-right: 50px"
            v-for="(item, i) in regsiter"
            :key="i"
          >
            <li>{{ `대상 소유자 : ${item.owner}` }}</li>
            <li>{{ `청구 금액 : ${item.billPrice}` }}</li>
            <li>{{ `채권자 : ${item.creditor}` }}</li>
            <li>{{ `접수정보 : ${item.receiveInfo}` }}</li>
            <li>{{ `접수일자 : ${item.receiveDate}` }}</li>
          </div>
        </ul>
      </div>
      <div v-else style="margin-bottom: 20px">
        <div>
          <strong>조회 내용 없음</strong>
        </div>
      </div>
      <h5>(근)저당권 및 전세권 등 ( 을구 )</h5>
      <div v-if="mortgage && mortgage.length" style="margin-bottom: 20px">
        <span class="text-17 text-danger">
          <font-awesome-icon icon="fa-solid fa-asterisk" /> 동일 금융사는 동순위로 봅니다.
        </span>
        <ul style="list-style: none; display: flex">
          <div
            class="fw-700 text-gray text-truncate"
            style="margin-right: 50px"
            v-for="(item, i) in mortgage"
            :key="i"
          >
            <li>{{ `순위번호 : ${item.ranking}` }}</li>
            <li>{{ `등기목적 : ${item.cont}` }}</li>
            <li>{{ `대출 원금 : ${item.bondAmount}` }}</li>
            <li>{{ `채권 최고액 : ${item.highBondAmount}` }}</li>
            <li>{{ `설정율(%): ${item.persent}` }}</li>
            <li>{{ `근저당권자 : ${item.mortgageHolder ? item.mortgageHolder : ""}` }}</li>
            <li>{{ `채무자 : ${item.owner}` }}</li>
          </div>
        </ul>
      </div>
      <div v-else style="margin-bottom: 20px">
        <div>
          <strong>조회 내용 없음</strong>
        </div>
      </div>
      <div v-if="item">
        <span>
          {{
            `채권 원금 합계 : ${
              item.totalBondAmount == null
                ? "없음"
                : item.totalBondAmount == -1
                ? "직접 확인 요망"
                : item.totalBondAmount
            }`
          }}
        </span>
        <span>
          {{
            `채권 최고액 합계 : ${
            item.totalHighBondAmount == null
              ? "없음"
              : item.totalHighBondAmount == -1
              ? "직접 확인 요망"
              : item.totalHighBondAmount
            }`
          }}
        </span>
      </div>
      <hr>
    </div>
    <b-row align-h="center">
      <b-col cols="12" md="4" lg="3" class="px-lg-0 pt-md-5">
        <header class="py-4 py-md-5 text-center">
          <h2 class="mb-3 text-18 text-lg-24">비딩 매칭 입찰</h2>
          <span class="text-14 text-lg-15 text-gray">
            비딩 매칭 입찰을 하실 수 있습니다.
          </span>
        </header>
        <article class="mt-3 mb-5 pb-5">
          <b-form @submit.prevent.stop="bidding">
            <section>
              <!-- 순위 -->

              <b-form-group
                class="mb-4 position-relative"
                label="순위*"
                :state="rankNumber"
                label-class="text-primary text-13"
                variant="outline"
              >
                <b-form-select
                  v-model="input.rank"
                  :style="{ backgroundPosition: 'right 4rem center' }"
                >
                  <option :value="null" selected>순위를 선택해주세요.</option>
                  <option v-for="item in rankLimit" :value="item" :key="item">
                    {{ item }}
                  </option>
                </b-form-select>
                <span
                  class="
                    position-absolute
                    mr-2
                    p-2
                    rounded-lg
                    bg-light-gray
                    opacity-5
                    text-gray text-14
                  "
                  :style="{
                    top: '50%',
                    right: '0',
                    transform: 'translateY(-50%)',
                  }"
                  >순위</span
                >
              </b-form-group>
              <!-- 한도 -->
              <b-form-group
                class="mb-4 position-relative"
                label="한도*"
                label-class="text-primary text-13"
                variant="outline"
              >
                <b-form-input
                  type="text"
                  v-model="input.limitAmount"
                  @input.native="$inputPrice($event, 'limitAmount')"
                  placeholder="한도"
                  :style="{
                    paddingRight: '20%',
                  }"
                ></b-form-input>
                <span
                  class="
                    position-absolute
                    mr-2
                    p-2
                    rounded-lg
                    bg-light-gray
                    opacity-5
                    text-gray text-14
                  "
                  :style="{
                    top: '50%',
                    right: '0',
                    transform: 'translateY(-50%)',
                  }"
                  >만원</span
                >
              </b-form-group>
              <!-- 금리 -->
              <b-form-group
                class="mb-4 position-relative"
                label="금리*"
                label-class="text-primary text-13"
                variant="outline"
              >
                <b-form-input
                  type="text"
                  v-model="input.interestRate"
                  @input.native="$inputDecimal($event, 'interestRate', 20)"
                  placeholder="금리"
                  :style="{
                    paddingRight: '20%',
                  }"
                ></b-form-input>
                <span
                  class="
                    position-absolute
                    mr-2
                    p-2
                    rounded-lg
                    bg-light-gray
                    opacity-5
                    text-gray text-14
                  "
                  :style="{
                    top: '50%',
                    right: '0',
                    transform: 'translateY(-50%)',
                  }"
                  >%</span
                >
              </b-form-group>
              <!-- 수당 -->
              <b-form-group
                class="mb-4 position-relative"
                label="수당*"
                label-class="text-primary text-13"
                variant="outline"
              >
                <b-form-input
                  v-model="input.benefit"
                  placeholder="수당"
                  step="0.1"
                  @input.native="$inputDecimal($event, 'benefit', 9.9)"
                  :style="{
                    paddingRight: '20%',
                  }"
                ></b-form-input>
                <span
                  class="
                    position-absolute
                    mr-2
                    p-2
                    rounded-lg
                    bg-light-gray
                    opacity-5
                    text-gray text-14
                  "
                  :style="{
                    top: '50%',
                    right: '0',
                    transform: 'translateY(-50%)',
                  }"
                  >%</span
                >
              </b-form-group>

              <b-form-group
                class="mb-4 position-relative"
                label="비용(플랫폼 수수료, 중도상환 수수료)*"
                label-class="text-primary text-13"
                variant="outline"
              >
                <b-row>
                  <b-col>
                    <b-form-input
                      v-model="input.platformCommission"
                      placeholder="플랫폼 수수료"
                      @input.native="
                        $inputDecimal($event, 'platformCommission', 10)
                      "
                      :style="{
                        paddingRight: '20%',
                      }"
                    ></b-form-input>
                    <span
                      class="
                        position-absolute
                        mr-3
                        p-2
                        rounded-lg
                        bg-light-gray
                        opacity-5
                        text-gray text-14
                      "
                      :style="{
                        top: '50%',
                        right: '0',
                        transform: 'translateY(-50%)',
                      }"
                      >%</span
                    >
                  </b-col>
                  <b-col>
                    <b-form-input
                      v-model="input.prepaymentCommission"
                      placeholder="중도상환 수수료"
                      @input.native="
                        $inputDecimal($event, 'prepaymentCommission', 10)
                      "
                      :style="{
                        paddingRight: '20%',
                      }"
                    ></b-form-input>
                    <span
                      class="
                        position-absolute
                        mr-3
                        p-2
                        rounded-lg
                        bg-light-gray
                        opacity-5
                        text-gray text-14
                      "
                      :style="{
                        top: '50%',
                        right: '0',
                        transform: 'translateY(-50%)',
                      }"
                      >%</span
                    >
                  </b-col>
                </b-row>
              </b-form-group>

              <!-- 플랫폼 수수료 -->
              <!-- <b-form-group
                class="mb-4 position-relative"
                label="플랫폼 수수료*"
                label-class="text-primary text-13"
                variant="outline"
              >
                <b-form-input
                  v-model="input.platformCommission"
                  placeholder="플랫폼 수수료"
                  @input.native="
                    $inputDecimal($event, 'platformCommission', 10)
                  "
                  :style="{
                    paddingRight: '20%',
                  }"
                ></b-form-input>
                <span
                  class="
                    position-absolute
                    mr-2
                    p-2
                    rounded-lg
                    bg-light-gray
                    opacity-5
                    text-gray text-14
                  "
                  :style="{
                    top: '50%',
                    right: '0',
                    transform: 'translateY(-50%)',
                  }"
                  >%</span
                >
              </b-form-group> -->
              <!-- 중도상환 수수료 -->
              <!-- <b-form-group
                class="mb-4 position-relative"
                label="중도상환 수수료*"
                label-class="text-primary text-13"
                variant="outline"
              >
                <b-form-input
                  v-model="input.prepaymentCommission"
                  placeholder="중도상환 수수료"
                  @input.native="
                    $inputDecimal($event, 'prepaymentCommission', 10)
                  "
                  :style="{
                    paddingRight: '20%',
                  }"
                ></b-form-input>
                <span
                  class="
                    position-absolute
                    mr-2
                    p-2
                    rounded-lg
                    bg-light-gray
                    opacity-5
                    text-gray text-14
                  "
                  :style="{
                    top: '50%',
                    right: '0',
                    transform: 'translateY(-50%)',
                  }"
                  >%</span
                >
              </b-form-group> -->
            </section>
            <section class="mt-5 py-5 d-flex flex-column">
              <b-btn variant="primary" class="w-100 mb-2 py-3" @click="bidding"
                >입찰</b-btn
              >
              <b-btn
                variant="outline-primary"
                class="w-100 py-3"
                :to="`/matching/${this.idBidding}/`"
                >취소</b-btn
              >
            </section>
          </b-form>
        </article>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      regsiter: null,
      mortgage: null,
      item: null,
      rankLimit: 9,
      input: {
        rank: null,
        limitAmount: null,
        minInterestRate: null,
        maxInterestRate: null,
        benefit: null,
        platformCommission: null,
        prepaymentCommission: null,
      },
    };
  },
  computed: {
    idBidding() {
      return this.$route.params.matching_id;
    },
    rankNumber() {
      return this.input.rank && this.input.rank >= 1;
    },
  },
  methods: {
    //가압류 내용
    async getRegsiter() {
      try {
        const { data } = await this.$axios.get(
          `/bidding/${this.idBidding}/regsiter`
        );
        if (data.status.code === "0000") {
          this.regsiter = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //근저당권 상세내용
    async getmortgage() {
      try {
        const { data } = await this.$axios.get(
          `/bidding/${this.idBidding}/mortgage`
        );
        if (data.status.code === "0000") {
          this.mortgage = data.data.sort(function(a,b){
            return a.ranking < b.ranking ? -1 : a.ranking > b.ranking ? 1 : 0;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getBidding() {
      try {
        const { data } = await this.$axios.get(
         `/bidding/register/${this.idBidding}/bidder`
        );
        if (data.status.code === "0000") {
          this.item = data.data;
          console.log(data.data)
        }
      } catch (error) {
        console.log(error);
      }
    },
    async bidding() {
      const form = {
        ...this.input,
        limitAmount: this.priceToNumber(this.input.limitAmount),
      };
      if (
        !form.rank ||
        !form.limitAmount ||
        !form.interestRate ||
        !form.benefit ||
        !form.platformCommission ||
        !form.prepaymentCommission
      ) {
        window.alert("필수 값들을 모두 입력해주세요.", {
          title: "필수 값 미입력",
        });
      } else if (form.rank < 1) {
        window.alert("순위는 1~9까지 입력해야합니다.", {
          title: "필수 값 오입력",
        });
      } else {
        try {
          const { data } = await this.$axios.post(
            `/bidding/${this.idBidding}/bidder`,
            form
          );
          if (data.status.code === "0000") {
            this.$router.push(`/matching/${this.idBidding}`);
            window.alert("입찰이 정상처리 되었습니다.");
          } else if (data.status.code === "4030") {
            const bool = await window.alert(
              `더 입찰 참여가 불가능합니다.(최대 3회)`,
              {
                title: "입찰 불가",
              }
            );
            if (bool) {
              this.$router.push(`/matching/${this.idBidding}`);
            }
          } else {
            window.alert(data.status.message);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  async mounted() {
    await this.getBidding();
    await this.getRegsiter();
    await this.getmortgage();
  }
};
</script>

<style lang="scss" scoped></style>
