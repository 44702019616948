<template>
  <b-modal
    id="modal-add-comment"
    title="코멘트 등록"
    ok-variant="primary m-0 ml-1 p-3"
    ok-title="확인"
    cancelTitle="닫기"
    cancelVariant="outline-primary m-0 p-3"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    contentClass="confirm border-0 fw-400"
    footerClass="w-100 d-flex align-items-center justify-content-between p-1 border-0 btn-group"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="sm"
  >
    <div class="text-center">
      <span>코멘트를 입력해주세요</span>
      <b-textarea
        class="mt-3 rounded boorder border-light-gray"
        v-model="content"
        placeholder="코멘트를 입력해주세요"
        rows="4"
      ></b-textarea>
    </div>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between btn-group flex-nowrap">
        <b-btn
          variant="outline-primary m-0 py-3 w-50 btn-block"
          @click="cancel()"
        >
          닫기
        </b-btn>
        <b-btn
          variant="primary m-0 ml-1 py-3 w-50 btn-block"
          :disabled="!content"
          @click="ok"
          >확인</b-btn
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  computed: {
    idBidding() {
      return this.$route.params.matching_id;
    },
  },
  data() {
    return {
      content: null,
    };
  },
  methods: {
    async ok() {
      if (!this.content) {
        await window.alert("코멘트 내용은 필수로 입력해야 합니다.", {
          title: "필수 입력",
        });
        window.location.reload();
      } else {
        try {
          const { data } = await this.$axios.post(
            `/bidding/${this.idBidding}/comments`,
            {
              // modal로 하는 일반 코멘트 등록은 type을 'CLIENT(의뢰자)'로 보낸다.
              type: "CLIENT",
              content: this.content,
            }
          );
          if (data.status.code === "0000") {
            const bool = await window.alert("코멘트가 등록되었습니다.");
            if (bool) {
              this.$root.$emit("hb::comment:added");
            }
          } else {
            window.alert(data.status.message);
          }
        } catch (error) {}
      }
    },
    cancel() {
      this.content = "";
      this.$bvModal.hide("modal-add-comment");
    },
  },
};
</script>

<style lang="scss" scoped></style>
