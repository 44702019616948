<template>
  <div class="" style="height: inherit;" ref="element" v-resize @resize="onResize">
    <b-container>
      <b-row style="margin-bottom: 10px">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span style="flex: 1;">카드상태</span>
          <b-form-select size="sm" style="flex: 5;" v-model="filterData.searchStatus" @change="filterSet" :options="searchStatusOption"></b-form-select>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span style="flex: 1;">검색조건</span>
            <b-form-select size="sm" style="flex: 1;" v-model="filterData.searchKeyType" @change="filterSet" :options="searchKeyOption"></b-form-select>
            <b-form-input 
              size="sm"
              v-model="filterData.searchKey"
              style="flex: 4;"
              @change="filterSet"
            ></b-form-input>
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px">
        <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
          <span>전체 {{ gridFilterData.length }} 건</span>
        </b-col>
        <b-col lg=6>
          
        </b-col>
      </b-row>
      <b-row :style="{'height': page_grid_height + 'px'}">
        <div class="">
          <b-table 
            id = "grid"
            striped hover 
            :items="gData"
            :fields="gridFields"
            small
          >
            <template #cell(No)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ row.index + 1 + ((currentPage - 1) * perPage) }}
              </div>
            </template>
            <template #cell(user_id)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].user_id }}
              </div>
            </template>
            <template #cell(name)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].name }}
              </div>
            </template>
            <template #cell(status)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].status }}
              </div>
            </template>
            <template #cell(addr)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].addr }}
              </div>
            </template>
            <template #cell(phone)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].phone }}
              </div>
            </template>
            <template #cell(card_select)>
              <b-button size="sm" @click="cardSelect" class="default_btn">등록</b-button>
            </template>
          </b-table>
        </div>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="gridFilterData.length"
          :per-page="perPage"
          first-text="⏮"
          prev-text="⏪"
          next-text="⏩"
          last-text="⏭"
          class="mt-4"
          size="sm"
        ></b-pagination>
      </b-row>

      <!-- modals -->
      <!--<modal-excel-upload :file_type="file_type"/>-->
      <modal-card-select ref="modal_cardSelect" :obj="obj" />
    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    
    // Modals
    //ModalExcelUpload: Modals.ExcelUpload,
    ModalCardSelect: Modals.CardSelect,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      formValid: false,
      salt: 0,
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      select_index: -1,
      select_rowData: {
        user_id : "",
        type : "",
        card_no : "",
        card_stop_yn : "",
        send_date : "",
        receive_date : "",
        lost_date : "",
        status : "",
        name : "",
        company_id : "",
        company_name : "",
        phone : "",
        addr : "",
        stop_date : "",
        use_yn : "",
        authority : "",
        payment_card_no : "",
        reg_user : "",
        reg_date : "",
      },
      perPage: 1,
      currentPage: 1,
      gridDefaultData: [

      ],
      gridFilterData: [

      ],
      gData: [

      ],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "user_id",
          label: "회원 ID"
        },
        {
          key: "name",
          label: "회원명"
        },
        {
          key: "status",
          label: "상태"
        },
        {
          key: "addr",
          label: "주소"
        },
        {
          key: "phone",
          label: "전화번호"
        },
        {
          key: "card_select",
          label: "카드등록"
        },
      ],
      filterData: {
        searchKeyType: "all",
        searchKey: "",
        searchStatus: "all",
      },
      searchKeyOption: [
        { text: "전체", value: "all" },
        { text: "회원ID", value: "user_id" },
        { text: "회원명", value: "name" },
        { text: "카드번호", value: "card_no" }
      ],
      searchStatusOption: [
        { text: "전체", value: "all" },
        { text: "사용", value: "use" },
        { text: "분실", value: "lost" },
        { text: "정지", value: "stop" },
        { text: "재발급", value: "reIssuance" },
        { text: "발송중", value: "send" },
        { text: "발송대기", value: "match" }
      ],
      file_type: "userCard_list",
      rules: {
        
      },
      excelUp_show: false,
      tranHistory_show: false,
      obj: {
        title: "회원 상세정보",
        ok_title: "확인",
        userCard_data: {
          user_id : "",
          type : "",
          card_no : "",
          card_stop_yn : "",
          send_date : "",
          receive_date : "",
          lost_date : "",
          status : "",
          name : "",
          company_id : "",
          company_name : "",
          phone : "",
          addr : "",
          stop_date : "",
          use_yn : "",
          authority : "",
          payment_card_no : "",
          reg_user : "",
          reg_date : "",
        }
      },
    };
  },
  computed: {
    
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      let items = this.gridFilterData;

      this.selectPage(newVal);
    },
  },
  methods: {
    async selectUserCardList() {
      console.log("회원카드 발급관리 페이지");
      try {
        const { data } = await this.$axios.post(
          "/api/selectUserCardIssuance", {
            //use_yn : "Y",
            //type : "C"
          }
        );
        if (data.length > 0) {
          this.gData = data;
          this.gridDefaultData = data;
          this.filterSet();
        } else if (data.length == 0) {
          this.gData = null;
          window.alert("데이터가 없습니다.");
        }

        this.pageSet();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    cardSelect() {
      this.obj.title = "회원카드 등록";
      this.obj.ok_title = "등록";
      this.obj.userCard_data = this.select_rowData;
      this.$bvModal.show("modal-card-select");
    },
    clipboardCopy(index) {
      var copy_data = JSON.stringify(this.gData[index]);
      window.navigator.clipboard.writeText(copy_data).then( function (res) {alert("복사되었습니다.");} );
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    filterSet() {
      let search_key = this.filterData.searchKey;
      let search_type = this.filterData.searchType;
      let search_status = this.filterData.searchStatus;
      let temp_data = [];

      //this.gridDefaultData.

      if (search_key != "") {
        if (search_type === "all") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.user_id.indexOf(search_key) != -1 || e.name.indexOf(search_key) != -1 || e.card_no.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "user_id") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.user_id.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "name") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.name.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "card_no") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.card_no.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        }

        this.gridFilterData = temp_data;
      } else {
        this.gridFilterData = this.gridDefaultData;
      }

      if (search_status != "all") {
        temp_data = this.gridFilterData.filter(
          function (e) {
            if (e.status.indexOf(search_status) != -1) {
              return true;
            }
          }
        )

        this.gridFilterData = temp_data;
      }

      this.pageSet();
    },
    selectRow(row_index) {
      var tbody = document.querySelector("#grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;

      if (this.select_index == -1) {
        this.select_index = row_index;
        this.select_rowData = this.gData[row_index];

        trs[row_index + 1].style.backgroundColor = "yellow";
      } else if (this.select_index > -1) {
        if (this.select_index === row_index) {
          this.select_index = -1;
          
          this.select_rowData = {};

          trs[selectIndex].style.backgroundColor = "white";
        } else {
          this.select_index = row_index;
          this.select_rowData = this.gData[row_index];

          trs[selectIndex].style.backgroundColor = "white";
          trs[row_index + 1].style.backgroundColor = "yellow";
        }
      }
    },
    onResize(e) {
      if (this.crt_width == 0 & this.crt_height == 0) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        //this.pageSet();
      } else if (this.crt_width != e.detail.width || this.crt_height != e.detail.height) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        this.pageSet();  
      }
    },
    pageSet() {
      var filter_height = 60;
      var button_height = 60;
      var pagination_height = 50;
      var grid_header_height = 60;
      var grid_row_height = 65;
      var grid_data_height = this.crt_height - filter_height - button_height - pagination_height - grid_header_height - 40;
      this.page_grid_height = grid_data_height;

      var page_row_count = Math.floor(grid_data_height / grid_row_height);
      var max_page_num = Math.ceil(this.gridFilterData.length / page_row_count);

      this.perPage = page_row_count;

      this.selectPage(1);
    },
    selectPage(page) {
      let temp_grid_data = this.gridFilterData;

      this.gData = temp_grid_data.slice(
        (page - 1) * this.perPage, page * this.perPage
      )
    },
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
  async mounted() {
    this.selectUserCardList();
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>