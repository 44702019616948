<template>
  <div>
    <header class="mb-3">
      <h2 class="mb-2 text-28 text-lg-38">1:1 문의</h2>
      <span class="text-14 text-gray fw-400"
        >1:1 문의를 확인하실 수 있습니다.</span
      >
    </header>
    <section>
      <div
        :style="{ borderTop: '3px solid #333', borderBottom: '3px solid #333' }"
      >
        <b-row align-v="center" class="mx-0 border-bottom border-gray">
          <b-col cols="12" md="2" class="px-0 pl-md-0 pr-md-4">
            <div
              class="py-2 py-md-4 text-center text-gray"
              :style="{ backgroundColor: '#fbf8f4' }"
            >
              문의 제목
            </div>
          </b-col>
          <b-col cols="12" md="10" class="py-2 py-md-0">
            <span class="py-3">
              {{ contact.title }}
            </span>
          </b-col>
        </b-row>
        <b-row align-v="center" class="mx-0 border-bottom border-gray">
          <b-col cols="12" md="2" class="px-0 pl-md-0 pr-md-4">
            <div
              class="py-2 py-md-4 text-center text-gray"
              :style="{ backgroundColor: '#fbf8f4' }"
            >
              문의 일시
            </div>
          </b-col>
          <b-col cols="12" md="4" class="text-center text-md-left py-2 py-md-0">
            <span class="py-3">
              {{
                contact.createdDate ? toLocaleDate(contact.createdDate) : "-"
              }}
            </span>
          </b-col>
          <b-col cols="12" md="2" class="px-0 pl-md-0 pr-md-4">
            <div
              class="py-2 py-md-4 text-center text-gray"
              :style="{ backgroundColor: '#fbf8f4' }"
            >
              처리상태
            </div>
          </b-col>
          <b-col cols="12" md="4" class="text-center text-md-left py-2 py-md-0">
            <span class="py-3">
              <span :class="`text-${getVariant(contact.status)}`">
                {{ getText(contact.status) }}
              </span>
            </span>
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="12" md="2" align-self="stretch">
            <div
              class="
                py-2 py-md-4
                text-center text-gray
                h-100
                d-flex
                flex-column
              "
              :style="{ backgroundColor: '#fbf8f4' }"
            >
              <span class="my-auto"> 문의 내용 </span>
            </div>
          </b-col>
          <b-col cols="12" md="10">
            <div class="my-3">
              <p
                class="text-17 fw-400 lh-180 pr-3"
                :style="{ minHeight: '15rem' }"
              >
                {{ contact.content }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="contact.answer" :style="{ borderBottom: '3px solid #333' }">
        <b-row align-v="center" class="mx-0 border-bottom border-gray">
          <b-col cols="12" md="2" class="px-0 pl-md-0 pr-md-4">
            <div
              class="py-2 py-md-4 text-center text-gray"
              :style="{ backgroundColor: '#fbf8f4' }"
            >
              답변 일시
            </div>
          </b-col>
          <b-col cols="12" md="4" class="text-center text-md-left py-2 py-md-0">
            <span class="py-3">
              {{ contact.answerDate ? toLocaleDate(contact.answerDate) : "-" }}
            </span>
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="12" md="2" align-self="stretch">
            <div
              class="
                py-2 py-md-4
                text-center text-gray
                h-100
                d-flex
                flex-column
              "
              :style="{ backgroundColor: '#fbf8f4' }"
            >
              <span class="my-auto"> 답변 내용 </span>
            </div>
          </b-col>
          <b-col cols="12" md="10">
            <div class="my-3">
              <p
                class="text-17 fw-400 lh-180 pr-3"
                :style="{ minHeight: '15rem' }"
              >
                {{ contact.answer }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- temp -->
      <!-- <div>
        (temp) {{ contact.status }}
        <div class="d-flex">
          <b-btn @click="contact.status = 'answered'">답변 완료</b-btn>
          <b-btn @click="contact.status = 'WAIT'">미완료</b-btn>
        </div>
      </div> -->
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contact: null,
    };
  },
  computed: {
    idx() {
      return this.$route.query.id;
    },
  },
  methods: {
    getVariant(progress) {
      const variant = {
        ANSWERED: "secondary",
        WAIT: "darkest",
        ERROR: "error",
      };
      return variant[progress] || null;
    },
    getText(progress) {
      const text = {
        ANSWERED: "답변 완료",
        WAIT: "답변 미완료",
        ERROR: "에러",
      };
      return text[progress] || null;
    },
    async getCurrentItem() {
      try {
        const { data } = await this.$axios.get(`/inquiries/${this.idx}`);
        if (data.status.code === "0000") {
          this.contact = data.data;

          // console.log(data.data);
        }
      } catch (error) {}
    },
  },
  async mounted() {
    await this.getCurrentItem();
  },
};
</script>

<style lang="scss" scoped></style>
