<template>
  <b-container class="my-5 py-5">
    <b-tabs
      content-class="border p-3 p-md-5"
      nav-class="text-14 text-lg-15 fw-700 text-nowrap"
      justified
    >
      <b-tab title="비딩 매칭" active>
        <b-row align-h="center">
          <b-col cols="10" md="8" lg="7">
            <header class="pb-4 pb-md-5 text-center">
              <h2 class="mb-3 text-18 text-lg-24">비딩매칭</h2>
            </header>
            <b-form @submit.prevent.stop="upload">
              <!-- ============================ 기본 정보 ============================ -->
              <fieldset class="mt-5">
                <legend class="mb-4 p-3 text-17 bg-primary text-white">기본정보</legend>
              </fieldset>
              <fieldset>
                <!-- (필수)주소 -->
                <span class="text-17 text-danger">
                  <font-awesome-icon icon="fa-solid fa-asterisk" />토지담보 대출 여부
                </span>
                <!-- 토지담보 대출 여부  -->
                <template>
                  <b-form-radio-group 
                    v-model="input.landColl" 
                    class="mb-4" 
                    variant="outline" hide-prepend
                    @change="changeLandColl()"
                    >
                      <b-form-radio class="fw-400 d-inline-flex" :value="false">
                        NO
                      </b-form-radio>
                      <b-form-radio
                        class="fw-400 d-inline-flex mr-5"
                        :value="true"
                      >
                        YES
                      </b-form-radio>
                  </b-form-radio-group>
                </template>
               {{addressObj}} 
                <template v-if="input.landColl">
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <kakao-map v-model="addressObj"/>
                </template>
                <template v-else>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <daum-address v-model="addressObj" />
                </template>
               
                <!-- 전유 공용면적-->
                <template v-if="!input.landColl">
                  <template v-if="isShowDong">
                    <span class="text-17 text-danger">
                      <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                    </span>
                    <b-form-select
                      v-model="selectedDong"
                      class="mb-4"
                      @change="dongChange2($event)"
                    >
                      <option :value="null" selected>동을 선택해주세요</option>
                      <option
                        v-for="(item, idx) in dongArr"
                        :value="item"
                        :key="idx"
                      >
                        {{ item }}
                      </option>
                    </b-form-select>
                  </template>
                  <template v-if="isShowHo">
                    <span class="text-17 text-danger">
                      <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                    </span>
                    <b-form-select
                      v-model="selectedHo"
                      class="mb-4"
                      v-if="isShowHo"
                      @change="hoChange2($event, selectedDong)"
                    >
                      <option :value="null" selected>호를 선택해주세요</option>
                      <option
                        v-for="(item, idx) in hoArr"
                        :value="item"
                        :key="idx"
                      >
                        {{ item }}
                      </option>
                    </b-form-select>
                  </template>
                </template>

                <!-- 전유공용 X, 층별개요 조회 값이 있을 경우 -->
                <!-- <template v-if="floorGubunArr.length > 0">
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-form-select
                    v-model="selectedFloorGubun"
                    class="mb-4"
                    @change="floorGubunChange($event)"
                  >
                    <option :value="null" selected>
                      층 구분을 선택해주세요
                    </option>
                    <option
                      v-for="(item, idx) in floorGubunArr"
                      :value="item"
                      :key="idx"
                    >
                      {{ item }}
                    </option>
                  </b-form-select>
                </template>
                <template v-if="isShowFloor">
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-form-select
                    v-model="selectedFloor"
                    class="mb-4"
                    @change="floorChange($event, selectedFloorGubun)"
                  >
                    <option :value="null" selected>층수를 선택해주세요</option>
                    <option
                      v-for="(item, idx) in floorArr"
                      :value="item"
                      :key="idx"
                    >
                      {{ item }}
                    </option>
                  </b-form-select>
                </template> -->
                <!-- ===================================== -->

                <!-- 토지 담보 대출이 아니고 전유, 층별개요 조회가 되지 않는 경우 또는 서버 에러로 인한 경우 
                     클라이언트가 상세주소 직접 입력 -->
                <template v-if="!input.landColl && (areaInfoFail || flrOulnInfoFail || notInfo)">
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <div>
                    <span class="text-15 text-danger"
                      >해당 물건이 단독주택 or 토지가 아닌 경우 동 호수를 입력해주세요.</span
                    >
                  </div>
                  <div>
                    <span class="text-15 text-danger">
                      아파트, 다세대, 오피스텔 등 : ㅇㅇ동 ㅇㅇ호 또는 ㅇㅇ호
                    </span>
                  </div>
                  <!-- <div>
                    <span class="text-15 text-danger">
                      건물, 토지 등 : 지상 or 지하 or 옥탑 / ㅇ층
                    </span>
                  </div> -->
                  <!-- writeAddressDetail -->
                  <b-input-group class="mb-4" variant="outline" hide-prepend>
                    <b-form-input
                      type="text"
                      v-model="input.addressDetail"
                      placeholder="상세주소*"
                    ></b-form-input>
                  </b-input-group>
                </template>
                <!-- ===================================================== -->
        
                <!-- (필수)물건종류 -->
                <template v-if="kind">
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-form-select
                    v-model="input.productKind"
                    class="mb-4"
                    @change="kindSelect"
                  >
                    <option
                      v-for="(item, idx) in kind"
                      :value="item.productKind"
                      :key="idx"
                    >
                      {{ item.text }}
                    </option>
                  </b-form-select>
                </template>
                <!-- (필수)대출종류 SHOW v-if="input.productKind"-->
                <template v-if="input.productKind">
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-form-select v-model="input.productKind2" class="mb-4" @change="productKind2Change()">
                    <option
                      v-for="item in kind2"
                      :v-if="item.idx == 0 ? 'selecetd' : ''"
                      :value="item.value"
                      :key="item.idx"
                    >
                      {{ item.text }}, {{item.value}}
                    </option>
                  </b-form-select>
                </template>
                <template v-if="input.productKind === 'KIND_5'">
                  <fieldset>
                    <legend class="mb-4 p-3 text-17 bg-primary text-white">
                      건축물 감정가 또는 시세
                    </legend>
                    <b-row>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-input-group
                          class="mb-4"
                          variant="outline"
                          hide-prepend
                        >
                          <b-form-input
                            type="text"
                            v-model="input.goingOrAppraisedPrice"
                            placeholder="감정가 또는 시세*"
                            @input.native="$inputPrice($event, 'goingOrAppraisedPrice')"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </template>
                <!-- 0810 진행종류가 매매자금인 경우 필수 입력 추가  -->
                <template v-if="input.productKind2 === 'KIND2_2'">
                  <fieldset>
                    <legend class="mb-4 p-3 text-17 bg-primary text-white">
                      매매내용
                    </legend>
                    <b-row>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-input-group
                          class="mb-4"
                          variant="outline"
                          hide-prepend
                        >
                          <!-- 20230622 매도자 명칭 to 소유자 -->
                          <b-form-input
                            type="text"
                            v-model="tradingFunds.seller"
                            placeholder="소유자*"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-input-group
                          class="mb-4"
                          variant="outline"
                          hide-prepend
                        >
                          <b-form-input
                            type="text"
                            v-model="tradingFunds.buyer"
                            placeholder="매수자*"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-input-group
                          class="mb-4"
                          variant="outline"
                          hide-prepend
                        >
                          <b-form-input
                            type="text"
                            v-model="tradingFunds.saleAmount"
                            placeholder="매매금액*"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-input-group
                          class="mb-4"
                          variant="outline"
                          hide-prepend
                        >
                          <b-form-input
                            type="text"
                            v-model="tradingFunds.balanceDate"
                            @input.native="inputDate($event)"
                            placeholder="잔금일* ex) 2022-08-12"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-row>
                    <!-- 20230622 매매자금 내용 추가 -->
                    {{tradingFunds.withTenant}}
                    <b-row>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-form-radio-group v-model="tradingFunds.withTenant" @change="changeWithTenant">
                          <b-form-radio class="fw-400 d-inline-flex mr-5" :value="false">
                            선순위 은행 동시진행
                          </b-form-radio>
                          <b-form-radio class="fw-400 d-inline-flex" :value="true" >
                            세입자끼고
                          </b-form-radio>
                          <!-- 20230622 퇴거자금인 경우 비활성화 추가 -->
                        </b-form-radio-group>
                      </b-col>
                    </b-row>

                    <!-- 20230622 선순위 동시진행 
                      추후 데이터 바인딩 해야함
                    -->
                    <b-row v-if="tradingFunds.withTenant">
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-input-group
                          class="mb-4"
                          variant="outline"
                          hide-prepend
                        >
                          <b-form-input
                            type="text"
                            v-model="tradingFunds.deposit"
                            placeholder="보증금(만원)*"
                            @input.native="$price($event, 'deposit')"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-input-group
                          class="mb-4"
                          variant="outline"
                          hide-prepend
                        >
                          <b-form-input
                            type="text"
                            v-model="tradingFunds.monthlyRent"
                            placeholder="월세(만원)*"
                            @input.native="$price($event, 'monthlyRent')"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-row>

                    <!-- 20230622 세입자 끼고 진행 추후 데이터 바인딩 필요-->
                    <b-row v-else>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-input-group
                          class="mb-4"
                          variant="outline"
                          hide-prepend
                        >
                          <b-form-input
                            type="text"
                            placeholder="은행명*"
                            v-model="tradingFunds.bankName"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-input-group
                          class="mb-4"
                          variant="outline"
                          hide-prepend
                        >
                          <b-form-input
                            type="text"
                            v-model="tradingFunds.principal"
                            @input.native="$inputPrice2($event, 'tradingFunds', 'principal')"
                            placeholder="원금*"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-input-group
                          class="mb-4"
                          variant="outline"
                          hide-prepend
                        >
                          <b-form-input
                            type="text"
                            v-model="tradingFunds.bondMaximum"
                            @input.native="$inputPrice2($event, 'tradingFunds', 'bondMaximum')"
                            placeholder="채권최고액*"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-row>

                  </fieldset>
                </template>
                <!-- (필수)소유자 -->
                <template>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-input-group class="mb-4" variant="outline" hide-prepend>
                    <b-form-input
                      type="text"
                      v-model="input.holder"
                      placeholder="소유자*"
                    ></b-form-input>
                  </b-input-group>
                </template>
                <!-- ============================ 소유자 공동명의 ====================== -->
                <fieldset class="mt-5">
                  <legend class="mb-4 p-3 text-17 bg-primary text-white">
                    소유자 공동명의
                  </legend>
                  <!-- 공동명의 유무 -->
                  <template>
                    <b-form-radio-group v-model="input.jointName">
                      <b-form-radio
                        class="fw-400 d-inline-flex mr-5"
                        :value="true"
                      >
                        유 (2인 이상)
                      </b-form-radio>
                      <b-form-radio class="fw-400 d-inline-flex" :value="false" :disabled="input.productKind2 == 'KIND2_5'">
                        무
                      </b-form-radio>
                    </b-form-radio-group>
                    <!-- 공동 소유자 / 지분 대출 SHOW -->
                    <template v-if="input.jointName">
                      <span class="text-17 text-danger">
                        <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                      </span>
                      <!-- 공동 소유자 입력 -->
                      <b-input-group
                        variant="outline"
                        class="mt-3"
                        hide-prepend
                        :style="{ gap: '1rem' }"
                      >
                        <w-input
                          class="m-0"
                          type="text"
                          ref="jointNameHolder"
                          v-model="jointNameHolder"
                          placeholder="공동 소유자명*"
                        ></w-input>
                        <b-form-select v-model="jointNameRelation">
                          <b-form-select-option
                            v-for="item in relation"
                            v-bind:key="item.id"
                            :value="item.value"
                            >{{ item.text }}
                          </b-form-select-option>
                        </b-form-select>
                        <b-btn
                          variant="primary"
                          v-on:click="addJointName(jointNameHolder, jointNameRelation)"
                        >
                          추가
                        </b-btn>
                      </b-input-group>
                      <!-- 공동 소유자 출력 -->
                      <template v-if="input.jointName">
                        <div
                          v-for="(item, i) in input.biddingJointName"
                          :key="i"
                          class="align-items-center text-17"
                        >
                          <template v-if="!item.delete">
                            <template v-if="!item.edit">
                              <b-input-group
                                variant="outline"
                                class="mt-3 justify-content-between"
                                hide-prepend
                                :style="{ gap: '1rem' }"
                              >
                                <w-input
                                  readonly
                                  class="m-0"
                                  type="text"
                                  ref="jointNameHolder"
                                  v-model="item.jointNameHolder"
                                ></w-input>
                                <b-form-select v-model="item.relation" disabled>
                                  <b-form-select-option
                                    v-for="item in relation"
                                    v-bind:key="item.id"
                                    :value="item.value"
                                    >{{ item.text }}
                                  </b-form-select-option>
                                </b-form-select>
                                <b-btn
                                  size="sm"
                                  variant="outline-dark"
                                  @click="editJointName(i)"
                                  >수정</b-btn
                                >
                                <b-btn
                                  size="sm"
                                  variant="outline-danger"
                                  @click="deleteJointName(i)"
                                  >삭제</b-btn
                                >
                              </b-input-group>
                            </template>
                            <template v-else>
                              <b-input-group
                                variant="outline"
                                class="mt-3"
                                hide-prepend
                                :style="{ gap: '1rem' }"
                              >
                                <w-input
                                  class="m-0"
                                  type="text"
                                  ref="jointNameHolder"
                                  v-model="item.jointNameHolder"
                                  placeholder="공동 소유자명"
                                ></w-input>
                                <b-form-select v-model="item.relation">
                                  <b-form-select-option
                                    v-for="item in relation"
                                    v-bind:key="item.id"
                                    :value="item.value"
                                    >{{ item.text }}
                                  </b-form-select-option>
                                </b-form-select>
                                <b-btn
                                  size="sm"
                                  variant="outline-dark"
                                  @click="jointNameSave(item, i)"
                                  >저장</b-btn
                                >
                                <b-btn
                                  size="sm"
                                  variant="outline-danger"
                                  @click="cancelBtn(item, i)"
                                  >취소</b-btn
                                >
                              </b-input-group>
                            </template>
                          </template>
                        </div>
                      </template>
                      <!-- 지분 대출 -->
                      <!-- <div>
                        <legend class="mb-3 p-3 text-17 bg-lighter">
                          지분 대출
                        </legend>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-form-radio-group 
                          v-model="input.shareLoan"
                          @change="changeShareLoan()"
                        >
                          <b-form-radio
                            class="fw-400 d-inline-flex mr-5"
                            :value="false"
                          >
                            본인지분
                          </b-form-radio>
                          <b-form-radio
                            class="fw-400 d-inline-flex"
                            :value="true"
                          >
                            공동지분
                          </b-form-radio>
                        </b-form-radio-group>
                        0810 공동소유자명이 있고, 본인 지분인 경우 필수 입력 추가 
                        <w-input
                          class="m-0"
                          type="text"
                          ref="jointNameHolder"
                          v-model="input.selfRatio"
                          placeholder="본인 지분율*"
                          @input.native="$inputDecimalPoint($event, 'selfRatio')"
                          v-if="!input.shareLoan"
                        ></w-input>
                      </div> -->
                    </template>
                  </template>
                </fieldset>
                <div class="mt-5">
                  <legend class="mb-4 p-3 text-17 bg-lighter">
                    소유자 연락처 / 요청금액
                  </legend>
                  <!-- 전화번호 -->
                  <template>
                    <!-- 0810 진행종류가 무설정론인 경우 필수 표시 추가  -->
                    <!-- <span
                      class="text-17 text-danger"
                      v-if="input.productKind2 === 'KIND2_4'"
                    >
                      <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                    </span> -->
                    <b-input-group class="mb-4" variant="outline" hide-prepend>
                      <span class="text-17 text-danger" v-if="input.productKind2 === 'KIND2_4'">
                        <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                      </span>
                      <w-input
                        class="w-100 m-0"
                        type="tel"
                        v-model="input.phone"
                        @input.native="$numberOnly($event, 'phone', 11)"
                        placeholder="소유자 전화번호"
                      ></w-input>
                    </b-input-group>
                    <!-- 0810 진행종류가 무설정론인 경우 필수 입력 추가  -->
                    <b-input-group
                      v-if="input.productKind2 === 'KIND2_4'"
                      class="mb-4"
                      variant="outline"
                      hide-prepend
                    >
                      <span class="text-17 text-danger">
                        <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                      </span>
                      <w-input
                        class="w-100 m-0"
                        type="tel"
                        v-model="input.birthDate"
                        @input.native="$numberOnly($event, 'birthDate', 8)"
                        placeholder="생년월일* ex) 19910812"
                      ></w-input>
                    </b-input-group>
                  </template>
                  <!-- 요청금액 -->
                  <template>
                    <span class="text-17 text-danger">
                      <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                    </span>
                    <b-input-group class="mb-0" variant="outline" hide-prepend>
                      <w-input
                        class="w-100 m-0"
                        type="text"
                        v-model="input.amount"
                        @input.native="$inputPrice($event, 'amount')"
                        placeholder="요청금액(만원)"
                      ></w-input>
                    </b-input-group>
                  </template>
                </div>
              </fieldset>
              <!-- ============================ 재직 정보 ============================ -->
              <fieldset class="mt-5">
                <legend class="mb-4 p-3 text-17 bg-primary text-white">
                  재직 정보
                </legend>
                <!-- (필수)재직정보 -->
                <template>
                  <p class="text-17">
                    <span class="text-17 text-danger">
                      <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                    </span>
                    소유자 재직상태를 선택해주세요.
                    <span class="fw-900" v-if="input.work"
                      >( {{ input.work }} )
                    </span>
                  </p>
                  <b-form-radio-group
                    stacked
                    :options="work"
                    v-model="input.work"
                    class="fw-400 text-17"
                  ></b-form-radio-group>
                  <!-- 신용결여 SHOW -->
                  <template v-if="input.work === 'BADCREDIT'">
                    <legend class="my-3 p-3 text-17 bg-lighter">
                      <span class="text-17 text-danger">
                        <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                      </span>
                      신용 현황
                    </legend>

                    <b-row>
                      <b-col>
                        <b-form-input
                          type="text"
                          v-model="input.creditScore"
                          placeholder="신용점수"
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <b-form-input
                          type="text"
                          v-model="input.totalTimes"
                          placeholder="총 납기회차"
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <b-form-input
                          type="text"
                          v-model="input.currentTimes"
                          placeholder="현재 회차"
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <b-form-input
                          type="text"
                          v-model="input.defaultTimes"
                          placeholder="미납 회차"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </template>
                </template>
              </fieldset>

              <!-- ============================ 세입자 정보 ========================== -->
              <!-- 매매자금 세입자 끼고 진행시엔 안 보이게 -->
              <fieldset class="mt-5" v-if="input.prodctKind2 !== 'KIND2_2' && !tradingFunds.withTenant">
                <legend class="mb-4 p-3 text-17 bg-primary text-white">
                  세입자 정보
                </legend>
                <!-- 세입자 유무 -->
                <b-form-radio-group v-model="input.tenant">
                  <b-form-radio class="fw-400 d-inline-flex mr-5" :value="true">
                    유
                  </b-form-radio>
                  <!-- 20230622 퇴거자금인 경우 비활성화 추가 -->
                  <b-form-radio class="fw-400 d-inline-flex" :value="false" :disabled="input.productKind2 == 'KIND2_3'">
                    무
                  </b-form-radio>
                </b-form-radio-group>
                <!-- 세입자 상세정보 SHOW -->
                <template v-if="input.tenant">
                  <legend class="my-3 p-3 text-17 bg-lighter">
                    <span class="text-17 text-danger">
                      <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                    </span>
                    상세 정보
                  </legend>
                  <b-row>
                    <b-col>
                      <b-input-group class="m-0" variant="outline" hide-prepend>
                        <w-input
                          class="w-100 m-0"
                          type="text"
                          v-model="deposit"
                          @input.native="$price($event, 'deposit')"
                          placeholder="보증금(만원)*"
                        ></w-input>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <b-input-group class="m-0" variant="outline" hide-prepend>
                        <w-input
                          class="w-100 m-0"
                          type="text"
                          v-model="monthlyRent"
                          @input.native="$price($event, 'monthlyRent')"
                          placeholder="월세(만원)*"
                        ></w-input>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <b-form-radio-group v-model="tenantAgree">
                        <b-form-radio
                          class="fw-400 d-inline-flex mr-5"
                          :value="true"
                        >
                          대출 동의
                        </b-form-radio>
                        <b-form-radio
                          class="fw-400 d-inline-flex"
                          :value="false"
                        >
                          대출 미동의
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-col>
                    <!-- 20230622 단독/다가구만 추가 버튼 활성화 -->
                    <b-col>
                      <b-btn
                        variant="primary"
                        v-if="input.productKind == 'KIND_3' || input.tenants.length == 0"
                        v-on:click="addTenant()"
                      >추가</b-btn>
                    </b-col>
                  </b-row>
                </template>
                <!-- 세입자 정보 출력 -->
                <template v-if="input.tenant">
                  <div
                    v-for="(item, i) in input.tenants"
                    :key="i"
                    class="align-items-center text-17"
                  >
                    <template v-if="!item.delete">
                      <template v-if="!item.edit">
                        <b-row>
                          <b-col>
                            <b-input-group class="m-0" variant="outline" hide-prepend>
                              <w-input
                                readonly
                                class="w-100 m-0"
                                type="text"
                                ref="jointNameHolder"
                                v-model="item.deposit"
                              ></w-input>
                            </b-input-group>
                          </b-col>
                          <b-col>
                            <b-input-group class="m-0" variant="outline" hide-prepend>
                              <w-input
                                readonly
                                class="w-100 m-0"
                                type="text"
                                ref="jointNameHolder"
                                v-model="item.monthlyRent"
                            ></w-input>
                            </b-input-group>
                          </b-col>
                          <b-col>
                            <b-form-radio-group 
                              disabled 
                              v-model="item.tenantAgree"
                            >
                              <b-form-radio
                                class="fw-400 d-inline-flex mr-5"
                                :value="true"
                              >
                                대출 동의
                              </b-form-radio>
                              <b-form-radio
                                class="fw-400 d-inline-flex"
                                :value="false"
                              >
                                대출 미동의
                              </b-form-radio>
                            </b-form-radio-group>
                          </b-col>
                          <b-col>
                            <b-btn
                              size="sm"
                              variant="outline-dark"
                              @click="editTenant(i)"
                            >수정</b-btn>
                            <b-btn
                              size="sm"
                              variant="outline-danger"
                              @click="deleteTenant(i)"
                            >삭제</b-btn>
                          </b-col>
                        </b-row>
                      </template>
                      <template v-else>
                        <b-row>
                          <b-col>
                            <b-input-group class="m-0" variant="outline" hide-prepend>
                              <w-input
                                class="w-100 m-0"
                                type="text"
                                ref="jointNameHolder"
                                v-model="item.deposit"
                                placeholder="보증금*"
                              ></w-input>
                            </b-input-group>
                          </b-col>
                          <b-col>
                            <b-input-group class="m-0" variant="outline" hide-prepend>
                              <w-input
                                class="w-100 m-0"
                                type="text"
                                ref="jointNameHolder"
                                v-model="item.monthlyRent"
                                placeholder="월세*"
                            ></w-input>
                            </b-input-group>
                          </b-col>
                          <b-col>
                            <b-form-radio-group 
                              v-model="item.tenantAgree"
                            >
                              <b-form-radio
                                class="fw-400 d-inline-flex mr-5"
                                :value="true"
                              >
                                대출 동의
                              </b-form-radio>
                              <b-form-radio
                                class="fw-400 d-inline-flex"
                                :value="false"
                              >
                                대출 미동의
                              </b-form-radio>
                            </b-form-radio-group>
                          </b-col>
                          <b-btn
                            size="sm"
                            variant="outline-dark"
                            @click="tenantSave(item, i)"
                          >저장</b-btn>
                          <b-btn
                            size="sm"
                            variant="outline-danger"
                            @click="cancelBtn(item, i)"
                          >취소</b-btn>
                        </b-row>
                      </template>
                    </template>
                  </div>
                </template>
              </fieldset>
              <!-- ============================ 등기부등본 업로드 ==================== -->
              <fieldset class="mt-5">

                <!-- 20230622 매매자금 이미지 업로드 추후 데이터 바인딩, 서버처리 해야함 @input="uploadImage"-->
                <input-image
                  v-if="input.productKind2=='KIND2_2'"
                  v-model="input.imageFile"
                  label-text="매매자금 이미지 업로드"
                  :accept="'image/jpg, image/jpeg, image/png'"
                  @input="uploadImage"
                />
                <!-- <legend class="mb-4 p-3 text-17 bg-primary text-white">
                  등기부등본 업로드
                </legend> --> 
                <!-- 업로드 버튼 -->
                <input-file
                  v-if="!input.landColl"
                  labelText="물건 등기부등본 업로드(PDF)"
                  accept=".pdf"
                  v-model="input.file"
                  :holder="input.holder"
                  type="B"
                  @owners="owners"
                  @fileDelete="fileDelete('B')"
                />
              </fieldset>
              <!-- 건물이 단독주택인 경우 토지 & 건물의 등기부 등본 등록 total 2개 -->
              <fieldset class="mt-5" v-if="input.productKind === 'KIND_5' || input.landColl">
                <input-file
                  labelText="토지 등기부등본 업로드(PDF)"
                  accept=".pdf"
                  v-model="input.landFile"
                  :holder="input.holder"
                  type="L"
                  @owners="owners"
                  @fileDelete="fileDelete('L')"
                />
              </fieldset>
              <!-- 확인용 -->
              <!-- ============================ 의뢰등록 버튼 ======================== -->
              <div class="my-5 text-center">
                <b-btn
                  variant="primary"
                  class="w-75 py-3"
                  @click="upload"
                  :disabled="submitted"
                >
                  {{ submitted ? "등록중입니다..." : "비딩매칭 의뢰등록" }}
                </b-btn>
              </div>
            </b-form>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="비딩 매칭이란?">
        <header class="text-28 text-lg-32 text-center pt-5">
          <strong
            class="text-primary pb-1 border-primary"
            :style="{ borderBottom: '3px solid black' }"
            >비딩 매칭</strong
          >이란?
        </header>
        <section class="mb-5 p-5">
          <p class="text-14 text-lg-16 lh-180 text-center text-gray lh-188">
            비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나
            그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽
            요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종
            결과물에 들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인
            프로젝트 모형의 채움 글로도 이용된다. 이런 용도로 사용할 때 로렘
            입숨을 그리킹(greeking)이라고도 부르며, 때로 로렘 입숨은 공간만
            차지하는 무언가를 지칭하는 용어로도 사용된다. 로렘 입숨은 전통
            라틴어와 닮은 점 때문에 종종 호기심을 유발하기도 하지만 그 이상의
            의미를 담지는 않는다. 문서에서 텍스트가 보이면 사람들은 전체적인
            프레젠테이션보다는 텍스트에 담긴 뜻에 집중하는 경향이 있어서
            출판사들은 서체나 디자인을 보일 때는 프레젠테이션 자체에 초점을
            맞추기 위해 로렘 입숨을 사용한다. 로렘 입숨은 영어에서 사용하는
            문자들의 전형적인 분포에 근접하다고도 하는데, 이 점 때문에
            프레젠테이션으로 초점을 이동하는 데에도 도움을 준다.
          </p>
        </section>
      </b-tab>
      <b-tab title="비딩 매칭이 좋은 이유">
        <ul class="list-unstyled pb-5 m-3 m-md-0">
          <li class="mb-5 d-flex flex-column">
            <section class="my-2">
              <h5 class="text-18 text-lg-20 text-primary fw-700">
                Q. 비딩매칭이 좋은 이유는?
              </h5>
            </section>
            <section class="my-2">
              <h5 class="text-18 text-lg-20 text-secondary fw-700">
                A. 비딩매칭이 좋은 이유입니다.
              </h5>
              <p class="mt-3 pl-4 pr-md-5 text-14 lh-188 text-gray">
                비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은
                출판이나 그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃
                같은 그래픽 요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기
                텍스트로, 최종 결과물에 들어가는 실제적인 문장 내용이 채워지기
                전에 시각 디자인 프로젝트 모형의 채움 글로도 이용된다. 이런
                용도로 사용할 때 로렘 입숨을 그리킹(greeking)이라고도 부르며,
                때로 로렘 입숨은 공간만 차지하는 무언가를 지칭하는 용어로도
                사용된다. 로렘 입숨은 전통 라틴어와 닮은 점 때문에 종종 호기심을
                유발하기도 하지만 그 이상의 의미를 담지는 않는다. 문서에서
                텍스트가 보이면 사람들은 전체적인 프레젠테이션보다는 텍스트에
                담긴 뜻에 집중하는 경향이 있어서 출판사들은 서체나 디자인을 보일
                때는 프레젠테이션 자체에 초점을 맞추기 위해 로렘 입숨을
                사용한다. 로렘 입숨은 영어에서 사용하는 문자들의 전형적인 분포에
                근접하다고도 하는데, 이 점 때문에 프레젠테이션으로 초점을
                이동하는 데에도 도움을 준다.
              </p>
            </section>
          </li>
        </ul>
      </b-tab>
    </b-tabs>

    <template v-if="loadingInfo.show">
      <div
        class="text-center"
        style="
          position: fixed;
          left: 0px;
          top: 0px;
          background: rgba(0, 0, 0, 0.5);
          width: 100%;
          height: 100%;
          z-index: 300;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div>
          <b-spinner variant="white" class="my-3" />
          <p class="text-18 text-white m-0">{{ loadingInfo.text }}</p>
        </div>
      </div>
    </template>
    <modal-mortgage-check :data="apiData" v-on:setMortgage="setMortgage" />
  </b-container>
</template>

<script>
import Product from "@/lib/product";
import Form from "@/components/Form/index";
import DaumAddress from "@/components/DaumAddress.vue";
import KakaoMap from "@/components/KakaoMap.vue";
import WInput from "../../components/common/WInput.vue";
import Modals from "@/components/Modals/index";
// import { asyncLoading } from "vuejs-loading-plugin";

export default {
  components: {
    InputFile: Form.InputFile,
    DaumAddress,
    KakaoMap,
    ModalMortgageCheck: Modals.MortgageCheck,

    //20230622 매매자금 이미지파일 추가
    InputImage: Form.InputImage,
  },
  data() {
    return {
      testKind: [
        {
          text: "진행 물건 종류를 선택하세요.*",
          value: null,
        },
        ...Product.kind2,
      ],
      kind: null,
      kind2: null,
      addressObj: {},
      input: {
        file: null,                   //물건 등기부 등본
        landFile: null,               //토지 등기부 등본
        amount: null,                 //요청금액
        holder: null,                 //의뢰자
        phone: null,                  //휴대폰번호
        productKind: null,            //물건종류
        productKind2: null,          //대출종류
        
        imageFile: null,              //등록한 이미지 파일
        imageUrl: null,
        registerFile: null,           //등록한 물건 등기부 파일
        landRegisterFile: null,       //등록한 토지 등기부 파일
       
        work: null,                   //직업
        jointName: false,             //공동 소유자 여부
        biddingJointName: [],         //공동소유자명

        creditScore: null,            //신용결여 - 신용점수
        totalTimes: null,             //신용결여 - 총 납기회차
        currentTimes: null,           //신용결여 - 현재회차
        defaultTimes: null,           //신용결여 - 미납회차

        birthDate: null,              //0810 무설정론인 경우 생년월일 변수
        writeAddressDetail: null,     //전유 또는 층별개요가 조회되지 않은 경우 직접 입력한 상세 주소
        goingOrAppraisedPrice: null,  //토지, 단독주택인 경우 건축물 감정가 또는 시세
        landColl: false,              //토지담보대출 여부

        tenant: false,                //세입자 여부
        tenants: [],
        /**
         * 
            tenantAgree: true,            //세입자 동의여부
            deposit: null,                //보증금
            monthlyRent: null,            //월세
         * modalShow: true,      //모달 여부
         * shareLoan: false,
         * selfRatio: null, //0810 공동소유자 & 본인지분의 지분율 변수
         */
      },
      
      /**
       * formValid: false,
       * isShowFloor: false,          // selectBox 조건
       * floorGubunArr: [],           //층의 구분 값 list
       * floorArr: null,              //층리스트
       * selectedFloorGubun: null,    //선택한 층의 구분 값
       * selectedFloor: null,         //선택한 층
       */

      isShowHo: false,          //호 selectBox 보임 여부
      isShowDong: false,        //동 selectBox 보임 여부

      hoArr: null,              //동 선택시 또는 동이 없는 경우 ho값이 담기는 변수
      dongArr: null,            //동이 list로 담길 변수  

      selectedHo: null,         //selectBox에서 선택한 호수
      selectedDong: null,       //selectBox에서 선택한 동
    
      //매매자금
      tradingFunds: {
        deposit: null,
        monthlyRent: null,
        seller: null,
        buyer: null,
        bankName: null,
        saleAmount: null,
        bondMaximum: null,
        principal: null,
        balanceDate: null,
        withTenant :false
      },     

      jointNameHolder: null,    //list에 담기 전 공동 소유자명
      jointNameRelation: null,  //list에 담기 전 공동 소유자명과의 관계

      tenantAgree: true,            //세입자 동의여부
      deposit: null,                //보증금
      monthlyRent: null,            //월세

      owner: null,              //등기부 등본상 소유자명
      dateOfBirth: null,        //등기부 등본상 소유자 생년월일
      
      text: null,               //등기부 등본 파싱한 text
      caution: null,            //등기부 등본 주의사항
      landText: null,           //토지 등기부 등본 파싱한 text

      brTitleInfo : null,       //표제부
      brFlrOulnInfo: null,      //선택한 층별개요 단일 값
      brFlrOulnInfos: [],       //최초로 받아온 층별개요 리스트

      brExposPubuseAreaInfo: null,  //선택한 전유부 단일 값
      brExposPubuseAreaInfos: [],   //최초로 받아온 전유부 리스트

      areaInfoFail: false,        //전유공용면적 서버 에러로 인한 경우 true
      flrOulnInfoFail: false,     //층별개요조회 서버 에러로 인한 경우 true
      notInfo: false,             //전유, 층별 둘 다 결과가 없을 경우 true
      submitted: false,

      apiData: null, //api조회후 근저당권 확인 모달창에서 emit으로 받을 변수

      rules: {
        holder: [(v) => !!v || "소유자를 입력하세요."],
        //phone: [(v) => !!v || "소유자 전화번호를 입력하세요."],
      },
      work: [
        {
          text: "직장인",
          value: "EMPLOYEE",
        },
        {
          text: "주부(무직)",
          value: "HOUSEWIFE_N",
        },
        {
          text: "주부(생활비 내역 있음)",
          value: "HOUSEWIFE_Y",
        },
        {
          text: "실사업자",
          value: "BUSINESSMAN_Y",
        },
        {
          text: "가라사업자(매출 증빙 X)",
          value: "BUSINESSMAN_N",
        },
        {
          text: "프리랜서(통장증빙 가능)",
          value: "FREELANCER",
        },
        {
          text: "신용결여(회복/회생/파산/워크아웃 등)",
          value: "BADCREDIT",
        },
        {
          text: "외국인 거소증 유",
          value: "FOREIGNER_RESIDENT_Y",
        },
        {
          text: "외국인 거소증 무",
          value: "FOREIGNER_RESIDENT_N",
        },
      ],

      relation: [
        {
          text: "관계를 선택하세요. *",
          value: null,
        },
        {
          text: "부부",
          value: "MARRIED",
        },
        {
          text: "부모",
          value: "PARENT",
        },
        {
          text: "형제",
          value: "BROTHRES",
        },
        {
          text: "제 3자",
          value: "OTHER_PERSON",
        },
      ],

      loadingInfo: {
        show: false,
        text: null,
      },

    };
  },
  
  watch: {
    addressObj: function () {debugger;
      /* 전유공용 면적으로 조회된 결과가 없는 경우 층별개요 조회
         여기에서의 size 는 BrExposPubuseAreaInfos변수의 길이
        -1인경우 공공데이터포털 측의 서버 문제
      */
      this.brExposPubuseAreaInfos = [];

      if(this.tenants){
        this.tenants = [];
      }

      if(this.biddingJointName){
        this.biddingJointName = [];
      }
      
      if(!this.input.landColl){
        this.getBrInfos();
        //this.getBrTitleInfo();
      }else{
        this.setKind('토지');
      }
    },
  },
  computed:{
    
  },
  methods: {
    //본인->공동 본인지분율 리셋
    // changeShareLoan(){
    //   if(!this.input.shareLoan){
    //     this.input.selfRatio = null;
    //   }
    // },

    //대출종류 값 변경시
    productKind2Change(){
      console.log(this.input.productKind2)
      console.log('testKindSelect')

      let e = this.input.productKind2;

      //퇴거자금 -> 세입자 정보 default true
      if(e == 'KIND2_3'){
        this.input.tenant = true;
      //지분 -> 소유자 공동명의 default true  
      }else if(e == 'KIND2_5'){
        this.input.jointName = true;
      }else if(e !== 'KIND2_2'){
        this.tradingFunds.withTenant = false;
        this.input.tenant = false;
        this.input.tenantAgree = false;
        this.deposit = null;
        this.monthlyRent = null;
      }else{
        this.input.tenant = false;
        this.input.jointName = false;
      }
    },

    //토지담보 여부 체크박스 
    changeLandColl(){
      if(this.input.landColl){
        if(this.addressObj && this.addressObj.address){
          this.setKind('토지');
        }
        this.brExposPubuseAreaInfo = null;
        this.brFlrOulnInfo = null;
        this.brTitleInfo = null;
      }else{
        this.kind = null;
        this.kind2 = null;
        this.input.productKind = null;
        this.input.productKind2 = null;
        this.input.goingOrAppraisedPrice = null;
      }
        this.selectedDong = null;
        this.selectedHo = null;
    },

    //매매자금시 선순위 or 세입자 끼고 체크박스
    changeWithTenant(){
      if(!this.tradingFunds.withTenant){
        this.tradingFunds = {withTenant : false}

        this.input.tenant = false;
        this.input.tenantAgree = false;
        this.deposit = null;
        this.monthlyRent = null;

      }else{
        this.input.tenant = true;
      }
    },
    inputCler() {
      if (this.addressObj.addressDetailMeta) {
       
        this.kind = null;
        this.kind2 = null;
        this.input.productKind = null;
        this.input.productKind2 = null;

        this.isShowDong = false;
        this.isShowHo = false;

        this.hoArr = null; 
        this.dongArr = null; 

        this.selectedHo = null; 
        this.selectedDong = null;


        //this.isShowFloor = false; // selectBox 조건
        //this.floorGubunArr = []; //층의 구분 값 list
        //this.floorArr = null; //층리스트
        //this.selectedFloorGubun = null; //선택한 층의 구분 값
        //this.selectedFloor = null; //선택한 층
      }
      if (this.owner) {
        this.owner = null;
      }
    },

    //근저당권 모달 emit시 실행
    setMortgage(apiData) {
      /** 채권 금액 변동시 ltv 재계산 */
      if(apiData.changed){
        apiData = this.getLtv(apiData);
      }

      this.finalUpload(apiData);
    },

    //최종 업로드 전 ltv재계산
    ltvCalc(amount, price){
      let ltv = null;

      if(price){
        if(price != "-1" && price != "0"){
          ltv = (amount / Number(price) * 100.0).toFixed(2);
        }
      }

      return ltv;
    },

    //최종 업로드 전 ltv재계산 데이터 처리
    getLtv(apiData){
      
      let ltvMeta = apiData.ltvMeta;
      let bondAmount = apiData.totalBondAmount;
      let ltotalBondAmount = apiData.ltotalBondAmount;

      if(bondAmount && bondAmount != 0 && bondAmount != -1){
        ltvMeta.bondAmount.kbPriceLtv =  this.ltvCalc(bondAmount, ltvMeta.kbPrice);
        ltvMeta.bondAmount.marketPriceLtv =  this.ltvCalc(bondAmount, ltvMeta.marketPrice);
        ltvMeta.bondAmount.auctionLtv =  this.ltvCalc(bondAmount, ltvMeta.auctionPrice);
      }

      let indvdLandPrice = ltvMeta.indvdLandPrice;

      //indvdLandPrice
      if(ltotalBondAmount && ltotalBondAmount != 0 && ltotalBondAmount != -1){
        ltvMeta.bondAmount.indvdLandPriceLtv =  this.ltvCalc(ltotalBondAmount, indvdLandPrice);
      }

      apiData.ltvMeta = ltvMeta;
      return apiData;
    },

    //파일삭제 버튼
    fileDelete(type) {
      if(type === 'B'){
        this.input.file = null;
        this.caution = null;
        this.owner = null;
      }else{
        this.input.landFile = null;
        this.caution = null;
        this.owner = null;
      }
    },

    //주소 관련 

    //주소 값 변경시 호출
    getBrInfos() {

      this.areaInfoFail = false;
      this.flrOulnInfoFail = false;
      this.notInfo = false;

      this.hoArr = null;
      this.selectedHo = null;

      this.dongArr = null;
      this.selectedDong = null;

      this.getBrExposPubuseAreaInfos().then((size) => {
        /* 전유공용 면적으로 조회된 결과가 없는 경우 층별개요 조회
            여기에서의 size 는 BrExposPubuseAreaInfos변수의 길이
           -1인경우 공공데이터포털 측의 서버 문제
         */
        console.log(size);
        if (size == 0) {
          this.getBrFlrOulnInfos();
        } else if (size == -1) {
          this.areaInfoFail = true;
          this.getBrFlrOulnInfos();
        }

        
      });
    },

    //층별개요 조회
    async getBrFlrOulnInfos() {

      this.loadingInfo = {
        show: true,
        text: "주소 조회 중.",
      };

      try {
        const { data } = await this.$axios.post(
          "/test/flrOulnInfo",
          this.addressObj.addressDetailMeta
        );

        if (data.status.code === "0000") {
          if (data.data.response) {
            if (data.data.response.body.totalCount > 0) {
              let item = data.data.response.body.items.item;

              if (Array.isArray(item)) {
                this.brFlrOulnInfos = [...new Set(item)];
              } else {
                this.brFlrOulnInfos.push(item);
              }

              if(this.brFlrOulnInfos && this.brFlrOulnInfos.length){

                this.brFlrOulnInfos.forEach(function(e){
                  e.area = String(e.area);
                })
                
                this.brFlrOulnInfos = this.brFlrOulnInfos.sort((a, b) =>
                  a.area.localeCompare(b.area, undefined, { numeric: true }))

                this.brFlrOulnInfo = this.brFlrOulnInfos[this.brFlrOulnInfos.length - 1];
                this.setKind('단독주택/다가구주택/타운하우스');
              }else{
                this.notInfo = true;
                this.kind = [...Product.kind];
                console.log("층별개요 조회 데이터 없음");
              }
          } else {
            console.log("층별개요 조회 실패");
            this.flrOulnInfoFail = true;
            this.setKind('ALL');
          }
        }
      }
      } catch (error) {
        console.log(error);
      }finally{
        this.loadingInfo = {
          show: false,
          text: null,
        };
      }
    },
    //전유공용 조회
    async getBrExposPubuseAreaInfos(pageNo) {

      this.loadingInfo = {
        show: true,
        text: "주소 조회 중.",
      };

      this.inputCler();
      
      let size = 0;
      let ing = false;

      if(!pageNo){
        pageNo = 1;
      }
      
      let addressDetailMeta = this.addressObj.addressDetailMeta;

      let reqBody = {
          sigunguCd: addressDetailMeta.sigunguCode,
          bjdongCd: addressDetailMeta.bcode,
          bun: addressDetailMeta.bun,
          ji: addressDetailMeta.ji,
          pageNo: pageNo,
          numOfRows: "100"
      };
      
      try {
        const { data } = await this.$axios.post("/test/pubuseAreaInfo", reqBody);
  
        if (data.status.code === "0000") {

          if (data.data.response) {debugger;
            let totalCount = data.data.response.body.totalCount;

            /* 공공데이터 포털 조회건수 제한으로 페이징 호출 */
            if (totalCount > 0) {
              let item = data.data.response.body.items.item;
              console.log(item);

              if (Array.isArray(item)) {

                let jeonus = [ ...new Set( item.filter(function (e) {
                  return e.exposPubuseGbCdNm === "전유"; }) )];

                if(this.brExposPubuseAreaInfos.length == 0){
                  this.brExposPubuseAreaInfos = jeonus;
                }else{
                  this.brExposPubuseAreaInfos = this.brExposPubuseAreaInfos.concat(jeonus);
                }
              } else {
                this.brExposPubuseAreaInfos.push(item);
              }

              // if(totalCount >(pageNo * 100)){
              //   this.getBrExposPubuseAreaInfos(pageNo+1);
              // }else{
              //   ing = true;
              // }
              
              ing = true;
              if(ing){
                let dongArr = [];
  
                // 동 List생성 + 중복 제거
                this.brExposPubuseAreaInfos.forEach(function (e) {
                  if (e.dongNm != "") {
                    let idx = dongArr.findIndex((v) => v == e.dongNm);
                    if (idx == -1) {
                      dongArr.push(String(e.dongNm));
                    }
                  }
                });
  
                //동이 없는 경우
                if (!dongArr || dongArr.length == 0) {
                  let hoArr = this.brExposPubuseAreaInfos.map((x) => {
                    return String(x.hoNm);
                  });
  
                  //호 정렬후 중복제거
                  this.hoArr = [ ...new Set(
                    hoArr.sort((a, b) =>
                      a.localeCompare(b, undefined, { numeric: true })))];
                  this.isShowHo = true;
                } else {
                  this.dongArr = dongArr.sort((a, b) =>
                    a.localeCompare(b, undefined, { numeric: true })
                  );
                  this.isShowDong = true;
                }
              }
            }else if(totalCount == 0){
              this.setKind('단독주택/다가구주택/타운하우스');
              ing = true;
            }
            size = this.brExposPubuseAreaInfos.length;
          } else {
            size = -1;
            console.log("전유공용 면적 조회 실패");
          }
        } else {
          size = -1;
          console.log("getBrExposPubuseAreaInfos() result code Not 0000", data.status.message);
        }
      } catch (error) {
        size = -1;
        console.log(error);
      }finally{
        if(size == -1 || ing){
          
          this.loadingInfo = {
            show: false,
            text: null,
          };

          if(size == -1){
            alert('주소 조회 실패');
          }
        }
      }
    },

    //전유공용 조회 후처리


    //표제부 조회
    async getBrTitleInfo(){debugger;
      try {

        this.loadingInfo = {
          show: true,
          text: "물건 조회 중.",
        };

        const { data } = await this.$axios.post("/test/brTitleInfo2", this.addressObj.addressDetailMeta);

        if (data.status.code === "0000") {
          console.log("성공");
          console.log('data', data);

          let item = null;

          if(data.data.response.body.totalCount == 1 ){
            item = data.data.response.body.items.item;
          }else if(data.data.response.body.totalCount > 1){
            item = data.data.response.body.items[0];
          }
  
          this.brTitleInfo = item;

          console.log('item', item);

        } else {
          window.alert(data.status.message);
        }
      } catch (error) {
        console.log(error);
      }finally{
        this.loadingInfo = {
          show: false,
          text: null,
        };
      }

    },

    //동 선택시 - 정상처리 확인
    dongChange2(selectedDongNm) {
      let hoArr = [];

      this.brExposPubuseAreaInfos.forEach(function (e) {
        if (e.dongNm == selectedDongNm) {
          hoArr.push(String(e.hoNm));
        }
      });

      //추출한 hoNm 중복제거 & 정렬
      if (hoArr.length > 0) {
        this.hoArr = [
          ...new Set(
            hoArr.sort((a, b) =>
              a.localeCompare(b, undefined, { numeric: true })
            )
          ),
        ];
        this.isShowHo = true;
      }
    },

    //호수 선택시
    hoChange2(selecetdHoNm, selectedDongNm) {
      let info = {};

      if (selectedDongNm) {
        this.brExposPubuseAreaInfos.forEach(function (e) {
          console.log(e);
          if (
            e.dongNm == selectedDongNm &&
            e.hoNm == selecetdHoNm &&
            !(e.flrGbCdNm && e.flrGbCdNm.indexOf("지상") > -1 && e.flrNoNm && String(e.flrNoNm).indexOf("지하") > -1)
          ) {
            info = e;
            return;
          }
        });
      } else {
        this.brExposPubuseAreaInfos.forEach(function (e) {
          if (
            e.hoNm == selecetdHoNm &&
            !(e.flrGbCdNm.includes("지상") && e.flrNoNm.includes("지하"))
          ) {
            info = e;
            return;
          }
        });
      }

      this.brExposPubuseAreaInfo = info;
      console.log(
        "final selected brExposPubuseAreaInfo ",
        this.brExposPubuseAreaInfo
      );

      console.log( "선택한 호수의 건물용도", this.brExposPubuseAreaInfo.mainPurpsCdNm);


      const yongDo = this.brExposPubuseAreaInfo.mainPurpsCdNm;

      //건물 종류 세팅
      this.setKind(yongDo);
    },
    //층 구분 선택시 - 현재 사용안함
    // floorGubunChange(floorGubun) {
    //   let floorArr = [];

    //   // 층 List생성 + 중복 제거
    //   this.brFlrOulnInfos.forEach(function (e) {
    //     if (e.flrGbCdNm == floorGubun && e.flrNoNm != "") {
    //       let idx = floorArr.findIndex((v) => v == e.flrNoNm);
    //       if (idx == -1) {
    //         floorArr.push(e.flrNoNm);
    //       }
    //     }
    //   });

    //   if (floorArr.length > 0) {
    //     this.floorArr = floorArr;
    //     this.isShowFloor = true;
    //   }
    // },

    //층 선택시 - 현재 사용안함
    // floorChange(selectedFloor, selectedFloorGubun) {
    //   let info = {};

    //   //층 구분이 있는 경우
    //   if (selectedFloorGubun) {
    //     this.brFlrOulnInfos.forEach(function (e) {
    //       if (e.flrGbCdNm == selectedFloorGubun && e.flrNoNm == selectedFloor) {
    //         info = e;
    //         return;
    //       }
    //     });

    //     //층 구분이 없는 경우
    //   } else {
    //     this.brFlrOulnInfos.forEach(function (e) {
    //       if (e.flrNoNm == selectedFloor) {
    //         info = e;
    //         return;
    //       }
    //     });
    //   }

    //   this.brFlrOulnInfo = info;

    //   console.log(info);

    //   let yongDo = this.brFlrOulnInfo.mainPurpsCdNm;
    //   this.setKind(yongDo);
    // },

    //text로 물건종류 찾기
    setKind(yongDo) {

      if (this.input.productKind) {
        this.input.productKind = null;
      }

      let kind = [];
      
      Product.kind.forEach(function (obj) {
        console.log(obj);
        if(yongDo == 'ALL'){
          kind.push(obj);
        }else if (obj.validText.includes(yongDo)) {
          kind.push(obj);
        }
      });

      if(kind.length > 0){
        this.kind = kind;
      }else{
        console.log('일치하는 건물용도 없음');

        if(this.brTitleInfo){
          if(this.brTitleInfo.mainPurpsCdNm == '공동주택'){
             
            let kind = [];

            kind.push(Product.kind[1]);
            this.kind = kind;
          }
        }
      }
    },

    /** 등기부 등본 소유자명 검증 */
    owners(e) {
      if (this.input.file || this.input.landFile) {
        
        this.owner = e.owners;

        if(e && e.caution && e.caution != null){
          this.caution = e.caution;
        }

        //소유자명
        const owner = e.owners.map((x) => {
          return x.owner;
        });

        //관계
        const ownership = e.owners.map((x) => {
          return x.ownership;
        });

        //소유자 명이 일치하고, 공동소유자가 존재하는 경우
        if (owner.includes(this.input.holder)) {
          if (ownership.includes("공유자") &&this.input.biddingJointName.length == 0) {
            alert("공동소유자가 존재합니다. 소유자 공동명의를 입력해주세요. ");
          }

        //소유자명이 일치하지 않는 경우
        }else if(!owner.includes(this.input.holder)){
          if (ownership.includes("공유자") &&this.input.biddingJointName.length == 0) {
            this.setKind2('지분');
            alert("공동소유자가 존재하며, 소유자명이 일치하지 않습니다. <br>"+ 
                  "정확한 소유자 명과, 소유자 공동명의를 입력해주세요. ");
          }else{
            alert( "입력하신 소유자명과 등기부등본의 소유자명이 일치하지 않습니다. ");
          }
        }
      } else {
        this.owner = null;
        this.caution = null;
      }
    },

    //text로 대출종류 찾기
    setKind2(e) {
      if (this.input.productKind2) {
        this.input.productKind2 = null;
        this.kind2 = null;
      }

      let kind2 = [];
      let value = null;

      Product.kind2.forEach(function (obj) {
        console.log('kind2', obj);
        if(e == 'ALL'){
          kind2.push(obj);
        }else if (obj.text.includes(e)) {
          kind2.push(obj);
          value = obj.value;
        }
      });

      if(kind2.length > 0){
        this.kind2 = kind2;
        this.input.productKind2 = value;
      }else{
        console.log('일치하는 대출종류 없음');
      }
    },

    //text로 물건 종류 찾기
    kindSelect(n) {
      let kind2 = [
        {
          text: "진행 물건 종류를 선택하세요.*",
          value: null,
        },
      ];

      Product.kind2.forEach(function (data) {
        data.key.forEach(function (obj) {
          if (obj === n) {
            kind2.push(data);
          }
        });
      });

      if (this.input.productKind2) {
        this.input.productKind2 = null;
      }

      this.kind2 = kind2;
    },

    //공동소유자명 list에 저장
    jointNameSave(item, i) {
      this.$set(this.input.biddingJointName, i, item);
      item.edit = !item.edit;
    },

    //세입자 정보 추가버튼
    addTenant(){
      if(!this.deposit){
        alert('보증금 입력은 필수입니다.');
        return;
      }

      this.input.tenants.push({
        tenantAgree : this.tenantAgree,
        deposit : this.priceToNumber(this.deposit),
        monthlyRent : this.monthlyRent ? this.priceToNumber(this.monthlyRent) : "0",
        edit: false,
      });

      this.deposit = null;
      this.tenantAgree = true;
      this.monthlyRent = null;

    },

    //세입자 정보 list에 저장
    tenantSave(item, i) {
      this.$set(this.input.tenants, i, item);
      item.edit = !item.edit;
    },

    //세입자 정보 수정
    editTenant(index) {
      this.input.tenants.forEach(function (el, idx) {
        if (index === idx) {
          el.edit = true;
        } else {
          el.edit = false;
        }
      }, this);
    },

    //추가한 세입자정보 list에서 삭제
    deleteTenant(i) {
      this.input.tenants.splice(i,1);
    },

    //공동소유자명 수정
    editJointName(index) {
      this.input.biddingJointName.forEach(function (el, idx) {
        if (index === idx) {
          el.edit = true;
        } else {
          el.edit = false;
        }
      }, this);
    },

    //공동소유자명 추가
    addJointName(jointNameHolder, jointNameRelation) {
      if (jointNameRelation !== null && jointNameHolder !== null) {
        this.input.biddingJointName.push({
          jointNameHolder: jointNameHolder,
          relation: jointNameRelation,
          edit: false,
        });

        this.jointNameHolder = null;
        this.jointNameRelation = null;
      } else if (jointNameHolder === null) {
        alert("공동 소유자명을 입력해주세요.");
      } else if (jointNameRelation === null) {
        alert("관계를 선택해주세요.");
      }
    },

    //공동소유자명 | 세입자 정보 수정 취소
    cancelBtn(item, i) {
      item.edit = !item.edit;
    },

    //추가한 공동소유자명 list에서 삭제
    deleteJointName(i) {
      this.input.biddingJointName.splice(i,1);
    },

    //매매자금 등록시 이미지파일 
    uploadImage(e){
      console.log(e);
      this.input.imageFile = {...e}
      this.input.imageUrl =
          typeof this.input.imageFile === "object" ? this.input.imageFile.path : null;
      console.log('this.input.imageFile', this.input.imageFile);
    },

    inputDate(e){
      console.log(e);
      let date = e.target.value.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
      this.tradingFunds.balanceDate = date;
    },

    async upload() {
      // 소유자명 미 일치
      if (this.owner && this.input.holder) {
        const owner = this.owner.map((x) => {
          return x.owner;
        });

        if (!owner.includes(this.input.holder)) {
          window.alert("정확한 소유자명을 입력해주세요.", { title: "소유자명 불일치" });
          return;
        }
      }

      // if(this.input.productKind2 === 'KIND2_2' && this.tradeDetailsMeta.withTenant){
        
      //   if(!this.deposit || !this.monthlyRent){
      //     window.alert("필수 값들을 모두 입력해주세요.", {
      //       title: "필수 값 미입력",
      //     });
      //     return;
      //   }else{
      //     this.input.tenants.push({
      //       tenantAgree : true,
      //       deposit : this.priceToNumber(this.deposit),
      //       monthlyRent : this.monthlyRent ? this.priceToNumber(this.monthlyRent) : "0",
      //       edit: false,
      //     });
  
      //     this.deposit = null;
      //     this.monthlyRent = null;
      //   }
      // }

      //0810 무설정론인 경우 등본첨부 필수 알림
      if ((this.input.productKind2 === "KIND2_4") & !this.input.file) {
        window.alert( `무설정론을 원하실 경우 </br> 등기부등본 첨부는 필수입니다.`,
          { title: "등기부등본 미첨부" }
        );
        return;
      }

      //재직상태가 신용결여가 아닌데, 신용현황에 대한 값이 있는 경우 초기화
      if (this.input.work !== "BADCREDIT") {
        this.input.creditScore = null;
        this.input.totalTimes = null;
        this.input.currentTimes = null;
        this.input.defaultTimes = null;
      }

      if(!this.input.jointName){
        // this.input.shareLoan = false;
        // this.input.selfRatio = null;
        this.input.biddingJointName = [];
      }

      //토지 담보 대출이 아니거나 물건 종류가 토지/단독/다가구가 아닌 경우 감정가 null
      if(!this.input.landColl && this.input.productKind !== 'KIND_5'){
        this.input.goingOrAppraisedPrice = null;
      }

      //동.호수 format
      if (this.selectedDong && this.selectedHo) {
        this.addressObj.addressDetail = this.selectedDong + " " + this.selectedHo;
      } else if (this.selectedHo) {
        this.addressObj.addressDetail = this.selectedHo;
      }else if(this.selectedDong){
        this.addressObj.addressDetail = this.selectedDong;
      }

      
      
      let {
        address,
        addressDetail,
        addressDetailMeta,
        jibunAddress,
        roadAddress,
      } = this.addressObj;

      //-----------
      this.submitted = true;

      let form = {
        ...this.input,

        address: roadAddress || jibunAddress,
        addressDetail: addressDetail || "",
        jibunAddress: jibunAddress,
        roadAddress: roadAddress,
        addressDetailMeta: {
          ...addressDetailMeta,
          dongNm: this.selectedDong ? this.selectedDong : "",
          hoNm: this.selectedHo ? this.selectedHo : "",
        },

        amount: this.input.amount
          ? this.priceToNumber(this.input.amount)
          : null,

        goingOrAppraisedPrice: this.input.goingOrAppraisedPrice
          ? this.priceToNumber(this.input.goingOrAppraisedPrice)
          : null,
        
        share:"",
        dateOfBirth: "",
        text: this.text,
        caution: this.caution,
        landText: this.landText,
        apiExistJointName: this.apiExistJointName,
        
        notInfo: this.notInfo,
        areaInfoFail: this.areaInfoFail,
        flrOulnInfoFail: this.flrOulnInfoFail,
        
        brTitleInfo: {...this.brTitleInfo},
        brFlrOulnInfo:  { ...this.brFlrOulnInfo },
        brExposPubuseAreaInfo: { ...this.brExposPubuseAreaInfo },

        tradingFunds: { 
          ...this.tradingFunds, 
          bondMaximum: this.tradingFunds.bondMaximum
            ? this.priceToNumber(this.tradingFunds.bondMaximum)
          : null,
          principal: this.tradingFunds.principal
            ? this.priceToNumber(this.tradingFunds.principal)
          : null,
        },


        // deposit: this.input.tenant
        //   ? this.priceToNumber(this.input.deposit)
        //   : null,
        // monthlyRent: this.input.tenant
        //   ? this.priceToNumber(this.input.monthlyRent)
        //   : null,
      };


      //등본파싱 후 가져온 생년월일 값 할당
      if (this.owner) {
        this.owner.some(function (e) {
          if (e.owner === form.holder) {
            form.dateOfBirth = e.dateOfBirth;
            form.share = e.share;
            return;
          }
        });
      }



      // if (this.landOwner) {
      //   this.landOwner.some(function (e) {
      //     if (e.owner === form.holder) {
      //       form.landShare = e.share;
      //       return;
      //     }
      //   });
      // }

      console.log(form);
      if (  
        // 주소: 없거나 빈 값
        !form.address || form.address === "" || 
        !form.landColl && (this.isShowDong && !this.selectedDong) || // 동: 셀렉트 보이고 선택 안했을 경우
        !form.landColl && (this.isShowHo && !this.selectedHo) || // 호수: 셀렉트 보이고 선택 안했을 경우
        !form.productKind || !form.productKind2 || // 물건종류, 대출종류: 없을 때
        !form.holder || form.holder === "" || // 소유자: 이름 없거나 빈 값
        !form.amount || //0928 요청금액
        !form.work || // 직장: 선택 안했을 때
        form.jointName && (form.biddingJointName.length == 0 || form.biddingJointName == null) || //소유자 공동명의 유 - 추가한 소유자명이 없을 경우
        // (form.tenant && !form.deposit) || // 세입자: 있을때 보증금과 월세 빈 값

        //0810 물건 징행종류가 매매자금 필수 입력사항
        (form.productKind2 === "KIND2_2" && !form.tradingFunds.seller) || 
        (form.productKind2 === "KIND2_2" && !form.tradingFunds.buyer) ||
        (form.productKind2 === "KIND2_2" && !form.tradingFunds.saleAmount) ||
        (form.productKind2 === "KIND2_2" && !form.tradingFunds.balanceDate) ||

        //매매자금 - 선순위 동시 진행시 필수 사항
        (form.productKind2 === "KIND2_2" && !form.tradingFunds.withTenant && !form.tradingFunds.bankName) ||
        (form.productKind2 === "KIND2_2" && !form.tradingFunds.withTenant && !form.tradingFunds.bondMaximum) ||
        (form.productKind2 === "KIND2_2" && !form.tradingFunds.withTenant && !form.tradingFunds.principal) ||

        //매매자금 - 세입자 끼고 진행시 필수 사항
        (form.productKind2 === "KIND2_2" && form.tradingFunds.withTenant && !form.tradingFunds.deposit) ||
        (form.productKind2 === "KIND2_2" && form.tradingFunds.withTenant && !form.tradingFunds.monthlyRent) ||

        //0810 무설정론 - 전화번호, 생년월일
        (form.productKind2 === "KIND2_4" && !form.phone) ||
        (form.productKind2 === "KIND2_4" && !form.birthDate) || 

       //  (form.jointName && !form.shareLoan && !form.selfRatio) || 0810 공동소유자가 있고 본인지분인 경우 지분율

        //토지, 단독주택 시세 입력
        (form.productKind === 'KIND_5' && !form.goingOrAppraisedPrice)  
      ) {
        window.alert("필수 값들을 모두 입력해주세요.", {
          title: "필수 값 미입력",
        });
        this.submitted = false;
      } else if (this.submitted) {
        if (!form.amount || form.amount === "") {
          form.amount = "0";
        }

        // 등기부 등본 직접등록시 aws파일 업로드
        if (this.input.file) {
          try {
            const formData = new FormData();
            formData.append("file", this.input.file);
            formData.append("type", "D");

            const { data } = await this.$axios.post("/files/image", formData);

            if (data.status.code === "0000") {
              form.registerFile = data.data;
              form.text = data.data.pdfText;
            }
          } catch (error) {
            window.alert("비딩매칭 등록에 실패하였습니다. - 등기부 등본 파일 등록 실패");
            this.submitted = false;
            console.log(error);
            return;
          }
        }

        // 등기부 등본 직접등록시 aws파일 업로드
        if (this.input.landFile) {
          try {
            const formData = new FormData();
            formData.append("file", this.input.landFile);
            formData.append("type", "D");

            const { data } = await this.$axios.post("/files/image", formData);

            if (data.status.code === "0000") {
              form.landRegisterFile = data.data;
              form.landText = data.data.pdfText;
            }
          } catch (error) {
            window.alert("비딩매칭 등록에 실패하였습니다. - 등기부 등본 파일 등록 실패");
            this.submitted = false;
            console.log(error);
          }
        }

        try {
          console.log("form", form); debugger;

          this.loadingInfo = {
            show: true,
            text: "비딩매칭 등록중입니다.",
          };

          const { data } = await this.$axios.post("/bidding/api", form);

          console.log("data :", data.data);

          if (data.status.code === "0000") {

            //등기부 등본 api발급시 공동소유자명이 존재하거나, 소유자명이 다른 경우
            if (data.data.apiExistJointName || data.data.notMatchedHolder) {

              this.text = data.data.text; //api로 받은 등기부 등본 파일 정보
              this.landText = data.data.landText;
              this.input.registerFile = data.data.registerFile;
              this.input.landRegisterFile = data.data.landRegisterFile;
              this.owner = data.data.pdfCheckResBody.owners; //등기부 등본상의 소유자명과 생년월일
              this.caution = data.data.pdfCheckResBody.caution;

              if(data.data.notMatchedHolder && data.data.apiExistJointName){
                await window.alert(`입력하신 소유자명이 일치하지 않으며(필수)</br> 
                  공동 소유자가 존재합니다.(미필수)</br>
                  수정 후 다시 등록 해주세요.</br>
                 `,
                  { title: "소유자명 불일치" }
                );
                this.submitted = false;
              }else if (data.data.notMatchedHolder) {
                //소유자명 불일치시
                await window.alert(
                  `입력하신 소유자명이 일치하지 않습니다.</br> 소유자명 수정 후 다시 등록 해주세요.`,
                  { title: "소유자명 불일치" }
                );
                this.submitted = false;
              } else if (data.data.apiExistJointName) {
                //공동소유자명 존재시
                const bool = await window.confirm(
                  "공동 소유자가 존재합니다. </br> 추가 하시겠습니까 ?",
                  {
                    title: "공동 소유자 존재",
                    bodyClass: "text-center",
                    okTitle: "추가",
                  }
                );

                if (bool) {
                  this.submitted = false;
                  this.loadingInfo = {
                    show: false,
                    text: null,
                  };
                  return;
                } else {
                  this.upload();
                }
              }
              
            } else {
              if (data.data.mortgage) {
                this.apiData = data.data;
                return this.$bvModal.show("modal-mortgage-check");
              } else {
                this.finalUpload(data.data);
              }
            }
          } else {
            window.alert(data.status.message);
          }
        } catch (error) {
          console.log("upload error", error);
          window.alert("비딩매칭 등록에 실패하였습니다.");
        } finally {
          this.submitted = false;
          this.loadingInfo = {
            show: false,
            text: null,
          };
        }
      }
    },

    async finalUpload(form) {

      try{
        
        this.loadingInfo = {
          show: true,
          text: "비딩매칭 등록중입니다.",
        };

        const { data } = await this.$axios.post("/bidding", form);
        console.log("finalUpload", data);

        if (data.status.code === "0000") {debugger;
          const bool = await window.alert(`비딩매칭이 등록되었습니다.`, {
            title: "비딩 매칭 업로드 완료",
          });
          if (bool) {
            this.$router.push(`/matching/${data.data.id}`);
          }
        }
      }catch(e){
        console.log(e);
      }finally{
        this.submitted = false;
        this.loadingInfo = {
          show: false,
          text: null,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
