<template>
  <b-container class="my-5 py-5">
    <b-row align-h="center">
      <b-col cols="10" md="8" lg="6">
        <header
          class="
            pb-4 pb-md-5
            d-flex
            flex-column
            align-items-center
            justify-content-center
          "
        >
          <h2 class="mb-3 text-18 text-lg-24">비딩매칭 수정</h2>
          <span class="text-14 text-gray fw-400"
            >비딩 매칭 내용을 수정하실 수 있습니다.</span
          >
          <b-badge
            variant="white"
            class="mt-3 px-4 py-1 border-light-gray border rounded-pill"
          >
            <span class="text-13 text-gray">
              남은 수정 횟수
              <strong class="text-error"> {{ input.possibleUpdate }} </strong>회
            </span>
          </b-badge>
        </header>
        <b-form @submit.prevent.stop="edit" class="mt-4">
          <!-- ============================ 기본 정보 ============================ -->
          <fieldset>
            <legend class="mb-4 p-3 text-17 bg-primary text-white">
              기본 정보
            </legend>
            <!-- 토지담보 대출 여부  -->
            <template>
              <span class="text-17 text-danger">
                <font-awesome-icon icon="fa-solid fa-asterisk" />토지담보 대출 여부
              </span>
              <b-form-radio-group 
                v-model="input.landColl" 
                class="mb-4" 
                variant="outline" hide-prepend
                @change="changeLandColl()"
                disabled
                >
                  <b-form-radio
                    class="fw-400 d-inline-flex mr-5"
                    :value="true"
                  >
                    YES
                  </b-form-radio>
                  <b-form-radio class="fw-400 d-inline-flex" :value="false">
                    NO
                  </b-form-radio>
              </b-form-radio-group>
            </template>

            <!-- (필수)주소 -->
            <template>
              <span class="text-17 text-danger">
                <font-awesome-icon icon="fa-solid fa-asterisk" />주소 - 필수
              </span>
              <b-input-group class="mb-4" variant="outline" hide-prepend>
                <b-form-input
                  type="text"
                  v-model="input.address"
                  placeholder="주소*"
                  disabled
                ></b-form-input>
              </b-input-group>
            </template>
            <!-- (필수)상세주소 -->
            <template v-if="input.addressDetail">
              <span class="text-17 text-danger">
                <font-awesome-icon icon="fa-solid fa-asterisk" />상세주소 - 필수
              </span>
              <b-input-group class="mb-4" variant="outline" hide-prepend>
                <b-form-input
                  type="text"
                  v-model="input.addressDetail"
                  placeholder="상세 주소"
                  disabled
                ></b-form-input>
              </b-input-group>
            </template>
            <!-- (필수)물건종류 -->
            <template>
              <span class="text-17 text-danger">
                <font-awesome-icon icon="fa-solid fa-asterisk" />물건 종류 - 필수
              </span>
              <b-form-select
                v-model="input.productKind"
                :options="kind"
                class="mb-4"
                disabled
              ></b-form-select>
            </template>
            <!-- (필수)대출종류 -->
            <template>
              <span class="text-17 text-danger">
                <font-awesome-icon icon="fa-solid fa-asterisk" />대출 종류 - 필수
              </span>
              <b-form-select
                v-model="input.productKind2"
                :options="kind2"
                class="mb-4"
                disabled
              ></b-form-select>
            </template>
            <!-- (필수)소유자 -->
            <template>
              <span class="text-17 text-danger">
                <font-awesome-icon icon="fa-solid fa-asterisk" />소유자명 - 필수
              </span>
              <b-input-group class="" variant="outline" hide-prepend>
                <b-form-input
                  type="text"
                  v-model="input.holder"
                  placeholder="소유자*"
                  disabled
                ></b-form-input>
              </b-input-group>
            </template>
          </fieldset>

          <template v-if="input.productKind === 'KIND_5'">
            <fieldset>
              <legend class="mb-4 p-3 text-17 bg-primary text-white">
                건축물 감정가 또는 시세
              </legend>
              <b-row>
                <b-col>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-input-group
                    class="mb-4"
                    variant="outline"
                    hide-prepend
                  >
                    <b-form-input
                      type="text"
                      v-model="input.goingOrAppraisedPrice"
                      placeholder="감정가 또는 시세*"
                      @input.native="$inputPrice($event, 'goingOrAppraisedPrice')"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </fieldset>
          </template>

          <!-- 0810 진행종류가 매매자금인 경우 필수 입력 추가  -->
          <template v-if="input.productKind2==='KIND2_2'">
            <fieldset>
              <legend class="mb-4 p-3 text-17 bg-primary text-white">
                매매내용
              </legend>
              <b-row>
                <b-col>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <!-- 20230622 매도자 명칭 to 소유자 -->
                  <b-input-group class="mb-4" variant="outline" hide-prepend>
                    <b-form-input
                      type="text"
                      v-model="input.tradingFunds.seller"
                      placeholder="소유자*"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-input-group class="mb-4" variant="outline" hide-prepend>
                    <b-form-input
                      type="text"
                      v-model="input.tradingFunds.buyer"
                      placeholder="매수자*"
                    ></b-form-input>
                </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-input-group class="mb-4" variant="outline" hide-prepend>
                    <b-form-input
                      type="text"
                      v-model="input.tradingFunds.saleAmount"
                      placeholder="매매금액*"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-input-group class="mb-4" variant="outline" hide-prepend>
                    <b-form-input
                      type="text"
                      v-model="input.tradingFunds.balanceDate"
                      placeholder="잔금일*"
                    ></b-form-input>
                </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-form-radio-group v-model="input.tradingFunds.withTenant">
                    <b-form-radio class="fw-400 d-inline-flex mr-5" :value="false">
                      선순위 은행 동시진행
                    </b-form-radio>
                    <b-form-radio class="fw-400 d-inline-flex" :value="true">
                      세입자끼고
                    </b-form-radio>
                  </b-form-radio-group>
                </b-col>
              </b-row>
              <b-row v-if="input.tradingFunds.withTenant">
                <b-col>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-input-group
                    class="mb-4"
                    variant="outline"
                    hide-prepend
                  >
                    <b-form-input
                      type="text"
                      placeholder="보증금*"
                      v-model="input.tradingFunds.deposit"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-input-group
                    class="mb-4"
                    variant="outline"
                    hide-prepend
                  >
                    <b-form-input
                      type="text"
                      placeholder="월세*"
                      v-model="input.tradingFunds.monthlyRent"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <!-- 20230622 세입자 끼고 진행 추후 데이터 바인딩 필요-->
              <b-row v-if="!input.tradingFunds.withTenant">
                <b-col>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-input-group
                    class="mb-4"
                    variant="outline"
                    hide-prepend
                  >
                    <b-form-input
                      type="text"
                      placeholder="은행명*"
                      v-model="input.tradingFunds.bankName"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-input-group
                    class="mb-4"
                    variant="outline"
                    hide-prepend
                  >
                    <b-form-input
                      type="text"
                      v-model="input.tradingFunds.principal"
                      @input.native="$inputPrice3($event, 'input', 'tradingFunds', 'principal')"
                      placeholder="원금*"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-input-group
                    class="mb-4"
                    variant="outline"
                    hide-prepend
                  >
                    <b-form-input
                      type="text"
                      v-model="input.tradingFunds.bondMaximum"
                      @input.native="$inputPrice3($event, 'input', 'tradingFunds', 'bondMaximum')"
                      placeholder="채권최고액*"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                
              </b-row>
            </fieldset>
          </template>
          <!-- ============================ 소유자 공동명의 ====================== -->
          <fieldset class="mt-5">
            <legend class="mb-4 p-3 text-17 bg-primary text-white">
              소유자 공동명의
            </legend>
            <!-- 공동명의 유무 -->
            <template>
              <b-form-radio-group v-model="input.jointName">
                <b-form-radio class="fw-400 d-inline-flex mr-5" :value="true">
                  유 (2인 이상)
                </b-form-radio>
                <b-form-radio class="fw-400 d-inline-flex" :value="false">
                  무
                </b-form-radio>
              </b-form-radio-group>
              <!-- 공동 소유자 / 지분 대출 SHOW -->
              <template v-if="input.jointName">
                <span class="text-17 text-danger">
                  <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                </span>
                <!-- 공동 소유자 입력 -->
                <b-input-group
                  class="mt-3"
                  variant="outline"
                  hide-prepend
                  :style="{ gap: '1rem' }"
                >
                  <w-input
                    class="m-0"
                    type="text"
                    ref="jointNameHolder"
                    v-model="jointNameHolder"
                    placeholder="공동 소유자명*"
                  ></w-input>
                  <b-form-select v-model="jointNameRelation">
                    <b-form-select-option
                      v-for="item in relation"
                      v-bind:key="item.id"
                      :value="item.value"
                      >{{ item.text }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-btn
                    variant="primary"
                    v-on:click="add(jointNameHolder, jointNameRelation)"
                  >
                    추가
                  </b-btn>
                </b-input-group>
                <!-- 공동 소유자 출력 -->
                <template v-if="input.jointName">
                  <div
                    v-for="(item, i) in input.biddingJointName"
                    :key="i"
                    class="align-items-center text-17"
                  >
                    <template v-if="!item.delete">
                      <template v-if="!item.edit">
                        <b-input-group
                          variant="outline"
                          class="mt-3 justify-content-between"
                          hide-prepend
                          :style="{ gap: '1rem' }"
                        >
                          <w-input
                            readonly
                            class="m-0"
                            type="text"
                            ref="jointNameHolder"
                            v-model="item.jointNameHolder"
                          ></w-input>
                          <b-form-select v-model="item.relation" disabled>
                            <b-form-select-option
                              v-for="item in relation"
                              v-bind:key="item.id"
                              :value="item.value"
                              >{{ item.text }}
                            </b-form-select-option>
                          </b-form-select>
                          <b-btn
                            size="sm"
                            variant="outline-dark"
                            @click="editBtn(i)"
                            >수정</b-btn
                          >
                          <b-btn
                            size="sm"
                            variant="outline-danger"
                            @click="deleteBtn(item, i)"
                            >삭제</b-btn
                          >
                        </b-input-group>
                      </template>
                      <template v-else>
                        <b-input-group
                          variant="outline"
                          class="mt-3"
                          hide-prepend
                          :style="{ gap: '1rem' }"
                        >
                          <w-input
                            class="m-0"
                            type="text"
                            ref="jointNameHolder"
                            v-model="item.jointNameHolder"
                            placeholder="공동 소유자명"
                          ></w-input>
                          <b-form-select v-model="item.relation">
                            <b-form-select-option
                              v-for="item in relation"
                              v-bind:key="item.id"
                              :value="item.value"
                              >{{ item.text }}
                            </b-form-select-option>
                          </b-form-select>
                          <b-btn
                            size="sm"
                            variant="outline-dark"
                            @click="jointNameSave(item, i)"
                            >저장</b-btn
                          >
                          <b-btn
                            size="sm"
                            variant="outline-danger"
                            @click="cancelBtn(item, i)"
                            >취소</b-btn
                          >
                        </b-input-group>
                      </template>
                    </template>
                  </div>
                </template>
                <!-- 지분 대출 -->
                <!-- <div>
                  <legend class="mb-3 p-3 text-17 bg-lighter">지분 대출</legend>
                  <b-form-radio-group 
                    v-model="input.shareLoan"
                    @change="changeShareLoan()">
                    <b-form-radio
                      class="fw-400 d-inline-flex mr-5"
                      :value="false"
                    >
                      본인지분
                    </b-form-radio>
                    <b-form-radio class="fw-400 d-inline-flex" :value="true">
                      공동지분
                    </b-form-radio>
                  </b-form-radio-group>
                  <span class="text-17 text-danger" v-if="!input.shareLoan">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <w-input
                    class="m-0"
                    type="text"
                    ref="jointNameHolder"
                    v-model="input.selfRatio"
                    placeholder="본인 지분율*"
                    @input.native="$inputDecimalPoint($event, 'selfRatio')"
                    v-if="!input.shareLoan"
                  ></w-input>
                </div> -->
              </template>
            </template>
          </fieldset>
          <div class="mt-5">
            <legend class="mb-4 p-3 text-17 bg-lighter">
              소유자 연락처 / 요청금액
            </legend>
            <!-- 전화번호 -->
            <template>
              <!-- 0810 진행종류가 무설정론인 경우 필수 표시 추가  -->
              <span class="text-17 text-danger" v-if="input.productKind2==='KIND2_4'">
                <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
              </span>
              <b-input-group class="mb-4" variant="outline" hide-prepend>
                <w-input
                  class="w-100 m-0"
                  type="tel"
                  v-model="input.phone"
                  @input.native="$numberOnly($event, 'phone', 11)"
                  placeholder="소유자 전화번호"
                ></w-input>
              </b-input-group>
              <!-- 0810 진행종류가 무설정론인 경우 필수 입력 추가  -->
              <b-input-group v-if="input.productKind2==='KIND2_4'" class="mb-4" variant="outline" hide-prepend>
                <span class="text-17 text-danger">
                  <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                </span>
                <w-input
                  class="w-100 m-0"
                  type="tel"
                  v-model="input.birthDate"
                  @input.native="$numberOnly($event, 'birthDate', 8)"
                  placeholder="생년월일 ex)19910405"
                ></w-input>
              </b-input-group>
            </template>
            <!-- 요청금액 -->
            <template>
              <span class="text-17 text-danger">
                <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
              </span>
              <b-input-group class="mb-0" variant="outline" hide-prepend>
                <w-input
                  class="w-100 m-0"
                  type="text"
                  v-model="input.amount"
                  @input.native="$inputPrice($event, 'amount')"
                  placeholder="요청금액(만원)"
                ></w-input>
              </b-input-group>
            </template>
          </div>
          <!-- ============================ 재직 정보 ============================ -->
          <fieldset class="mt-5">
            <legend class="mb-4 p-3 text-17 bg-primary text-white">
              재직 정보
            </legend>
            <!-- (필수)재직정보 -->
            <template>
              <p class="text-17">
                <span class="text-17 text-danger">
                  <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                </span>
                소유자 재직상태를 선택해주세요.
                <span class="fw-900" v-if="input.work"
                  >( {{ input.work }} )
                </span>
              </p>
              <b-form-radio-group
                stacked
                :options="work"
                v-model="input.work"
                class="fw-400 text-17"
              ></b-form-radio-group>
              <!-- <template v-if="input.work === 'BADCREDIT'">
              <fieldset class="mt-5">
                <b-form-group>
                  <b-form-radio-group
                    :options="creditMatters"
                    v-model="input.creditMatters"
                    class="fw-400 d-md-flex justify-content-between"
                  ></b-form-radio-group>
                </b-form-group>
              </fieldset>
            </template> -->
              <!-- 신용결여 SHOW -->
              <template v-if="input.work == 'BADCREDIT'">
                <legend class="my-3 p-3 text-17 bg-lighter">신용 현황</legend>
                <b-row>
                  <b-col>
                    <b-form-input
                      type="text"
                      v-model="input.creditScore"
                      placeholder="신용점수"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <b-form-input
                      type="text"
                      v-model="input.totalTimes"
                      placeholder="총 납기회차"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <b-form-input
                      type="text"
                      v-model="input.currentTimes"
                      placeholder="현재 회차"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <b-form-input
                      type="text"
                      v-model="input.defaultTimes"
                      placeholder="미납 회차"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </template>
            </template>
          </fieldset>
          <!-- ============================ 세입자 정보 ========================== -->
          <!-- 매매자금 세입자 끼고 진행시엔 안 보이게 -->
          <fieldset class="mt-5" v-if="input.prodctKind2 !== 'KIND2_2' && !input.tradingFunds.withTenant">
            <legend class="mb-4 p-3 text-17 bg-primary text-white">
              세입자 정보
            </legend>
            <!-- 세입자 유무 -->
            <b-form-radio-group v-model="tenant">
              <b-form-radio class="fw-400 d-inline-flex mr-5" :value="true">
                유
              </b-form-radio>
              <b-form-radio class="fw-400 d-inline-flex" :value="false">
                무
              </b-form-radio>
            </b-form-radio-group>
            <!-- 세입자 상세정보 SHOW -->
            <template v-if="tenant">
              <legend class="my-3 p-3 text-17 bg-lighter">
                <span class="text-17 text-danger">
                  <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                </span>
                상세 정보
              </legend>
              <b-row>
                <b-col>
                  <b-input-group class="m-0" variant="outline" hide-prepend>
                    <w-input
                      class="w-100 m-0"
                      type="text"
                      v-model="deposit"
                      @input.native="$price($event, 'deposit')"
                      placeholder="보증금(만원)*"
                    ></w-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <b-input-group class="m-0" variant="outline" hide-prepend>
                    <w-input
                      class="w-100 m-0"
                      type="text"
                      v-model="monthlyRent"
                      @input.native="$price($event, 'monthlyRent')"
                      placeholder="월세(만원)*"
                    ></w-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <b-form-radio-group v-model="tenantAgree">
                    <b-form-radio
                      class="fw-400 d-inline-flex mr-5"
                      :value="true"
                    >
                      대출 동의
                    </b-form-radio>
                    <b-form-radio
                      class="fw-400 d-inline-flex"
                      :value="false"
                    >
                      대출 미동의
                    </b-form-radio>
                  </b-form-radio-group>
                </b-col>
                <b-col>
                  <b-btn
                    v-if="input.productKind == 'KIND_3' || tenants.length == 0"
                    variant="primary"
                    v-on:click="addTenant()"
                  >추가</b-btn>
                </b-col>
              </b-row>
            </template>
            <!-- 세입자 정보 출력 -->
            <template v-if="tenant">
              <div
                v-for="(item, i) in tenants"
                :key="i"
                class="align-items-center text-17"
              >
                <template v-if="!item.delete">
                  <template v-if="!item.edit">
                    <b-row>
                      <b-col>
                        <b-input-group class="m-0" variant="outline" hide-prepend>
                          <w-input
                            readonly
                            class="w-100 m-0"
                            type="text"
                            v-model="item.deposit"
                          ></w-input>
                        </b-input-group>
                      </b-col>
                      <b-col>
                        <b-input-group class="m-0" variant="outline" hide-prepend>
                          <w-input
                            readonly
                            class="w-100 m-0"
                            type="text"
                            v-model="item.monthlyRent"
                          ></w-input>
                        </b-input-group>
                      </b-col>
                      <b-col>
                        <b-form-radio-group disabled v-model="item.tenantAgree">
                          <b-form-radio
                            class="fw-400 d-inline-flex mr-5"
                            :value="true"
                          >
                            대출 동의
                          </b-form-radio>
                          <b-form-radio
                            class="fw-400 d-inline-flex"
                            :value="false"
                          >
                            대출 미동의
                          </b-form-radio>
                        </b-form-radio-group>
                      </b-col>
                      <b-btn
                        size="sm"
                        variant="outline-dark"
                        @click="editTenant(i)"
                      >수정</b-btn>
                      <b-btn
                        size="sm"
                        variant="outline-danger"
                        @click="deleteTenant(i)"
                      >삭제</b-btn>
                    </b-row>
                  </template>
                  <template v-else>
                    <b-row>
                      <b-col>
                        <b-input-group class="m-0" variant="outline" hide-prepend>
                          <w-input
                            class="w-100 m-0"
                            type="text"
                            v-model="item.deposit"
                          ></w-input>
                        </b-input-group>
                      </b-col>
                      <b-col>
                        <b-input-group class="m-0" variant="outline" hide-prepend>
                          <w-input
                            class="w-100 m-0"
                            type="text"
                            v-model="item.monthlyRent"
                          ></w-input>
                        </b-input-group>
                      </b-col>
                      <b-col>
                        <b-form-radio-group v-model="item.tenantAgree">
                          <b-form-radio
                            class="fw-400 d-inline-flex mr-5"
                            :value="true"
                          >
                            대출 동의
                          </b-form-radio>
                          <b-form-radio
                            class="fw-400 d-inline-flex"
                            :value="false"
                          >
                            대출 미동의
                          </b-form-radio>
                        </b-form-radio-group>
                      </b-col>
                      <b-btn
                        size="sm"
                        variant="outline-dark"
                        @click="tenantSave(item, i)"
                      >저장</b-btn>
                      <b-btn
                        size="sm"
                        variant="outline-danger"
                        @click="cancelBtn(item, i)"
                      >취소</b-btn>
                    </b-row>
                  </template>
                </template>
              </div>
            </template>
          </fieldset>
          <template>  
            <b-row class="my-5 text-center">
              <b-col cols="12" md="6">
                <b-btn
                  variant="outline-primary"
                  class="w-100 py-3"
                  @click="$router.go(-1)"
                  >취소</b-btn
                >
              </b-col>
              <b-col cols="12" md="6">
                <b-btn
                  variant="primary"
                  class="w-100 py-3"
                  @click="edit"
                  :disabled="submitted"
                >
                  {{ submitted ? "수정중입니다..." : "매칭 등록" }}
                </b-btn>
              </b-col>
            </b-row>
          </template>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Product from "@/lib/product";
export default {
  data() {
    return {
      input: {
        address: null,
        addressDetail: null,
        amount: null,
        
        holder: null,
        
        phone: null,
        productKind: null,
        productKind2: null,
        registerFile: null,

        totalTimes: null,
        currentTimes: null,
        defaultTimes: null,

        work: null,
        creditScore: null,
        shareLoan: false,
        
        jointName: false,
        biddingJointName: [
          {
            jointNameHolder: null,
            relation: null,
          },
        ],

        selfRatio: null,      //0810 공동소유자 & 본인지분의 지분율 변수
        birthDate: null,      //0810 무설정론인 경우 생년월일 변수
        
        //0810 매매자금 경우 변수map
        tradingFunds: {
          id: null,
          deposit: null,
          monthlyRent: null,
          seller: null,
          buyer: null,
          bankName: null,
          saleAmount: null,
          bondMaximum: null,
          principal: null,
          balanceDate: null,
          withTenant :false
        }, 

        goingOrAppraisedPrice: null,
      },
      jointNameHolder: null,
      jointNameRelation: null,

      
      tenant: false,
      tenants: [
        {
          deposit: null,
          monthlyRent: null,
          tenantAgree: true,
          // edit:false,
          // delete:false,
        }
      ],
      tenantAgree: true,
      deposit: null,
      monthlyRent: null,

      work: [
        {
          text: "직장인",
          value: "EMPLOYEE",
        },
        {
          text: "주부(무직)",
          value: "HOUSEWIFE_N",
        },
        {
          text: "주부(생활비 내역 있음)",
          value: "HOUSEWIFE_Y",
        },
        {
          text: "실사업자",
          value: "BUSINESSMAN_Y",
        },
        {
          text: "가라사업자(매출 증빙 X)",
          value: "BUSINESSMAN_N",
        },
        {
          text: "프리랜서(통장증빙 가능)",
          value: "FREELANCER",
        },
        {
          text: "신용결여(회복/회생/파산/워크아웃 등)",
          value: "BADCREDIT",
        },
        {
          text: "외국인 거소증 유",
          value: "FOREIGNER_RESIDENT_Y",
        },
        {
          text: "외국인 거소증 무",
          value: "FOREIGNER_RESIDENT_N",
        },
      ],
      relation: [
        {
          text: "관계를 선택하세요. *",
          value: null,
        },
        {
          text: "부부",
          value: "MARRIED",
        },
        {
          text: "부모",
          value: "PARENT",
        },
        {
          text: "형제",
          value: "BROTHRES",
        },
        {
          text: "제 3자",
          value: "OTHER_PERSON",
        },
      ],
      submitted: false,
    };
  },
  computed: {
    idBidding() {
      return this.$route.params.matching_id;
    },
    kind() {
      return [
        {
          text: "진행 물건 종류를 선택하세요.*",
          value: null,
        },
        ...Product.kind,
      ];
    },
    kind2() {
      return [
        {
          text: "진행 대출 종류를 선택하세요.*",
          value: null,
        },
        ...Product.kind2,
      ];
    },
    jointNameSet() {
      return this.input.biddingJointName.forEach(function (el) {
        this.$set(el, "edit", false);
        this.$set(el, "delete", false);
      }, this);
    },
  },
  methods: {
    //세입자 정보 list에 저장
    tenantSave(item, i) {
      this.$set(this.tenants, i, item);
      item.edit = !item.edit;
    },

    //기존 데이터 삭제시 Back
    //새로 추가한 데이터 삭제는 List에서 바로 삭제
    deleteTenant(i) {
      console.log('deleteTenant', i);
      if(!this.tenants[i].id){
        console.log(i);
        this.tenants.splice(i,1);
      }else{
        this.$set(this.tenants[i], 'delete', true);
      }
    },
    //세입자 정보 추가버튼
    addTenant(){
      if(!this.deposit){
        alert('보증금 입력은 필수입니다.');
        return;
      }

      this.tenants.push({
        tenantAgree : this.tenantAgree,
        deposit : this.priceToNumber(this.deposit),
        monthlyRent : this.monthlyRent 
          ? this.priceToNumber(this.monthlyRent) 
          : "0",
        edit: false,
        delete:false,
        add: true
      });

      this.deposit = null;
      this.tenantAgree = true;
      this.monthlyRent = null;
    },
    //세입자 정보 수정
    editTenant(index) {
      //this.$set(this.tenants[index], "edit", true);
      this.tenants.forEach(function (el, idx) {
        if (index === idx) {
          this.$set(el, "edit", true);
          //el.edit = true;
        } else {
          //el.edit = false;
          this.$set(el, "edit", false);
        }
      }, this);
    },
    changeShareLoan(){
      if(this.input.shareLoan){
        this.input.selfRatio = null;
      }
    },
    async getItem() {
      try {
        console.log('data before ::: ', this.input.tradingFunds);
        const { data } = await this.$axios.get(`/bidding/${this.idBidding}`);
        if (data.status.code === "0000") {
          this.input = data.data;
          console.log('data after ::: ', this.input.tradingFunds);

          // 금액 콤마세팅
          this.input.amount = this.toLocale(this.input.amount);

          if(this.input.tradingFunds){
            this.input.tradingFunds.bondMaximum = this.input.tradingFunds.bondMaximum 
              ? this.toLocale(this.input.tradingFunds.bondMaximum ) : '';
  
            this.input.tradingFunds.principal = this.input.tradingFunds.principal 
              ? this.toLocale(this.input.tradingFunds.principal ) : '';
          }else{
            this.input.tradingFunds = {
              id: null,
              deposit: null,
              monthlyRent: null,
              seller: null,
              buyer: null,
              bankName: null,
              saleAmount: null,
              bondMaximum: null,
              principal: null,
              balanceDate: null,
              withTenant :false
            }
          }

          // this.input.deposit = this.toLocale(this.input.deposit);
          // this.input.monthlyRent = this.toLocale(this.input.monthlyRent);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getJointName() {
      try {
        const { data } = await this.$axios.get(
          `/bidding/${this.idBidding}/jointName`
        );
        if (data.status.code === "0000") {
          this.input.biddingJointName = data.data;
          this.jointNameSet;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getBidder() {
      try {
        const { data } = await this.$axios.get(
          `/bidding/${this.idBidding}/bidder`
        );
        if (data.status.code === "0000") {
          this.input.bidders = data.data.content;
        }
      } catch (error) {
        console.log(error);
      }
    },
    getWork(work) {
      const text = {
        EMPLOYEE: "직장인",
        HOUSEWIFE_N: "주부(무직)",
        HOUSEWIFE_Y: "주부(생활비 내역 있음)",
        BUSINESSMAN_Y: "실사업자",
        BUSINESSMAN_N: "가라사업자(매출증빙 X)",
        FREELANCER: "프리랜서(소득증빙가능)",
        BADCREDIT: "신용결여(회복/회생/파산/워크아웃 등)",
        FOREIGNER_RESIDENT_Y: "외국인 거소증 유",
        FOREIGNER_RESIDENT_N: "외국인 거소증 무",
        ERROR: "에러",
      };
      return text[work] || text.error;
    },
    getJoint(jointNameRelation) {
      const text = {
        MARRIED: "부부",
        PARENT: "부모",
        BROTHRES: "형제",
        OTHER_PERSON: "제3자",
        ERROR: "에러",
      };
      return text[jointNameRelation] || text.error;
    },
    dongHo(str = this.input.addressDetail) {
      if (!str) return null;
      else {
        const rules = {
          dongho: /[$동$호]/,
          dong: /[$동]/,
          ho: /[$호]/,
        };
        const result = str.split(rules.dongho);
        if (
          rules.dongho.test(str) &&
          rules.dong.test(str) &&
          rules.ho.test(str)
        ) {
          // 동, 호가 있는 경우
          return {
            dong: result[0].replace(" ", ""),
            ho: result[1].replace(" ", ""),
          };
        } else if (
          rules.dongho.test(str) &&
          !rules.dong.test(str) &&
          rules.ho.test(str)
        ) {
          // 호만 있는 경우
          return {
            dong: "",
            ho: result[0].replace(" ", ""),
          };
        } else {
          // 동호 모두 없음
          return {
            dong: "",
            ho: "",
          };
        }
      }
    },
    jointNameSave(item, i) {
      this.$set(this.input.biddingJointName, i, item);
      item.edit = !item.edit;
    },
    editBtn(index) {
      this.input.biddingJointName.forEach(function (el, idx) {
        if (index === idx) {
          el.edit = true;
        } else {
          el.edit = false;
        }
      }, this);
    },
    add(jointNameHolder, jointNameRelation) {
      if (jointNameRelation !== null && jointNameHolder !== null) {
        this.input.biddingJointName.push({
          jointNameHolder: jointNameHolder,
          relation: jointNameRelation,
          edit: false,
          delete: false,
        });

        this.jointNameHolder = null;
        this.jointNameRelation = null;
      } else if (jointNameHolder === null) {
        alert("공동 소유자명을 입력해주세요.");
      } else if (jointNameRelation === null) {
        alert("관계를 선택해주세요.");
      }
    },
    cancelBtn(item, i) {
      item.edit = !item.edit;
    },
    deleteBtn(item, i) {
      item.delete = true;
      this.$set(this.input.biddingJointName, i, item);
    },
    async edit() {

      this.submitted = true;

      if(this.input.jointName && this.input.biddingJointName && this.input.biddingJointName.length > 0){
        var biddingJointName = this.input.biddingJointName;
        
        biddingJointName = biddingJointName.filter(function (e) {
            return e.delete;
          });

        if((this.input.biddingJointName.length - biddingJointName.length) == 0){
          window.alert("필수 값들을 모두 입력해주세요.", {
            title: "필수 값 미입력",
          });
          this.submitted = false;
          return;
        }
      }else if(this.input.jointName && this.input.biddingJointName.length == 0){
        window.alert("필수 값들을 모두 입력해주세요.", {
            title: "필수 값 미입력",
          });
          this.submitted = false;
          return;
      }

      if (
        
        //기본 정보
        !this.input.address || this.input.address === "" || 

        !this.input.work ||
        !this.input.holder || this.input.holder === "" || 
        !this.input.productKind || this.input.productKind === "" ||   
        !this.input.productKind2 || this.input.productKind2 === "" ||

        //0810 매매자금 - 매도자, 매수자, 매매금액, 잔금일
        (this.input.productKind2 === "KIND2_2" && !this.input.tradingFunds.seller) || 
        (this.input.productKind2 === "KIND2_2" && !this.input.tradingFunds.buyer) ||
        (this.input.productKind2 === "KIND2_2" && !this.input.tradingFunds.saleAmount) ||
        (this.input.productKind2 === "KIND2_2" && !this.input.tradingFunds.balanceDate) ||
        
        //매매자금(세입자) - 보증금, 월세
        (this.input.productKind2 === "KIND2_2" && this.input.tradingFunds.withTenant && !this.input.tradingFunds.deposit) ||
        (this.input.productKind2 === "KIND2_2" && this.input.tradingFunds.withTenant && !this.input.tradingFunds.monthlyRent) ||

        //매매자금(선순위) - 은행명, 원금, 채권최고액 
        (this.input.productKind2 === "KIND2_2" && !this.input.tradingFunds.withTenant && !this.input.tradingFunds.bankName) ||
        (this.input.productKind2 === "KIND2_2" && !this.input.tradingFunds.withTenant && !this.input.tradingFunds.principal) ||
        (this.input.productKind2 === "KIND2_2" && !this.input.tradingFunds.withTenant && !this.input.tradingFunds.bondMaximum) ||

        //0810 본인 지분율 필수값 
        // (this.input.jointName && !this.input.shareLoan && !this.input.selfRatio) ||
        
        //0810 무설정론  전화번호, 생년월일 
        (this.input.productKind2 === 'KIND2_4' && !this.input.phone) ||
        (this.input.productKind2 === 'KIND2_4' && !this.input.birthDate) ||

        // (this.input.jointName && !this.input.shareLoan && !this.input.selfRatio) ||//0810 공동소유자가 있고 본인지분인 경우 지분율

        //토지 or 단독주택 시세 입력
        (this.input.productKind === 'KIND_5' && !this.input.goingOrAppraisedPrice) 
      ) {
        window.alert("필수 값들을 모두 입력해주세요.", {
          title: "필수 값 미입력",
        });

        this.submitted = false;

      } else if (this.submitted) {

        //재직상태가 신용결여X 신용현황에 대한 값이 있는 경우 초기화
        if (
          (this.input.work !== "BADCREDIT" && this.input.creditScore) ||
          this.input.totalTimes ||
          this.input.currentTimes ||
          this.input.defaultTimes
        ) {
          this.input.creditScore = null;
          this.input.totalTimes = null;
          this.input.currentTimes = null;
          this.input.defaultTimes = null;
        }

        if(!this.input.jointName){
          // this.input.shareLoan = false;
          // this.input.selfRatio = null;
          this.input.biddingJointName = [];
        }

        // if(this.input.selfRatio){
        //   this.input.selfRatio = this.input.selfRatio.replace("%","");
        // }

        if(this.input.goingOrAppraisedPrice && isNaN(this.input.goingOrAppraisedPrice) ){
          this.input.goingOrAppraisedPrice = this.input.goingOrAppraisedPrice.replace(",","")
        }

        const { address, addressDetail, addressDetailMeta } = this.input.addressDetailMeta;
        const form = {
          ...this.input,
          tenants: [...this.tenants],
          address: address,
          addressDetail: addressDetail || "",
          addressDetailMeta: {
            dongNm: addressDetail ? this.dongHo(addressDetail).dong : "",
            hoNm: addressDetail ? this.dongHo(addressDetail).ho : "",
            ...addressDetailMeta,
          },
          amount: this.priceToNumber(this.input.amount),
          // deposit: this.input.tenant
          //   ? this.priceToNumber(this.input.deposit)
          //   : null,
          // monthlyRent: this.input.tenant
          //   ? this.priceToNumber(this.input.monthlyRent)
          //   : null,

          tradingFunds: { 
            ...this.input.tradingFunds, 
            bondMaximum: this.input.tradingFunds.bondMaximum
              ? this.priceToNumber(this.input.tradingFunds.bondMaximum)
            : null,
            principal: this.input.tradingFunds.principal
              ? this.priceToNumber(this.input.tradingFunds.principal)
            : null,
          },

        };
        try {
          const { data } = await this.$axios.put(
            `/bidding/${this.idBidding}`,
            form
          );
          if (data.status.code === "0000") {
            const bool = await window.alert(
              `비딩매칭 수정이 완료됐습니다. 남은 수정 횟수 ${data.data.possibleUpdate}회`,
              {
                title: "비딩 매칭 수정완료",
              }
            );
            if (bool) {
              this.$router.push(`/matching/${this.idBidding}`);
            }
          } else if (data.status.code === "4030") {
            const bool = await window.alert(`더이상 수정하실 수 없습니다.`, {
              title: "수정불가",
            });
            if (bool) {
              this.$router.push(`/matching/${this.idBidding}`);
            }
          } else {
            window.alert(data.status.message);
          }
        } catch (error) {
          window.alert(error);
          this.submitted = false;
          console.log(error);
        }
      }
    },

    async getTenants(){
      try {
        const { data } = await this.$axios.get(
          `/tenant/bidding/${this.idBidding}`
        );
        if (data.status.code === "0000") {
          this.tenants = data.data;
          this.tenant = data.data.length ? true : false
          //this.tenantsSet;
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  async mounted() {
    await this.getItem();
    await this.getJointName();
    await this.getTenants();
    await this.getBidder();
  },
};
</script>

<style lang="scss" scoped></style>
