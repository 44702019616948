<template>
  <div class="pb-2" :class="{ 'bg-gray-f6': item.auto }">
    <div v-if="item.auto">
      <b-badge class="py-2 rounded-pill text-white bg-darkest">
        <!-- 자동입찰 확인을 위해 임시표기 -->
        <span class="text-20">자동입찰</span>
      </b-badge>
    </div>
    {{ `입찰 취소 여부 : ${item.cancelBidder}` }}
    {{ `매칭 취소 여부 : ${item.cancelMatch}` }}
    <b-row align-v="center">
      <b-col cols="12" md="2" lg="1">
        <template
          v-if="
            item.relativeUser && (item.relativeUser.serviceId === user.serviceId ||
              bidding.relativeUser.serviceId === user.serviceId) &&
            item.relativeUser.profile
          "
        >
          <figure
            class="mb-0 bg-img w-100 ratio-100 border border-lightest"
            :style="{
              backgroundImage: `url(${s3 + item.relativeUser.profile})`,
              minWidth: '84px',
            }"
          ></figure>
        </template>
        <template v-else>
          <b-avatar
            square
            size="100%"
            :style="{ minHeight: '5.25rem' }"
            variant="primary"
          ></b-avatar>
        </template>
      </b-col>
      <b-col cols="12" md="7" lg="9" class="mt-2 mt-md-0">
        <b-row align-v="start">
          <b-col cols="12">
            <div class="d-md-flex align-items-center horizon-scroll">
              <strong class="text-20 text-primary text-truncate">
                <template
                  v-if="
                    ( item.relativeUser && item.relativeUser.serviceId === user.serviceId ||
                      bidding.relativeUser.serviceId === user.serviceId || 
                      bidding.relativeUser.serviceId === user.serviceId && 
                      item.relativeBank
                    )
                  "
                >
                  <!-- 회사명, 은행명 등 입찰자 표시 -->

                  <!-- 자동입찰인 경우 실제 업체명 또는 대체 업체명으로 표기 -->
                  <div v-if="item.auto">
                    실제 업체명 : {{item.relativeUser.companyMeta.companyName}} || 
                    보여지는 업체명 : {{item.relativeUser.companyMeta.companyName2}}

                    <!-- 1026 테스트 후 주석 풀 예정 -->
                    <!-- <span v-if="item.relativeUser.companyMeta && item.relativeUser.companyMeta.companyName2">
                      {{ item.relativeUser.companyMeta.companyName2 }}
                    </span>
                    <span v-else-if="item.relativeUser.companyMeta && item.relativeUser.companyMeta.companyName">
                      {{ item.relativeUser.companyMeta.companyName }}
                    </span>
                    <span v-else-if="item.relativeUser.companyMeta && item.relativeBank.bankName">
                      {{ item.relativeBank.bankName }}
                    </span> -->
                  </div>
                  <div v-else>
                    {{
                    item.relativeUser 
                      ? item.relativeUser.companyMeta == null
                        ? onlyId(item.relativeUser.serviceId)
                        : item.relativeUser.companyMeta.companyName
                      : null
                  }}
                  </div>
                </template>

                <template v-else> ***** </template>
              </strong>
              <template>
                <!-- 02/18 입찰 순서 -->
                <template
                  v-if="
                    item.relativeUser && (item.relativeUser.serviceId === user.serviceId ||
                    bidding.relativeUser.serviceId === user.serviceId)
                  "
                >
                  <!-- 입찰 순서를 보기 위해 -->
                  {{ `${item.regProcedure}번째 입찰` }}
                </template>
              </template>
              <div class="d-md-flex align-items-center">
                <!-- <template v-if="bidding.progress === 'MATCHED'">
                  <div>
                    <b-badge
                      v-if="condition.matched.value"
                      :class="`${condition.matched.variant}`"
                      class="w-sm-100 ml-md-2 my-1 my-md-0 py-1 rounded-pill text-white"
                    >
                      <span class="text-17"> {{ condition.matched.text }}</span>
                    </b-badge>
                  </div>
                </template> -->

                <!-- 조건 수용 진행시 입찰자 이름 옆에 조건 수용 진행 가능 표시 -->
                <template>
                  <div v-for="(item, i) in condition" :key="i">
                    <b-badge
                      v-if="item.value"
                      :class="`${item.variant}`"
                      class="
                        w-sm-100
                        ml-md-2
                        my-1 my-md-0
                        py-1
                        rounded-pill
                        text-white
                      "
                    >
                      <span class="text-17"> {{ item.text }}</span>
                    </b-badge>
                  </div>
                </template>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="3" lg="4">
            <ul class="mt-2 list-unstyled text-17 text-lg-15">
              <!-- <li class="mb-2 d-flex align-items-center">
                <strong :style="{ width: '72px' }">순위</strong>
                <span class="ml-auto ml-md-0 text-gray text-right text-md-left">
                  <template
                    v-if="
                      (item.relativeUser && item.relativeUser.serviceId === user.serviceId ||
                      bidding.relativeUser.serviceId === user.serviceId)
                    "
                  >
                    {{ item.rank }}
                  </template>
                  <template v-else> ***** </template>
                </span>
              </li> -->
              <li class="mb-2 d-flex align-items-center">
                <strong :style="{ width: '72px' }">수당</strong>
                <span class="ml-auto ml-md-0 text-gray text-right text-md-left">
                  <template
                    v-if="
                      (item.relativeUser && item.relativeUser.serviceId === user.serviceId ||
                      bidding.relativeUser.serviceId === user.serviceId)
                    "
                  >
                    {{ item.benefit }}%
                  </template>
                  <template v-else> ***** </template>
                </span>
              </li>
              <li>
                <strong :style="{ width: '120px' }">비용</strong>
                  <span class="ml-auto ml-md-0 text-gray text-right text-md-left">
                    <template
                      v-if="
                        (item.relativeUser && item.relativeUser.serviceId === user.serviceId ||
                        bidding.relativeUser.serviceId === user.serviceId)
                      "
                    >
                      중도상환 수수료 : {{ item.prepaymentCommission }}% |
                    </template>
                    <template v-else> ***** </template>
                  </span>
                  <span class="ml-auto ml-md-0 text-gray text-right text-md-left">
                    <template
                      v-if="
                        (item.relativeUser && item.relativeUser.serviceId === user.serviceId ||
                        bidding.relativeUser.serviceId === user.serviceId)
                      "
                    >
                     플랫폼 수수료 : {{ item.platformCommission }}%
                    </template>
                    <template v-else> ***** </template>
                  </span>
              </li>
              <!-- <li class="mb-2 d-flex align-items-center">
                <strong :style="{ width: '120px' }">중도상환 수수료</strong>
                <span class="ml-auto ml-md-0 text-gray text-right text-md-left">
                  <template
                    v-if="
                      (item.relativeUser && item.relativeUser.serviceId === user.serviceId ||
                      bidding.relativeUser.serviceId === user.serviceId)
                    "
                  >
                    {{ item.prepaymentCommission }}%
                  </template>
                  <template v-else> ***** </template>
                </span>
              </li> -->
            </ul>
          </b-col>
          <b-col cols="12" md="5" lg="4">
            <ul class="my-2 list-unstyled text-17 text-lg-15">
              <li class="mb-2 d-flex align-items-center">
                <strong :style="{ width: '120px' }">한도</strong>
                <span class="ml-auto ml-md-0 text-gray text-right text-md-left">
                  <template
                    v-if="
                      (item.relativeUser && item.relativeUser.serviceId === user.serviceId ||
                      bidding.relativeUser.serviceId === user.serviceId)
                    "
                  >
                    {{ toLocale(item.limitAmount) }}만원
                  </template>
                  <template v-else> ***** </template>
                </span>
              </li>
              <!-- <li class="mb-2 d-flex align-items-center">
                <strong :style="{ width: '120px' }">플랫폼 수수료</strong>
                <span class="ml-auto ml-md-0 text-gray text-right text-md-left">
                  <template
                    v-if="
                      (item.relativeUser && item.relativeUser.serviceId === user.serviceId ||
                      bidding.relativeUser.serviceId === user.serviceId)
                    "
                  >
                    {{ item.platformCommission }}%
                  </template>
                  <template v-else> ***** </template>
                </span>
              </li> -->
            </ul>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <ul class="my-2 list-unstyled text-17 text-lg-15">
              <li class="mb-2 d-flex align-items-center">
                <strong :style="{ width: '120px' }">금리</strong>
                <span class="ml-auto ml-md-0 text-gray text-right text-md-left">
                  <template
                    v-if="
                      (item.relativeUser && item.relativeUser.serviceId === user.serviceId ||
                      bidding.relativeUser.serviceId === user.serviceId)
                    "
                  >
                    {{ item.interestRate }}%
                  </template>
                  <template v-else> ***** </template>
                </span>
              </li>
              <!-- <li class="mb-2 d-flex align-items-center">
                <strong :style="{ width: '120px' }">중도상한 수수료</strong>
                <span class="ml-auto ml-md-0 text-gray text-right text-md-left">
                  <template
                    v-if="
                      (item.relativeUser && item.relativeUser.serviceId === user.serviceId ||
                      bidding.relativeUser.serviceId === user.serviceId)
                    "
                  >
                    {{ item.prepaymentCommission }}%
                  </template>
                  <template v-else> ***** </template>
                </span>
              </li> -->
            </ul>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="3" lg="2">
        <div class="text-md-center pr-0 pr-xl-4">
          <!-- user가 입찰bidding한 사람과 부합해야함 -->
          <template
            v-if="
              bidding.progress !== 'TRANSFERRED' && //송금완료
              bidding.progress !== 'CANCEL_PROGRESS' && //의뢰취소
              bidding.progress !== 'COMPLETE' && //대출 완료
              bidding.bidders.some(
                //본인이 입찰한 건
                //0901 bid.relativeUser 추가
                (bid) =>
                  bid.relativeUser && 
                  bid.relativeUser.serviceId === user.serviceId &&
                  bid.rejected == false
              ) &&
              item.relativeUser && item.relativeUser.serviceId === user.serviceId

              /**&&
              !item.matched &&
              !item.cancelBidder */
            "
          >
            <!-- 매칭 X, 입찰 취소가 아닐때 X , 부결 X,  && 의뢰자가 매칭을 수정 했을 때 (조건 수용 진행 버튼을 안눌렀을 경우)-->
            <b-btn
              v-if="
                !item.acceptCondition &&
                item.relativeBidding.possibleUpdate < 2 &&
                item.relativeBidding.lastUpdatedDate > item.fixedUpdatedDate &&
                !item.rejected &&
                item.relativeBidding.progress != 'CANCELPROGRESS'
              "
              variant="outline-primary"
              class="w-100 position-relative text-nowrap"
              @click="changeAcceptCondition(item)"
              :disabled="
                item.cancelMatch ||
                item.rejected ||
                item.cancelBidder ||
                item.matched
              "
            >
              <span class="text-17"> 조건 수용 진행 가능</span>
            </b-btn>

            <!-- 매칭 X, 입찰 취소가 아닐때 X, 부결 X, -->
            <b-btn
              variant="primary"
              class="w-100"
              block
              :to="{
                path: this.$route.path + '/edit-bidding',
                query: { idBidder: item.id },
              }"
              :disabled="
                item.possibleUpdate === 0 ||
                item.cancelMatch ||
                item.rejected ||
                item.cancelBidder ||
                item.matched
              "
            >
              <span
                >{{ item.possibleUpdate === 0 ? "수정 불가" : "수정" }}
                <small
                  class="d-block"
                  v-if="
                    item.possibleUpdate &&
                    item.possibleUpdate > 0 &&
                    !item.cancelMatch &&
                    !item.rejected
                  "
                  >남은 수정 횟수 {{ item.possibleUpdate }}회</small
                >
                <small class="d-block" v-else-if="!item.possibleUpdate"
                  >(수정 횟수 소진)</small
                >
              </span>
            </b-btn>
            <!-- 매칭 전 입찰취소 버튼 -->
            <b-btn
              v-if="!item.cancelMatch && !item.rejected"
              variant="outline-primary"
              class="w-100 position-relative text-nowrap"
              block
              @click="cancelBidder(item.id)"
              :disabled="
                item.cancelMatch ||
                item.rejected ||
                item.cancelBidder ||
                item.matched
              "
            >
              <!-- 채팅알림이 있는 경우 -->
              <!-- 0810
                입찰 취소버튼에 채팅알림 아이콘 span태그 css삭제 -> 
                class : rounded-circle, bg-error, 
                :style="{ top: '0', right: '-16px', maxWidth: '30px' }"
                v-if="channel && channel.unreadMessageCount"
                {{ channel.unreadMessageCount }}
               -->
              <span class="position-absolute p-0 text-white py-1 px-2 text-17"></span>
              입찰 취소
            </b-btn>
            <modal-bidder-cancel
              @hidden="resetModal"
              :idBidder="currentBidder"
              :bidder="item"
              v-model="reason"
              :id="item.id"
              @aa="item"
            />
            <!-- 0810 의뢰자가 먼저 채팅을 건 내역이 있을 경우에만 보이게 처리 v-if="lastMessage" 추가함 -->
            <b-btn
              v-if="item.matched || lastMessage"
              variant="outline-primary"
              class="w-100 position-relative text-nowrap"
              block
              :to="{
                path: $route.path,
                query: { chat: item.id },
                params: { fixedScroll: true },
              }"
            >
              <!-- 채팅알림이 있는 경우 -->
              <span
                class="
                  position-absolute
                  rounded-circle
                  p-0
                  bg-error
                  text-white
                  py-1
                  px-2
                  text-17
                "
                :style="{ top: '0', right: '-16px', maxWidth: '30px' }"
                v-if="channel && channel.unreadMessageCount"
                >{{ channel.unreadMessageCount }}</span
              >
              <i class="icon icon-chatting"></i>
              채팅하기
            </b-btn>
            <modal-chatting
              target-type="BIDDER"
              :channel="channel"
              :target="bidding.relativeUser"
              :item="item"
            />
          </template>
          <!-- 의뢰자(글쓴이)의 경우 -->

          <template
            v-if="
              isMine(bidding.relativeUser) &&
              bidding.progress !== 'TRANSFERRED' &&
              bidding.progress !== 'COMPLETE'
            "
          >
            <div class="d-flex flex-column">
              <!-- 비딩이아닌 매칭이 완료되지 않은 경우를 가져와야 함 -->
              <b-btn
                variant="outline-primary"
                class="w-100 mb-2 text-nowrap"
                v-if="condition.matched.value"
                @click="cancelBidding(item.id)"
              >
                <i class="icon icon-matching"></i>
                매칭 취소
              </b-btn>
              <b-btn
                variant="primary"
                class="w-100 mb-2 text-nowrap"
                @click="matching(item.id)"
                v-if="
                  // condition.acceptCondition.value &&
                  !condition.matched.value
                "
                :disabled="
                  (bidding.progress === 'MATCHED' &&
                    bidding.progress === 'CANCEL_PROGRESS' &&
                    item.rejected === true) ||
                  item.cancelMatch === true ||
                  item.cancelBidder === true
                "
              >
                <i class="icon icon-matching"></i>
                매칭하기
              </b-btn>
              <b-btn
                v-if="item.relativeUser"
                variant="outline-primary"
                class="w-100 position-relative text-nowrap"
                :to="{
                  path: $route.path,
                  query: { chat: item.id },
                  params: { fixedScroll: true },
                }"
                :disabled="bidding.progress == 'CANCEL_PROGRESS'"
              >
                <!-- 채팅알림이 있는 경우 -->
                <span
                  class="
                    position-absolute
                    rounded-circle
                    p-0
                    bg-error
                    text-white
                    py-1
                    px-2
                    text-17
                  "
                  :style="{ top: '0', right: '-16px', maxWidth: '30px' }"
                  v-if="channel && channel.unreadMessageCount"
                  >{{ channel.unreadMessageCount }}</span
                >
                <i class="icon icon-chatting"></i>
                채팅하기
              </b-btn>
              <modal-chatting
                target-type="USER"
                :channel="channel"
                :target="item.relativeUser"
                :item="item"
              />
            </div>
          </template>
          <!-- {{channel}} -->
        </div>
      </b-col>
      <!-- {{ channel }}
      =========
      {{ bidding.relativeUser }}
      =========
      {{ item }} -->
    </b-row>
    <!-- Bidding에서 modal에게 idBidder 값을 넘겨준다 -->

    <modal-bidding-cancel
      @hidden="resetModal"
      :idBidder="currentId"
      v-model="reason"
    />

    <!-- 0810 의뢰자가 매칭하기 버튼 클릭시 소유자 전화번호 적을 모달창 추가 -->
    <modal-owner-phone @hidden="resetModal" :idBidding="bidding.id" />
  </div>
</template>

<script>
import Modals from "@/components/Modals/index";
export default {
  components: {
    ModalBiddingCancel: Modals.BiddingCancel,
    ModalBidderCancel: Modals.BidderCancel,
    ModalChatting: Modals.Chatting,
    ModalOwnerPhone: Modals.OwnerPhone,
  },
  props: {
    bidding: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  mounted() {
    this.checkUnreadChat();
  },
  data() {
    return {
      currentId: null,
      currentBidder: null,
      reason: "",
      channel: null,
      lastMessage: null, //0810 채팅한 이력이 있는지 확인하기 위한 변수
    };
  },
  computed: {
    idBidding() {
      return this.$route.params.matching_id;
    },
    condition() {
      const acceptCondition = {
        variant: "badge-success",
        text: "조건 수용 진행 가능",
        value: this.item.acceptCondition,
      };
      const cancleBidder = {
        variant: "badge-red",
        text: "입찰 취소",
        value: this.item.cancelBidder,
      };
      const cancelMatch = {
        variant: "badge-dark-gray",
        text: "매칭 취소",
        value: this.item.cancelMatch,
      };
      const matched = {
        variant: "badge-secondary",
        text: "매칭",
        value: this.item.matched,
      };
      const rejected = {
        variant: "badge-dark-gray",
        text: "부결",
        value: this.item.rejected,
      };

      return {
        acceptCondition,
        cancleBidder,
        cancelMatch,
        matched,
        rejected,
      };
    },
  },
  watch: {
    condition(n) {
      // console.log("condition changed:", n);
    },
  },
  methods: {
    async changeAcceptCondition(bidder) {
      if (bidder.relativeUser.serviceId) {
        try {
          const { data } = await this.$axios.put(
            "/bidding/" +
              this.idBidding +
              "/bidder/" +
              bidder.id +
              "/update-condition?acceptCondition=true"
          );
          if (data.status.code === "0000") {
            await window.alert("조건 수용 진행이 처리 되었습니다.");
            this.item.acceptCondition = true;
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        window.alert("아직 입찰을 진행하지 않았습니다.");
      }
    },

    checkPersonalChat(target, operator) {
      const uniqueUrl = "BIDDING_CHAT_" + this.item.id;
      
      const isRelativeBidding = [
        this.item.relativeUser.serviceId,
        this.bidding.relativeUser.serviceId,
      ].includes(this.user.serviceId);
      if (!isRelativeBidding) return;
      this.sb.GroupChannel.getChannel(uniqueUrl, (groupChannel, error) => {
          // 채팅방 유무 조회
          if (error) {
            // Handle error.
            if (error.code === 400201) {
              // 채팅방 없는 경우 생성
              this.createPersonalChat(target, operator);
            } else {
              alert(error);
            }
          }
          this.channel = groupChannel;

          if(this.channel && this.channel.lastMessage){
            //0810 마지막 채팅 내용
            this.lastMessage = this.channel.lastMessage;
          }
      });
    },
    async createPersonalChat(target, operator) {
      if(target.serviceId){
        const uniqueUrl = "BIDDING_CHAT_" + this.item.id;
  
        const userIds = [target.serviceId, this.user.serviceId];
        var params = new this.sb.GroupChannelParams();
        params.isPublic = false;
        params.isEphemeral = false;
        params.isDistinct = false;
        params.isSuper = false;
        params.addUserIds(userIds);
        // 얼림 기능 부여
        params.operatorUserIds = [operator.serviceId]; // Or .operators(Array<User>)
        params.name = uniqueUrl;
        params.channelUrl = uniqueUrl;
  
        // console.log(1);
  
        this.sb.GroupChannel.createChannel(params, async (channel, err) => {
          if (err) {
            // console.log(err);
            /** 2/15일 주석처리 */
            //return await alert("err");
          }
          // console.log(2);
          // console.log(channel, uniqueUrl);
          this.channel = channel;
        });

      }
    },
    checkUnreadChat() {
      if(this.item.relativeUser){
        this.checkPersonalChat(
          this.item.relativeUser.id === this.user.id
            ? this.bidding.relativeUser
            : this.item.relativeUser,
          this.bidding.relativeUser
        );

      }
    },
    getVariant(progress) {
      const variant = {
        ING: "success",
        REJECTED: "dark-gray",
        CANCEL_MATCHING: "dark-gray",
        MATCHED: "d-none",
        ERROR: "error",
      };
      return variant[progress] || null;
    },
    getText(progress) {
      const text = {
        acceptCondition: "조건 수용 진행 가능",
        REJECTED: "부결",
        CANCEL_MATCHING: "매칭 취소",
        MATCHED: "매칭 완료",
        ERROR: "에러",
      };
      return text[progress] || null;
    },
    async matching(idBidder) {
      const bool = await window.confirm(
        `이 입찰과 매칭하시겠습니까? `,
        // idBiddier:${idBidder}, idBidding:${this.idBidding}
        {
          title: "매칭하기",
          bodyClass: "text-center",
          okTitle: "매칭",
          cancelTitle: "취소",
        }
      );

      //0810 매칭하기 버튼 클릭시 소유자의 전화번호를 적는 모달창 아직 미완이므로 주석처리
      // if(bool){
      //   this.$bvModal.show("modal-owner-phone");
      //   console.log('if in')
      // }

      if (bool) {
        try {
          const { data } = await this.$axios.post(
            `/bidding/${this.idBidding}/bidder/${idBidder}/matching`
          );
          if (data.status.code === "1002") {
            window.alert(data.status.message);
          } else if (data.status.code === "0000") {
            this.item.matched = true;
            this.bidding.progress = "MATCHED";
            window.alert("매칭이 완료되었습니다.");
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    resetModal() {
      this.currentId = null;
      this.currentBidder = null;
      this.reason = null;
    },
    cancelBidding(id) {
      this.currentId = id;
    },
    cancelBidder(id) {
      this.currentBidder = id;
    },
  },
};
</script>

<style lang="scss" scoped></style>
