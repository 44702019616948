<template>
  <div class="mt-3 mb-5 py-5">
    <b-container class="mb-5 pb-5">
      <guide-header text="매칭 가이드" class="mb-5 text-white" />
      <article class="pb-5" v-for="(item, i) in guides" :key="i">
        <b-row>
          <b-col cols="12" md="4">
            <img
              :src="item.src"
              :alt="item.title + ' 가이드'"
              class="w-100 h-auto"
            />
          </b-col>
          <b-col cols="12" md="8" class="pt-3 pt-md-0">
            <header class="d-flex align-items-end pt-3">
              <h4 class="text-24 text-lg-30 mb-0 text-score-black text-primary">
                {{ item.title }}
              </h4>
              <strong class="text-20 ml-2 text-score-bold">가이드</strong>
            </header>
            <hr />
            <p
              class="text-14 text-lg-16 lh-175 font-weight-normal"
              v-html="item.content"
            ></p>
          </b-col>
        </b-row>
      </article>
    </b-container>
  </div>
</template>

<script>
import GuideHeader from "@/components/Items/GuideHeader.vue";
export default {
  components: {
    GuideHeader,
  },
  data() {
    return {
      guides: [
        {
          title: "첫번째",
          content: `
  비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나 그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽 요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종 결과물에 들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인 프로젝트 모형의 채움 글로도 이용된다. 이런 용도로 사용할 때 로렘 입숨을 그리킹(greeking)이라고도 부르며, 때로 로렘 입숨은 공간만 차지하는 무언가를 지칭하는 용어로도 사용된다.
<br><br>
  로렘 입숨은 전통 라틴어와 닮은 점 때문에 종종 호기심을 유발하기도 하지만 그 이상의 의미를 담지는 않는다. 문서에서 텍스트가 보이면 사람들은 전체적인 프레젠테이션보다는 텍스트에 담긴 뜻에 집중하는 경향이 있어서 출판사들은 서체나 디자인을 보일 때는 프레젠테이션 자체에 초점을 맞추기 위해 로렘 입숨을 사용한다.
`,
          src: require("@/assets/images/Guidance/matching-guide-1.png"),
        },
        {
          title: "두번째",
          content: `
  비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나 그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽 요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종 결과물에 들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인 프로젝트 모형의 채움 글로도 이용된다. 이런 용도로 사용할 때 로렘 입숨을 그리킹(greeking)이라고도 부르며, 때로 로렘 입숨은 공간만 차지하는 무언가를 지칭하는 용어로도 사용된다.
<br><br>
  로렘 입숨은 전통 라틴어와 닮은 점 때문에 종종 호기심을 유발하기도 하지만 그 이상의 의미를 담지는 않는다. 문서에서 텍스트가 보이면 사람들은 전체적인 프레젠테이션보다는 텍스트에 담긴 뜻에 집중하는 경향이 있어서 출판사들은 서체나 디자인을 보일 때는 프레젠테이션 자체에 초점을 맞추기 위해 로렘 입숨을 사용한다.
`,
          src: require("@/assets/images/Guidance/matching-guide-2.png"),
        },
        {
          title: "세번째",
          content: `
  비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나 그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽 요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종 결과물에 들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인 프로젝트 모형의 채움 글로도 이용된다. 이런 용도로 사용할 때 로렘 입숨을 그리킹(greeking)이라고도 부르며, 때로 로렘 입숨은 공간만 차지하는 무언가를 지칭하는 용어로도 사용된다.
<br><br>
  로렘 입숨은 전통 라틴어와 닮은 점 때문에 종종 호기심을 유발하기도 하지만 그 이상의 의미를 담지는 않는다. 문서에서 텍스트가 보이면 사람들은 전체적인 프레젠테이션보다는 텍스트에 담긴 뜻에 집중하는 경향이 있어서 출판사들은 서체나 디자인을 보일 때는 프레젠테이션 자체에 초점을 맞추기 위해 로렘 입숨을 사용한다.
`,
          src: require("@/assets/images/Guidance/matching-guide-3.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss"></style>
