<template>
  <!-- 
    내 입찰건 (의뢰자 행동)
    - 의뢰코멘트 : CLIENT
    - 의뢰수정 : BIDDING_HISTORY
    - 의뢰취소 : CANCEL_PROGRESS
    - 매칭취소 : CANCEL_MATCHING 
    내 의뢰건 (입찰자 행동)
    - 입찰수정 : BIDDER_HISTORY
    - 입찰취소 : CANCEL_BIDDER
    - 조건수용진행 : UPDATE_CONDICTION
    - 부결 : REJECT
  -->

  <div>
    <!-- 알림 불러오는 중 -->
    <template v-if="!notifications">
      <div class="text-center">
        <b-spinner variant="primary" class="my-5" />
      </div>
    </template>
    <!-- 알림 없음 -->
    <template v-if="notifications && !notifications.content.length">
      <div
        class="py-3 bg-light text-center"
        :style="{ borderTop: '3px solid #333', borderBottom: '3px solid #333' }"
      >
        알림이 없습니다.
      </div>
    </template>
    <!-- 알림 있음 -->
    <template v-if="notifications && notifications.content.length">
      <b-row
        class="py-3"
        :style="{ borderTop: '3px solid #333', borderBottom: '3px solid #333' }"
      >
        <b-col
          cols="12"
          v-for="(item, i) in notifications.content"
          :key="i"
          class="py-3"
          :class="i == 0 ? '' : 'border-md-top border-light-gray'"
        >
          <b-row align-v="center">
            <b-col cols="12" md="9">
              <article class="d-flex align-items-start pr-md-4">
                <i class="icon icon-28 icon-alarm mr-3"></i>
                <section class="w-100">
                  <header class="text-18">
                    <!-- 내 의뢰건/입찰건 구분 -->
                    <h3>
                      <!-- 의뢰코멘트 / 의뢰수정 / 의뢰취소 / 매칭취소 -->
                      <!-- 0810 매칭완료 알림표시 위해  item.type === 'MATCHED'  추가-->
                      <template
                        v-if="
                          item.type === 'CLIENT' ||
                          item.type === 'BIDDING_HISTORY' ||
                          item.type === 'CANCEL_PROGRESS' ||
                          item.type === 'CANCEL_MATCHING' ||
                          item.type === 'MATCHED' 
                        "
                        >내 입찰건
                      </template>
                      <!-- 입찰수정 / 입찰취소 / 조건수용진행 / 부결 -->
                      <template
                        v-if="
                          item.type === 'BIDDER_HISTORY' ||
                          item.type === 'CANCEL_BIDDER' ||
                          item.type === 'UPDATE_CONDICTION' ||
                          item.type === 'REJECT'
                        "
                        >내 의뢰건
                      </template>
                    </h3>
                    <!-- 물건주소 -->
                    <h5>{{ item.address }}</h5>
                  </header>
                  <!-- 상세내용 -->
                  <div class="text-18">
                    <p>
                      <!-- 내 입찰건 -->
                      <!-- 0810 매칭완료 알림표시 위해  item.type === 'MATCHED'  추가-->
                      <template
                        v-if="
                          item.type === 'CLIENT' ||
                          item.type === 'BIDDING_HISTORY' ||
                          item.type === 'CANCEL_PROGRESS' ||
                          item.type === 'CANCEL_MATCHING' || 
                          item.type === 'MATCHED' 
                        "
                        >입찰하신
                        <span v-if="item.type === 'CLIENT'">물건에</span>
                        <span v-if="item.type === 'BIDDING_HISTORY'"
                          >물건의 내용이</span
                        >
                        <!-- 0810 매칭완료 알림표시 위해  item.type === 'MATCHED'  추가-->
                        <span
                          v-if="
                            item.type === 'CANCEL_PROGRESS' ||
                            item.type === 'CANCEL_MATCHING' ||
                            item.type === 'MATCHED'
                          "
                          >물건이</span
                        >
                      </template>
                      <!-- 내 의뢰건 -->
                      <template
                        v-if="
                          item.type === 'BIDDER_HISTORY' ||
                          item.type === 'CANCEL_BIDDER'
                        "
                        >입찰된
                        <span v-if="item.type === 'BIDDER_HISTORY'"
                          >물건의 입찰 내용이</span
                        >
                        <span v-if="item.type === 'CANCEL_BIDDER'">물건이</span>
                      </template>
                      <!-- 0810 입찰시 알림표시 위해  item.type === 'BID'  추가-->
                      <template
                        v-if="
                          item.type === 'REJECT' ||
                          item.type === 'UPDATE_CONDICTION' ||
                          item.type === 'BID'
                        "
                        >의뢰하신
                        <!-- 0810 입찰시 알림표시 위해  item.type === 'BID'  추가-->
                        <span 
                          v-if="item.type === 'REJECT' || item.type === 'BID'"
                        >물건이</span>
                        <span v-if="item.type === 'UPDATE_CONDICTION' "
                          >물건에</span
                        >
                      </template>
                      <strong class="text-third">
                        {{ getText(item.type) }}</strong
                      >
                      되었습니다.
                    </p>
                    <template
                      v-if="item.categorys && item.beforeUpdateDatas !== null"
                    >
                      <ul
                        class="d-flex p-0 m-0 text-17"
                        :style="{ gap: '5rem', listStyle: 'none' }"
                      >
                        <li>
                          <span>==category==</span>
                          <p
                            class="m-0"
                            v-for="(item2, i) in item.categorys"
                            :key="i"
                          >
                            {{ getText2(item2) }}
                          </p>
                        </li>
                        <li>
                          ==before==
                          <p
                            class="m-0"
                            v-for="(item3, i) in item.beforeUpdateDatas"
                            :key="i"
                          >
                            {{ item3 }}
                          </p>
                        </li>
                        <li>
                          ==after==
                          <p
                            class="m-0"
                            v-for="(item4, i) in item.afterUpdateDatas"
                            :key="i"
                          >
                            {{ item4 }}
                          </p>
                        </li>
                      </ul>
                    </template>
                  </div>
                  <span>
                    {{ item.createdDate }}
                  </span>

                  <div class="mt-3 d-md-flex align-items-center">
                    <b-badge
                      variant="gray"
                      class="py-2"
                      v-if="
                        item.type === 'CANCEL_MATCHING' ||
                        item.type === 'REJECT' ||
                        item.type === 'CANCEL_PROGRESS'
                      "
                    >
                      <span class="text-13">
                        {{ getText(item.type) }}
                        사유</span
                      >
                    </b-badge>
                    <!-- 0810 입찰시 알림표시 위해  item.type === 'BID'  추가
                              매칭시 알림표시 위해  item.type === 'MATCHED'  추가
                    -->
                    <span
                      v-html="
                        item.type === 'CANCEL_MATCHING' ||
                        item.type === 'REJECT' ||
                        item.type === 'CLIENT' ||
                        item.type === 'CANCEL_BIDDER' ||
                        item.type === 'CANCEL_PROGRESS' ||
                        item.type === 'UPDATE_CONDICTION' ||
                        item.type === 'UPDATE_BIDDING' ||
                        item.type === 'UPDATE_BIDDER' ||
                        item.type === 'MATCHED' ||
                        item.type === 'BID'
                          ? item.content
                          : ''
                      "
                      class="
                        ml-2 ml-md-3
                        text-gray
                        text-underline
                        text-17
                        text-lg-16
                        text-underline
                      "
                    >
                    </span>
                  </div>
                  <template
                    v-if="
                      item.type === 'CANCEL_MATCHING' || item.type === 'REJECT'
                    "
                  >
                    <hr />
                    <div class="pb-2">
                      <small class="text-light-gray text-13"
                        >{{ getText(item.type) }} 사유 확인 후 수용 가능 여부를
                        선택해주세요.</small
                      >
                    </div>
                  </template>
                </section>
              </article>
            </b-col>
            <!-- 자세히 보기 -->
            <b-col cols="12" md="3">
              <div class="px-md-3">
                <b-btn
                  variant="outline-primary"
                  class="w-100"
                  :to="`/matching/${item.idBidding}`"
                >
                  자세히보기
                </b-btn>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- 페이지 넘버 -->
      <div class="mt-5 d-flex flex-column">
        <b-pagination
          @change="getNotifications($event)"
          :value="notifications.page.number + 1"
          :total-rows="notifications.page.totalElements"
          :per-page="notifications.page.size"
          class="mx-auto"
          first-class="d-none"
          last-class="d-none"
          hide-ellipsis
        >
          <template #prev-text>
            <i class="icon icon-prev ml-1"></i>
          </template>
          <template #next-text>
            <i class="icon icon-next ml-1"></i>
          </template>
        </b-pagination>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    userType: {
      type: String,
    },
  },
  data() {
    return {
      notifications: null,
      biddingComment: null,
      page: {
        page: 1,
      },
    };
  },
  watch: {
    async userType(n) {
      await this.getNotifications();
    },
  },
  computed: {
    query() {
      return this.userType === "";
    },
  },
  methods: {
    getText(progress) {
      const text = {
        BIDDING_HISTORY: "수정",
        BIDDER_HISTORY: "수정",
        REJECT: "부결",
        CANCEL_MATCHING: "매칭 취소",
        CANCEL_PROGRESS: "의뢰 취소",
        CANCEL_BIDDER: "입찰 취소",
        ERROR: "에러",
        UPDATE_CONDICTION: "코멘트가 등록",
        UPDATE_BIDDING: "의뢰 내용이 수정",
        UPDATE_BIDDER: "입찰 내용이 수정",
        CLIENT: "코멘트가 등록",
        MATCHED: "매칭",
        BID: "입찰"
      };
      return text[progress] || null;
    },

    getText2(kind) {
      const text = {
        PHONE: "전화번호",
        AMOUNT: "요청 금액",
        WORK: "직업",
        CREDITMATTERS: "회복/회생 여부",
        TENANT: "세입자",
        
        DEPOSIT: "보증금",
        MONTHLYRENT: "월세",
        TENANTAGREE: "세입자 동의여부",
        
        CREDITSCORE: "신용 점수",
        SHARELOAN: "공동소유",
        TOTALTIMES: "총 납기회차",
        CURRENTTIMES: "현재 회차",
        DEFAULTTIMES: "미납 회차",
        JOINTNAMEHOLDER: "공동 소유자명",
        JOINTRELATION: "공동 소유자 관계",
        JOINTNAME: "공동 소유자",
        RANKING: "순위",
        LIMITAMOUNT: "한도",
        INTERESTRATE: "금리",
        // MININTERESTRATE: "최저금리",
        // MAXINTERESTRATE: "최고금리",
        BENEFIT: "수당",
        PLATFORMCOMMISSION: "플랫폼 수수료",
        PREPAYMENTCOMMISSION: "중도상환 수수료",
        SELLER: "매도자",
        BUYER: "매수자",
        SALEAMOUNT: "매매금액",
        BALANCEDATE: "잔금일",
        SELFRATIO: "본인지분",
        GOINGORAPPRAISEDPRICE: "건축물 감정가/시세",
      };
      return text[kind] || null;
    },

    /* 클라이언트 비딩 댓글 작성 또는 수정시 알림리스트 test 추가한 부분*/
    async getNotifications(n = 1) {
      try {
        const { data } = await this.$axios.get("/notifications", {
          params: { page: n - 1 },
        });
        if (data.status.code === "0000") {
          console.log(data);

          this.notifications = {
            ...data.data,
            content: data.data.content,
          };
          this.paginationEvent();
        }
      } catch (error) {}
    },

    paginationEvent(page) {
      window.scrollTo(0, 0);
    },
  },
  
  async mounted() {
    await this.getNotifications();
  },
};
</script>

<style lang="scss"></style>
