<template>
  <div>
    <div
      ref="map"
      :style="{
        width: '100%',
        height: '360px',
        maxWidth: '90vw',
        maxHeight: '50vh',
      }"
    ></div>
  <div>
    {{address}}
  </div>
  </div>
</template>
<script>
export default {
  props: {
    address: {
      type: String,
    },
    addressDetail: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      lat: null,
      lng: null,
    };
  },
  methods: {
    initMap() {
      // 맵 표시할 곳
      var container = this.$refs.map;
      var options = {
        //지도를 생성할 때 필요한 기본 옵션
        center: new kakao.maps.LatLng(33.450701, 126.570667), //지도의 중심좌표.
        level: 2, //지도의 레벨(확대, 축소 정도)
      };
      var map = new kakao.maps.Map(container, options); //지도 생성 및 객체 리턴

      // 주소-좌표 변환 객체를 생성합니다
      var geocoder = new kakao.maps.services.Geocoder();

      // 주소로 좌표를 검색합니다
      geocoder.addressSearch(this.address, function(result, status) {
        // 정상적으로 검색이 완료됐으면
        if (status === kakao.maps.services.Status.OK) {
          var coords = new kakao.maps.LatLng(result[0].y, result[0].x);

          // 네이버 매물검색을 위해 주소로 X,Y좌표 변환
          // naver.maps.Service.geocode({ query : result[0].address.address_name }, function(status, response) {
          //   if (status === naver.maps.Service.Status.ERROR) {
          //     return alert('Something wrong!');
          //   }
          //   console.log(response)
          // });

          var marker = new kakao.maps.Marker({
            map: map,
            position: coords,
          });

          // 인포윈도우로 장소에 대한 설명을 표시합니다
          // var infowindow = new kakao.maps.InfoWindow({
          //   content: `<div style="width:150px;text-align:center;padding:6px 0;">${this.address}</div>`,
          // });
          // infowindow.open(map, marker);

          // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
          map.setCenter(coords);
        }
      });
    },
  },
  mounted() {
    if (this.address) {
      this.initMap();
    }
  },
  watch: {
    address(n) {
      this.initMap(n);
    },
  },
};
</script>

<style lang="scss" scoped></style>
