<template>
  <b-form @input="check" @submit.prevent="$emit('submit', $event)">
    <slot />
  </b-form>
</template>

<script>
export default {
  name: "w-form",
  props: {
    salt: { type: Number },
    value: { default: null },
  },
  data() {
    return {
      serverState: null,
    };
  },
  watch: {
    salt(n) {
      this.check();
    },
  },
  methods: {
    check(c) {
      const state = [];
      const rulesChecker = (arr) => {
        arr.forEach((child) => {
          if (child.valid) {
            state.push(child.valid.state);
          } else if (child.$children && child.$children.length) {
            rulesChecker(child.$children);
          }
        });
      };
      rulesChecker(this.$children);

      // console.log((this.$children);
      this.$nextTick(() => {
        // console.log(("@@form state:", state);
        this.$emit("input", !state.some((b) => !b));
      });
    },
    inputListener(v) {
      let val = v;
      switch (this.type) {
        case "number":
          val = +v;
          break;

        default:
          break;
      }
      this.$emit("input", val);
    },
  },
};
</script>

<style></style>
