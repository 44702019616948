<template>
  <div>
    <b-row>
      <b-col v-for="(item, i) in items" :key="i" class="mt-4" cols="12" xl="4">
        <!-- 등록완료 -->
        <article
          v-if="!item.registering"
          class="position-relative p-4 cursor-pointer h-100 matching-item"
          :style="{
            background: `${getBgColor(item.progress)}10`,
            border: `1px solid ${getBgColor(item.progress)}95`,
          }"
          @click="$router.push('/matching/' + item.id)"
        >
          <!-- 알림표시 -->
          <div v-if="meta.showHistory && item.updated">
            <div
              class="position-absolute"
              :style="{
                right: '-15px',
                top: '40px',
                zIndex: '-1',
                width: '80px',
                height: '35px',
                background: getBgColor(item.progress),
                transform: 'rotate(45deg) skewX(45deg)',
              }"
            ></div>
            <span
              class="position-absolute d-block text-20 fw-900 text-white"
              :style="{
                right: '2px',
                top: '30px',
                fontFamily: 'fontello',
                letterSpacing: '0.5rem',
                transform: 'rotate(45deg)',
              }"
              >NEW</span
            >
            <div
              class="position-absolute"
              :style="{
                right: '17px',
                top: '8px',
                zIndex: '-1',
                width: '80px',
                height: '35px',
                background: getBgColor(item.progress),
                transform: 'rotate(45deg) skewX(-45deg)',
              }"
            ></div>
          </div>

          <!-- 확인 용 -->
          <p class="skew">
            입찰자 수:  {{item.bidderCount}}
            <br />
             <!-- 리스트 카드에서 
                  로그인한 ID가 입찰한 비딩건 표시 (매칭취소, 부결 안된 것만 표시됨) 
                  마이페이지에서는 bidders가 있고, 기본 리스트에서는 없으므로
                  기본 리스트에서만 보이게 v-if="item.bidders.length == 0"
            -->
            <span v-if="item.bidders.length == 0">
              {{ `본인 입찰여부 : ${item.bidder ? "O" : "X"}` }}
            </span>
            <br />

            {{
              item.productKind === "KIND_1"
                ? "아파트"
                : item.productKind === "KIND_2"
                ? "빌라/다세대/도시형/연립"
                : item.productKind === "KIND_3"
                ? "토지/단독/다가구/타운하우스"
                : item.productKind === "KIND_4"
                ? "오피스텔"
                : ""
            }}
            -
            {{
              item.productKind2 === "KIND2_1"
                ? "담보대출"
                : item.productKind2 === "KIND2_2"
                ? "매매자금"
                : item.productKind2 === "KIND2_3"
                ? "퇴거자금"
                : item.productKind2 === "KIND2_4"
                ? "무설정론"
                : item.productKind2 === "KIND2_5"
                ? "지분"
                : ""
            }}
            <br />
            의뢰자:
            {{
              `${item.relativeUser.serviceId}, ${item.relativeUser.companyMeta.companyName}`
            }}
          </p>

          <!-- 상세내용 -->
          <section>
            <div class="border-bottom pb-3">
              <span
                class="text-17 fw-900 text-white py-1 px-4 d-inline-block"
                :style="{
                  background: getBgColor(item.progress),
                  borderRadius: '100px',
                }"
              >
                {{ getText(item.progress) }}</span
              >
              <p class="text-20 fw-900 mt-3 mb-0"
                v-if="item.addressDetailMeta"
              >
                {{ `${item.addressDetailMeta.sido} 
                    ${item.addressDetailMeta.sigungu} 
                    ${item.addressDetailMeta.bname}` }}
              </p>
              <p 
                class="text-20 fw-900 mt-3 mb-0"
                v-else>
                {{item.address}}
              </p>
            </div>
            <ul class="list-unstyled">
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-center
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '80px' }">필요금액</strong>
                <span class="fw-700 text-gray text-truncate">
                  {{ toLocale(item.amount) }}만원
                </span>
              </li>
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-center
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '80px' }">세입자</strong>
                <span class="fw-700 text-gray text-truncate">
                  {{
                    item.tenant
                      ? `${toLocale(item.deposit)}만원 / ${toLocale(
                          item.monthlyRent
                        )}만원 / ${item.tenantAgree ? "동의" : "미동의"}`
                      : "본인거주"
                  }}
                </span>
              </li>
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-center
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '80px' }">의뢰일자</strong>
                <span class="fw-700 text-gray text-truncate">
                  {{ getDate(item.createdDate) }}
                </span>
              </li>
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-center
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '80px' }">재직정보</strong>
                <span class="fw-700 text-gray text-truncate">
                  {{ getWork(item.work) }}
                </span>
                <span v-if="item.creditMatters">
                  {{ ` (신용이상) ` }}
                </span>
              </li>
            </ul>
          </section>

          <!-- 3/23 마이페이지 입찰자 전환시 입찰 목록 미리보기로 띄우기 위해 찍어놓음 -->
          <!-- 서버단 필드명 = isBidder -->
          <!-- <div v-if="item.bidders.length > 0" class="skew">
            <div v-for="(item, i) in item.bidders" :key="i">
              {{item.relativeUser}}
              <div>
                {{
                  item.regProcedure === 1
                    ? "첫 번째 입찰 "
                    : item.regProcedure === 2
                    ? "두 번째 입찰 "
                    : "세 번째 입찰 "
                }}
                {{
                  `상태 : 
                    ${
                      item.matched === true
                        ? "매칭"
                        : item.cancelMatch === true
                        ? "매칭취소"
                        : item.cancelBidder === true
                        ? "입찰취소"
                        : item.rejected === true
                        ? "부결"
                        : ""
                    }
                    ${
                      item.acceptCondition === true
                        ? " (조건 수용 진행 가능)"
                        : ""
                    }`
                }}
              </div>
            </div>
          </div> -->

          <!-- matched bidder grade를 불러오기 -->
          <section>
            <!-- 의뢰자가 받은 평점 -->
            <template v-if="isMine(item.relativeUser) && item.grade2">
              <div class="mt-2 d-flex align-items-center text-17 text-lg-15">
                <!-- <i
                  v-for="(star, i) in 5"
                  class="icon mb-n1 ml-1 mt-n1"
                  :class="{
                    'icon-star-full': +item.grade >= i + 1,
                    'icon-star-half': +item.grade >= i + 0.5,
                    'icon-star-empty': +item.grade <= i,
                  }"
                  :style="{
                    minWidth: '14px',
                    width: '14px',
                    height: '14px',
                  }"
                  :key="i"
                /> -->
                <span class="ml-1 mr-0 fw-400 text-nowrap text-17 text-gray">
                  <!-- ({{ item.grade }}) -->
                  입찰자에게 받은 평점 : {{ getGrade(item.grade2) }}
                </span>
              </div>
            </template>
            <!-- 입찰자가 받은 평점 -->
            
            <template v-else-if="item.bidderGrade2">
              <div class="mt-2 d-flex align-items-center text-17 text-lg-15">
                <!-- <i
                  v-for="(star, i) in 5"
                  class="icon mb-n1 ml-1 mt-n1"
                  :class="{
                    'icon-star-full': +item.bidderGrade >= i + 1,
                    'icon-star-half': +item.bidderGrade >= i + 0.5,
                    'icon-star-empty': +item.bidderGrade <= i,
                  }"
                  :style="{
                    minWidth: '14px',
                    width: '14px',
                    height: '14px',
                  }"
                  :key="i"
                /> -->
                <span class="ml-1 mr-0 fw-400 text-nowrap text-17 text-gray">
                  <!-- ({{ item.bidderGrade }}) -->
                  의뢰자에게 받은 평점 : {{ getGrade(item.bidderGrade2) }}
                </span>
              </div>
            </template>
            <!-- 평점작성(의뢰자) -->
            <!-- :disabled="item.insertGrade" -->
            <!-- item.bidder) {
        if (item.bidderInsertGrade -->
        
            <template v-if="isMine(item.relativeUser)">
              <b-btn
                variant="primary"
                class="d-block w-100 mt-2"
                v-if="item.progress === 'COMPLETE'"
                :disabled="item.insertGrade"
                @click="inputGrade(item)"
                >평점 작성
              </b-btn>
            </template>
            <!-- 평점작성(입찰자) -->
            
            <template
              v-else-if="
                (currentMatchedBidder && user && item.bidders &&
                  item.bidders.some(
                    (bid) => bid.relativeUser && bid.relativeUser.id === user.id
                  )) ||
                item.bidder
              "
            >
              <b-btn
                variant="primary"
                class="d-block w-100 mt-2"
                v-if="item.progress === 'COMPLETE'"
                :disabled="item.bidderInsertGrade"
                @click="inputGrade(item)"
                >평점 작성
              </b-btn>
            </template>
          </section>
        </article>
        <!-- 등록중(수정중) -->
        <article v-else class="position-relative p-4 h-100 bg-light border">
          <!-- 상세내용 -->
          <section>
            <div class="border-bottom pb-3">
              <span
                class="
                  text-17
                  fw-900
                  text-white
                  py-1
                  px-4
                  d-inline-block
                  bg-dark
                "
                :style="{
                  borderRadius: '100px',
                }"
              >
                비딩매칭 등록중</span
              >
              <p class="text-20 fw-900 mt-3 mb-0">
                {{ getAddress(item.roadAddress) }}
              </p>
            </div>
            <ul class="list-unstyled">
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-center
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '80px' }">필요금액</strong>
                <span class="fw-700 text-gray text-truncate">
                  {{ toLocale(item.amount) }}만원
                </span>
              </li>
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-center
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '80px' }">세입자</strong>
                <span class="fw-700 text-gray text-truncate">
                  {{
                    item.tenant
                      ? `${toLocale(item.deposit)}만원 / ${toLocale(
                          item.monthlyRent
                        )}만원 / ${item.tenantAgree ? "동의" : "미동의"}`
                      : "본인거주"
                  }}
                </span>
              </li>
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-center
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '80px' }">의뢰일자</strong>
                <span class="fw-700 text-gray text-truncate">
                  {{ getDate(item.createdDate) }}
                </span>
              </li>
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-center
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '80px' }">재직정보</strong>
                <span class="fw-700 text-gray text-truncate">
                  {{ getWork(item.work) }}
                </span>
                <span v-if="item.creditMatters">
                  {{ ` (신용이상) ` }}
                </span>
              </li>
            </ul>
          </section>
        </article>
      </b-col>
    </b-row>

    <!-- <modal-grade
      @hidden="resetModal"
      :idBidding="currentIdBidding"
      :type="userType"
      :idBidder="idBidder"
      v-model="grade"
    /> -->

    <modal-grade2
      @hidden="resetModal"
      :idBidding="currentIdBidding"
      :type="userType"
      :idBidder="idBidder"
      v-model="grade"
    />

  </div>
</template>

<script>
import Modals from "@/components/Modals/index";
import Product from "@/lib/product.js";

export default {
  components: {
    //ModalGrade: Modals.Grade,
    ModalGrade2: Modals.Grade2,
  },
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      currentIdBidding: null,
      grade: "중",
      idBidder: null,
      kind: Product.kind,
      userType: null,
    };
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  methods: {
    currentMatchedBidder() {
      if (this.item.bidders) {
        const current = this.item.bidders.find((r) => {
          return r.matched === true;
        });
        return current;
      } else {
        return null;
      }
    },
    getState(bidder) {
      const variant = null;
      if (bidder.cancelMatch) {
        return "매칭취소";
      }
    },
    getVariant(progress) {
      const variant = {
        ING: "darkest",
        MATCHED: "error",
        APPROVAL: "success",
        AUTOGRAPH: "third",
        TRANSFERRED: "secondary",
        COMPLETE: "primary",
        REJECTED: "darkest",
        CANCEL_PROGRESS: "outline-primary",
        acceptCondition: "success",
        ERROR: "error",
      };
      return variant[progress] || variant.error;
    },
    getGrade(grade) {
      const val = {
        A: "상",
        B: "중",
        C: "하",
        ERROR: ""
      };
      return val[grade] || val.error;
    },
    getBgColor(progress) {
      const color = {
        ING: "#FFAF15", // 진행 중
        MATCHED: "#FF3D4C", // 매칭 완료
        APPROVAL: "#0DC3C3", // 승인 완료
        AUTOGRAPH: "#0DC3C3", // 자서 완료
        TRANSFERRED: "#346ef9", // 송금 완료
        COMPLETE: "#346ef9", // 대출 완료
        REJECTED: "#aaaaaa", // 부결
        CANCEL_PROGRESS: "#aaaaaa", // 진행 취소
        ERROR: "#aaaaaa", // 에러
      };
      return color[progress] || color.error;
    },
    getAddress(address) {
      if(address){
        if (address.indexOf("로") !== -1) {
          address = address.slice(0, address.indexOf("로") + 1);
        } else if (address.indexOf("길") !== -1) {
          address = address.slice(0, address.indexOf("길") + 1);
        }
        return address;
      }
    },
    getText(progress) {
      const text = {
        ING: "진행중",
        MATCHED: "매칭 완료",
        APPROVAL: "승인 완료",
        AUTOGRAPH: "자서 완료",
        TRANSFERRED: "송금 완료",
        COMPLETE: "대출 완료",
        REJECTED: "부결",
        CANCEL_PROGRESS: "진행 취소",
        acceptCondition: "조건수용 진행가능",
        ERROR: "에러",
      };
      return text[progress] || text.error;
    },
    getImage(kind) {
      return this.kind.find((r) => r.value === kind).image;
    },
    getWork(work) {
      const text = {
        EMPLOYEE: "직장인",
        HOUSEWIFE_N: "주부(무직)",
        HOUSEWIFE_Y: "주부(생활비 내역 있음)",
        BUSINESSMAN_Y: "실사업자",
        BUSINESSMAN_N: "가라사업자(매출증빙 X)",
        FREELANCER: "프리랜서(소득증빙가능)",
        BADCREDIT: "신용결여(회복/회생/파산/워크아웃 등)",
        FOREIGNER_RESIDENT_Y: "외국인 거소증 유",
        FOREIGNER_RESIDENT_N: "외국인 거소증 무",
        ERROR: "에러",
      };
      return text[work] || text.error;
    },
    resetModal() {
      this.currentIdBidding = null;
      this.grade = 3.5;
    },
    async inputGrade(item) {
      if (item.bidder) {
        if (item.bidderInsertGrade) {
          await window.alert("이미 평점 작성을 완료하였습니다.");
        }
      }

      if (item.insertGrade) {
        await window.alert("이미 평점 작성을 완료하였습니다.");
      } else if (item.progress === "COMPLETE") {
        try {
          const { data } = await this.$axios.get(
            `/bidding/${item.id}/bidder-matched`
          );
          if (data.status.code === "0000") {
            this.currentIdBidding = item.id;
            this.idBidder = data.data.id;

            if (data.data.relativeUser.id === this.$store.state.user.id) {
              this.userType = "client";
            } else {
              this.userType = "bidder";
            }

            this.$bvModal.show("modal-grade2");
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.matching-item {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateY(-10px);
  }
}
</style>
