var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('aside',{staticClass:"position-fixed d-none d-lg-block",style:({
      top: '50%',
      right: '0',
      height: '200px',
      zIndex: '100',
    })},[_c('b-btn',{staticClass:"pl-1 pr-2 h-100",style:({ borderRadius: '4rem 0 0 4rem' }),attrs:{"variant":"primary"}},[_c('i',{staticClass:"icon icon-24 ml-n1 icon-sidebar-toggler"})])],1),_c('aside',{staticClass:"position-fixed d-block d-lg-none",style:({
      top: '50%',
      right: '0',
      height: '48px',
      zIndex: '100',
    })},[_c('b-btn',{staticClass:"py-3 px-1 w-100",style:({ borderRadius: '2rem 0 0 2rem' }),attrs:{"variant":"primary"}},[_c('i',{staticClass:"icon icon-sidebar-toggler"})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }