<template></template>

<script>
export default {
  data() {
    return {
      input: {
        loginType: "EMAIL",
        serviceId: "",
        password: "",
        autoLogin: false,
      },
      naverUrl: null,
    };
  },
  methods: {
    async snsLogin(id, type) {
      try {
        const serviceId = `${type}_${id}`;
        console.log("serviceId", serviceId);
        const body = {
          serviceId,
          password: serviceId,
          loginType: type,
        };
        // console.log(body);
        // 이메일 중복체크
        const { data: isSigned } = await this.$axios.post("/auth/token", body);
        if (isSigned.status.code === "40411") {
          // 중복이 아니면? 회원가입
          this.$router.push({
            name: "SignUpForm",
            params: { serviceId, loginType: type },
          });
        } else if (isSigned.status.code === "0000") {
          const loginData = {
            token: `${isSigned.data.token_type} ${isSigned.data.access_token}`,
            autoLogin: false,
          };
          this.getMe(loginData);
          this.$router.push("/");
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    var naverLogin = new window.naver.LoginWithNaverId({
      clientId: "62LknMd92KuRBoC3s95T",
      callbackUrl: `${window.location.origin}/`,
      callbackHandle: true,
      // isPopup: false, /* 팝업을 통한 연동처리 여부 */
    });
    /* 설정정보를 초기화하고 연동을 준비 */
    naverLogin.init();

    naverLogin.getLoginStatus(async (status) => {
      console.log("status", status);

      let user = naverLogin.user;
      if (status) {
        let id = user.id;
        this.snsLogin(id, "NAVER");
      } else {
        console.log("callback 처리에 실패하였습니다.");
      }
    });
  },
};
</script>

<style></style>
