<template>
  <div>
    <div class="table-wrapper py-3">
      <table class="px-3">
        <tr class="">
          <th class="pt-1 pb-3" :style="{ minWidth: '13rem' }">
            문의 제목
          </th>
          <th class="pt-1 pb-3" :style="{ minWidth: '8rem' }">
            문의 일시
          </th>
          <th class="pt-1 pb-3" :style="{ minWidth: '8rem' }">
            처리 상태
          </th>
          <th class="pt-1 pb-3" :style="{ minWidth: '10rem' }">
            <!-- a11y read text -->
            <span :style="{ opacity: '0.00000001' }">자세히보기 버튼</span>
          </th>
        </tr>
        <template v-if="items && !items.length">
          <tr>
            <td colspan="4" class="border-top text-center">
              데이터가 없습니다.
            </td>
          </tr>
        </template>
        <template v-else>
          <tr
            v-for="(item, i) in items"
            :key="i"
            :class="
              i == 0
                ? 'border-top border-2 border-gray'
                : 'border-top border-light-gray'
            "
          >
            <td class="text-nowrap px-3">
              <div class="text-nowrap">
                {{ item.title }}
              </div>
            </td>
            <td class="text-center">
              {{ toLocaleDate(item.createdDate) }}
            </td>
            <td class="text-center">
              <span :class="`text-${getVariant(item.status)}`">
                {{ getText(item.status) }}
              </span>
            </td>
            <td class="text-center">
              <b-btn
                variant="outline-primary"
                :to="{
                  path: '/mypage/contact/inquiry',
                  query: { id: item.id },
                }"
              >
                자세히 보기
              </b-btn>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <div class="mt-5 d-flex flex-column" v-if="items && items.length">
      <b-pagination
        @change="getList($event)"
        :value="page.number + 1"
        :total-rows="page.totalElements"
        :per-page="page.size"
        class="mx-auto"
        first-class="d-none"
        last-class="d-none"
        hide-ellipsis
      >
        <template #prev-text>
          <i class="icon icon-prev ml-1"></i>
        </template>
        <template #next-text>
          <i class="icon icon-next ml-1"></i>
        </template>
      </b-pagination>
    </div>
    <template v-if="!items">
      <div class="text-center">
        <b-spinner variant="primary" class="my-5" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: {
        page: 1,
      },
      items: null,
    };
  },
  methods: {
    getVariant(progress) {
      const variant = {
        ANSWERED: "secondary",
        WAIT: "darkest",
        ERROR: "error",
      };
      return variant[progress] || null;
    },
    getText(progress) {
      const text = {
        ANSWERED: "답변 완료",
        WAIT: "답변 미완료",
        ERROR: "에러",
      };
      return text[progress] || null;
    },
    async getList() {
      try {
        const { data } = await this.$axios.get("/inquiries");
        if (data.status.code === "0000") {
          this.items = data.data.content;
          this.page = data.data.page;
        }
      } catch (error) {}
    },
  },
  async mounted() {
    await this.getList();
  },
};
</script>

<style lang="scss" scoped></style>
