// import roleMappingData from "@/lib/role";
export default {
  install(Vue, options) {
    Vue.mixin({
      computed: {
        meta() {
          return this.$route.meta;
        },
        user() {
          return this.$store.getters.user;
        },
        loaded() {
          return this.$store.getters.loaded;
        },
      },
      data() {
        return {
          s3: "https://homebank-s3.s3.ap-northeast-2.amazonaws.com/",
          progress: {
            progress: false,
            per: 0,
          },
        };
      },
      filters: {
        getDDay(value) {
          const now = new Date();
          const gap = new Date(value).getTime() - now.getTime();
          const result = Math.ceil(gap / (1000 * 60 * 60 * 24));
          return result > 0 ? `D - ${result}` : "모집 종료";
        },

        toLocaleDate(value) {
          const date = new Date(value);
          date.setHours(date.getHours() + 9);
          return date.toISOString().split("T").join(" ").substr(0, 19);
        },
      },
      methods: {
        encdoeStr(str) {
          /**
           * ㎡ = 13217
           */
          return String.fromCharCode(str);
        },
        pyUnit(value) {
          return `${Number(value / 3.30579)
            .toFixed()
            .toLocaleString()}평`;
        },
        toLocaleDate(value) {
          const date = new Date(value);
          // date.setHours(date.getHours() + 9);
          //   String.prototype.replaceAt = function(index, replacement) {
          //     return this.substring(0, index) + replacement + this.substring(index + replacement.length);
          // }
          return date.toLocaleString().replaceAll(". 오", " / 오");
          // .replaceAll('. ', '/')
          // .replaceAt(9,' ')
          // .split("T")
          // .join(" ")
          // .substr(0, 19);
        },
        getDate(value) {
          const date = new Date(value);
          return date.toLocaleDateString().replace(/.$/, "");
        },
        getDateStr(value) {
          const yyyy = String(value).slice(0, -4);
          const mm = String(value).slice(-4, -2);
          const dd = String(value).slice(-2);
          return `${yyyy}-${mm}-${dd}`;
        },
        getCalcDate(value, add) {
          let date = new Date(value);
          date.setHours(date.getHours() + 9);
          if (add) {
            date = new Date(date.setDate(date.getDate() + add));
          }
          return date;
        },
        getFileSize(x) {
          const s = ["bytes", "KB", "MB", "GB", "TB", "PB"];
          const e = Math.floor(Math.log(x) / Math.log(1024));
          return x ? (x / Math.pow(1024, e)).toFixed(0) + " " + s[e] : 0;
        },
        onlyId(value) {
          return value.split("@")[0];
        },
        snsId(value) {
          const type = value.split("_")[0];
          const id = [value.split("_").pop()].join("");
          if (type === "GOOGLE") {
            return {
              type: require("@/assets/icons/google.png"),
              id,
              variant: "light",
            };
          }
          if (type === "KAKAO") {
            return {
              type: require("@/assets/icons/kakaotalk.png"),
              id,
              variant: "kakao",
            };
          }
          if (type === "NAVER") {
            return {
              type: require("@/assets/icons/naver.svg"),
              id,
              variant: "naver",
            };
          } else return null;
        },
        async getMe(loginData) {
          return await this.$store.dispatch("getMe", loginData);
        },
        async downloadFile({ file, name }) {
          try {
            const url = this.s3 + file;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (error) {
            console.log(error);
          }
        },
        async signOut() {
          localStorage.removeItem("homeBank__token");
          sessionStorage.removeItem("homeBank__token");
          // sessionStorage.removeItem("homeBank__token");
          this.$store.commit("set", ["user", null]);
          axios.defaults.headers.common["Authorization"] = undefined;
          this.$router.push("/account/login");
        },
        // getRole(role) {
        //   return roleMappingData[role];
        // },
        getMobile({ openMobile, mobile }) {
          return openMobile ? mobile : "비공개";
        },
        getEmail({ openEmail, email }) {
          return openEmail ? email : "비공개";
        },
        pageClicked(e, page = 0) {
          this.routeControl("page", page);
        },
        routeControl(key, value) {
          const path = this.$route.path;
          if (this.$route.query[key] !== value) {
            const query = {
              ...this.$route.query,
              [key]: value,
            };
            if (key === "page") delete query.page;

            this.$router.push({ path, query });
          }
        },
        isMine(relativeUser) {
          const user = this.$store.getters && this.$store.getters.user;

          return user && relativeUser && relativeUser.id === user.id;
        },
        $numberOnly(e, key, maxLength, comma) {
          if (e && e.target) {
            let val = e.target.value.replace(/[^0-9]/g, "");
            val = val.substr(0, maxLength);
            if (comma) {
              val = (+val).toLocaleString();
            }
            this.input[key] = val;
          }
        },
        $inputNumber(e, key, min, max, comma) {
          if (e && e.target) {
            let val = e.target.value.replace(/[^0-9]/g, "");
            if (min > val) val = min;
            else if (max < val) val = max;

            if (comma) {
              val = (+val).toLocaleString();
            }
            this.input[key] = val;
          }
        },

        // 사용 보류
        getDateFormat(e, key, min, max) {
          if (e && e.target) {
            let val = e.target.value.replace(/-/g, "");

            if (min > val) val = min;
            else if (max < val) val = max;

            let tempDate = "";

            if (val.length < 5) {
              return (tempDate = val);
            } else if (val.length < 7) {
              tempDate = `${val.substr(0, 4)}-${val.substr(4, 2)}`;
            } else {
              tempDate = `${val.substr(0, 4)}-${val.substr(4, 2)}-${val.substr(
                6
              )}`;
            }

            if (key.includes("tradeDetailsMeta")) {
              key = key.substr(key.indexOf(".") + 1);
              this.tradeDetailsMeta[key] = tempDate;
            } else {
              this.input.tradeDetailsMeta[key] = tempDate;
            }
          }
        },
        $inputDecimal(e, key, max) {
          if (e && e.target) {
            let val = e.target.value.replace(/[^-0-9\.]+/g, "");
            // let val = e.target.value.replace(
            //   /(^\d*[.]\d{3}$) | ([^0-9.]) |(^\d*[.]{2})/,
            //   ""
            // );
            let pattern = /^\d*(\.\d{0,1})?$/;

            if (max < val) val = max;

            if (!pattern.test(val)) {
              // console.log(pattern.test(val));
              this.input[key] = Number(val).toFixed(1);
              return;
            } else {
              this.input[key] = val;
            }
          }
        },
        $inputPrice(e, key) {
          if (e && e.target) {
            let val = e.target.value;

            this.input[key] = `${e.target.value
              .replace(/\D/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          }
        },

        $price(e, key) {
          if (e && e.target) {
            let val = e.target.value;

            this[key] = `${e.target.value
              .replace(/\D/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          }
        },
        
        $inputPrice2(e, depth1, depth2) {
          if (e && e.target) {
            this[depth1][depth2] = `${e.target.value
              .replace(/\D/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          }
        },

        $inputPrice3(e, depth1, depth2, depth3) {
          if (e && e.target) {
            this.input[depth1][depth2][depth3] = `${e.target.value
              .replace(/\D/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          }
        },
        
        $inputListPrice2(e, i, depth1, depth2) {
          console.log(this.input[depth1][i][depth2]);
          if (e && e.target) {
            this.input[depth1][i][depth2] = `${e.target.value
              .replace(/\D/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          }
        },

        $inputDecimalPoint(e, key) {
          if (e && e.target) {
            let val = e.target.value.replace("%","");
            
            console.log(val.indexOf("."));

            //입력된 길이가 2보다 크고, 소수점이 포함되어있지 않은 경우 2자리만 허용 
            //그렇지 않은 경우 소수점 포함 5자리까지 허용
            if(val.length > 2 && val.indexOf(".") == -1 && (val % 1) == 0){
              val = val.substr(0, 2);
            }else{
              val = val.substr(0, 5);
            }
            
            //소수점, 정수 제외 replace
            if(isNaN( val )){
              val = val.replace(/[^0-9.]/g, "");
            }
            
            this.input[key] = `${val}%`;
          }
        },

        numberToPrice(val) {
          if (val) {
            val = val.toLocaleString("ko-KR");
            console.log(val);
          }
          return val;
        },

        priceToNumber(val) {
          if (val) {
            return val.replaceAll(",", "");
          }
        },

        //파일 재업로드시 실행
        async attachFile(file) {
          const formData = new FormData();
          formData.append("file", file);

          // let config = useProgress
          //   ? {
          //       onUploadProgress: (e) => {
          //         this.progress = {
          //           progress: e.total > e.loaded,
          //           per: ((e.loaded / e.total) * 100).toFixed(),
          //         };
          //         // console.log(this.progress);
          //       },
          //     }
          //   : {};
          //   // const { data } = this.$axios.post("/files/image", formData);

          const { data } = await this.$axios.post(
            "/files/image",
            formData
            // config
          );
          if (data.status.code === "0000") {
            return data.data;
          } else {
            this.files = [];
            await alert(data.status.message);
          }
        },

        // Quill

        async fileUpload(file, useProgress) {
          const formData = new FormData();
          formData.append("file", file);

          let config = useProgress
            ? {
                onUploadProgress: (e) => {
                  this.progress = {
                    progress: e.total > e.loaded,
                    per: ((e.loaded / e.total) * 100).toFixed(),
                  };
                  // console.log(this.progress);
                },
              }
            : {};
          // const { data } = this.$axios.post("/files/image", formData);

          const { data } = await this.$axios.post(
            "/files/file",
            formData,
            config
          );
          if (data.status.code === "0000") {
            return data.data;
          } else {
            this.files = [];
            await alert(data.status.message);
          }
        },
        async imageAdded(file, Editor, cursorLocation, resetUploader) {
          const result = await this.fileUpload(file);
          let url = result.url; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        },
        imageRemoved(el) {
          // todo
        },
        // 가격 단위의 숫자 표현
        toLocale(value) {
          return Number(value).toLocaleString("ko-KR");
        },
      },
    });
  },
};
