<template>
  <div>
    <div class="d-flex">
      <b-input-group class="mb-4" variant="outline" hide-prepend>
        <b-form-input
          type="text"
          v-model="value.address"
          placeholder="주소*"
          readonly
          @click="getPostCode"
        ></b-form-input>
        <template #append>
          <div class="ml-3">
            <b-btn
              variant="outline-primary"
              readonly
              class="text-17 text-nowrap"
              @click="getPostCode"
              >검색</b-btn
            >
          </div>
        </template>
      </b-input-group>
    </div>
    <!-- <p class="text-17 m-0">예상 지번주소 : {{ value.address }}</p> -->
    <!-- <b-form-input
      type="text"
      v-model="value.address"
      placeholder="예상 지번주소"
      readonly
    ></b-form-input> -->
    <!-- <b-input-group class="mb-4" variant="outline" hide-prepend>
      <w-input
        type="text"
        v-model="value.addressDetail"
        placeholder="상세 주소"
        class="w-100 m-0"
        ref="addrDetail"
      ></w-input>
    </b-input-group> -->
    <div class="pt-3">
      <div ref="addr" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      wrap: null,
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.wrap = this.$refs.addr;
    });
  },
  methods: {
    foldDaumzonecode() {
      // iframe을 넣은 element를 안보이게 한다.
      this.wrap.style.display = "none";
    },
    getPostCode() {
      // 현재 scroll 위치를 저장해놓는다.
      var currentScroll = Math.max(
        document.body.scrollTop,
        document.documentElement.scrollTop
      );
      new daum.Postcode({
        //autoMappingJibun: false, //연관주소가 지번일 때 지번 주소 자동매핑을 막음
       // autoMappingRoad: false, //연관주소가 도로명일 때  주소 자동매핑을 막음

        oncomplete: (data) => {
          console.log(data);
          // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          var addr = ""; // 주소 변수
          var extraAddr = ""; // 참고항목 변수

          //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === "R") {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            // 조합된 참고항목을 해당 필드에 넣는다.
          }
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          //===================================================================
          //jibunAddressEnglish 또는 autoJibunAddressEnglish에 랜덤으로 값이 담겨 필터링 추가함
          let jibun = "";

          if (data.autoJibunAddressEnglish === "") {
            jibun = data.jibunAddressEnglish.split(",")[0];
          } else {
            jibun = data.autoJibunAddressEnglish.split(",")[0];
          }

          //====================================================================
          this.add = jibun;
          this.$emit("input", {
            zipNo: data.zonecode,
            address: `${addr} ${extraAddr}`,
            sido: data.sido,
            jibunAddress:
              data.jibunAddress !== ""
                ? data.jibunAddress
                : data.autoJibunAddress,
            roadAddress:
              data.roadAddress != "" ? data.roadAddress : data.autoRoadAddress,
            addressDetailMeta: {
              bcode: data.bcode,
              bname: data.bname,
              bname1: data.bname1,
              buildingCode: data.buildingCode,
              buildingName: data.buildingName,
              bun: jibun.split("-")[0] || "",
              ji: jibun.split("-")[1] || "",
              sido: data.sido,
              sigungu: data.sigungu,
              sigunguCode: data.sigunguCode,
            },
          });
          // const geocoder = new kakao.maps.services.Geocoder();

          // // 주소로 좌표를 검색합니다
          // geocoder.addressSearch(`${addr} ${extraAddr}`, function(result, status) {

          //     // 정상적으로 검색이 완료됐으면
          //     if (status === kakao.maps.services.Status.OK) {
          //       console.log("OK",result)
          //       const coords = new kakao.maps.LatLng(result[0].y, result[0].x);
          //       const message = 'latlng: new kakao.maps.LatLng(' + result[0].y + ', ';
          //       message += result[0].x + ')';
          //     }
          // });
          // 커서를 상세주소 필드로 이동한다.
          //this.$refs.addrDetail.$el.focus();

          // iframe을 넣은 element를 안보이게 한다.
          // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
          this.wrap.style.display = "none";

          // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
          document.body.scrollTop = currentScroll;
        },

        // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
        // onresize: (size) => {
        //   this.wrap.style.height = size.height + "px";
        // },
        // width: "100%",
        // height: "100%",
      }).open({ autoClose: true }); //주소 선택시 팝업창 닫기
      // iframe을 넣은 element를 보이게 한다.
      // this.wrap.style.display = "block";
      // test(address);
    },
  },
};
</script>

<style></style>
