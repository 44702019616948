<template>
  <div
    class="position-relative"
    :style="
      valid && valid.state === false
        ? { marginBottom: '41px' }
        : { marginBottom: '20px' }
    "
  >
    <b-input-group class="m-0">
      <template #prepend v-if="$slots.prepend">
        <b-input-group-text
          :class="{ 'is-invalid': valid && valid.state === false }"
        >
          <slot name="prepend"></slot>
        </b-input-group-text>
      </template>
      <template #append>
        <!-- <div> -->
        <slot name="append" :validate="valid && valid.state"></slot>
        <!-- </div> -->
      </template>
      <b-input
        class="text-12"
        @input="inputListener"
        :value="value"
        :type="type"
        :class="[
          inputClass,
          {
            'is-invalid': valid && valid.state === false,
          },
        ]"
        :placeholder="placeholder"
        :readonly="readonly"
        autocomplete="off"
      />
    </b-input-group>
    <div
      class="position-absolute text-right"
      style="left:0;right:0;top:100%"
      v-if="valid"
    >
      <b-form-invalid-feedback
        class="text-error m-0"
        :state="valid.state"
        v-text="valid.message"
      />
      <b-form-valid-feedback :state="valid.state" v-text="successMessage" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { default: null },
    value: { default: null },
    type: {
      type: String,
      default: () => "text",
    },
    inputClass: { default: () => undefined, type: String },
    placeholder: { default: () => undefined, type: String },
    readonly: { default: () => undefined, type: String },
    successMessage: { default: () => null },
    rules: { type: Array, default: () => [] },
    serverCheck: { type: Function, default: null },
  },
  data() {
    return {
      timeout: null,
      // localState: { state: null, message: "" },
      serverState: { state: null, message: "" },
    };
  },
  computed: {
    localState() {
      const val = this.value;
      this.serverState = !!this.serverCheck
        ? { state: false, message: "체크 중입니다." }
        : { state: true, message: "" };
      this.timeout = null;
      let valid = { state: null, message: "" };
      if (val === null) return valid;

      const rules = this.serverCheck
        ? [
            ...this.rules,
            (v) => {
              setTimeout(async () => {
                this.serverState = await this.serverCheck(v);
                this.$emit("state-update");
              }, 500);
              return true;
            },
          ]
        : this.rules;
      clearTimeout(this.timeout);
      valid = rules.reduce((result, rule) => {
        if (val === null) {
          return;
        }
        if (result.state !== false) {
          const ruleResult = rule(val);
          result =
            ruleResult !== true
              ? {
                  state: false,
                  ...ruleResult,
                }
              : {
                  state: true,
                  message: null,
                };
        }
        return result;
      }, valid);

      return valid;
    },
    valid() {
      if (!this.rules.length) return undefined;
      return !this.localState.state ? this.localState : this.serverState;
    },
  },
  watch: {
    valid(n) {
      this.$emit("valid-update", n);
    },
  },
  methods: {
    inputListener(v) {
      let val = v;
      switch (this.type) {
        case "number":
          // console.log(v);
          val = +v;
          break;

        default:
          break;
      }
      // if (this.rules) {
      //   this.serverState = this.checkValid(val);
      // }
      this.$emit("input", val);
    },
  },
};
</script>

<style></style>
