<template>
  <header
    :class="'bg-' + variant"
    class="mb-4 px-4 d-flex flex-column justify-content-center text-score-black text-20 text-lg-23"
    :style="{ height: '56px' }"
  >
    {{ text }}
  </header>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    text: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped></style>
