<template>
  <div>
    <header class="mb-3">
      <h2 class="mb-2 text-28 text-lg-38">1:1 문의</h2>
      <span class="text-14 text-gray fw-400"
        >1:1 문의를 확인하실 수 있습니다.</span
      >
    </header>
    <b-form @submit.prevent="submit" class="mt-5">
      <div
        :style="{ borderTop: '3px solid #333', borderBottom: '3px solid #333' }"
      >
        <b-row align-v="center" class="mx-0 border-bottom border-gray">
          <b-col cols="12" md="2" class="px-0 pl-md-0 pr-md-4">
            <div
              class="py-2 py-md-4 text-center text-gray"
              :style="{ backgroundColor: '#fbf8f4' }"
            >
              문의 제목
            </div>
          </b-col>
          <b-col cols="12" md="10" class="px-1">
            <div class="py-3">
              <b-form-input
                v-model="input.title"
                class="rounded-lg input-inquiry"
                placeholder="문의 제목을 입력해주세요"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="12" md="2" align-self="stretch">
            <div
              class="py-2 py-md-4 text-center text-gray h-100 d-flex flex-column"
              :style="{ backgroundColor: '#fbf8f4' }"
            >
              <span class="my-auto">
                문의 내용
              </span>
            </div>
          </b-col>
          <b-col cols="12" md="10">
            <div class="my-3">
              <b-textarea
                v-model="input.content"
                rows="10"
                class="rounded-lg input-inquiry"
                placeholder="문의 내용을 입력해주세요"
              >
              </b-textarea>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row class="mt-4" align-h="center">
        <b-col cols="12" md="8">
          <b-btn variant="primary" class="w-100 py-4" @click="submit"
            >등록</b-btn
          >
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: {
        title: null,
        content: null,
      },
    };
  },
  methods: {
    async submit() {
      if (
        !this.input.title ||
        !this.input.content ||
        this.input.title === "" ||
        this.input.content === ""
      ) {
        await window.alert(
          "문의 내용과 제목은 모두 필수적으로 입력해야 합니다.",
          {
            title: "필수값 입력",
          }
        );
      } else {
        try {
          const { data } = await this.$axios.post("/inquiries", this.input);
          if (data.status.code === "0000") {
            this.$router.push("/mypage/contact");
          }
        } catch (error) {}
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
