var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2",class:{ 'bg-gray-f6': _vm.item.auto }},[(_vm.item.auto)?_c('div',[_c('b-badge',{staticClass:"py-2 rounded-pill text-white bg-darkest"},[_c('span',{staticClass:"text-20"},[_vm._v("자동입찰")])])],1):_vm._e(),_vm._v(" "+_vm._s(("입찰 취소 여부 : " + (_vm.item.cancelBidder)))+" "+_vm._s(("매칭 취소 여부 : " + (_vm.item.cancelMatch)))+" "),_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","md":"2","lg":"1"}},[(
          _vm.item.relativeUser && (_vm.item.relativeUser.serviceId === _vm.user.serviceId ||
            _vm.bidding.relativeUser.serviceId === _vm.user.serviceId) &&
          _vm.item.relativeUser.profile
        )?[_c('figure',{staticClass:"mb-0 bg-img w-100 ratio-100 border border-lightest",style:({
            backgroundImage: ("url(" + (_vm.s3 + _vm.item.relativeUser.profile) + ")"),
            minWidth: '84px',
          })})]:[_c('b-avatar',{style:({ minHeight: '5.25rem' }),attrs:{"square":"","size":"100%","variant":"primary"}})]],2),_c('b-col',{staticClass:"mt-2 mt-md-0",attrs:{"cols":"12","md":"7","lg":"9"}},[_c('b-row',{attrs:{"align-v":"start"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-md-flex align-items-center horizon-scroll"},[_c('strong',{staticClass:"text-20 text-primary text-truncate"},[(
                  ( _vm.item.relativeUser && _vm.item.relativeUser.serviceId === _vm.user.serviceId ||
                    _vm.bidding.relativeUser.serviceId === _vm.user.serviceId || 
                    _vm.bidding.relativeUser.serviceId === _vm.user.serviceId && 
                    _vm.item.relativeBank
                  )
                )?[(_vm.item.auto)?_c('div',[_vm._v(" 실제 업체명 : "+_vm._s(_vm.item.relativeUser.companyMeta.companyName)+" || 보여지는 업체명 : "+_vm._s(_vm.item.relativeUser.companyMeta.companyName2)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.item.relativeUser ? _vm.item.relativeUser.companyMeta == null ? _vm.onlyId(_vm.item.relativeUser.serviceId) : _vm.item.relativeUser.companyMeta.companyName : null)+" ")])]:[_vm._v(" ***** ")]],2),[(
                  _vm.item.relativeUser && (_vm.item.relativeUser.serviceId === _vm.user.serviceId ||
                  _vm.bidding.relativeUser.serviceId === _vm.user.serviceId)
                )?[_vm._v(" "+_vm._s(((_vm.item.regProcedure) + "번째 입찰"))+" ")]:_vm._e()],_c('div',{staticClass:"d-md-flex align-items-center"},[_vm._l((_vm.condition),function(item,i){return _c('div',{key:i},[(item.value)?_c('b-badge',{staticClass:"\n                      w-sm-100\n                      ml-md-2\n                      my-1 my-md-0\n                      py-1\n                      rounded-pill\n                      text-white\n                    ",class:("" + (item.variant))},[_c('span',{staticClass:"text-17"},[_vm._v(" "+_vm._s(item.text))])]):_vm._e()],1)})],2)],2)]),_c('b-col',{attrs:{"cols":"12","md":"3","lg":"4"}},[_c('ul',{staticClass:"mt-2 list-unstyled text-17 text-lg-15"},[_c('li',{staticClass:"mb-2 d-flex align-items-center"},[_c('strong',{style:({ width: '72px' })},[_vm._v("수당")]),_c('span',{staticClass:"ml-auto ml-md-0 text-gray text-right text-md-left"},[(
                    (_vm.item.relativeUser && _vm.item.relativeUser.serviceId === _vm.user.serviceId ||
                    _vm.bidding.relativeUser.serviceId === _vm.user.serviceId)
                  )?[_vm._v(" "+_vm._s(_vm.item.benefit)+"% ")]:[_vm._v(" ***** ")]],2)]),_c('li',[_c('strong',{style:({ width: '120px' })},[_vm._v("비용")]),_c('span',{staticClass:"ml-auto ml-md-0 text-gray text-right text-md-left"},[(
                      (_vm.item.relativeUser && _vm.item.relativeUser.serviceId === _vm.user.serviceId ||
                      _vm.bidding.relativeUser.serviceId === _vm.user.serviceId)
                    )?[_vm._v(" 중도상환 수수료 : "+_vm._s(_vm.item.prepaymentCommission)+"% | ")]:[_vm._v(" ***** ")]],2),_c('span',{staticClass:"ml-auto ml-md-0 text-gray text-right text-md-left"},[(
                      (_vm.item.relativeUser && _vm.item.relativeUser.serviceId === _vm.user.serviceId ||
                      _vm.bidding.relativeUser.serviceId === _vm.user.serviceId)
                    )?[_vm._v(" 플랫폼 수수료 : "+_vm._s(_vm.item.platformCommission)+"% ")]:[_vm._v(" ***** ")]],2)])])]),_c('b-col',{attrs:{"cols":"12","md":"5","lg":"4"}},[_c('ul',{staticClass:"my-2 list-unstyled text-17 text-lg-15"},[_c('li',{staticClass:"mb-2 d-flex align-items-center"},[_c('strong',{style:({ width: '120px' })},[_vm._v("한도")]),_c('span',{staticClass:"ml-auto ml-md-0 text-gray text-right text-md-left"},[(
                    (_vm.item.relativeUser && _vm.item.relativeUser.serviceId === _vm.user.serviceId ||
                    _vm.bidding.relativeUser.serviceId === _vm.user.serviceId)
                  )?[_vm._v(" "+_vm._s(_vm.toLocale(_vm.item.limitAmount))+"만원 ")]:[_vm._v(" ***** ")]],2)])])]),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('ul',{staticClass:"my-2 list-unstyled text-17 text-lg-15"},[_c('li',{staticClass:"mb-2 d-flex align-items-center"},[_c('strong',{style:({ width: '120px' })},[_vm._v("금리")]),_c('span',{staticClass:"ml-auto ml-md-0 text-gray text-right text-md-left"},[(
                    (_vm.item.relativeUser && _vm.item.relativeUser.serviceId === _vm.user.serviceId ||
                    _vm.bidding.relativeUser.serviceId === _vm.user.serviceId)
                  )?[_vm._v(" "+_vm._s(_vm.item.interestRate)+"% ")]:[_vm._v(" ***** ")]],2)])])])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3","lg":"2"}},[_c('div',{staticClass:"text-md-center pr-0 pr-xl-4"},[(
            _vm.bidding.progress !== 'TRANSFERRED' && //송금완료
            _vm.bidding.progress !== 'CANCEL_PROGRESS' && //의뢰취소
            _vm.bidding.progress !== 'COMPLETE' && //대출 완료
            _vm.bidding.bidders.some(
              //본인이 입찰한 건
              //0901 bid.relativeUser 추가
              function (bid) { return bid.relativeUser && 
                bid.relativeUser.serviceId === _vm.user.serviceId &&
                bid.rejected == false; }
            ) &&
            _vm.item.relativeUser && _vm.item.relativeUser.serviceId === _vm.user.serviceId

            /**&&
            !item.matched &&
            !item.cancelBidder */
          )?[(
              !_vm.item.acceptCondition &&
              _vm.item.relativeBidding.possibleUpdate < 2 &&
              _vm.item.relativeBidding.lastUpdatedDate > _vm.item.fixedUpdatedDate &&
              !_vm.item.rejected &&
              _vm.item.relativeBidding.progress != 'CANCELPROGRESS'
            )?_c('b-btn',{staticClass:"w-100 position-relative text-nowrap",attrs:{"variant":"outline-primary","disabled":_vm.item.cancelMatch ||
              _vm.item.rejected ||
              _vm.item.cancelBidder ||
              _vm.item.matched},on:{"click":function($event){return _vm.changeAcceptCondition(_vm.item)}}},[_c('span',{staticClass:"text-17"},[_vm._v(" 조건 수용 진행 가능")])]):_vm._e(),_c('b-btn',{staticClass:"w-100",attrs:{"variant":"primary","block":"","to":{
              path: this.$route.path + '/edit-bidding',
              query: { idBidder: _vm.item.id },
            },"disabled":_vm.item.possibleUpdate === 0 ||
              _vm.item.cancelMatch ||
              _vm.item.rejected ||
              _vm.item.cancelBidder ||
              _vm.item.matched}},[_c('span',[_vm._v(_vm._s(_vm.item.possibleUpdate === 0 ? "수정 불가" : "수정")+" "),(
                  _vm.item.possibleUpdate &&
                  _vm.item.possibleUpdate > 0 &&
                  !_vm.item.cancelMatch &&
                  !_vm.item.rejected
                )?_c('small',{staticClass:"d-block"},[_vm._v("남은 수정 횟수 "+_vm._s(_vm.item.possibleUpdate)+"회")]):(!_vm.item.possibleUpdate)?_c('small',{staticClass:"d-block"},[_vm._v("(수정 횟수 소진)")]):_vm._e()])]),(!_vm.item.cancelMatch && !_vm.item.rejected)?_c('b-btn',{staticClass:"w-100 position-relative text-nowrap",attrs:{"variant":"outline-primary","block":"","disabled":_vm.item.cancelMatch ||
              _vm.item.rejected ||
              _vm.item.cancelBidder ||
              _vm.item.matched},on:{"click":function($event){return _vm.cancelBidder(_vm.item.id)}}},[_c('span',{staticClass:"position-absolute p-0 text-white py-1 px-2 text-17"}),_vm._v(" 입찰 취소 ")]):_vm._e(),_c('modal-bidder-cancel',{attrs:{"idBidder":_vm.currentBidder,"bidder":_vm.item,"id":_vm.item.id},on:{"hidden":_vm.resetModal,"aa":_vm.item},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),(_vm.item.matched || _vm.lastMessage)?_c('b-btn',{staticClass:"w-100 position-relative text-nowrap",attrs:{"variant":"outline-primary","block":"","to":{
              path: _vm.$route.path,
              query: { chat: _vm.item.id },
              params: { fixedScroll: true },
            }}},[(_vm.channel && _vm.channel.unreadMessageCount)?_c('span',{staticClass:"\n                position-absolute\n                rounded-circle\n                p-0\n                bg-error\n                text-white\n                py-1\n                px-2\n                text-17\n              ",style:({ top: '0', right: '-16px', maxWidth: '30px' })},[_vm._v(_vm._s(_vm.channel.unreadMessageCount))]):_vm._e(),_c('i',{staticClass:"icon icon-chatting"}),_vm._v(" 채팅하기 ")]):_vm._e(),_c('modal-chatting',{attrs:{"target-type":"BIDDER","channel":_vm.channel,"target":_vm.bidding.relativeUser,"item":_vm.item}})]:_vm._e(),(
            _vm.isMine(_vm.bidding.relativeUser) &&
            _vm.bidding.progress !== 'TRANSFERRED' &&
            _vm.bidding.progress !== 'COMPLETE'
          )?[_c('div',{staticClass:"d-flex flex-column"},[(_vm.condition.matched.value)?_c('b-btn',{staticClass:"w-100 mb-2 text-nowrap",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.cancelBidding(_vm.item.id)}}},[_c('i',{staticClass:"icon icon-matching"}),_vm._v(" 매칭 취소 ")]):_vm._e(),(
                // condition.acceptCondition.value &&
                !_vm.condition.matched.value
              )?_c('b-btn',{staticClass:"w-100 mb-2 text-nowrap",attrs:{"variant":"primary","disabled":(_vm.bidding.progress === 'MATCHED' &&
                  _vm.bidding.progress === 'CANCEL_PROGRESS' &&
                  _vm.item.rejected === true) ||
                _vm.item.cancelMatch === true ||
                _vm.item.cancelBidder === true},on:{"click":function($event){return _vm.matching(_vm.item.id)}}},[_c('i',{staticClass:"icon icon-matching"}),_vm._v(" 매칭하기 ")]):_vm._e(),(_vm.item.relativeUser)?_c('b-btn',{staticClass:"w-100 position-relative text-nowrap",attrs:{"variant":"outline-primary","to":{
                path: _vm.$route.path,
                query: { chat: _vm.item.id },
                params: { fixedScroll: true },
              },"disabled":_vm.bidding.progress == 'CANCEL_PROGRESS'}},[(_vm.channel && _vm.channel.unreadMessageCount)?_c('span',{staticClass:"\n                  position-absolute\n                  rounded-circle\n                  p-0\n                  bg-error\n                  text-white\n                  py-1\n                  px-2\n                  text-17\n                ",style:({ top: '0', right: '-16px', maxWidth: '30px' })},[_vm._v(_vm._s(_vm.channel.unreadMessageCount))]):_vm._e(),_c('i',{staticClass:"icon icon-chatting"}),_vm._v(" 채팅하기 ")]):_vm._e(),_c('modal-chatting',{attrs:{"target-type":"USER","channel":_vm.channel,"target":_vm.item.relativeUser,"item":_vm.item}})],1)]:_vm._e()],2)])],1),_c('modal-bidding-cancel',{attrs:{"idBidder":_vm.currentId},on:{"hidden":_vm.resetModal},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('modal-owner-phone',{attrs:{"idBidding":_vm.bidding.id},on:{"hidden":_vm.resetModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }