// 매칭 필터
export default {
  // 종류
  kind: [
    {
      text: "아파트",
      validText: "아파트/주상복합",
      value: "KIND_1",
      productKind: "KIND_1",
      image: require("@/assets/images/Matching/KIND_1.jpg"),
      disabled: false,
    },
    {
      text: "빌라/다세대/도시형/연립주택",
      validText: "빌라/다세대주택/도시형/연립주택",
      value: "KIND_2",
      productKind: "KIND_2",
      image: require("@/assets/images/Matching/KIND_2.jpg"),
      disabled: false,
    },
    {
      // 7/4일 토지 -> 토지/단독/다가구/타운하우스
      text: "단독/다가구/타운하우스",
      validText: "단독주택/다가구주택/타운하우스",
      value: "KIND_3",
      productKind: "KIND_3",
      image: require("@/assets/images/Matching/KIND_2.jpg"),
      disabled: false,
    },
    {
      // 7/4일 오피스텔/도시형 -> 오피스텔
      text: "오피스텔",
      validText: "오피스텔",
      value: "KIND_4",
      productKind: "KIND_4",
      image: require("@/assets/images/Matching/KIND_3.jpg"),
      disabled: false,
    },
    {
      text: "토지",
      validText: "토지",
      value: "KIND_5",
      productKind: "KIND_5",
      image: require("@/assets/images/Matching/KIND_2.jpg"),
      disabled: false,
    },
  ],
  kind2: [
    {
      // 7/4일 담보 -> 담보대출
      text: "담보대출",
      value: "KIND2_1",
      // 아파트, 빌라, 토지, 오피스텔
      key: ["KIND_1", "KIND_2", "KIND_3", "KIND_4", "KIND_5"],
      image: require("@/assets/images/Matching/KIND_1.jpg"),
      disabled: false,
    },
    {
      text: "매매자금",
      value: "KIND2_2",
      // 아파트, 빌라, 토지, 오피스텔
      key: ["KIND_1", "KIND_2", "KIND_3", "KIND_4", "KIND_5"],
      image: require("@/assets/images/Matching/KIND_2.jpg"),
      disabled: false,
    },
    {
      text: "퇴거자금",
      value: "KIND2_3",
      // 아파트, 빌라, 토지, 오피스텔
      key: ["KIND_1", "KIND_2", "KIND_3", "KIND_4", "KIND_5"],
      image: require("@/assets/images/Matching/KIND_3.jpg"),
      disabled: false,
    },
    {
      text: "무설정론",
      value: "KIND2_4",
      // 아파트, 빌라 0928 오피스텔 추가
      key: ["KIND_1", "KIND_2","KIND_4"],
      image: require("@/assets/images/Matching/KIND_4.jpg"),
      disabled: false,
    },
    {
      text: "지분",
      value: "KIND2_5",
      // 아파트, 빌라, 토지, 오피스텔
      key: ["KIND_1", "KIND_2", "KIND_3", "KIND_4", "KIND_5"],
      image: require("@/assets/images/Matching/KIND_4.jpg"),
      disabled: false,
    },
  ],
  // kind: [
  //   {
  //     text: "아파트",
  //     value: "KIND_1",
  //     productKind: "KIND_1",
  //     image: require("@/assets/images/Matching/KIND_1.jpg"),
  //   },
  //   {
  //     text: "빌라",
  //     value: "KIND_2",
  //     productKind: "KIND_2",
  //     image: require("@/assets/images/Matching/KIND_2.jpg"),
  //   },
  //   {
  //     text: "오피스텔/도시형생활주택",
  //     value: "KIND_3",
  //     productKind: "KIND_3",
  //     image: require("@/assets/images/Matching/KIND_3.jpg"),
  //   },
  //   {
  //     text: "단독/다가구주택/타운하우스",
  //     value: "KIND_4",
  //     productKind: "KIND_4",
  //     image: require("@/assets/images/Matching/KIND_4.jpg"),
  //   },
  //   {
  //     text: "부동산지분대출",
  //     value: "KIND_5",
  //     productKind: "KIND_5",
  //     image: require("@/assets/images/Matching/KIND_5.jpg"),
  //   },
  //   {
  //     text: "상가건물/상가주택/집합상가",
  //     value: "KIND_6",
  //     productKind: "KIND_6",
  //     image: require("@/assets/images/Matching/KIND_6.jpg"),
  //   },
  //   {
  //     text: "토지/건물(준공)대환대출",
  //     value: "KIND_7",
  //     productKind: "KIND_7",
  //     image: require("@/assets/images/Matching/KIND_7.jpg"),
  //   },
  //   {
  //     text: "PF건축자금대출/미준공건축자금대출",
  //     value: "KIND_8",
  //     productKind: "KIND_8",
  //     image: require("@/assets/images/Matching/KIND_8.jpg"),
  //   },
  //   {
  //     text: "무설정아파트론",
  //     value: "KIND_9",
  //     productKind: "KIND_9",
  //     image: require("@/assets/images/Matching/KIND_9.jpg"),
  //   },
  //   {
  //     text: "전세자금대출",
  //     value: "KIND_10",
  //     productKind: "KIND_10",
  //     image: require("@/assets/images/Matching/KIND_10.jpg"),
  //   },
  //   {
  //     text: "임대아파트대출/보증금담보대출",
  //     value: "KIND_11",
  //     productKind: "KIND_11",
  //     image: require("@/assets/images/Matching/KIND_11.jpg"),
  //   },
  //   {
  //     text: "기타/공장 등 특수물건",
  //     value: "KIND_12",
  //     productKind: "KIND_12",
  //     image: require("@/assets/images/Matching/KIND_12.jpg"),
  //   },
  // ],
  // 지역
  location: [
    // {
    //   text: "전국",
    //   value: "all",
    // },
    {
      text: "서울",
      value: "seoul",
    },
    {
      text: "수도권",
      value: "capital",
    },
    {
      text: "광역시",
      value: "metropolitan",
    },
    {
      text: "그 외 지역",
      value: "etc",
    },
    // {
    //   text: "인천",
    //   value: "incheon",
    // },
    // {
    //   text: "경기",
    //   value: "kyungki",
    // },
    // {
    //   text: "부산",
    //   value: "busan",
    // },
    // {
    //   text: "대구",
    //   value: "daegu",
    // },
    // {
    //   text: "대전",
    //   value: "daejeon",
    // },
    // {
    //   text: "광주",
    //   value: "gwangju",
    // },
    // {
    //   text: "울산",
    //   value: "ulsan",
    // },
    // {
    //   text: "강원",
    //   value: "kangwon",
    // },
    // {
    //   text: "충청",
    //   value: "chungcheong",
    // },
    // {
    //   text: "경상",
    //   value: "kyungsang",
    // },
    // {
    //   text: "전라",
    //   value: "jeolla",
    // },
    // {
    //   text: "제주",
    //   value: "jeju",
    // },
  ],
  progress: {},
};
