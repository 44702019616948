<template>
  <div id="app" v-if="loaded">
    <!--<upside-bar v-if="!this.$cookies.isKey('oneDay')" />-->
    <div>
      <div class="d-flex" style="width: 100%; height: 100vh; padding-bottom: 50px;">
        <!--
        <div id="gnb" style="width: 350px">
          <global-nav />
        </div>
      -->
        <div style="width: inherit;">
          <router-view />
        </div>
      </div>
      <!--
      <div id="footer" style="position:absolute; bottom:0px;">
        <global-footer />
      </div>
    -->
    </div>
    <!--
    <router-view />
    -->
    <!-- modals -->
    <modal-account-service />
  </div>
</template>

<script>
import Nav from "@/components/Nav/index.js";
import UpsideBar from "@/components/UpsideBar";
import Modals from "@/components/Modals/index";

export default {
  components: {
    UpsideBar,
    GlobalNav: Nav.GlobalNav,
    GlobalFooter: Nav.GlobalFooter,
    ModalAccountService: Modals.AccountService,
  },
  metaInfo: {
    title: "ocpp-testpage",
  },
  watch: {
    loaded(n) {
      /*
      if (n) {
        // loaded 참 되는 경우 트리거
        // Unread Message Check
        var userEventHandler = new this.sb.UserEventHandler();
        userEventHandler.onTotalUnreadMessageCountUpdated = function (
          totalCount,
          countByCustomTypes
        ) {
          console.log("userEventHandler");
          console.log(totalCount, countByCustomTypes);
        };
        this.sb.addUserEventHandler(this.user.serviceId, userEventHandler);
      }
        */
    },
  },
  data() {
    return {};
  },
  created() {
    this.setAlert();
    this.setConfirm();
    // this.init();
  },
  methods: {
    setAlert() {
      window.alert = async (msg = "", options) => {
        const opt = {
          okTitle: "확인",
          okVariant: "primary w-100 h-100 m-0 p-3 border-top border-1",
          headerClass: `px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative`,
          headerCloseContent: " <i class='icon icon-cancel text-18'></i>",
          headerCloseVariant: "text text-gray position-absolute modal-close",
          hideHeaderClose: false,
          titleClass: "mx-auto text-center text-16 text-lg-17",
          headerTextVariant: "primary text-lg-16",
          contentClass: "confirm border-0 fw-400",
          footerClass:
            "p-1 border-0 font-weight-bold text-center d-flex justify-content-center btn-group text-lg-14",
          centered: true,
          noCloseOnBackdrop: true,
          noStacking: true,
          id: "ALERT",
          size: "sm",
          // autoFocusButton: "ok",
          ...options,
        };
        const msgVNode = this.$createElement("div", {
          class: "mt-2",
          domProps: {
            innerHTML: `

            <section class="text-center text-17 text-lg-15">${msg}</section>
          `,
          },
        });
        return await this.$bvModal.msgBoxOk([msgVNode], opt);
      };
    },
    setConfirm() {
      window.confirm = async (msg = "", options) => {
        const opt = {
          cancelTitle: "취소",
          okVariant: "primary m-0 ml-1 py-3 w-50 btn-block",
          okTitle: "확인",
          cancelVariant: "outline-primary m-0 py-3 w-50 btn-block",
          headerClass: `px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative`,
          headerCloseContent: " <i class='icon icon-cancel text-18'></i>",
          headerCloseVariant: "text text-gray position-absolute modal-close",
          hideHeaderClose: false,
          titleClass: "mx-auto text-center text-16 text-lg-17",
          headerTextVariant: "primary text-lg-16",
          contentClass: "confirm border-0 position-relative",
          footerClass:
            "w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap",
          centered: true,
          noCloseOnBackdrop: true,
          size: "sm",
          noStacking: true,
          id: "CONFIRM",
          autoFocusButton: "ok",
          ...options,
        };
        const msgVNode = this.$createElement("div", {
          class: "mt-2",
          domProps: {
            innerHTML: `
            <h6 class="px-4 text-lg-14 lh-160 fw-400">${msg}</h6>
          `,
          },
        });
        const bool = await this.$bvModal.msgBoxConfirm([msgVNode], opt);
        if (bool !== null) {
          return bool;
        }
      };
    },
  },
};
</script>

<style lang="scss"></style>
