<template>
  <div class="mt-3 mb-5 py-5">
    <b-container>
      <header class="mb-3">
        <h2 class="mb-2 text-28 text-lg-38">문의</h2>
        <span class="text-14 text-gray fw-400"
          >일반 문의, 제휴 무의 등 자유롭게 문의하세요.</span
        >
      </header>
      <b-form @submit.prevent="submit" class="mt-5">
        <div
          :style="{
            borderTop: '3px solid #333',
            borderBottom: '3px solid #333',
          }"
        >
          <b-row align-v="center" class="mx-0 border-bottom border-gray">
            <b-col cols="12" md="2" class="px-0 pl-md-0 pr-md-4">
              <div
                class="py-2 py-md-4 text-center text-gray"
                :style="{ backgroundColor: '#fbf8f4' }"
              >
                문의 제목
              </div>
            </b-col>
            <b-col cols="12" md="10" class="px-1">
              <div class="py-3">
                <b-form-input
                  v-model="input.title"
                  class="rounded-lg input-inquiry"
                  placeholder="문의 제목을 입력해주세요"
                ></b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row align-v="center" class="mx-0 border-bottom border-gray">
            <b-col cols="12" md="2" class="px-0 pl-md-0 pr-md-4">
              <div
                class="py-2 py-md-4 text-center text-gray"
                :style="{ backgroundColor: '#fbf8f4' }"
              >
                이메일
              </div>
            </b-col>
            <b-col cols="12" md="10" class="px-1">
              <div class="pt-3 pb-4 position-relative">
                <b-form-input
                  type="email"
                  v-model="input.senderEmail"
                  :state="
                    input.senderEmail && input.senderEmail.length > 5
                      ? rules.serviceId
                      : null
                  "
                  class="rounded-lg input-inquiry"
                  placeholder="답변 받으실 이메일을 입력해주세요"
                  required
                ></b-form-input>
                <small
                  v-if="
                    !rules.serviceId &&
                      input.senderEmail &&
                      input.senderEmail.length > 5
                  "
                  class="position-absolute text-danger"
                  >이메일 양식을 확인해주세요.</small
                >
              </div>
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col cols="12" md="2" align-self="stretch">
              <div
                class="py-2 py-md-4 text-center text-gray h-100 d-flex flex-column"
                :style="{ backgroundColor: '#fbf8f4' }"
              >
                <span class="my-auto">
                  문의 내용
                </span>
              </div>
            </b-col>
            <b-col cols="12" md="10">
              <div class="my-3">
                <b-textarea
                  v-model="input.content"
                  rows="10"
                  class="rounded-lg input-inquiry"
                  placeholder="문의 내용을 입력해주세요"
                >
                </b-textarea>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row class="mt-4 mx-md-5" align-h="center">
          <b-col cols="12" md="5" class="text-center">
            {{ rules.serviceId }}
            <b-btn
              variant="primary"
              class="w-100 mx-auto py-4"
              @click="submit"
              :style="{ maxWidth: '440px' }"
              >등록</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import regex from "@/lib/regex";

export default {
  data() {
    return {
      input: {
        title: null,
        senderEmail: null,
        content: null,
      },
    };
  },
  computed: {
    rules() {
      const { senderEmail } = this.input;
      const serviceId =
        senderEmail !== null &&
        senderEmail.length > 5 &&
        senderEmail !== "" &&
        regex.serviceId.test(senderEmail);
      return {
        serviceId,
      };
    },
  },
  methods: {
    async submit() {
      if (
        !this.input.title ||
        this.input.title === "" ||
        !this.input.content ||
        this.input.content === "" ||
        !this.input.senderEmail ||
        this.input.senderEmail === ""
      ) {
        await window.alert("모든 내용을 필수적으로 입력해야 합니다.", {
          title: "필수값 입력",
        });
      } else if (!this.rules.serviceId) {
        await window.alert("이메일 양식을 확인하세요.", {
          title: "이메일 양식 불일치",
        });
      } else {
        try {
          const { data } = await this.$axios.post("/partnership", this.input);

          if (data.status.code === "0000") {
            await window.alert(`문의가 정상적으로 등록되었습니다.`, {
              title: "제휴 문의",
            });
            this.$router.push("/");
          }
        } catch (error) {
          await window.alert(`에러로 인해 문의 내용을 전송하지 못했습니다.`, {
            title: "문의 실패",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
