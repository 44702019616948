<template>
  <b-row>
    {{ registerFile }}
    <b-col>
      <b-form-group class="position-relative m-0" variant="outline">
        <input
          type="text"
          :style="{ paddingRight: '2rem', textOverflow: 'ellipsis' }"
          class="form-control"
          disabled
          placeholder="이 곳에 파일을 드래그드랍 하세요"
          v-model.lazy="filename"
          @dragover.prevent
          @dragenter.prevent
          @drop.prevent="onDrop"
        />
        <template v-if="filename">
          <b-btn
            variant="text"
            @click="fileDelete()"
            class="position-absolute p-0 text-gray"
            :style="{
              top: '50%',
              transform: 'translateY(-50%)',
              right: '1rem',
            }"
          >
            <i class="icon icon-times text-12"></i>
          </b-btn>
        </template>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group>
        <label
          class="btn btn-outline-primary w-100 fw-700"
          :style="{ height: '50px' }"
        >
          {{ labelText }}
          <b-form-file
            class="d-none"
            :accept="accept"
            v-model.lazy="datafile"
            @change="fileChanged"
            :state="state"
          ></b-form-file>
        </label>
        <b-form-invalid-feedback :state="state">
          <span class="text-error" v-if="isInvalid">
            {{ feedbackMsg }}
          </span>
        </b-form-invalid-feedback>
        <!-- <div
        class="
          my-2
          py-1
          px-2
          attached-file
          text-darkest text-left
          bg-light
          border border-light-gray
          d-flex
          align-items-center
          justify-content-between
        "
        v-if="file && !meta.hideFileDesc"
      >
        <i class="icon icon-attach text-24 mr-1"></i>
        <template v-if="file.name">
          <span class="text-13 text-gray mr-auto">{{ file.name }}</span>
        </template>
        <b-btn variant="text p-0 text-gray" @click="fileDelete()" class="px-2">
          <i class="icon icon-times text-12"></i>
        </b-btn> -->
        <!-- <template v-if="file.originalFilename">
          <span class="text-13 text-gray mr-auto">{{
            file.originalFilename
          }}</span>
        </template> -->
        <!-- <template v-else>
          <span class="text-13 text-gray mr-auto">업로드 중입니다...</span>
        </template> -->
        <!-- </div> -->
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    registerFile: {
      type: Object,
    },
    labelText: {
      type: String,
    },
    accept: {
      type: String,
    },
    state: {
      type: Boolean,
    },
    feedbackMsg: {
      type: String,
    },
    isInvalid: {
      type: Boolean,
    },
    holder: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  data() {
    return {
      datafile: null,
      file: null,
      isPdf: null,
      filename: null,
    };
  },
  watch: {},
  methods: {
    onDrop(event) {
      this.inputImageFile(event.dataTransfer.files);
    },
    async inputImageFile(files) {
      const file = files[0];
      const pdfType = new RegExp("\.pdf$", "i");

      console.log(file.name);

      if (!pdfType.test(file.name)) {
        alert("PDF 파일만 등록이 가능합니다");
        return false;
      }

      //선택한 파일의 값이 있을 경우
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        // this.filename = file.name;

        if (!this.feedbackMsg) {

          //등기부 등본 체크
          const { data } = await this.$axios.post(
            "/files/pdf-check/"+this.type,
            formData
            // config
          );

          if (data.status.code === "0000") {
            console.log(data);
            this.file = file;
            this.filename = file.name;
            this.isPdf = true;
            this.$emit("input", file);
            this.$emit("owners", data.data);
          } else {
            this.file = null;
            this.filename = null;
            this.isPdf = false;
            this.$emit("input", null);
            this.$emit("owners", null);
            await alert(data.status.message);
          }
        } else {
          //사업자 등록증, 대부 등록증 업로드
          const data = await this.attachFile(file);
          this.file = file;
          this.filename = file.name;
          this.$emit("input", data);
        }
      }
    },
    async fileChanged(e) {
      const file = e.target.files[0];

      //선택한 파일의 값이 있을 경우
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        if (!this.feedbackMsg) {
          //등기부 등본 체크
          const { data } = await this.$axios.post(
            "/files/pdf-check/"+this.type,
            formData
            // config
          );

          if (data.status.code === "0000") {

            data.data.type = this.type;

            this.file = file;
            this.filename = file.name;
            this.isPdf = true;

            this.$emit("input", file);
            this.$emit("owners", data.data);

          } else {

            this.file = null;
            this.filename = null;
            this.isPdf = false;

            this.$emit("input", null);
            this.$emit("owners", null);

            await alert(data.status.message);
          }
        } else {
          //사업자 등록증, 대부 등록증 업로드
          const data = await this.attachFile(file);
          this.file = file;
          this.filename = file.name;
          this.$emit("input", data);
        }
      }
    },
    fileDelete() {
      this.datafile = null;
      this.filename = null;
      this.file = null;
      this.isPdf = null;
      this.$emit("fileDelete");
      this.$emit("owners", null);
      this.$emit("input", null);
    },
  },
};
</script>

<style lang="scss" scoped></style>
