<template>
  <b-modal
    id="modal-result-amount"
    title="송금 금액 입력"
    ok-title="확인"
    @ok="ok"
    cancelTitle="닫기"
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="primary m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="sm"
  >
    <div class="text-center">
      <span>최종 대출 금액을 입력해주세요</span>
      <div v-if="bidder">
        {{`수당 : ${bidder.benefit} %`}}
      </div>
      
      <b-input-group class="my-1" variant="outline" hide-prepend>
        <span>실제 대출 금액</span>
        <w-input
          class="w-100 m-4"
          type="text"
          v-model="input.loanAmount"
          @input.native="$test($event, 'loanAmount')"
          placeholder="실제 대출 금액(만원)"
        ></w-input>
        <span>송금 금액(실제 대출 금액에 대한 수당)</span>
        <b-form-input
          class="w-100 m-4"
          type="text"
          v-model="input.resultAmount"
          @input.native="$test($event, 'resultAmount')"
          placeholder="송금 금액(원)"
          disabled
        ></b-form-input>
      </b-input-group>
    </div>
  </b-modal>
</template>

<script>
export default {
  computed: {
    idBidding() {
      return this.$route.params.matching_id;
    },
  },
  data() {
    return {
      input: { resultAmount: null, loanAmount: null },
    };
  },
  props: {
    bidder:{
      type: Object,
    },
    value: {
      type: String,
    },
  },
  methods: {
    $test(e, key) {
      if (e && e.target) {
        if(key === "loanAmount"){

          const value = e.target.value
            .replaceAll(",","");

            
          this.input.resultAmount = `${String(Number(value) * (this.bidder.benefit / 100))
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`

          let i = 0;
          let zero = '00';
          let count = value.split('0').length - 1;

          while(true){
            if(count === i){
              this.input.resultAmount = `${this.input.resultAmount.replaceAll('0','')+zero}`
              this.input.resultAmount = `${this.input.resultAmount
                .replace(/\D/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원`
                
              break;
            }
            i++;
            zero += 0;
          };
        }

        this.input[key] = `${e.target.value
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }
    },
    async ok() {
      const { resultAmount, loanAmount } = this.input;
      const bool = await window.confirm(
        `송금 금액이 맞는지 최종적으로 확인해주세요.<br>
        <strong stlye="text-primary">${resultAmount}</strong>
        `,
        {
          title: "송금 금액 입력",
          bodyClass: "text-center",
          okTitle: "확인",
          cancelTitle: "취소",
        }
      );
      if (bool) {
        try {
          const { data } = await this.$axios.post(
            `/bidding/${this.idBidding}/result-amount`,
            {
              loanAmount: this.priceToNumber(loanAmount)
                              .replace('만원',''),
              resultAmount: this.priceToNumber(resultAmount)
                                .replace('원','')
            }
          );
          if (data.status.code === "0000") {
            const bool = await window.alert(`정상처리되었습니다.`, {
              title: "송금 금액 입력 완료",
              bodyClass: "text-center",
              okTitle: "확인",
            });
            if (bool) {
              window.location.reload();
            }
          } else {
            window.alert(data.status.message);
          }
        } catch (error) {
          window.alert(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
