<template>
  <b-container ref="temp">
    <b-form @submit.prevent.stop="login">
      <b-form-group>
        <b-form-input
          v-model="input.serviceId"
          type="email"
          placeholder="아이디"
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-form-input
          v-model="input.password"
          type="password"
          placeholder="비밀번호"
        ></b-form-input>
      </b-form-group>
      <div class="d-flex align-items-center">
        <b-form-group class="ml-auto text-gray">
          <b-form-checkbox v-model="input.autoLogin" :value="true">
            자동로그인
          </b-form-checkbox>
        </b-form-group>
      </div>

      <div class="mt-3">
        <b-btn variant="primary" class="w-100" type="submit" size="lg">
          <span class="text-18">로그인</span>
        </b-btn>
      </div>
      <section class="text-right">
        <b-btn
          variant="link text-light-gray text-underline"
          to="/account/find-account"
        >
          <span class="text-14">아이디, 비밀번호를 잊으셨나요?</span>
        </b-btn>
      </section>
    </b-form>
    <article class="my-5 pb-5">
      <header class="text-center pb-3">
        <h6 class="text-lg-15">SNS 로그인</h6>
      </header>
      <section
        class="
          mt-2
          d-flex
          flex-column
          align-items-center
          justify-content-between
        "
      >
        <b-btn class="w-100 my-2 text-nowrap btn-sns btn-kakao" @click="kakao"
          >카카오 로그인</b-btn
        >
        <b-btn
          id="naverIdLogin"
          variant="naver"
          class="w-100 my-2 btn-sns btn-naver"
        >
          네이버 로그인</b-btn
        >

        <b-btn
          variant="white"
          id="btn-google"
          class="g-signin2 btn-sns w-100 my-2"
          @click="google"
        >
          <span class="">구글 로그인</span>
        </b-btn>
      </section>

      <section class="mt-5">
        <b-btn
          variant="outline-primary"
          class="py-3 w-100"
          to="/account/sign-up"
          size="lg"
        >
          <span class="text-18">회원가입</span>
        </b-btn>
      </section>
    </article>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      input: {
        loginType: "EMAIL",
        serviceId: "",
        password: "",
        autoLogin: false,
      },
      naverUrl: null,
    };
  },
  methods: {
    async login() {
      if (!this.input.serviceId.length) {
        if (!this.input.password.length) {
          window.alert("필수값들을 모두 입력해주세요.", {
            title: "필수 값 미입력",
          });
        }
      } else {
        try {
          const { data } = await this.$axios.post("/auth/token", this.input);
          if (data.status.code === "1003") {
            window.alert("입력하신 아이디 혹은 비밀번호가 일치하지 않습니다.", {
              title: "계정 오류",
            });
          } else if (data.status.code === "4041") {
            window.alert("가입되지 않은 계정입니다.", {
              title: "가입되지 않은 계정",
            });
          } else if (data.status.code === "0000") {
            const loginData = {
              token: `${data.data.token_type} ${data.data.access_token}`,
              autoLogin: this.input.autoLogin,
            };
            // const token = `${data.data.token_type} ${data.data.access_token}`;
            this.getMe(loginData);

            this.$router.push("/");
          }
        } catch (error) {
          window.alert(error);
        }
      }
    },
    async snsLogin(id, type) {
      try {
        const serviceId = `${type}_${id}`;
        
        //네이버 로그인 아이디
        alert(serviceId)
        
        const body = {
          serviceId,
          password: serviceId,
          loginType: type,
        };
        console.log(body);
        // 이메일 중복체크
        const { data: isSigned } = await this.$axios.post("/auth/token", body);
        if (isSigned.status.code === "40411") {
          // 중복이 아니면? 회원가입
          this.$router.push({
            name: "SignUpForm",
            params: { serviceId, loginType: type },
          });
        } else if (isSigned.status.code === "0000") {
          const loginData = {
            token: `${isSigned.data.token_type} ${isSigned.data.access_token}`,
            autoLogin: false,
          };
          this.getMe(loginData);
          this.$router.push("/");
        }
      } catch (error) {
        console.error(error);
      }
    },
    kakao() {
      Kakao.Auth.login({
        scope: "account_email",
        success: async (obj) => {
          const me = await Kakao.API.request({
            url: "/v2/user/me",
          });
          // console.log("@@kakao me :", me);
          const { id } = me;
          this.snsLogin(id, "KAKAO");
        },
        fail: (err) => {
          window.alert("에러로 인해 로그인에 실패했습니다.");
        },
      });
    },
    google() {
      this.$gAuth
        .signIn()
        .then((GoogleUser) => {
          //console.log('GoogleUser',GoogleUser)
          //console.log('GoogleUser id', GoogleUser.Av.mw)
          //on success do something
          // console.log("GoogleUser", GoogleUser);
          //const id = GoogleUser.it.Tt;

          const id = GoogleUser.Av.mw;
          if (id === undefined) {
            window.alert("계정을 다시 확인해주세요.");
          }
          this.snsLogin(id, "GOOGLE");
        })
        .catch((error) => {
          console.log(error);
          window.alert("에러로 인해 로그인에 실패했습니다.");
        });
    },
    async initNaver() {
      console.log(window.location.origin);

      // var naverLogin = new window.naver.LoginWithNaverId({
      //   clientId: "5gCMcDWRMuH2EsvuO97O",
      //   callbackUrl: `${window.location.origin}/account/naver/`,

      //   // isPopup: false, /* 팝업을 통한 연동처리 여부 */
      // });
      // /* 설정정보를 초기화하고 연동을 준비 */
      // naverLogin.init();

      // naverLogin.getLoginStatus(async (status) => {
      //   // console.log("status", status);
      //   const url = naverLogin.generateAuthorizeUrl();
      //   const link = document.createElement("a");
      //   link.href = url;
      //   this.$refs.temp.append(link);
      //   link.click();
      //   this.$refs.temp.removeChild(link);

      //   let user = naverLogin.user;
      //   if (status) {
      //     let id = user.email;
      //     // let { accessToken } = naverLogin;
      //     this.snsLogin(id, "NAVER");
      //   } else {
      //     // console.log("callback 처리에 실패하였습니다.");
      //   }
      // });
    },
  },
  mounted() {
    var naverLogin = new window.naver.LoginWithNaverId({
      clientId: "62LknMd92KuRBoC3s95T",
      callbackUrl: `${window.location.origin}/account/naver/`,
      loginButton: {
        color: "green",
        type: 3,
        height: 60,
      } /* 로그인 버튼의 타입을 지정 */,
      // isPopup: false, /* 팝업을 통한 연동처리 여부 */
    });
    /* 설정정보를 초기화하고 연동을 준비 */
    naverLogin.init();
  },
};
</script>

<style lang="scss" scoped></style>
