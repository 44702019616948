<template>
  <!-- <div v-if="!meta.hideUpsidebar">
    <aside class="w-100" v-if="toggle && banner && banner.active">
      <header class="position-absolute m-2" :style="{ top: '0', right: '0' }">
        <b-btn variant="dark" pill class="px-2" @click="toggle = false">
          닫기 &times;
        </b-btn>
      </header>
      <section
        class="bg-img ratio-10 d-none d-md-block cursor-pointer"
        @click="openUrl(banner.link)"
        :style="{ backgroundImage: `url(${s3}${banner.url})` }"
      />
      <section
        class="bg-img ratio-sm-40 d-block d-md-none cursor-pointer"
        @click="openUrl(banner.link)"
        :style="{ backgroundImage: `url(${s3}${banner.mobileUrl})` }"
      />
    </aside>
  </div> -->
  <div id="upsideBar" v-if="!meta.hideUpsidebar">
    <aside
      class="w-100"
      :style="{ background: '#1A49B9' }"
      v-if="toggle && banner && banner.active"
    >
      <b-container
        class="container-xl d-flex container-fluid justify-content-between"
      >
        <section
          :style="{ height: '60px' }"
          @click="openUrl(banner.link)"
          class="cursor-pointer text-white d-inline-flex align-items-center"
        >
          <strong class="text-18">공지사항</strong>
          <span class="line-bar bg-white mx-4"></span>
          <p class="text-17 fw-400 m-0">[긴급] 서비스 이용 점검 안내</p>
        </section>
        <section class="d-flex align-items-center">
          <div class="mx-4 mb-1">
            <input
              type="checkbox"
              id="upsideChk"
              class="d-none upsideChk"
              v-model="oneDay"
            />
            <label class="m-0 text-white text-12 cursor-pointer" for="upsideChk"
              >오늘 하루 열지 않기</label
            >
          </div>
          <b-btn variant="link text-decoration-none" @click="close()">
            <span class="text-white text-20">&times;</span>
          </b-btn>
        </section>
      </b-container>
    </aside>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggle: true,
      active: null,
      banner: null,
      oneDay: false,
    };
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  methods: {
    async getBanner() {
      try {
        const { data } = await this.$axios.get(`/banner`);
        if (data.status.code === "0000") {
          this.banner = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    close() {
      this.toggle = false;
      if (this.oneDay === true) {
        this.$cookies.set("oneDay", "true", "1d");
      }
    },
  },
  async mounted() {
    await this.getBanner();
  },
};
</script>

<style lang="scss" scoped>
.upsideChk {
  + label {
    position: relative;
    padding-left: 22px;
    &::after {
      content: "";
      width: 15px;
      height: 15px;
      background: #fff;
      border: 4px solid #fff;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
    }
  }
  &:checked {
    + label {
      &::after {
        background: #1a49b9;
      }
    }
  }
}
</style>
