<template>
  <b-container class="text-center">
    <article
      class="my-5 py-5 border-top border-bottom border-light-gray bg-light"
    >
      <h3 class="text-24 text-lg-30 text-primary fw-400 text-nowrap">
        홈뱅크 가입을 축하드립니다!
      </h3>
      <p
        class="
          text-14
          d-flex
          align-items-center
          justify-content-center
          w-100
          mt-3
          text-dark
          fw-400
        "
        v-if="loginType"
      >
        <b-avatar
          size="20"
          :src="loginType.src"
          variant="transparent mr-2"
        ></b-avatar>
        {{ loginType.name }}로 가입했습니다.
      </p>
      <small class="text-primary fw-400"
        >가입일 : {{ getDate(new Date()) }}
      </small>
      <template v-if="params">
        <!--  && params.role !== 'USER' -->
        <p class="mt-4 px-3 text-14 mb-0">
          <!-- 중개사/상담사/금융사 계정은  -->
          심사가 완료된 후 이용 가능합니다.
        </p>
      </template>
    </article>
    <div class="pt-3 pb-5">
      <b-btn variant="primary" class="w-100 py-3" to="/account/login"
        >로그인 하러 가기</b-btn
      >
    </div>
    <!-- {{ params }} -->
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      res: null,
    };
  },
  computed: {
    params() {
      return this.$route.params;
    },
    loginType() {
      const types = {
        KAKAO: { name: "카카오", src: require("@/assets/icons/kakaotalk.png") },
        GOOGLE: { name: "구글", src: require("@/assets/icons/google.png") },
        NAVER: { name: "네이버", src: require("@/assets/icons/naver.svg") },
      };
      if (this.params && this.params.loginType) {
        return types[this.params.loginType];
      } else return null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
