<template>
  <div>
    <notification :userType="userType" />
  </div>
</template>

<script>
import Notification from "@/components/Items/Notification";
export default {
  props: {
    userType: {
      type: String,
    },
  },
  components: {
    Notification,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
