<template>
  <b-modal
    :visible="!!idBidder"
    title="입찰 취소"
    ok-title="입찰 취소"
    @ok="ok()"
    cancelTitle="취소"
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="primary m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="sm"
    @hidden="$emit('hidden')"
  >
    <div class="text-center">
      <!-- idBidding : {{ idBidding }} idBidder : {{ idBidder }} -->
      <span>정말 입찰을 취소하시겠습니까?</span>
      <b-textarea
        class="mt-3 rounded boorder border-light-gray"
        placeholder="입찰 취소 사유를 입력해주세요.  입력이 없다면 '단순 변심에 의한 입찰 취소입니다.' 메시지가 자동 저장됩니다."
        rows="4"
        :value="reason"
        @input="$emit('input', $event)"
      ></b-textarea>
    </div>
  </b-modal>
</template>

<script>
export default {
  computed: {
    idBidding() {
      return this.$route.params.matching_id;
    },
  },
  props: {
    value: {
      type: String,
    },
    idBidder: {
      type: Number,
    },
    id: {
      type: Number,
    },
    bidder: {
      type: Object,
    }
  },
  data() {
    return {
      reason: null,
    };
  },
  watch: {
    value(n) {
      this.reason = n;
    },
  },
  methods: {
    async ok() {
      try {
        const { data } = await this.$axios.post(
          `/bidding/${this.idBidding}/bidder/${this.idBidder}/cancel-bidder`,
          {
            reason: this.reason
              ? this.reason
              : "단순 변심에 의한 입찰 취소입니다.",
          }
        );
        if (data.status.code === "0000") {
            this.$emit('aa', data.data)
            this.bidder = data.data
          const bool = await window.alert("정상적으로 입찰이 취소되었습니다.", {
            title: "입찰 취소",
          });
          if (bool) {
            window.location.reload();
          }
        } else {
          window.alert(data.status.message);
        }
      } catch (error) {}
    },
  },
};
</script>

<style lang="scss" scoped></style>
