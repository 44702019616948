<template>
  <!-- 건축물대장 -->
  <article>
    <div v-if="itemMeta && metaData">
      <div class="px-5">
        <!-- itemMeta: {{ itemMeta.buildingMeta }} // metaData: {{ metaData }} -->
      </div>
      <header
        class="px-4 px-md-5 d-flex align-items-center justify-content-between"
      >
        <span class="text-14 text-lg-16 text-primary ">
          건물정보
        </span>
        <span class="text-dark-gray text-13 text-lg-14 fw-300">
          열람일자 : {{ metaData.savedDate }}
        </span>
      </header>
      <section class="my-3 px-4 px-md-5">
        <div v-for="(item, i) in metaData.content" :key="i">
          <b-btn
            variant="outline-light-gray bg-transparent w-100 py-3 rounded-0 text-darkest d-flex align-items-center"
            v-b-toggle="`collapse-building-${i}`"
            @click="toggle(i)"
          >
            <span class="text-13 text-lg-15">{{ item.category }}</span>
            <i
              class="icon icon-16 icon-caret-collapse ml-auto"
              :class="{ active: current === i }"
            />
          </b-btn>
          <b-collapse
            :id="`collapse-building-${i}`"
            class="bg-gray-fa px-4"
            accordion="collapse-building"
          >
            <div class="py-3">
              <ul class="list-unstyled m-0 p-0">
                <li
                  class="py-2 d-flex align-items-center justify-content-between"
                  v-for="(item2, j) in item.list"
                  :key="j"
                  :class="
                    j === item.list.length - 1
                      ? ''
                      : 'mb-2 border-bottom border-dark-gray'
                  "
                >
                  <span
                    class="text-gray fw-300 text-13 text-lg-14 ml-0 mr-auto text-nowrap"
                  >
                    {{ listName(item2.key) }}
                  </span>
                  <span
                    class="text-darkest fw-600 text-13 text-lg-14 ml-auto mr-0 pl-3 text-right"
                  >
                    {{ item2.value || "-" }}
                  </span>
                </li>
              </ul>
            </div>
          </b-collapse>
        </div>
      </section>
    </div>
    <div v-else>
      <p class="m-0 py-3 text-center text-gray-light text-14 fw-300">
        건축물대장 정보를 불러올 수 없습니다.
      </p>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    itemMeta: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      current: null,
      test : null
    };
  },
  mounted() {
    // console.log("buildingMeta:", this.itemMeta.buildingMeta);
  },
  computed: {
    metaData() {
      const { buildingMeta } = this.itemMeta;
      if (buildingMeta) {
        const {
          bldNm,
          bcRat,
          mainPurpsCdNm,
          etcPurps,
          strctCdNm,
          roofCdNm,
          heit,
          grndFlrCnt,
          ugrndFlrCnt,
          platArea,
          archArea,
          totArea,
          vlRatEstmTotArea,
          hhldCnt,
          bylotCnt,
          pmsDay,
          stcnsDay,
          useAprDay,
          indrAutoUtcnt,
          oudrAutoUtcnt,
          indrMechUtcnt,
          oudrMechUtcnt,
          rideUseElvtCnt,
          emgenUseElvtCnt,
        } = buildingMeta.brTitleInfo || {};
          this.test = buildingMeta;
        const { mainAtchGbCdNm, mainPurpsCdNmArea, area, flrNoNm } =
          buildingMeta.brExposPubuseAreaInfo || {};

        const data = {
          savedDate: buildingMeta.buildingMetaSavedDate
            ? this.toLocaleDate(buildingMeta.buildingMetaSavedDate)
            : "-",
          content: [
            {
              category: "표제부",
              list: [
                { key: "bldNm", value: bldNm || "-" },
                { key: "mainPurpsCdNm", value: mainPurpsCdNm || "-" },
                { key: "etcPurps", value: etcPurps || "-" },
                { key: "strctCdNm", value: strctCdNm || "-" },
                { key: "roofCdNm", value: roofCdNm || "-" },
                {
                  key: "heit",
                  value: `${heit}${this.encdoeStr(8575)}` || "-",
                },
                { key: "floor", value: `${grndFlrCnt}/${ugrndFlrCnt}` || "-" },
                {
                  key: "platArea",
                  value:
                    `${platArea}${this.encdoeStr(13217)}(${this.pyUnit(
                      platArea
                    )})` || "-",
                },
                {
                  key: "archArea",
                  value:
                    `${archArea}${this.encdoeStr(13217)}(${this.pyUnit(
                      archArea
                    )})/${bcRat ? `${bcRat}` : "-"}` || "-",
                },
                {
                  key: "totArea",
                  value:
                    `${totArea}${this.encdoeStr(13217)}(${this.pyUnit(
                      totArea
                    )})` || "-",
                },
                {
                  key: "vlRatEstmTotArea",
                  value:
                    `${vlRatEstmTotArea}${this.encdoeStr(13217)}(${this.pyUnit(
                      vlRatEstmTotArea
                    )})` || "-",
                },
                { key: "hhldCnt", value: hhldCnt || "-" },
                { key: "bylotCnt", value: bylotCnt || "-" },
              ],
            },
            {
              category: "건축물현황/전유부",
              list: [
                { key: "mainAtchGbCdNm", value: mainAtchGbCdNm || "-" },
                { key: "mainPurpsCdNmArea", value: mainPurpsCdNmArea || "-" },
                {
                  key: "area",
                  value: area
                    ? `${area}${this.encdoeStr(13217)}(${this.pyUnit(area)})`
                    : "-",
                },
                { key: "flrNoNm", value: flrNoNm || "-" },
              ],
            },
            {
              category: "주차장",
              list: [
                {
                  key: "cntAuto",
                  value:
                    `옥내 ${indrAutoUtcnt}대 | 옥외 ${oudrAutoUtcnt}대` || "-",
                },
                {
                  key: "cntmech",
                  value:
                    `옥내 ${indrMechUtcnt}대 | 옥외 ${oudrMechUtcnt}대` || "-",
                },
              ],
            },
            {
              category: "승강기",
              list: [
                { key: "elvUse", value: rideUseElvtCnt || "-" },
                { key: "elvEmr", value: emgenUseElvtCnt || "-" },
              ],
            },
            {
              category: "일자 정보",
              list: [
                {
                  key: "pmsDay",
                  // value: new Date(Date(pmsDay)).toLocaleDateString() || "-",
                  value: this.getDateStr(pmsDay) || "-",
                },
                {
                  key: "stcnsDay",
                  // value: new Date(Date(stcnsDay)).toLocaleDateString() || "-",
                  value: this.getDateStr(stcnsDay) || "-",
                },
                {
                  key: "useAprDay",
                  // value: new Date(Date(useAprDay)).toLocaleDateString() || "-",
                  value: this.getDateStr(useAprDay) || "-",
                },
              ],
            },
          ],
        };
        return data;
      } else return null;
    },
  },
  methods: {
    toggle(idx) {
      if (this.current !== null && this.current === idx) {
        this.current = null;
      } else {
        this.current = idx;
      }
    },
    listName(key) {
      const names = {
        bldNm: "건물이름",
        mainPurpsCdNm: "주용도",
        etcPurps: "기타용도",
        strctCdNm: "주구조",
        roofCdNm: "지붕구조",
        heit: "높이",
        // 층
        floor: "지상/지하",
        platArea: "대지면적",
        archArea: "건축면적/건패율",
        totArea: "연면적",
        vlRatEstmTotArea: "용적률산정연면적",
        hhldCnt: "세대수",
        bylotCnt: "외필지수",
        // 전유부
        mainAtchGbCdNm: "건축물",
        mainPurpsCdNmArea: "용도",
        area: "면적",
        flrNoNm: "층",
        // 주차장
        cntAuto: "자주식",
        cntmech: "기계식",
        //  승강기
        elvUse: "승용",
        elvEmr: "비상용",
        // 일자 정보
        pmsDay: "허가일",
        stcnsDay: "착공일",
        useAprDay: "사용승인일",
      };
      return names[key];
    },
  },
};
</script>

<style lang="scss" scoped></style>
