<template>
  <div>
    <!-- <template v-if="user.role === 'USER'">
      <h3 class="text-center py-4">
        개인회원은 평점확인을 하실 수 없습니다.
      </h3>
    </template> -->
    <template>
      <header class="d-flex flex-column">
        <span class="mb-3 text-16 fw-800">전체 평점</span>
        <!-- <div class="d-flex align-items-end">
          <template v-if="average">
            <h3 class="text-30 text-lg-48 text-primary">
              {{ average.toFixed(1) }}
            </h3>
          </template>
          <template v-else>
            <h3 class="text-30 text-lg-48 text-primary">0.0</h3>
          </template>
          <span class="ml-2 text-18 text-lg-20 text-light-gray">/ 5.0</span>
        </div> -->
      </header>
      <article class="mt-4">
        <template v-if="items && items.length">
          <matching-items :items="items" />
        </template>
        <template v-else>
          <div class="py-4 bg-light text-center">
            평점을 받은 매칭 내역이 없습니다.
          </div>
        </template>
      </article>
    </template>
  </div>
</template>

<script>
import Items from "@/components/Items/index";

export default {
  components: {
    MatchingItems: Items.Matching,
  },
  props: {
    userType: {
      type: String,
    },
  },
  data() {
    return {
      average: null,
      items: [],
    };
  },
  methods: {
    async getAverage() {
      try {
        const { data } = await this.$axios.get(
          `/bidding-history/${this.userType}/average`
        );
        if (data.status.code === "0000") {
          this.average = data.data.average;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getItem() {
      try {
        const { data } = await this.$axios.get(
          `/bidding-history/${this.userType}/grade-list`
        );
        if (data.status.code === "0000") {
          console.log("data", data);
          const temp = [];
          const arr = data.data.content;

          if (this.userType === "bidder") {
            arr.forEach((el) => {
              const item = {
                grade: el.grade,
                ...el.relativeBidding,
              };
              temp.push(item);
            });
          } else {
            arr.forEach((el) => {
              const item = {
                grade: el.grade,
                ...el,
              };
              temp.push(item);
            });
          }
          this.items = temp;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    // userType(n, oldValue) {
    //   if (n === "client") {
    //     this.$router.push("/mypage/matching-history?type=all");
    //   }
    // },

    userType(){
      this.getAverage();
      this.getItem();
    }
  },
  async mounted() {
    // if (this.userType === "client") {
    //   const bool = window.alert("의뢰자 상태에선 평점확인이 불가능합니다.");
    //   if (bool) {
    //     this.$router.go(-1);
    //   }
    // }
    await this.getAverage();
    await this.getItem();
    console.log(this.userType);
  },
};
</script>

<style lang="scss" scoped></style>
