<template>
  <b-modal
    :visible="+$route.query.chat === item.id"
    id="modal-chatting"
    title="채팅"
    header-class="px-4 py-2 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative bg-primary text-white"
    header-close-content="&times;"
    header-close-variant="text text-white position-absolute modal-close mb-0 mt-n4 opacity-10"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    body-class="p-0 border-0"
    content-class="confirm border-0 fw-400 mobile-h100"
    footer-class="d-block border-0"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="md"
    @shown="showEvent"
    @hidden="resetChat"
  >
    <article v-if="channel">
      <header class="p-4 d-flex align-items-center justify-content-between">
        <!-- 0810 채팅상대 이미지 경로가 잘못되어  s3경로 추가 -->
        <b-avatar square size="lg" :src="`${s3 + target.profile}`" />
        <div class="ml-3 mr-auto d-flex flex-column">
          <small class="text-17 text-gray">
            {{ targetType === "BIDDER" ? "의뢰자" : "입찰자" }}
          </small>
          <span class="text-16 text-lg-18">
            <strong class="text-primary">{{
              targetType === "BIDDER"
                ? target.serviceId
                : target.companyMeta && target.companyMeta.companyName
            }}</strong>
            님
          </span>
          <small
            class="py-0 px-1 border border-light-gray text-light-gray text-13"
            v-if="targetType !== 'BIDDER'"
            >010-1234-1234</small
          >
        </div>
        <template v-if="targetType !== 'BIDDER'">
          <b-btn
            variant="outline-primary"
            class="ml-auto mr-0 px-2 px-md-3"
            v-if="!channel.isFrozen"
            @click="freezeToggle"
          >
            <i class="icon icon-ice"></i>
            채팅 얼리기
          </b-btn>
          <b-btn
            variant="outline-primary"
            class="ml-auto mr-0 px-2 px-md-3"
            v-else
            @click="freezeToggle"
          >
            <i class="icon icon-ice"></i>
            채팅 재개
          </b-btn>
        </template>
      </header>
      <section class="chatting-wrapper p-4" ref="chatwrap">
        <div
          class="chat-bubble"
          v-for="chat in chats"
          :key="chat.messageId"
          :class="{
            'chat-right': chat._sender.userId === user.serviceId,
            'chat-left': chat._sender.userId !== user.serviceId,
          }"
        >
          <p class="m-0 p-3 text-17" v-text="chat.message"></p>
          <!-- 0810 채팅입력시간 출력 -->
          <span>{{getCreateDate(chat.createdAt)}}</span>
        </div>
      </section>
    </article>
    <template #modal-footer v-if="channel">
      <b-form class="flex-grow-1 m-0 p-0">
        <template v-if="!channel.isFrozen">
          <b-row class="mx-n1">
            <b-col cols="9" md="10" class="px-1">
              <b-textarea
                @keyup.stop.prevent.enter="send"
                class="w-100"
                rows="4"
                placeholder="대화를 입력해주세요."
                v-model="input.message"
              ></b-textarea>
            </b-col>
            <b-col cols="3" md="2" class="px-1">
              <b-btn block @click="send" variant="primary" class="h-100"
                >전송</b-btn
              >
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <b-row class="mx-n1">
            <b-col cols="9" md="10" class="px-1">
              <div
                class="
                  d-flex
                  flex-column
                  h-100
                  align-items-center
                  justify-content-center
                  border border-light-gray border-1
                  rounded-lg
                  bg-light
                  pointer-event-none
                "
              >
                <span class="text-light-gray"
                  >의뢰자가 채팅방을 얼렸습니다.</span
                >
                <span class="text-light-gray" v-if="user.role === 'REALTOR'"
                  >상대방도 채팅을 입력할 수 없습니다.</span
                >
              </div>
            </b-col>
            <b-col cols="3" md="2" class="px-1">
              <b-btn @click="send" variant="primary" class="h-100 py-5" disabled
                >전송</b-btn
              >
            </b-col>
          </b-row>
        </template>
      </b-form>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    target: {
      type: Object,
      default: () => ({}),
    },
    targetType: {
      type: String,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    channel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      input: {},
      chats: [],
    };
  },
  methods: {
    // 0810 채팅 입력시간 날짜 포맷 변경 값 계산
    getCreateDate(milliseconds){

      const days = ['일', '월', '화', '수', '목', '금', '토'];

      let data = new Date(milliseconds);

      const year = data.getFullYear();   
      const month = data.getMonth() + 1;  
      const date = data.getDate();        
      const day = days[data.getDay()];    

      const hour = data.getHours();    
      const minute = data.getMinutes();    
      const seconds = data.getSeconds();    

      return `${year}.${month}.${date}.(${day}) ${hour}:${minute}:${seconds}`;
    },
    showEvent() {
      this.getMessagesByTimeStamp();
      this.setChannelEventHandler();
    },
    resetChat() {
      this.chats = [];
      this.input = { message: "" };
      this.$router.replace({ path: this.$route.path });
      this.removeChannelEventHandler();
    },
    scrollEnd() {
      this.$nextTick(() => {
        this.$refs.chatwrap.scrollTop = this.$refs.chatwrap.scrollHeight;
      });
    },
    async send(e) {
      const message = this.input.message.trim();

      if (!!message) {
        if (!e.shiftKey) {
          const params = new this.sb.UserMessageParams();
          params.message = this.input.message;
          params.pushNotificationDeliveryOption = "default";

          this.channel.sendUserMessage(params, async (userMessage, error) => {
            if (error) {
              alert("채팅 전송에 실패했습니다. 잠시 후 다시 시도해주세요.");
            }
            await this.chats.push(userMessage);
            this.input = {};
            this.scrollEnd();
          });
        }
      } else {
        this.input.message = "";
        e.preventDefault();
      }
    },
    async getMessagesByTimeStamp() {
      const params = new this.sb.MessageListParams();
      params.isInclusive = true;
      params.prevResultSize = 20;
      params.shouldReverse = false;
      await this.channel.getMessagesByTimestamp(
        new Date().getTime(),
        params,
        (messages, error) => {
          if (error) {
            alert("채팅을 불러오기에 실패했습니다. 잠시 후 다시 시도해주세요.");
          }
          // console.log(messages);
          this.chats = messages.concat(this.chats);
          this.channel.markAsRead();
        }
      );
      this.scrollEnd();
    },
    async setChannelEventHandler() {
      const channelHandler = new this.sb.ChannelHandler();
      channelHandler.onMessageReceived = async (channel, message) => {
        await this.chats.push(message);
        channel.markAsRead();
        this.scrollEnd();
      };
      this.sb.addChannelHandler(this.channel.url, channelHandler);
    },
    async removeChannelEventHandler() {
      this.sb.removeChannelHandler(this.channel.url);
    },
    async freezeToggle() {
      this.channel[this.channel.isFrozen ? "unfreeze" : "freeze"]();
    },
  },
};
</script>

<style lang="scss">
.mobile-h100 {
  max-height: 100vh;
  @media screen and (min-width: 768px) {
    max-height: auto;
  }
}
</style>
