<template>
  <b-container fluid class="px-0 home-container">
    <!-- 메인/빠른검색 -->
    <article class="py-5">
      <section>
        <div>
          <p class="text-anisette text-center text-black text-30 m-0">
            OCPP - TESTPAGE
          </p>
        </div>
      </section>
      <section>
        <div>
          <b-row>
            <b-col lg="6">
              <div>
                <b-row>
                  <template>
                    <b-col lg="2" class="mb-1" style="display: flex; align-items: center; justify-content: center;">
                      <div>
                        <span class="text-17">
                          <font-awesome-icon />sessionIndex
                        </span>
                      </div>
                    </b-col>
                    <b-col lg="7" class="mb-1">
                      <div>
                        <b-form-select size="sm" v-model="sessionIndex" :options="sessionOptions"></b-form-select>
                      </div>
                    </b-col>
                    <b-col lg="1" class="mb-1">
                      <div>
                        <b-button class= "default_btn" size="sm" @click="sessionSearch">조회</b-button>
                      </div>
                    </b-col>
                  </template>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </section>
      <section>
        <div>
          <b-tabs v-model="tabIndex" pills card>
            <b-tab title="Server" active @click="changeTab">
              <div class="mt-1">
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">changeAvailability</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">getConfiguration</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">changeConfiguration</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">clearCache</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">dataTransferReq</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">remoteStartTransaction</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">remoteStopTransaction</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">reset</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">unlockConnector</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">updateFirmware</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">sendLocalList</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">getLocalListVersion</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">smartChargingSetProfile</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">smartChargingClearProfile</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">smartChargingCompositeSchedule</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">reservNow</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">reservCancel</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">remoteTrigger</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">getDiagnostics</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">CertificateSigned</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">DeleteCertificate</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">ExtendedTriggerMessage</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">GetInstalledCertificateIds</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">GetLog</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">InstallCertificate</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">SignedUpdateFirmware</b-button>
              </div>
            </b-tab>
            <b-tab title="Client" @click="changeTab">
              <div class="mt-1">
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">Authorize</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">BootNotification</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">DataTransfer</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">Heartbeat</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">MeterValues</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">StartTransaction</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">StopTransaction</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">StatusNotification</b-button>
                <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">SignCertificate</b-button>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </section>
      <section>
        <div style="padding: 10px;">
          <b-row>
            <b-col lg="6">
              <template>
                <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 10px;">
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" />입력
                  </span>
                  <div>
                    <b-button class="mx-1 my-1 default_btn" size="sm" @click="fileSave">저장</b-button>
                    <b-button class="mx-1 my-1 default_btn" size="sm" @click="send">호출</b-button>
                  </div>
                </div>
              </template>
              <template>
                <div v-if="select.protocol === 'changeAvailability'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />connectorId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" type=number v-model="changeAvailabilityReqData.connectorId"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />type
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="changeAvailabilityReqData.type" :options="caOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'getConfiguration'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />key
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="getConfigurationReqData.requestKey"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'changeConfiguration'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />key <!--50-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="changeConfigurationReqData.key"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />value <!--500-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="changeConfigurationReqData.value"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'clearCache'">

                </div>
                <div v-else-if="select.protocol === 'dataTransferReq'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />vendorId <!--255-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="dataTransferReqData.vendorID"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />messageId <!--50-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="dataTransferReqData.messageID"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />data
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="dataTransferReqData.data"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'remoteStartTransaction'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />connectorId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="remoteStartTransactionReqData.connectorId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />idTag <!--20-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="remoteStartTransactionReqData.idTag"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />chargingProfileId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="remoteStartTransactionReqData.chargingProfile.chargingProfileId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />transactionId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="remoteStartTransactionReqData.chargingProfile.transactionId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />stackLevel
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="remoteStartTransactionReqData.chargingProfile.stackLevel"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />chargingProfilePurpose
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="remoteStartTransactionReqData.chargingProfile.chargingProfilePurpose" :options="rStartCppOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-1">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />chargingProfileKind
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10">
                        <div>
                          <b-form-select size="sm" v-model="remoteStartTransactionReqData.chargingProfile.chargingProfileKind" :options="rStartCpkOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />recurrencyKind
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="remoteStartTransactionReqData.chargingProfile.recurrencyKind" :options="rStartRkOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />validFrom
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="remoteStartTransactionReqData.chargingProfile.validFromD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="remoteStartTransactionReqData.chargingProfile.validFromT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />validTo
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="remoteStartTransactionReqData.chargingProfile.validToD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="remoteStartTransactionReqData.chargingProfile.validToT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />duration
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="remoteStartTransactionReqData.chargingProfile.chargingSchedule.duration"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />startSchedule
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="remoteStartTransactionReqData.chargingProfile.chargingSchedule.startScheduleD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="remoteStartTransactionReqData.chargingProfile.chargingSchedule.startScheduleT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />chargingRateUnit
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="remoteStartTransactionReqData.chargingProfile.chargingSchedule.chargingRateUnit" :options="rStartCRUOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />minChargingRate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="remoteStartTransactionReqData.chargingProfile.chargingSchedule.startSchedule"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="12" class="mb-2">
                        <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 10px;">
                          <span class="text-17 text-danger">
                            <font-awesome-icon icon="fa-solid fa-asterisk" />chargingSchedulePeriod
                          </span>
                          <div>
                            <b-button class="mx-1 my-1 default_btn" size="sm" @click="addRStartTranCell()">추가</b-button>
                          </div>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="12" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-table striped hover 
                            :items="remoteStartTransactionReqData.chargingProfile.chargingSchedule.chargingSchedulePeriod"
                            :fields="rStartTranFields"
                          >
                            <template #cell(startPeriod)="row">
                              <b-form-input 
                                size="sm"
                                type=number
                                v-model="remoteStartTransactionReqData.chargingProfile.chargingSchedule.chargingSchedulePeriod[row.index].startPeriod"
                              ></b-form-input>
                            </template>
                            <template #cell(limit)="row">
                              <b-form-input 
                                size="sm"
                                type=number
                                v-model="remoteStartTransactionReqData.chargingProfile.chargingSchedule.chargingSchedulePeriod[row.index].limit"
                              ></b-form-input>
                            </template>
                            <template #cell(numberPhases)="row">
                              <b-form-input 
                                size="sm"
                                type=number
                                v-model="remoteStartTransactionReqData.chargingProfile.chargingSchedule.chargingSchedulePeriod[row.index].numberPhases"
                              ></b-form-input>
                            </template>
                            <template #cell(removeCell)="row">
                              <b-button size="sm" @click="removeRStartTranCell(remoteStartTransactionReqData.chargingProfile.chargingSchedule.chargingSchedulePeriod[row.index], row.index, $event.target)" class="default_btn">제거</b-button>
                            </template>
                          </b-table>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'remoteStopTransaction'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />transactionId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="remoteStopTransactionReqData.transactionId" 
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'reset'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />type
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="resetConData.type" :options="resetTypeOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'unlockConnector'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />connectorId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="unlockConnectorReqData.connectorId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'updateFirmware'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />location
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type="url"
                            v-model="updateFirmwareReqData.location"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />retries
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="updateFirmwareReqData.retries"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />retrieveDate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="updateFirmwareReqData.retrieveDateD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="updateFirmwareReqData.retrieveDateT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />retryInterval
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="updateFirmwareReqData.retryInterval"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'sendLocalList'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />listVersion <!--20-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="sendLocalListData.listVersion"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />idTag <!--20-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="sendLocalListData.idTag"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />expiryDate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="sendLocalListData.expiryDateD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="sendLocalListData.expiryDateT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />parentIdTag <!--20-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="sendLocalListData.parentIdTag"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />status
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="sendLocalListData.updateType" :options="sllUpdateTypeOption"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'getLocalListVersion'">
                  <b-row>

                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'smartChargingSetProfile'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />connectorId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="setChargingProfileReqData.connectorId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />chargingProfileId <!--20-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="setChargingProfileReqData.csChargingProfiles.chargingProfileId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />transactionId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="setChargingProfileReqData.csChargingProfiles.transactionId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />stackLevel
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="setChargingProfileReqData.csChargingProfiles.stackLevel"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />chargingProfilePurpose
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="setChargingProfileReqData.csChargingProfiles.chargingProfilePurpose" :options="rStartCppOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />chargingProfileKind
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="setChargingProfileReqData.csChargingProfiles.chargingProfileKind" :options="rStartCpkOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />recurrencyKind
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="setChargingProfileReqData.csChargingProfiles.recurrencyKind" :options="rStartRkOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />validFrom
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="setChargingProfileReqData.csChargingProfiles.validFromD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="setChargingProfileReqData.csChargingProfiles.validFromT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />validTo
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="setChargingProfileReqData.csChargingProfiles.validToD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="setChargingProfileReqData.csChargingProfiles.validToT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />duration
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="setChargingProfileReqData.csChargingProfiles.chargingSchedule.duration"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />startSchedule
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="setChargingProfileReqData.csChargingProfiles.chargingSchedule.startSchedule"
                          ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />chargingRateUnit
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="setChargingProfileReqData.csChargingProfiles.chargingSchedule.chargingRateUnit" :options="rStartCRUOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />minChargingRate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="setChargingProfileReqData.csChargingProfiles.chargingSchedule.startSchedule"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="12" class="mb-2">
                        <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 10px;">
                          <span class="text-17 text-danger">
                            <font-awesome-icon icon="fa-solid fa-asterisk" />chargingSchedulePeriod
                          </span>
                          <div>
                            <b-button class="mx-1 my-1 default_btn" size="sm" @click="addSChgProfileCell()">추가</b-button>
                          </div>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="12" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-table striped hover 
                            :items="setChargingProfileReqData.csChargingProfiles.chargingSchedule.chargingSchedulePeriod"
                            :fields="sChgProfileFields"
                          >
                            <template #cell(startPeriod)="row">
                              <b-form-input 
                                size="sm"
                                type=number
                                v-model="setChargingProfileReqData.csChargingProfiles.chargingSchedule.chargingSchedulePeriod[row.index].startPeriod"
                              ></b-form-input>
                            </template>
                            <template #cell(limit)="row">
                              <b-form-input 
                                size="sm"
                                type=number
                                v-model="setChargingProfileReqData.csChargingProfiles.chargingSchedule.chargingSchedulePeriod[row.index].limit"
                              ></b-form-input>
                            </template>
                            <template #cell(numberPhases)="row">
                              <b-form-input 
                                size="sm"
                                type=number
                                v-model="setChargingProfileReqData.csChargingProfiles.chargingSchedule.chargingSchedulePeriod[row.index].numberPhases"
                              ></b-form-input>
                            </template>
                            <template #cell(removeCell)="row">
                              <b-button size="sm" @click="removeSChgProfileCell(setChargingProfileReqData.csChargingProfiles.chargingSchedule.chargingSchedulePeriod[row.index], row.index, $event.target)" class="default_btn">제거</b-button>
                            </template>
                          </b-table>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'smartChargingClearProfile'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />id
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="clearChargingProfileReqData.id"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />connectorId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="clearChargingProfileReqData.connectorId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />chargingProfilePurpose
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="clearChargingProfileReqData.chargingProfilePurpose" :options="ccpProfilePurposeOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />stackLevel
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="clearChargingProfileReqData.stackLevel"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'smartChargingCompositeSchedule'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />connectorId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="getCompositeScheduleReqData.connectorId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />duration
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="getCompositeScheduleReqData.duration"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />chargingRateUnit
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="getCompositeScheduleReqData.chargingRateUnit" :options="gcsChargingRateUnitOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'reservNow'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />connectorId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="reserveNowReqData.connectorId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />expiryDate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="reserveNowReqData.expiryDateD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="reserveNowReqData.expiryDateT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />idTag <!--20-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="reserveNowReqData.idTag"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />parentIdTag <!--20-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="reserveNowReqData.parentIdTag"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />reservationId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="reserveNowReqData.reservationId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'reservCancel'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />reservationId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="cancelReservationReqData.reservationId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'remoteTrigger'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />requestedMessage
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="triggerMessageReqData.requestedMessage" :options="rtReqMsgOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />connectorId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="triggerMessageReqData.connectorId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'getDiagnostics'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />location
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type="url"
                            v-model="getDiagnosticsReqData.location"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />retries
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="getDiagnosticsReqData.retries"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />retryInterval
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="getDiagnosticsReqData.retryInterval"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />startTime
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="getDiagnosticsReqData.startTimeD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="getDiagnosticsReqData.startTimeT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />stopTime
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="getDiagnosticsReqData.stopTimeD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="getDiagnosticsReqData.stopTimeT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'CertificateSigned'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />certificateChain <!--255-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="certSignedReqData.certificateChain"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'DeleteCertificate'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />hashAlgorithm
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="delCertReqData.certificateHashData.hashAlgorithm" :options="delCertHashAlgorithmOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />issuerNameHash <!--255-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="delCertReqData.certificateHashData.issuerNameHash"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />issuerKeyHash <!--50-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="delCertReqData.certificateHashData.issuerKeyHash"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />serialNumber
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="delCertReqData.certificateHashData.serialNumber"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'ExtendedTriggerMessage'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />requestedMessage
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="extTriggerMessageReqData.requestedMessage" :options="extReqMsgOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />connectorId <!--50-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="extTriggerMessageReqData.connectorId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'GetInstalledCertificateIds'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />certificateType
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="getInstCertIdsReqData.certificateType" :options="gInstCertTypeOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'GetLog'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />logType
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="getLogReqData.logType" :options="gLogStatusOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />requestId <!--255-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="getLogReqData.requestId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />retries <!--50-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="getLogReqData.retries"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />retryInterval
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="getLogReqData.retryInterval"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />remoteLocation
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="getLogReqData.log.remoteLocation"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />oldestTimestamp
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="getLogReqData.log.oldestTimestampD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="getLogReqData.log.oldestTimestampT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />latestTimestamp
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="getLogReqData.log.latestTimestampD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="getLogReqData.log.latestTimestampT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'InstallCertificate'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />certificateType
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="installCertificateReqData.certificateType" :options="iCertStatusOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />certificate <!--50-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="installCertificateReqData.certificate"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'SignedUpdateFirmware'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />retries <!--255-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="signedUpdateFirmwareReqData.retries"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />retryInterval <!--50-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="signedUpdateFirmwareReqData.retryInterval"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />requestId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="signedUpdateFirmwareReqData.requestId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />location
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="signedUpdateFirmwareReqData.firmware.location"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />retrieveDateTime
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="signedUpdateFirmwareReqData.firmware.retrieveDateTimeD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="signedUpdateFirmwareReqData.firmware.retrieveDateTimeT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />installDateTime
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="signedUpdateFirmwareReqData.firmware.installDateTimeD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="signedUpdateFirmwareReqData.firmware.installDateTimeT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />signingCertificate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="signedUpdateFirmwareReqData.firmware.signingCertificate"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />signature
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="signedUpdateFirmwareReqData.firmware.signature"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'Authorize'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />expiryDate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="authorizeConData.idTagInfo.expiryDateD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="authorizeConData.idTagInfo.expiryDateT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />parentIdTag
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="authorizeConData.idTagInfo.parentIdTag"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />status
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="authorizeConData.idTagInfo.status" :options="authStatusOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'BootNotification'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />status
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="bootNotificationConData.status" :options="bnStatusOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />currentTime
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="bootNotificationConData.currentTimeD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="bootNotificationConData.currentTimeT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />interval
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="bootNotificationConData.interval"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'DataTransfer'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />status
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="dataTransferConData.status" :options="dtStatusOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />data
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="dataTransferConData.data"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'Heartbeat'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />currentTime
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="heartbeatConData.currentTimeD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="heartbeatConData.currentTimeT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'MeterValues'">
                  <b-row>
                    
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'StartTransaction'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />transactionId
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            type=number
                            v-model="startTransactionConData.transactionId"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />expiryDate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="startTransactionConData.idTagInfo.expiryDateD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="startTransactionConData.idTagInfo.expiryDateT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />parentIdTag <!--20-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="startTransactionConData.idTagInfo.parentIdTag"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />status
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="startTransactionConData.idTagInfo.status" :options="startTranStatusOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'StopTransaction'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />expiryDate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div style="display: flex; flex-direction: row; gap: 10px;">
                          <b-form-input 
                            size="sm"
                            type="date"
                            v-model="stopTransactionConData.idTagInfo.expiryDateD"
                            ></b-form-input>
                          <b-form-input 
                            size="sm"
                            type="time"
                            v-model="stopTransactionConData.idTagInfo.expiryDateT"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />parentIdTag <!--20-->
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input 
                            size="sm"
                            v-model="stopTransactionConData.idTagInfo.parentIdTag"
                            ></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />status
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="stopTransactionConData.idTagInfo.status" :options="stopTranStatusOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'StatusNotification'">
                  
                </div>
                <div v-else-if="select.protocol === 'LogStatusNotification'">
                  
                </div>
                <div v-else-if="select.protocol === 'SecurityEventNotification'">
                  
                </div>
                <div v-else-if="select.protocol === 'SignCertificate'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />status
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-select size="sm" v-model="signCertificateConfData.status" :options="signCertStatusOptions"></b-form-select>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === 'SignedFirmwareStatusNotification'">
                  
                </div>
              </template>
            </b-col>
            <b-col lg="6">
              <template>
                <div style="height: 61.5px; margin-bottom: 10px;">
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" />결과
                  </span>
                </div>
              </template>
              <b-form-textarea
                id="resultText"
                v-model="result_text"
                placeholder="결과 출력..."
                size="sm"
                rows="4"
                max-rows="16"
              ></b-form-textarea>
            </b-col>
          </b-row>
        </div>
      </section>
    </article>      
  </b-container>
</template>

<script>
import BankList from "./BankList.vue";
import Product from "@/lib/product";

// 숫자 올리는 애니메이션
import IOdometer from "vue-odometer";
import "odometer/themes/odometer-theme-default.css";
import QuickMenu from "@/components/Nav/QuickMenu.vue";

import $ from 'jquery';

export default {
  name: "Ocpp Test",
  components: { 
    
  },
  props: {
    data: null, //해당 data에는 모든 api를 조회한 값이 담겨옴 (비딩 등록 직전의 데이터)
  },
  data() {
    return {
      tabIndex: 0,
      sessionIndex: null,
      sessionOptions: [],
      authorizeConData: {
        idTagInfo: {
          expiryDate: "20241018123059",
          parentIdTag: "",
          status: ""  
        }
      },
      bootNotificationConData: {
        interval : 0,
	      status : "Accepted",
        currentTime : "20241018123059"
      },
      cancelReservationReqData: {
        reservationId: 0
      },
      changeAvailabilityReqData: {
        connectorId : 1,
	      type : ""
      },
      changeConfigurationReqData: {
        key : "",
	      value : ""
      },
      chargingProfileData: {
        chargingProfileId : 0,
        transactionId : 0,
        stackLevel : 0,
        chargingProfilePurpose : "",
        chargingProfileKind : "",
        recurrencyKind : "",
        validFrom : "",
        validTo : "",
        chargingSchedule: {
          duration : "300",
          startSchedule : "2022-01-01T00:00Z",
          chargingRateUnit : "W",
          chargingSchedulePeriod : [
            {
              startPeriod : 0,
              limit : 0,
              numberPhases : 0
            },
            {
              startPeriod : 0,
              limit : 0,
              numberPhases : 0
            },
            {
              startPeriod : 0,
              limit : 0,
              numberPhases : 0
            },
          ], 
          minchargingRate : 0
        }
      },
      clearChargingProfileReqData: {
        id : 0,
        connectorId : 0,
        chargingProfilePurpose : "",
        stackLevel : 0
      },
      dataTransferConData: {
        status: ""
      },
      dataTransferReqData: {
        vendorID : "",
	      messageID : "",
	      data : ""
      },
      getCompositeScheduleReqData: {
        connectorId : 0,
        duration : 0,
        chargingRateUnit : ""
      },
      getConfigurationReqData: {
        requestKey : ""
      },
      getDiagnosticsReqData: {
        location : "",
        retries : 1,
        retryInterval : 0,
        startTime : "20220426235900",
        stopTime : "20220427010000"
      },
      heartbeatConData: {
        currentTime : "20220427010000"
      },
      remoteStartTransactionReqData: {
        connectorId : 1,
	      idTag : "",
        chargingProfile : {
          chargingProfileId : 0,
          transactionId : 0,
          stackLevel : 0,
          chargingProfilePurpose : "",
          chargingProfileKind : "",
          recurrencyKind : "",
          validFrom : "",
          validTo : "",
          chargingSchedule : {
            duration : 0,
            startSchedule : "",
            chargingRateUnit : "",
            chargingSchedulePeriod : [
              {
                startPeriod : 0,
                limit : 0.0,
                numberPhases : 0
              }
            ],
            minchargingRate : 0.0
          }
        }
      },
      rStartTranFields: [
        "startPeriod",
        "limit",
        "numberPhases",
        "removeCell"
      ],
      remoteStopTransactionReqData: {
        transactionId : 0
      },
      reserveNowReqData: {
        connectorId : 1,
        expiryDate : "",
        idTag : "",
        reservationId : 0
      },
      resetConData: {
        type : ""
      },
      sendLocalListData: {
        listVersion : 0,
        localAuthorizationList : [
          {
            idTag : "",
            idTagInfo : {
              expiryDate : "",
              parentIdTag : "",
              status : ""
            }
          }
        ],
	      updateType : ""
      },
      setChargingProfileReqData: {
        connectorId : 1,
        csChargingProfiles : {
          chargingProfileId : 0,
          transactionId : 0,
          stackLevel : 0,
          chargingProfilePurpose : "",
          chargingProfileKind : "",
          recurrencyKind : "",
          validFrom : "",
          validTo : "",
          chargingSchedule : {
            duration : 0,
            startSchedule : 0,
            chargingRateUnit : "",
            chargingSchedulePeriod : [
             {
              startPeriod : 0,
              limit : 0.0,
              numberPhases : 0
             }
            ],
            minchargingRate : 0.0
          }
        }
      },
      sChgProfileFields: [
        "startPeriod",
        "limit",
        "numberPhases",
        "removeCell"
      ],
      startTransactionConData: {
        idTagInfo : {
          expiryDate : "",
          parentIdTag : "",
          status : ""
        },
        transactionId : ""
      },
      stopTransactionConData: {
        idTagInfo : {
          expiryDate : "",
          parentIdTag : "",
	        status : ""  
        }
      },
      triggerMessageReqData: {
        requestedMessage : "",
	      connectorId : 1
      },
      unlockConnectorReqData: {
        connectorId : 1
      },
      updateFirmwareReqData: {
        location : "",
        retries : 1,
        retrieveDate : "",
        retrieveDateD : "",
        retrieveDateT : "",
        retryInterval : 1
      },
      certSignedReqData: {
        certificateChain: ""
      },
      delCertReqData: {
        certificateHashData: {
          hashAlgorithm: "",
          issuerNameHash: "",
          issuerKeyHash: "",
          serialNumber: ""
        }
      },
      extTriggerMessageReqData: {
        requestedMessage: "",
        connectorId: 1
      },
      getInstCertIdsReqData: {
        certificateType: ""
      },
      getLogReqData: {
        logType: "",
        requestId: 1,
        retries: 1,
        retryInterval: 1,
        log: {
          remoteLocation: "",
          oldestTimestamp: "20241031173200",
          latestTimestamp: "20241031173200"
        }
      },
      installCertificateReqData: {
        certificateType: "",
        certificate: ""
      },
      signedUpdateFirmwareReqData: {
        retries: 1,
        retryInterval: 1,
        requestId: 1,
        firmware: {
          location: "",
          retrieveDateTime: "20241031173200",
          installDateTime: "20241031173200",
          signingCertificate: "",
          signature: ""
        }
      },
      signCertificateConfData: {
        status: ""
      },
      selected: null,
      caOptions: [
        { value: "Inoperative", text: "작동하지 않음" },
        { value: "Operative", text: "작동중" }
      ],
      rStartCppOptions: [
        { value: "ChargePointMaxProfile", text: "ChargePointMaxProfile" },
        { value: "TxDefaultProfile", text: "TxDefaultProfile" },
        { value: "TxProfile", text: "TxProfile" }
      ],
      ccpProfilePurposeOptions: [
        { value: "ChargePointMaxProfile", text: "ChargePointMaxProfile" },
        { value: "TxDefaultProfile", text: "TxDefaultProfile" },
        { value: "TxProfile", text: "TxProfile" }
      ],
      rStartCpkOptions: [
        { value: "Absolute", text: "절대" },
        { value: "Recurring", text: "반복" },
        { value: "Relative", text: "상대" }
      ],
      rStartRkOptions: [
        { value: "Daily", text: "매일" },
        { value: "Weekly", text: "매주" }
      ],
      rStartCRUOptions: [
        { value: "A", text: "A" },
        { value: "W", text: "W" }
      ],
      resetTypeOptions: [
        { value: "Hard", text: "Hard" },
        { value: "Soft", text: "Soft" }
      ],
      sllUpdateTypeOption: [
        { value: "Differential", text: "Differential" },
        { value: "Full", text: "Full" },
      ],
      sllStatusOptions: [
        { value: "Accepted", text: "Accepted" },
        { value: "Blocked", text: "Blocked" },
        { value: "Expired", text: "Expired" },
        { value: "Invalid", text: "Invalid" },
        { value: "ConcurrentTx", text: "ConcurrentTx" }
      ],
      rtReqMsgOptions: [
        { value: "BootNotification", text: "BootNotification" },
        { value: "DiagnosticsStatusNotification", text: "DiagnosticsStatusNotification" },
        { value: "FirmwareStatusNotification", text: "FirmwareStatusNotification" },
        { value: "Heartbeat", text: "Heartbeat" },
        { value: "MeterValues", text: "MeterValues" },
        { value: "StatusNotification", text: "StatusNotification" }
      ],
      gcsChargingRateUnitOptions: [
        { value: "A", text: "A" },
        { value: "W", text: "W" }
      ],
      authStatusOptions: [
        { value: "Accepted", text: "Accepted" },
        { value: "Blocked", text: "Blocked" },
        { value: "Expired", text: "Expired" },
        { value: "Invalid", text: "Invalid" },
        { value: "ConcurrentTx", text: "ConcurrentTx" }
      ],
      bnStatusOptions: [
        { value: "Accepted", text: "Accepted" },
        { value: "Pending", text: "Pending" },
        { value: "Rejected", text: "Rejected" }
      ],
      dtStatusOptions: [
        { value: "Accepted", text: "Accepted" },
        { value: "Rejected", text: "Rejected" },
        { value: "UnknownMessageId", text: "UnknownMessageId" },
        { value: "UnknownVendorId", text: "UnknownVendorId" }
      ],
      startTranStatusOptions: [
        { value: "Accepted", text: "Accepted" },
        { value: "Blocked", text: "Blocked" },
        { value: "Expired", text: "Expired" },
        { value: "Invalid", text: "Invalid" },
        { value: "ConcurrentTx", text: "ConcurrentTx" }
      ],
      stopTranStatusOptions: [
        { value: "Accepted", text: "Accepted" },
        { value: "Blocked", text: "Blocked" },
        { value: "Expired", text: "Expired" },
        { value: "Invalid", text: "Invalid" },
        { value: "ConcurrentTx", text: "ConcurrentTx" }
      ],
      delCertHashAlgorithmOptions: [
        { value: "SHA256", text: "SHA256" },
        { value: "SHA384", text: "SHA384" },
        { value: "SHA512", text: "SHA512" }
      ],
      extReqMsgOptions: [
        { value: "BootNotification", text: "BootNotification" },
        { value: "LogStatusNotification", text: "LogStatusNotification" },
        { value: "FirmwareStatusNotification", text: "FirmwareStatusNotification" },
        { value: "Heartbeat", text: "Heartbeat" },
        { value: "MeterValues", text: "MeterValues" },
        { value: "SignChargePointCertificate", text: "SignChargePointCertificate" },
        { value: "StatusNotification", text: "StatusNotification" }
      ],
      gInstCertTypeOptions: [
        { value: "CentralSystemRootCertificate", text: "CentralSystemRootCertificate" },
        { value: "ManufacturerRootCertificate", text: "ManufacturerRootCertificate" }
      ],
      gLogStatusOptions: [
        { value: "DiagnosticsLog", text: "DiagnosticsLog" },
        { value: "SecurityLog", text: "SecurityLog" }
      ],
      iCertStatusOptions: [
        { value: "CentralSystemRootCertificate", text: "CentralSystemRootCertificate" },
        { value: "ManufacturerRootCertificate", text: "ManufacturerRootCertificate" }
      ],
      signCertStatusOptions: [
        { value: "Accepted", text: "Accepted" },
        { value: "Rejected", text: "Rejected" }
      ],
      default: true,
      select: { 
        protocol: "changeAvailability",
      },
      fileName: "",
      callName: "",
      json_data: {},
      result_data: {},
      result_text: "",
      jsonRead_data: {}
    };
  },
  computed: {
    gcInputState() {
      let gcInput_text = this.gcInput;
      if (gcInput_text.length > 50) {
        this.gcInput = "";
      }
      return gcInput_text.length < 51 ? true : false
    }
  },
  watch: {
    remoteStartTransactionReqData: function (newVal, oldVal) {
      let tempDate1 = this.remoteStartTransactionReqData.chargingProfile.validFromD + this.remoteStartTransactionReqData.chargingProfile.validFromT;
      let tempDT1 = tempDate1.replaceAll("-", "").replaceAll(":", "");
      if (tempDT1.length === 12) {
        tempDT1 = tempDT1 + "00";
      }
      this.remoteStartTransactionReqData.chargingProfile.validFrom = tempDT1;

      let tempDate2 = this.remoteStartTransactionReqData.chargingProfile.validToD + this.remoteStartTransactionReqData.chargingProfile.validToT;
      let tempDT2 = tempDate2.replaceAll("-", "").replaceAll(":", "");
      if (tempDT2.length === 12) {
        tempDT2 = tempDT2 + "00";
      }
      this.remoteStartTransactionReqData.chargingProfile.validTo = tempDT2;
      
      let tempDate3 = this.remoteStartTransactionReqData.chargingProfile.chargingSchedule.startScheduleD + this.remoteStartTransactionReqData.chargingProfile.chargingSchedule.startScheduleT;
      let tempDT3 = tempDate3.replaceAll("-", "").replaceAll(":", "");
      if (tempDT3.length === 12) {
        tempDT3 = tempDT3 + "00";
      }
      this.remoteStartTransactionReqData.chargingProfile.chargingSchedule.startSchedule = tempDT3;
    },
    setChargingProfileReqData: function (newVal, oldVal) {
      let tempDate1 = this.setChargingProfileReqData.csChargingProfiles.validFromD + this.setChargingProfileReqData.csChargingProfiles.validFromT;
      let tempDT1 = tempDate1.replaceAll("-", "").replaceAll(":", "");
      if (tempDT1.length === 12) {
        tempDT1 = tempDT1 + "00";
      }
      this.setChargingProfileReqData.csChargingProfiles.validFrom = tempDT1;

      let tempDate2 = this.setChargingProfileReqData.csChargingProfiles.validToD + this.setChargingProfileReqData.csChargingProfiles.validToT;
      let tempDT2 = tempDate2.replaceAll("-", "").replaceAll(":", "");
      if (tempDT2.length === 12) {
        tempDT2 = tempDT2 + "00";
      }
      this.setChargingProfileReqData.csChargingProfiles.validTo = tempDT2;
    },
    updateFirmwareReqData: function (newVal, oldVal) {
      let tempDate = this.updateFirmwareReqData.retrieveDateD + this.updateFirmwareReqData.retrieveDateT;
      let tempDT = tempDate.replaceAll("-", "").replaceAll(":", "");
      if (tempDT.length === 12) {
        tempDT = tempDT + "00";
      }
      this.updateFirmwareReqData.retrieveDate = tempDT;
    },
    sendLocalListData: function (newVal, oldVal) {
      let tempDate = this.sendLocalListData.expiryDateD + this.sendLocalListData.expiryDateT;
      let tempDT = tempDate.replaceAll("-", "").replaceAll(":", "");
      if (tempDT.length === 12) {
        tempDT = tempDT + "00";
      }
      this.sendLocalListData.expiryDate = tempDT;
    },
    reserveNowReqData: function (newVal, oldVal) {
      let tempDate = this.reserveNowReqData.expiryDateD + this.reserveNowReqData.expiryDateT;
      let tempDT = tempDate.replaceAll("-", "").replaceAll(":", "");
      if (tempDT.length === 12) {
        tempDT = tempDT + "00";
      }
      this.reserveNowReqData.expiryDate = tempDT;
    },
    getDiagnosticsReqData: function (newVal, oldVal) {
      let tempDate1 = this.getDiagnosticsReqData.startTimeD + this.getDiagnosticsReqData.startTimeT;
      let tempDT1 = tempDate1.replaceAll("-", "").replaceAll(":", "");
      if (tempDT1.length === 12) {
        tempDT1 = tempDT1 + "00";
      }
      this.getDiagnosticsReqData.startTime = tempDT1;

      let tempDate2 = this.getDiagnosticsReqData.stopTimeD + this.getDiagnosticsReqData.stopTimeT;
      let tempDT2 = tempDate2.replaceAll("-", "").replaceAll(":", "");
      if (tempDT2.length === 12) {
        tempDT2 = tempDT2 + "00";
      }
      this.getDiagnosticsReqData.stopTime = tempDT2;
    },
    getLogReqData: function (newVal, oldVal) {
      let tempDate1 = this.getLogReqData.log.oldestTimestampD + this.getLogReqData.log.oldestTimestampT;
      let tempDT1 = tempDate1.replaceAll("-", "").replaceAll(":", "");
      if (tempDT1.length === 12) {
        tempDT1 = tempDT1 + "00";
      }
      this.getLogReqData.log.oldestTimestamp = tempDT1;

      let tempDate2 = this.getLogReqData.log.latestTimestampD + this.getLogReqData.log.latestTimestampT;
      let tempDT2 = tempDate2.replaceAll("-", "").replaceAll(":", "");
      if (tempDT2.length === 12) {
        tempDT2 = tempDT2 + "00";
      }
      this.getLogReqData.log.latestTimestamp = tempDT2;
    },
    signedUpdateFirmwareReqData: function (newVal, oldVal) {
      let tempDate1 = this.signedUpdateFirmwareReqData.firmware.retrieveDateTimeD + this.signedUpdateFirmwareReqData.firmware.retrieveDateTimeT;
      let tempDT1 = tempDate1.replaceAll("-", "").replaceAll(":", "");
      if (tempDT1.length === 12) {
        tempDT1 = tempDT1 + "00";
      }
      this.signedUpdateFirmwareReqData.firmware.retrieveDateTime = tempDT1;

      let tempDate2 = this.signedUpdateFirmwareReqData.firmware.installDateTimeD + this.signedUpdateFirmwareReqData.firmware.installDateTimeT;
      let tempDT2 = tempDate2.replaceAll("-", "").replaceAll(":", "");
      if (tempDT2.length === 12) {
        tempDT2 = tempDT2 + "00";
      }
      this.signedUpdateFirmwareReqData.firmware.installDateTime = tempDT2;
    },
    authorizeConData: function (newVal, oldVal) {
      let tempDate = this.authorizeConData.idTagInfo.expiryDateD + this.authorizeConData.idTagInfo.expiryDateT;
      let tempDT = tempDate.replaceAll("-", "").replaceAll(":", "");
      if (tempDT.length === 12) {
        tempDT = tempDT + "00";
      }
      this.authorizeConData.idTagInfo.expiryDate = tempDT;
    },
    bootNotificationConData: function (newVal, oldVal) {
      let tempDate = this.bootNotificationConData.currentTimeD + this.bootNotificationConData.currentTimeT;
      let tempDT = tempDate.replaceAll("-", "").replaceAll(":", "");
      if (tempDT.length === 12) {
        tempDT = tempDT + "00";
      }
      this.bootNotificationConData.currentTime = tempDT;
    },
    heartbeatConData: function (newVal, oldVal) {
      let tempDate = this.heartbeatConData.currentTimeD + this.heartbeatConData.currentTimeT;
      let tempDT = tempDate.replaceAll("-", "").replaceAll(":", "");
      if (tempDT.length === 12) {
        tempDT = tempDT + "00";
      }
      this.heartbeatConData.currentTime = tempDT;
    },
    startTransactionConData: function (newVal, oldVal) {
      let tempDate = this.startTransactionConData.idTagInfo.expiryDateD + this.startTransactionConData.idTagInfo.expiryDateT;
      let tempDT = tempDate.replaceAll("-", "").replaceAll(":", "");
      if (tempDT.length === 12) {
        tempDT = tempDT + "00";
      }
      this.startTransactionConData.idTagInfo.expiryDate = tempDT;
    },
    stopTransactionConData: function (newVal, oldVal) {
      let tempDate = this.stopTransactionConData.idTagInfo.expiryDateD + this.stopTransactionConData.idTagInfo.expiryDateT;
      let tempDT = tempDate.replaceAll("-", "").replaceAll(":", "");
      if (tempDT.length === 12) {
        tempDT = tempDT + "00";
      }
      this.stopTransactionConData.idTagInfo.expiryDate = tempDT;
    },
  },
  methods: {
    changeProtocolType(e) {
      //console.log(event.target.textContent);
      let button_text = e.target.textContent;
      this.select.protocol = button_text;

      if (this.select.protocol === "changeAvailability") {
        let file_name = "ChangeAvailabilityRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "getConfiguration") {
        let file_name = "GetConfigurationRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "changeConfiguration") {
        let file_name = "ChangeConfigurationRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "clearCache") {
        //let file_name = "ChangeAvailabilityRequest";
        //this.getJsonFile(file_name);
      } else if (this.select.protocol === "dataTransferReq") {
        let file_name = "DataTransferRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "remoteStartTransaction") {
        let file_name = "RemoteStartTransactionRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "remoteStopTransaction") {
        let file_name = "RemoteStopTransactionRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "reset") {
        let file_name = "ResetConfirmation";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "unlockConnector") {
        let file_name = "UnlockConnectorRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "updateFirmware") {
        let file_name = "UpdateFirmwareRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "sendLocalList") {
        let file_name = "SendLocalList";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "getLocalListVersion") {
        //let file_name = "SendLocalList";
        //this.getJsonFile(file_name);
      } else if (this.select.protocol === "smartChargingSetProfile") {
        let file_name = "SetChargingProfileRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "smartChargingClearProfile") {
        let file_name = "ClearChargingProfileRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "smartChargingCompositeSchedule") {
        let file_name = "GetCompositeScheduleRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "reservNow") {
        let file_name = "ReserveNowRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "reservCancel") {
        let file_name = "CancelReservationRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "remoteTrigger") {
        let file_name = "TriggerMessageRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "getDiagnostics") {
        let file_name = "GetDiagnosticsRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "CertificateSigned") {
        let file_name = "CertificateSignedRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "DeleteCertificate") {
        let file_name = "DeleteCertificateRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "ExtendedTriggerMessage") {
        let file_name = "ExtendedTriggerMessageRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "GetInstalledCertificateIds") {
        let file_name = "GetInstalledCertificateIdsRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "GetLog") {
        let file_name = "GetLogRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "InstallCertificate") {
        let file_name = "InstallCertificateRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "SignedUpdateFirmware") {
        let file_name = "SignedUpdateFirmwareRequest";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "Authorize") {
        let file_name = "AuthorizeConfirmation";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "BootNotification") {
        let file_name = "BootNotificationConfirmation";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "DataTransfer") {
        let file_name = "DataTransferConfirmation";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "Heartbeat") {
        let file_name = "HeartbeatConfirmation";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "MeterValues") {
        //let file_name = "AuthorizeConfirmation";
        //this.getJsonFile(file_name);
      } else if (this.select.protocol === "StartTransaction") {
        let file_name = "StartTransactionConfirmation";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "StopTransaction") {
        let file_name = "StopTransactionConfirmation";
        this.getJsonFile(file_name);
      } else if (this.select.protocol === "StatusNotification") {
        //let file_name = "AuthorizeConfirmation";
        //this.getJsonFile(file_name);
      } else if (this.select.protocol === "SignCertificate") {
        let file_name = "SignCertificateConfirmation";
        this.getJsonFile(file_name);
      }
    },
    async getJsonFile(fileName) {
      this.json_data.fileName = fileName;

      await this.$axios.post(
            "/auth-api/jsonFileRead",
            this.json_data
            // config
      ).then((res) => {
          console.log(res);
          //window.alert("저장이 완료되었습니다.");
          if (this.select.protocol === "changeAvailability") {
            this.changeAvailabilityReqData = res.data;
            console.log(this.changeAvailabilityReqData);
          } else if (this.select.protocol === "getConfiguration") {
            this.getConfigurationReqData = res.data;
            console.log(this.getConfigurationReqData);
          } else if (this.select.protocol === "changeConfiguration") {
            this.changeConfigurationReqData = res.data;
            console.log(this.changeConfigurationReqData);
          } else if (this.select.protocol === "clearCache") {
            
          } else if (this.select.protocol === "dataTransferReq") {
            this.dataTransferReqData = res.data;
            console.log(this.dataTransferReqData);
          } else if (this.select.protocol === "remoteStartTransaction") {
            this.remoteStartTransactionReqData = res.data;
            console.log(this.remoteStartTransactionReqData);

            let temp_date = this.remoteStartTransactionReqData.chargingProfile.validFrom;
            this.remoteStartTransactionReqData.chargingProfile.validFromD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.remoteStartTransactionReqData.chargingProfile.validFromT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);

            temp_date = this.remoteStartTransactionReqData.chargingProfile.validTo;
            this.remoteStartTransactionReqData.chargingProfile.validToD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.remoteStartTransactionReqData.chargingProfile.validToT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);

            temp_date = this.remoteStartTransactionReqData.chargingProfile.chargingSchedule.startSchedule;
            this.remoteStartTransactionReqData.chargingProfile.chargingSchedule.startScheduleD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.remoteStartTransactionReqData.chargingProfile.chargingSchedule.startScheduleT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);
          } else if (this.select.protocol === "remoteStopTransaction") {
            this.remoteStopTransactionReqData = res.data;
            console.log(this.remoteStopTransactionReqData);
          } else if (this.select.protocol === "reset") {
            this.resetConData = res.data;
            console.log(this.resetConData);
          } else if (this.select.protocol === "unlockConnector") {
            this.unlockConnectorReqData = res.data;
            console.log(this.unlockConnectorReqData);
          } else if (this.select.protocol === "updateFirmware") {
            this.updateFirmwareReqData = res.data;
            console.log(this.updateFirmwareReqData);

            let temp_date = this.updateFirmwareReqData.retrieveDate;
            this.updateFirmwareReqData.retrieveDateD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.updateFirmwareReqData.retrieveDateT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);
          } else if (this.select.protocol === "sendLocalList") {
            this.sendLocalListData = res.data;
            console.log(this.sendLocalListData);

            let temp_date = this.sendLocalListData.expiryDate;
            this.sendLocalListData.expiryDateD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.sendLocalListData.expiryDateT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);
          } else if (this.select.protocol === "getLocalListVersion") {
            //let file_name = "SendLocalList";
            //this.getJsonFile(file_name);
          } else if (this.select.protocol === "smartChargingSetProfile") {
            this.setChargingProfileReqData = res.data;
            console.log(this.setChargingProfileReqData);

            let temp_date = this.setChargingProfileReqData.csChargingProfiles.validFrom;
            this.setChargingProfileReqData.csChargingProfiles.validFromD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.setChargingProfileReqData.csChargingProfiles.validFromT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);

            temp_date = this.setChargingProfileReqData.csChargingProfiles.validTo;
            this.setChargingProfileReqData.csChargingProfiles.validToD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.setChargingProfileReqData.csChargingProfiles.validToT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);
          } else if (this.select.protocol === "smartChargingClearProfile") {
            this.clearChargingProfileReqData = res.data;
            console.log(this.clearChargingProfileReqData);
          } else if (this.select.protocol === "smartChargingCompositeSchedule") {
            this.getCompositeScheduleReqData = res.data;
            console.log(this.getCompositeScheduleReqData);
          } else if (this.select.protocol === "reservNow") {
            this.reserveNowReqData = res.data;
            console.log(this.reserveNowReqData);

            let temp_date = this.reserveNowReqData.expiryDate;
            this.reserveNowReqData.expiryDateD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.reserveNowReqData.expiryDateT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);
          } else if (this.select.protocol === "reservCancel") {
            this.cancelReservationReqData = res.data;
            console.log(this.cancelReservationReqData);
          } else if (this.select.protocol === "remoteTrigger") {
            this.triggerMessageReqData = res.data;
            console.log(this.triggerMessageReqData);
          } else if (this.select.protocol === "getDiagnostics") {
            this.getDiagnosticsReqData = res.data;
            console.log(this.getDiagnosticsReqData);

            let temp_date = this.getDiagnosticsReqData.startTime;
            this.getDiagnosticsReqData.startTimeD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.getDiagnosticsReqData.startTimeT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);

            temp_date = this.getDiagnosticsReqData.stopTime;
            this.getDiagnosticsReqData.stopTimeD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.getDiagnosticsReqData.stopTimeT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);
          } else if (this.select.protocol === "CertificateSigned") {
            this.certSignedReqData = res.data;
            console.log(this.certSignedReqData);
          } else if (this.select.protocol === "DeleteCertificate") {
            this.delCertReqData = res.data;
            console.log(this.delCertReqData);
          } else if (this.select.protocol === "ExtendedTriggerMessage") {
            this.extTriggerMessageReqData = res.data;
            console.log(this.extTriggerMessageReqData);
          } else if (this.select.protocol === "GetInstalledCertificateIds") {
            this.getInstCertIdsReqData = res.data;
            console.log(this.getInstCertIdsReqData);
          } else if (this.select.protocol === "GetLog") {
            this.getLogReqData = res.data;
            console.log(this.getLogReqData);

            let temp_date = this.getLogReqData.log.oldestTimestamp;
            this.getLogReqData.log.oldestTimestampD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.getLogReqData.log.oldestTimestampT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);

            temp_date = this.getLogReqData.log.latestTimestamp;
            this.getLogReqData.log.latestTimestampD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.getLogReqData.log.latestTimestampT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);
          } else if (this.select.protocol === "InstallCertificate") {
            this.installCertificateReqData = res.data;
            console.log(this.installCertificateReqData);
          } else if (this.select.protocol === "SignedUpdateFirmware") {
            this.signedUpdateFirmwareReqData = res.data;
            console.log(this.signedUpdateFirmwareReqData);

            let temp_date = this.signedUpdateFirmwareReqData.firmware.retrieveDateTime;
            this.signedUpdateFirmwareReqData.firmware.retrieveDateTimeD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.signedUpdateFirmwareReqData.firmware.retrieveDateTimeT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);

            temp_date = this.signedUpdateFirmwareReqData.firmware.installDateTime;
            this.signedUpdateFirmwareReqData.firmware.installDateTimeD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.signedUpdateFirmwareReqData.firmware.installDateTimeT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);
          } else if (this.select.protocol === "Authorize") {
            this.authorizeConData = res.data;
            console.log(this.authorizeConData);

            let temp_date = this.authorizeConData.idTagInfo.expiryDate;
            this.authorizeConData.idTagInfo.expiryDateD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.authorizeConData.idTagInfo.expiryDateT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);
          } else if (this.select.protocol === "BootNotification") {
            this.bootNotificationConData = res.data;
            console.log(this.bootNotificationConData);

            let temp_date = this.bootNotificationConData.currentTime;
            this.bootNotificationConData.currentTimeD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.bootNotificationConData.currentTimeT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);
          } else if (this.select.protocol === "DataTransfer") {
            this.dataTransferConData = res.data;
            console.log(this.dataTransferConData);
          } else if (this.select.protocol === "Heartbeat") {
            this.heartbeatConData = res.data;
            console.log(this.heartbeatConData);

            let temp_date = this.heartbeatConData.currentTime;
            this.heartbeatConData.currentTimeD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.heartbeatConData.currentTimeT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);
          } else if (this.select.protocol === "MeterValues") {
            //let file_name = "AuthorizeConfirmation";
            //this.getJsonFile(file_name);
          } else if (this.select.protocol === "StartTransaction") {
            this.startTransactionConData = res.data;
            console.log(this.startTransactionConData);

            let temp_date = this.startTransactionConData.idTagInfo.expiryDate;
            this.startTransactionConData.idTagInfo.expiryDateD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.startTransactionConData.idTagInfo.expiryDateT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);
          } else if (this.select.protocol === "StopTransaction") {
            this.stopTransactionConData = res.data;
            console.log(this.stopTransactionConData);

            let temp_date = this.stopTransactionConData.idTagInfo.expiryDate;
            this.stopTransactionConData.idTagInfo.expiryDateD = temp_date.substring(0,4) + "-" + temp_date.substring(4,6) + "-" + temp_date.substring(6,8);
            this.stopTransactionConData.idTagInfo.expiryDateT = temp_date.substring(8,10) + ":" + temp_date.substring(10,12) + ":" + temp_date.substring(12,14);
          } else if (this.select.protocol === "StatusNotification") {
            //let file_name = "AuthorizeConfirmation";
            //this.getJsonFile(file_name);
          } else if (this.select.protocol === "SignCertificate") {
            this.signCertificateConfData = res.data;
            console.log(this.signCertificateConfData);
          }
        }
      ).catch((res) => {    
          // 실패했을 경우    
          console.error("실패 ", res);
          //window.alert("저장이 실패했습니다.");
        }
      );
    },
    changeTab(e) {
      let tab_index = this.tabIndex;
      if (tab_index === 1) {
        this.select.protocol = "changeAvailability";
        let file_name = "ChangeAvailabilityRequest";
        this.getJsonFile(file_name);
      } else if (tab_index === 0) {
        this.select.protocol = "Authorize";
        let file_name = "AuthorizeConfirmation";
        this.getJsonFile(file_name);
      }
    },
    async sessionSearch() {
      const json_data = {};

      await this.$axios.post(
            "/auth-api/sessions",
            json_data
            // config
      ).then((res) => {
          console.log(res);
          this.sessionOptions = res.data;
          if (res.data.length > 0) {
            window.alert("조회가 완료되었습니다.");
          } else {
            window.alert("연결된 충전기가 없습니다.");
          }
        }
      ).catch((res) => {    
          // 실패했을 경우    
          console.error("실패 ", res);
          window.alert("연결된 충전기가 없습니다.");
        }
      );
/*
      if (data.status.code === "0000") {
            console.log(data);
      } else {

      };
      */
    },
    async fileSave() {
      const formData = new FormData();

      if (this.select.protocol === "changeAvailability") {
        this.changeAvailabilityReqData.fileName = "ChangeAvailabilityRequest";
        this.json_data = this.changeAvailabilityReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "getConfiguration") {
        this.getConfigurationReqData.fileName = "GetConfigurationRequest";
        this.json_data = this.getConfigurationReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "changeConfiguration") {
        this.changeConfigurationReqData.fileName = "ChangeConfigurationRequest";
        this.json_data = this.changeConfigurationReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "clearCache") {
        
      } else if (this.select.protocol === "dataTransfer") {
        this.dataTransferReqData.fileName = "DataTransferRequest";
        this.json_data = this.dataTransferReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "remoteStartTransaction") {
        this.remoteStartTransactionReqData.fileName = "RemoteStartTransactionRequest";
        this.json_data = this.remoteStartTransactionReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "remoteStopTransaction") {
        this.remoteStopTransactionReqData.fileName = "RemoteStopTransactionRequest";
        this.json_data = this.remoteStopTransactionReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "reset") {
        this.resetConData.fileName = "ResetConfirmation";
        this.json_data = this.resetConData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "unlockConnector") {
        this.unlockConnectorReqData.fileName = "UnlockConnectorRequest";
        this.json_data = this.unlockConnectorReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "updateFirmware") {
        this.updateFirmwareReqData.fileName = "UpdateFirmwareRequest";
        this.json_data = this.changeAvailabilityReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "sendLocalList") {
        this.sendLocalListData.fileName = "SendLocalList";
        this.json_data = this.sendLocalListData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "getLocalListVersion") {
        
      } else if (this.select.protocol === "smartChargingSetProfile") {
        this.setChargingProfileReqData.fileName = "SetChargingProfileRequest";
        this.json_data = this.setChargingProfileReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "smartChargingClearProfile") {
        this.clearChargingProfileReqData.fileName = "ClearChargingProfileRequest";
        this.json_data = this.clearChargingProfileReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "smartChargingCompositeSchedule") {
        this.getCompositeScheduleReqData.fileName = "GetCompositeScheduleRequest";
        this.json_data = this.getCompositeScheduleReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "reservNow") {
        this.reserveNowReqData.fileName = "ReserveNowRequest";
        this.json_data = this.reserveNowReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "reservCancel") {
        this.cancelReservationReqData.fileName = "CancelReservationRequest";
        this.json_data = this.cancelReservationReqData;
      } else if (this.select.protocol === "remoteTrigger") {
        this.triggerMessageReqData.fileName = "TriggerMessageRequest";
        this.json_data = this.triggerMessageReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "getDiagnostics") {
        this.getDiagnosticsReqData.fileName = "GetDiagnosticsRequest";
        this.json_data = this.getDiagnosticsReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "CertificateSigned") {
        this.certSignedReqData.fileName = "CertificateSignedRequest";
        this.json_data = this.certSignedReqData;
      } else if (this.select.protocol === "DeleteCertificate") {
        this.delCertReqData.fileName = "DeleteCertificateRequest";
        this.json_data = this.delCertReqData;
      } else if (this.select.protocol === "ExtendedTriggerMessage") {
        this.extTriggerMessageReqData.fileName = "ExtendedTriggerMessageRequest";
        this.json_data = this.extTriggerMessageReqData;
      } else if (this.select.protocol === "GetInstalledCertificateIds") {
        this.getInstCertIdsReqData.fileName = "GetInstalledCertificateIdsRequest";
        this.json_data = this.getInstCertIdsReqData;
      } else if (this.select.protocol === "GetLog") {
        this.getLogReqData.fileName = "GetLogRequest";
        this.json_data = this.getLogReqData;
      } else if (this.select.protocol === "InstallCertificate") {
        this.installCertificateReqData.fileName = "InstallCertificateRequest";
        this.json_data = this.installCertificateReqData;
      } else if (this.select.protocol === "SignedUpdateFirmware") {
        this.signedUpdateFirmwareReqData.fileName = "SignedUpdateFirmwareRequest";
        this.json_data = this.signedUpdateFirmwareReqData;
      } else if (this.select.protocol === "Authorize") {
        this.authorizeConData.fileName = "AuthorizeConfirmation";
        this.json_data = this.authorizeConData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "BootNotification") {
        this.bootNotificationConData.fileName = "BootNotificationConfirmation";
        this.json_data = this.bootNotificationConData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "DataTransfer") {
        this.dataTransferConData.fileName = "DataTransferConfirmation";
        this.json_data = this.dataTransferConData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "Heartbeat") {
        this.heartbeatConData.fileName = "HeartbeatConfirmation";
        this.json_data = this.heartbeatConData;
      } else if (this.select.protocol === "MeterValues") {
        
      } else if (this.select.protocol === "StartTransaction") {
        this.startTransactionConData.fileName = "StartTransactionConfirmation";
        this.json_data = this.startTransactionConData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "StopTransaction") {
        this.stopTransactionConData.fileName = "StopTransactionConfirmation";
        this.json_data = this.stopTransactionConData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "StatusNotification") {
        
      } else if (this.select.protocol === "SignCertificate") {
        this.signCertificateConfData.fileName = "SignCertificateConfirmation";
        this.json_data = this.signCertificateConfData;
      }

      await this.$axios.post(
            "/auth-api/jsonFileSave",
            this.json_data
            // config
      ).then((res) => {
          console.log(res);
          window.alert("저장이 완료되었습니다.");
        }
      ).catch((res) => {    
          // 실패했을 경우    
          console.error("실패 ", res);
          window.alert("저장이 실패했습니다.");
        }
      );
    },
    async send() {
      const formData = new FormData();

      if (this.select.protocol === "changeAvailability") {
        this.callName = "changeAvailability";
        this.changeAvailabilityReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.changeAvailabilityReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "getConfiguration") {
        this.callName = "getConfiguration";
        this.getConfigurationReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.getConfigurationReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "changeConfiguration") {
        this.callName = "changeConfiguration";
        this.changeConfigurationReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.changeConfigurationReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "clearCache") {
        this.callName = "clearCache";
        let no_data = {};
        no_data.sessionIndex = this.sessionIndex;
        this.json_data = no_data;
      } else if (this.select.protocol === "dataTransferReq") {
        this.callName = "dataTransfer";
        this.dataTransferReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.dataTransferReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "remoteStartTransaction") {
        this.callName = "remoteStartTransaction";
        this.remoteStartTransactionReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.remoteStartTransactionReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "remoteStopTransaction") {
        this.callName = "remoteStopTransaction";
        this.remoteStopTransactionReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.remoteStopTransactionReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "reset") {
        this.callName = "reset";
        this.resetConData.sessionIndex = this.sessionIndex;
        this.json_data = this.resetConData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "unlockConnector") {
        this.callName = "unlockConnector";
        this.unlockConnectorReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.unlockConnectorReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "updateFirmware") {
        this.callName = "UpdateFirmware";
        this.updateFirmwareReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.updateFirmwareReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "sendLocalList") {
        this.callName = "SendLocalList";
        this.sendLocalListData.sessionIndex = this.sessionIndex;
        this.json_data = this.sendLocalListData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "getLocalListVersion") {
        this.callName = "GetLocalListVersion";
        let no_data = {};
        no_data.sessionIndex = this.sessionIndex;
        this.json_data = no_data;
      } else if (this.select.protocol === "smartChargingSetProfile") {
        this.callName = "SmartChargingSetProfile";
        this.setChargingProfileReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.setChargingProfileReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "smartChargingClearProfile") {
        this.callName = "SmartChargingClearProfile";
        this.clearChargingProfileReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.clearChargingProfileReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "smartChargingCompositeSchedule") {
        this.callName = "SmartChargingCompositeSchedule";
        this.getCompositeScheduleReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.getCompositeScheduleReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "reservNow") {
        this.callName = "ReservNow";
        this.reserveNowReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.reserveNowReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "reservCancel") {
        this.callName = "ReservCancel";
        this.cancelReservationReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.cancelReservationReqData;
      } else if (this.select.protocol === "remoteTrigger") {
        this.callName = "RemoteTrigger";
        this.triggerMessageReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.triggerMessageReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "getDiagnostics") {
        this.callName = "GetDiagnostics";
        this.getDiagnosticsReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.getDiagnosticsReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "CertificateSigned") {
        this.callName = "CertificateSigned";
        this.certSignedReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.certSignedReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "DeleteCertificate") {
        this.callName = "DeleteCertificate";
        this.delCertReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.delCertReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "ExtendedTriggerMessage") {
        this.callName = "ExtendedTriggerMessage";
        this.extTriggerMessageReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.extTriggerMessageReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "GetInstalledCertificateIds") {
        this.callName = "GetInstalledCertificateIds";
        this.getInstCertIdsReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.getInstCertIdsReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "GetLog") {
        this.callName = "GetLog";
        this.getLogReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.getLogReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "InstallCertificate") {
        this.callName = "InstallCertificate";
        this.installCertificateReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.installCertificateReqData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "SignedUpdateFirmware") {
        this.callName = "SignedUpdateFirmware";
        this.signedUpdateFirmwareReqData.sessionIndex = this.sessionIndex;
        this.json_data = this.signedUpdateFirmwareReqData;
        //formData.append("json_str", json_data);
      }/* else if (this.select.protocol === "Authorize") {
        this.callName = "Authorize";
        this.authorizeConData.sessionIndex = this.sessionIndex;
        this.json_data = this.authorizeConData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "BootNotification") {
        this.callName = "BootNotification";
        this.bootNotificationConData.sessionIndex = this.sessionIndex;
        this.json_data = this.bootNotificationConData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "DataTransfer") {
        this.callName = "DataTransfer";
        this.dataTransferConData.sessionIndex = this.sessionIndex;
        this.json_data = this.dataTransferConData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "Heartbeat") {
        this.callName = "Heartbeat";
        
      } else if (this.select.protocol === "MeterValues") {
        this.callName = "MeterValues";
        
      } else if (this.select.protocol === "StartTransaction") {
        this.callName = "StartTransaction";
        this.startTransactionConData.sessionIndex = this.sessionIndex;
        this.json_data = this.startTransactionConData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "StopTransaction") {
        this.callName = "StopTransaction";
        this.stopTransactionConData.sessionIndex = this.sessionIndex;
        this.json_data = this.stopTransactionConData;
        //formData.append("json_str", json_data);
      } else if (this.select.protocol === "StatusNotification") {
        this.callName = "StatusNotification";
        
      }*/

      await this.$axios.post(
            "/auth-api/"+this.callName,
            this.json_data
            // config
      ).then((res) => {
          console.log(res);
          this.result_text = JSON.stringify(res.data);
          window.alert("호출에 성공했습니다.");
        }
      ).catch((res) => {    
          // 실패했을 경우    
          console.error("실패 ", res);
          window.alert("호출에 실패했습니다.");
        }
      );     
    },
    addRStartTranCell() {
      this.remoteStartTransactionReqData.chargingProfile.chargingSchedule.chargingSchedulePeriod.push({"startPeriod": 0, "limit": 0.0, "numberPhases": 0});
    },
    removeRStartTranCell(data, index, e) {
      console.log(index);
      if (this.remoteStartTransactionReqData.chargingProfile.chargingSchedule.chargingSchedulePeriod.length > 1) {
        this.remoteStartTransactionReqData.chargingProfile.chargingSchedule.chargingSchedulePeriod.splice(index);
      } else {
        window.alert("하나 이상의 데이터는 존재해야 합니다.");
      }
    },
    addSChgProfileCell() {
      this.setChargingProfileReqData.csChargingProfiles.chargingSchedule.chargingSchedulePeriod.push({"startPeriod": 0, "limit": 0.0, "numberPhases": 0});
    },
    removeSChgProfileCell(data, index, e) {
      console.log(index);
      if (this.setChargingProfileReqData.csChargingProfiles.chargingSchedule.chargingSchedulePeriod.length > 1) {
        this.setChargingProfileReqData.csChargingProfiles.chargingSchedule.chargingSchedulePeriod.splice(index);
      } else {
        window.alert("하나 이상의 데이터는 존재해야 합니다.");
      }
    },
    modalShow(item) {
      this.$bvModal.show("finCompany");
    },
  },
  async mounted() {
    $("#gnb").hide();
    $("#footer").hide();

    // await this.getBidding();
    // await this.counting();
    // await this.getbestCompany();
    let file_name = "ChangeAvailabilityRequest";
    this.getJsonFile(file_name);
  },
};
</script>

<style lang="scss" scoped>
.hide {
  display: none !important;
}
.show {
  display: inline-block !important;
}
.home-container {
  .main-bg {
    position: relative;
    &::before {
      content: "";
      background-image: url("../assets/images/Home/pattern_1.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      animation: 50s linear infinite alternate-reverse rotate;
    }
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
  .tab_btn {
    width: 250px;
    color: white;
    border: solid 2px rgba(255, 242, 217, 0.3);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .default_btn {
    border-radius: 0.625rem;
    font-weight: 700;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
}
</style>
