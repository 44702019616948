<template>
  <div class= "d-flex" style="width:100%; height: 100%; flex-direction: column; align-items: center;">
    <div style="width: 100%; height: 200px; flex-direction: column; padding-left: 10px; padding-right: 10px;">
      <div class="d-flex align-items-center justify-content-center" style="height: 100px;">
        <b-navbar-brand
          href="/dashboard"
          class="position-absolute"
        >
          <img
            src="@/assets/logo.svg"
            alt="홈뱅크 로고"
            :style="{ width: 'auto', height: '80px' }"
          />
        </b-navbar-brand>
      </div>
      <div class="d-flex align-items-center justify-content-center" style="height: 100px;">
        <div>
          <span style="margin-right: 10px;">관리자1</span>
          <span>관리자님 접속중</span>
        </div>
        <div>
          <b-button style="color: white; border-radius: 0.625rem; font-weight: 700; padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5;" @click="logout()">로그아웃</b-button>          
        </div>
      </div>
    </div>
    <div style="overflow: auto;" >
      <section class="my-3 px-4 px-md-5">
        <div v-for="(item, i) in content" :key="i">
          <b-btn
            variant="outline-light-gray bg-transparent w-100 py-3 rounded-0 text-darkest d-flex align-items-center"
            v-b-toggle="`collapse-building-${i}`"
            @click="toggle(i)"
          >
            <span class="text-13 text-lg-15">{{ item.category }}</span>
            <i
              class="icon icon-16 icon-caret-collapse ml-auto"
              :class="{ active: current === i }"
            />
          </b-btn>
          <b-collapse
            :id="`collapse-building-${i}`"
            class="bg-gray-fa px-4"
            accordion="collapse-building"
          >
            <div class="py-3">
              <ul class="list-unstyled m-0 p-0">
                <li
                  class="py-2 d-flex align-items-center justify-content-between"
                  v-for="(item2, j) in item.list"
                  :key="j"
                  :class="
                    j === item.list.length - 1
                      ? ''
                      : 'mb-2 border-bottom border-dark-gray'
                  "
                  @click="clickMenu(item2.value)"
                >
                  <span
                    class="text-darkest fw-300 text-13 text-lg-14 ml-0 mr-auto text-nowrap"
                  >
                    {{ item2.key }}
                  </span>
                </li>
              </ul>
            </div>
          </b-collapse>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import router from '../../router';

export default {
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  data() {
    return {
      current: null,
      test : null,
      active:null,
      content: [
        {
          category: "Dashboard",
          list: [
            { key: "대시보드 바로가기", value: "/dashboard/dashboard" },
          ],
        },
        {
          category: "충전소/충전기 관리",
          list: [
            { key: "충전소 관리", value: "/charger/station_list" },
            { key: "충전기 관리", value: "/charger/charger_list" },
            { key: "충전기 상태정보", value: "/charger/charger_status" },
            { key: "사용현황", value: "/charger/charge_use_status" },
            { key: "단말기 관리", value: "/charger/reader_list" },
            { key: "충전기 모델 관리", value: "/charger/charger_model_list" },
          ],
        },
        {
          category: "회원관리",
          list: [
            { key: "회원 목록", value: "/user/user_list" },
            { key: "법인 목록", value: "/user/company_list" },
            { key: "회원카드 관리", value: "/user/user_card_list" },
            { key: "회원카드 발급관리", value: "/user/user_card_issuance" },
            { key: "공카드 발급관리", value: "/user/user_card_noMatch" },
          ],
        },
        {
          category: "로밍 관리",
          list: [
            { key: "충전기 목록", value: "/roming/roming_charger_list" },
            { key: "회원카드 목록", value: "/roming/roming_card_list" },
            { key: "충전내역 관리", value: "/roming/roming_charging_history" },
            { key: "충전기 사용내역", value: "/roming/roming_payment_history" },
          ],
        },
        {
          category: "결제 관리",
          list: [
            { key: "충전 내역", value: "/payment/charging_history" },
            { key: "결제 내역", value: "/payment/payment_history" },
            { key: "정산 관리", value: "/payment/calculate" }
          ],
        },
        {
          category: "장애 관리",
          list: [
            { key: "충전기 파손신고", value: "/issue/damage_report" },
            { key: "충전기 정비관리", value: "/issue/maintenance" },
            { key: "점검 스케쥴 내역", value: "/issue/as_schedule" }
          ],
        },
        {
          category: "고객센터",
          list: [
            { key: "상담 내역", value: "/customer/consulting_list" },
            { key: "공지사항", value: "/customer/notice" },
            { key: "QnA 관리", value: "/customer/qna" },
            { key: "FAQ 관리", value: "/customer/faq" }
          ],
        },
        {
          category: "운영 관리",
          list: [
            {
              key: "약관 관리", value: "/operation/term",
            },
            {
              key: "메시지 관리", value: "/operation/message",
            },
          ],
        },
        {
          category: "관리자 관리",
          list: [
            {
              key: "메뉴 관리", value: "/admin/menu_set",
            },
            {
              key: "관리자 관리", value: "/admin/admin_list",
            },
            {
              key: "코드 관리", value: "/admin/code_set",
            },
            {
              key: "전력량 요금 관리", value: "/admin/charge_set",
            },
            {
              key: "펌웨어 관리", value: "/admin/fw_ver_set",
            },
            {
              key: "차량 관리", value: "/admin/car_info",
            },
            {
              key: "메시지 폼 관리", value: "/admin/message_form",
            },
            {
              key: "설정값 관리", value: "/admin/enc_set",
            },
            {
              key: "업체 관리", value: "/admin/admin_company_list",
            },
            {
              key: "펌웨어 업데이트 관리", value: "/admin/fw_update_history",
            },
          ],
        },
        {
          category: "로그 관리",
          list: [
            {
              key: "사용자 접속 로그", value: "/log/user_conn",
            },
            {
              key: "관리자 접속 로그", value: "/log/admin_conn",
            },
            {
              key: "관리자 명령 로그", value: "/log/admin_use_list",
            },
            {
              key: "OCPP 인증 로그", value: "/log/ocpp_auth",
            },
            {
              key: "OCPP 부팅 로그", value: "/log/ocpp_boot",
            },
            {
              key: "OCPP 메타벨류 로그", value: "/log/ocpp_meta_value",
            },
            {
              key: "OCPP 접속 로그", value: "/log/ocpp_conn",
            },
            {
              key: "OCPP 충전 로그", value: "/log/ocpp_tran",
            },
            {
              key: "OCPP 충전기 연결신호 로그", value: "/log/ocpp_heartbeat",
            },
            {
              key: "OCPP 충전기 상태 로그", value: "/log/ocpp_status",
            },
            {
              key: "OCPP 데이터 전송 로그", value: "/log/ocpp_data_tran",
            },
            {
              key: "OCPP 충전기 목록", value: "/log/ocpp_charger",
            },
            {
              key: "OCPP 충전 시작 로그", value: "/log/ocpp_start_tran",
            },
            {
              key: "OCPP 충전 정지 로그", value: "/log/ocpp_stop_tran",
            },
            {
              key: "OCPP 원격 충전 시작 로그", value: "/log/ocpp_rstart_tran",
            },
            {
              key: "OCPP 원격 충전 정지 로그", value: "/log/ocpp_rstop_tran",
            },
            {
              key: "OCPP 결제 NOTI 로그", value: "/log/ocpp_payment_noti",
            },
            {
              key: "펌웨어 업데이트 로그", value: "/log/fw_status",
            },
          ],
        },
        {
          category: "통계",
          list: [
            {
              key: "운영실적 월별 통계", value: "/statistics/operating_statistics",
            },
            {
              key: "충전기 이용 통계", value: "/statistics/cp_statistics",
            },
            {
              key: "충전소 이용 통계", value: "/statistics/station_statistics",
            },
          ],
        },
      ],
    }
  },
  watch: {
    $route(newValue) {
      this.toggle = false;
    },
  },
  methods: {
    toggle(idx) {
      if (this.current !== null && this.current === idx) {
        this.current = null;
      } else {
        this.current = idx;
        let move_url = this.content[idx].list[0].value;
        router.push({ path: move_url });
      }
    },
    clickMenu(select_url) {
      //let page_path = "";
      router.push({ path: select_url })
        .catch(()=>{
          router.go(router.currentRoute);
        });
    },
  },
  async mounted() {
    this.toggle(0);
  },
};
</script>

<style lang="scss" scoped>
.headerMenu {
  .nav-item {
    position: relative;
    &::after {
      content: "";
      width: 0%;
      height: 4px;
      position: absolute;
      background: #1a49b9;
      bottom: 5px;
      transition: all 0.2s ease-in-out;
    }
    &:hover,
    &.active {
      &::after {
        width: 100%;
      }
    }
    .nav-link {
      display: block;
      height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .default_btn {
    border-radius: 0.625rem;
    font-weight: 700;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
}
</style>
