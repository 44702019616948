<template>
  <b-modal
    id="modal-card-reIssuance"
    :title="obj.title"
    :ok-title="obj.ok_title"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="primary m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="xl"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div>
        <b-row>
          <b-col lg="2" class="mb-2">
            <div>
              <span class="text-17">
                <font-awesome-icon />충전소 ID
              </span>
            </div>
          </b-col>
          <b-col lg="10" class="mb-2">
            <div>
              <b-form-input size="sm" type=number v-model="stationData.sid"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="2" class="mb-2">
            <div>
              <span class="text-17">
                <font-awesome-icon />충전소 이름
              </span>
            </div>
          </b-col>
          <b-col lg="10" class="mb-2">
            <div>
              <b-form-input size="sm" v-model="stationData.name"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="2" class="mb-2">
            <div>
              <span class="text-17">
                <font-awesome-icon />충전소 위치
              </span>
            </div>
          </b-col>
          <b-col lg="10" class="mb-2">
            <div>
              <b-form-input readonly size="sm" v-model="stationData.addr"></b-form-input>
              <b-button class="mx-1 my-1 tab_btn" @click="getPostCode">검색</b-button>
            </div>
            <div>
              <b-form-input size="sm" v-model="stationData.addr_dtl"></b-form-input>
              <b-button class="mx-1 my-1 tab_btn" @click="callMap">지도보기</b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="2" class="mb-2">
            <div>
              <span class="text-17">
                <font-awesome-icon />충전소 구분
              </span>
            </div>
          </b-col>
          <b-col lg="10" class="mb-2">
            <div>
              <b-form-select size="sm" v-model="stationData.kind" :options="kindOptions" @change="changeKind($event)"></b-form-select>
              <b-form-select size="sm" v-model="stationData.kind_dtl" :options="kindDtlOptions"></b-form-select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="2" class="mb-2">
            <div>
              <span class="text-17">
                <font-awesome-icon />이용가능 시간
              </span>
            </div>
          </b-col>
          <b-col lg="10" class="mb-2">
            <div>
              <b-form-select size="sm" v-model="stationData.use_time" :options="useTimeOptions"></b-form-select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="2" class="mb-2">
            <div>
              <span class="text-17">
                <font-awesome-icon />운영기관
              </span>
            </div>
          </b-col>
          <b-col lg="10" class="mb-2">
            <div>
              <b-form-select size="sm" v-model="stationData.bid" :options="bnameOptions" @change="changeBid($event)"></b-form-select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="2" class="mb-2">
            <div>
              <span class="text-17">
                <font-awesome-icon />사업자등록번호
              </span>
            </div>
          </b-col>
          <b-col lg="10" class="mb-2">
            <div>
              <b-form-input size="sm" type=number v-model="stationData.bis_id"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" class="mb-2">
            <div>
              <span class="text-17">
                <font-awesome-icon />예약
              </span>
              <b-form-select size="sm" v-model="stationData.reserv_yn" :options="reservOptions"></b-form-select>
            </div>
          </b-col>
          <b-col lg="6" class="mb-2">
            <div>
              <span class="text-17">
                <font-awesome-icon />이용제한
              </span>
              <b-form-select size="sm" v-model="stationData.limit_yn" :options="limitOptions"></b-form-select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="2" class="mb-2">
            <div>
              <span class="text-17">
                <font-awesome-icon />주차비
              </span>
            </div>
          </b-col>
          <b-col lg="10" class="mb-2">
            <div>
              <b-form-input size="sm" v-model="stationData.free_dtl"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="2" class="mb-2">
            <div>
              <span class="text-17">
                <font-awesome-icon />충전요금
              </span>
            </div>
          </b-col>
          <b-col lg="10" class="mb-2">
            <div>
              <b-form-select size="sm" v-model="stationData.fee_id" :options="feeOptions"></b-form-select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="2" class="mb-2">
            <div>
              <span class="text-17">
                <font-awesome-icon />특이사항
              </span>
            </div>
          </b-col>
          <b-col lg="10" class="mb-2">
            <div>
              <b-form-input size="sm" v-model="stationData.note"></b-form-input>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="pt-3">
      <div ref="addr" />
    </div>
  </b-modal>
</template>

<script>
import KakaoMap from "@/components/KakaoMap.vue";

export default {
  components: {
    KakaoMap,
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      edit_type: String,
      title: String,
      ok_title: String,
      station_data: Object,
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      stationData: [],
      kindListData: [],
      kindDtlListData: [],
      kindOptions: [],
      kindDtlOptions: [],
      useTimeOptions: [
        { value: "24", text: "24시간 이용가능" },
        { value: "12", text: "12시간 이용가능" }
      ],
      bnameOptions: [],
      reservOptions: [
        { value: "Y", text: "가능" },
        { value: "N", text: "불가능" }
      ],
      limitOptions: [
        { value: "Y", text: "전체이용" },
        { value: "N", text: "거주자외 출입제한" }
      ],
      feeListData: [],
      feeOptions: [],
      addressObj: {},
      wrap: null,
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.selsctkindList();
        this.wrap = this.$refs.addr;

        if (this.obj.edit_type === "add") {
          this.stationData = this.obj.station_data;
        }
      });
    },
    async selsctkindList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCode", {
            use_yn: "Y", 
            type: "STC"
          }
        );
        if (data.length > 0) {
          this.kindListData = data.filter(
            function (e) {
              if (e.level == 1) {
                return true;
              }
            }
          )

          this.kindDtlListData = data.filter(
            function (e) {
              if (e.level == 2) {
                return true;
              }
            }
          )

          let options = [];

          this.kindListData.forEach((e,i) => {
            let add_option = { value: e.code, text: e.code_name }
            options.push(add_option);
          });

          this.kindOptions = options;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.stationData.kind = this.kindOptions[0].value;
          }
        } else if (data.length == 0) {
          this.kindOptions = null;
          this.kindDtlOptions = null;
        }

        this.selectCompanyList();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectCompanyList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCompanyList", {
            type : "S",
            use_yn : "Y"
          }
        );
        if (data.length > 0) {
          let options = [];

          data.forEach((e,i) => {
            let add_option = { value: e.company_id, text: e.name }
            options.push(add_option);
          });

          this.bnameOptions = options;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.stationData.bid = this.bnameOptions[0].value;
          }
        } else if (data.length == 0) {
          this.bnameOptions = null;
        }

        this.selectFeeList();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectFeeList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectFeeList", {
            use_yn : "Y"
          }
        );
        if (data.length > 0) {
          this.feeListData = data.filter(
            (e, i, callback) =>
              i === callback.findIndex(
                (e1) => e1.cf_id === e.cf_id
              )
          );
        
          let options = [];

          let select_bid = "";

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            select_bid = this.bnameOptions[0].value;
          } else {
            select_bid = this.stationData.bid;
          }

          this.feeListData.forEach((e,i) => {
            if (e.bid === select_bid) {
              let add_option = { value: e.cf_id, text: e.cf_title }
              options.push(add_option);
            }
          });

          this.feeOptions = options;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.stationData.fee_id = this.feeOptions[0].value;
          }
        } else if (data.length == 0) {
          this.feeOptions = null;
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async ok() {
      try {
        var type_url = "";

        if (this.obj.edit_type === "detail") {
          this.$bvModal.hide("modal-station-detail");
        } else {
          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            type_url = "/api/addStation";
          } else if (this.obj.edit_type === "edit") {
            type_url = "/api/updateStation";
          }

          const { data } = await this.$axios.post(
            type_url, {
              items: excelJsonData
            }
          );

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            window.alert("충전소 정보가 등록되었습니다.");
          } else if (this.obj.edit_type === "edit") {
            window.alert("충전소 정보가 수정되었습니다.");
          }

          this.$bvModal.hide("modal-station-detail");
        }
        //this.$root.$emit("hb::pdf::hidden", true);
      }  catch (error) {
        if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
          window.alert("충전소 정보 등록을 실패했습니다.");
        } else if (this.obj.edit_type === "edit") {
          window.alert("충전소 정보 수정을 실패했습니다.");
        }

        this.$bvModal.hide("modal-excel-upload");
        console.log(error);
      }
    },
    changeKind (select_kind) {
      this.kindDtlListData.forEach((e,i) => {
        let options = [];
        if (e.r_code === select_kind) {
          let add_option = { value: e.code, text: e.code_name };
          options.push(add_option);
        }

        this.kindDtlOptions = options;
      });
    },
    changeBid (select_bid) {
      let options = [];

      if (select_bid === "") {
        select_bid = this.bnameOptions[0].value;
      }

      this.feeListData.forEach((e,i) => {
        if (e.bid === select_bid) {
          let add_option = { value: e.cf_id, text: e.cf_title }
          options.push(add_option);
        }
      });

      this.feeOptions = options;
    },
    setData(select_rowData) {
      this.stationData = select_rowData;

      if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
        this.stationData.kind = this.kindOptions[0].value;
        this.stationData.bid = this.bnameOptions[0].value;
        this.stationData.fee_id = this.feeOptions[0].value;
      }

      this.changeBid(this.stationData.bid);
    },
    callMap () {

    },
    foldDaumzonecode() {
      // iframe을 넣은 element를 안보이게 한다.
      this.wrap.style.display = "none";
    },
    getPostCode() {
      // 현재 scroll 위치를 저장해놓는다.
      var currentScroll = Math.max(
        document.body.scrollTop,
        document.documentElement.scrollTop
      );
      new daum.Postcode({
        //autoMappingJibun: false, //연관주소가 지번일 때 지번 주소 자동매핑을 막음
       // autoMappingRoad: false, //연관주소가 도로명일 때  주소 자동매핑을 막음

        oncomplete: (data) => {
          console.log(data);
          // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          var addr = ""; // 주소 변수
          var extraAddr = ""; // 참고항목 변수

          //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === "R") {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            // 조합된 참고항목을 해당 필드에 넣는다.
          }
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          //===================================================================
          //jibunAddressEnglish 또는 autoJibunAddressEnglish에 랜덤으로 값이 담겨 필터링 추가함
          let jibun = "";

          if (data.autoJibunAddressEnglish === "") {
            jibun = data.jibunAddressEnglish.split(",")[0];
          } else {
            jibun = data.autoJibunAddressEnglish.split(",")[0];
          }

          //====================================================================
          this.add = jibun;
          this.addressObj = {
            gps_x: "",
            gps_y: "",
            zipNo: data.zonecode,
            address: `${addr} ${extraAddr}`,
            sido: data.sido,
            jibunAddress:
              data.jibunAddress !== ""
                ? data.jibunAddress
                : data.autoJibunAddress,
            roadAddress:
              data.roadAddress != "" ? data.roadAddress : data.autoRoadAddress,
            addressDetailMeta: {
              bcode: data.bcode,
              bname: data.bname,
              bname1: data.bname1,
              buildingCode: data.buildingCode,
              buildingName: data.buildingName,
              bun: jibun.split("-")[0] || "",
              ji: jibun.split("-")[1] || "",
              sido: data.sido,
              sigungu: data.sigungu,
              sigunguCode: data.sigunguCode,
            },
          };

          this.stationData.addr = this.addressObj.address;
          
          const geocoder = new kakao.maps.services.Geocoder();

          let gps_x = "";
          let gps_y = "";

          // 주소로 좌표를 검색합니다
          geocoder.addressSearch(`${addr} ${extraAddr}`, function(result, status) {
            // 정상적으로 검색이 완료됐으면
            if (status === kakao.maps.services.Status.OK) {
              console.log("OK",result)
              const coords = new kakao.maps.LatLng(result[0].y, result[0].x);
              //const message = 'latlng: new kakao.maps.LatLng(' + result[0].y + ', ';
              //message += result[0].x + ')';

              gps_x = result[0].x;
              gps_y = result[0].y;
            }
          });

          this.stationData.gps = gps_x + "," + gps_y;
          this.addressObj.gps_x = gps_x;
          this.addressObj.gps_y = gps_y;
          // 커서를 상세주소 필드로 이동한다.
          // this.$refs.addrDetail.$el.focus();

          // iframe을 넣은 element를 안보이게 한다.
          // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
          this.wrap.style.display = "none";

          // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
          document.body.scrollTop = currentScroll;
        },

        // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
        // onresize: (size) => {
        //   this.wrap.style.height = size.height + "px";
        // },
        // width: "100%",
        // height: "100%",
      }).open({ autoClose: true }); //주소 선택시 팝업창 닫기
      // iframe을 넣은 element를 보이게 한다.
      // this.wrap.style.display = "block";
      // test(address);
    },
  },
};
</script>

<style lang="scss" scoped></style>
