<template>
  <div class="py-5">
    <b-container>
      <header class="mb-3">
        <h2 class="mb-2 text-28 text-lg-38">고객센터</h2>
        <span class="text-14 text-gray fw-400"
          >공지사항, 대출시 안내사항을 확인하실 수 있습니다.</span
        >
      </header>
      <notice />
    </b-container>
  </div>
</template>

<script>
import Notice from "@/components/Items/Notice.vue";
export default {
  components: { Notice },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
