<template>
  <div class="mb-4 w-100 d-flex flex-wrap" :style="{ gap: '0 1rem' }">
    <div v-for="(opt, i) in options" :key="i" class="pt-2">
      <b-form-checkbox
        :checked="value.includes(opt)"
        @input="inputEventListener(opt, $event)"
        class="text-17 text-lg-15 mb-2"
        :style="{ wordBreak: 'initial' }"
        :disabled="opt.disabled"
        :id="opt.value"
      >
        <!-- :class="[opt.disabled ? 'hide' : 'show']" -->
        {{ opt.text }}
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
    },
    title: {
      type: String,
    },
    options: {
      type: Array,
    },
  },
  methods: {
    inputEventListener(v, bool) {
      if (bool) {
        this.$emit("input", [...this.value, v]);
      } else {
        this.$emit(
          "input",
          this.value.filter((val) => val.value !== v.value)
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hide {
  display: none !important;
}
.show {
  display: inline-block !important;
}
</style>
