<template>
  <div class="mb-4 w-100">
    <b-row class="w-100 mx-0">
      <b-col
        :cols="cols"
        :md="md"
        v-for="(opt, i) in options"
        :key="i"
        class="pt-2"
      >
        <b-form-checkbox
          :checked="value.includes(opt)"
          @input="inputEventListener(opt, $event)"
          class="text-17 text-lg-15 mb-2"
          :style="{ wordBreak: 'initial' }"
        >
          {{ opt.text }}
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
    },
    title: {
      type: String,
    },
    options: {
      type: Array,
    },
    cols: {
      type: String,
      default: "6",
    },
    md: {
      type: String,
      default: "4",
    },
  },
  created() {
    // this.options.forEach(function (el) {
    //   this.$set(el, "disable", false);
    // }, this);
  },
  methods: {
    inputEventListener(v, bool) {
      if (bool) {
        this.$emit("input", [...this.value, v]);
        console.log(v.text);
        // this.options.forEach(function (el) {
        //   if (v.text !== el.text) {
        //     el.disable = false;
        //   }
        // }, this);
      } else {
        this.$emit(
          "input",
          this.value.filter((val) => val.value !== v.value)
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
