<template>
  <div class="layout">
    <b-row align-h="center" class="mx-lg-n5">
      <b-col cols="12" md="12" lg="12" xl="12">
        <b-row align-h="center" style="height: 100vh;" align-v="center">
          <b-col>
            <router-view/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
};
</script>
