<template>
  <div>
    <header class="mb-3">
      <h2 class="mb-2 text-28 text-lg-38">1:1 문의</h2>
      <span class="text-14 text-gray fw-400"
        >1:1 문의를 확인하실 수 있습니다.</span
      >
      <div class="mt-3 text-right">
        <b-btn variant="primary" to="/mypage/contact/inquiry/add"
          >새로 등록하기</b-btn
        >
      </div>
    </header>
    <article>
      <contact />
    </article>
  </div>
</template>

<script>
import Contact from "@/components/Items/Contact";
export default {
  components: {
    Contact,
  },
};
</script>

<style lang="scss" scoped></style>
