<template>
  <legend class="form-legend">
    <h6>
      {{ text }}
    </h6>
  </legend>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
