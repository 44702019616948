<template>
  <div class="my-5 py-5">
    <b-container class="py-5">
      <b-row>
        <b-col cols="12" md="5">
          <img
            :src="require('@/assets/images/Guidance/introduce.png')"
            alt="회사 소개"
            class="w-100 h-auto"
          />
        </b-col>
        <b-col cols="12" md="7" class="pt-3 pt-md-0">
          <header class="pt-4 mb-4">
            <h3 class="text-28 text-lg-38 mb-0 text-score-black text-primary">
              회사소개 한줄
            </h3>
          </header>
          <p class="text-14 text-lg-16 lh-175 font-weight-normal">
            비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나
            그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽
            요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종
            결과물에 들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인
            프로젝트 모형의 채움 글로도 이용된다. 이런 용도로 사용할 때 로렘
            입숨을 그리킹(greeking)이라고도 부르며, 때로 로렘 입숨은 공간만
            <br /><br />
            차지하는 무언가를 지칭하는 용어로도 사용된다. 로렘 입숨은 전통
            라틴어와 닮은 점 때문에 종종 호기심을 유발하기도 하지만 그 이상의
            의미를 담지는 않는다. 문서에서 텍스트가 보이면 사람들은 전체적인
            프레젠테이션보다는 텍스트에 담긴 뜻에 집중하는 경향이 있어서
            출판사들은 서체나 디자인을 보일 때는 프레젠테이션 자체에 초점을
            맞추기 위해 로렘 입숨을 사용한다.
          </p>
        </b-col>
      </b-row>

      <section class="my-5 py-5 overflow-hidden">
        <header class="mt-md-5 pt-md-5 pb-4">
          <h3 class="text-28 text-lg-38 mb-0 text-score-black text-primary">
            회사 비전
          </h3>
        </header>

        <div class="position-relative">
          <b-carousel
            class="position-relative"
            :perPage="1"
            :paginationEnabled="false"
            :autoplay="true"
            v-model="currentSlide"
            :loop="true"
          >
            <b-carousel-slide
              v-for="(item, i) in guides"
              :key="i"
              :img-src="item.src"
            />
          </b-carousel>
          <b-form-radio-group
            class="position-absolute radio-group-pagination d-flex radio-page"
            :style="{
              zIndex: '3',
              bottom: '16px',
              left: '50%',
              transform: 'translateX(-50%)',
            }"
            buttons
            button-variant="rounded-circle"
            ref="radioPagination"
            :options="guides"
            v-model="currentSlide"
          >
            <!-- <b-form-radio
              v-for="(item, i) in guides"
              :key="i"
              :value="i"
              v-model="currentSlide"
              class="radio-page"
              >{{ i + 1 }}</b-form-radio
            > -->
          </b-form-radio-group>
        </div>
        <p class="text-16 fw-400 text-center pt-4 mb-md-5 pb-md-5">
          비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나
          그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽 요소나
          시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종 결과물에
          들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인 프로젝트
          모형의 채움 글로도 이용된다. 이런 용도로 사용할 때 로렘 입숨을
          그리킹(greeking)이라고도 부르며, 때로 로렘 입숨은 공간만 차지하는
          무언가를 지칭하는 용어로도 사용된다.
        </p>
      </section>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentSlide: 0,
      guides: [
        {
          text: "1",
          value: 0,
          src: require("@/assets/images/Guidance/introduce-vision.png"),
        },
        {
          text: "2",
          value: 1,
          src: require("@/assets/images/Guidance/introduce-vision.png"),
        },
        {
          text: "3",
          value: 2,
          src: require("@/assets/images/Guidance/introduce-vision.png"),
        },
        {
          text: "4",
          value: 3,
          src: require("@/assets/images/Guidance/introduce-vision.png"),
        },
        {
          text: "5",
          value: 4,
          src: require("@/assets/images/Guidance/introduce-vision.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
