<template>
  <b-modal
    id="bv-modal-kakoMap"
    title="토지주소 조회"
    ok-variant="primary m-0 ml-1 p-3"
    ok-title="확인"
    cancelTitle="닫기"
    cancelVariant="outline-primary m-0 p-3"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    hide-header-close
    contentClass="confirm border-0 fw-400"
    footerClass="w-100 d-flex align-items-center justify-content-between p-1 border-0 btn-group"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="xl"
  >
    <div>
      <b-input-group class="mb-4" variant="outline" hide-prepend>
        <b-form-input
          type="text"
          v-model="address"
          placeholder="예)삼평동681, 주안동229-7"
          @keyup.enter="search()"
        ></b-form-input>
        <template #append>
          <div class="ml-3">
            <b-btn
              variant="outline-primary"
              readonly
              class="text-17 text-nowrap"
              @click="search()"
              >검색</b-btn
            >
          </div>
        </template>
        </b-input-group>
        <div v-if="kakaoMaps && kakaoMaps.meta && kakaoMaps.meta.total_count > 0">
            <ul
                v-for="item in kakaoMaps.documents" 
                :key="item.id" 
                class="mt-2"
                style="list-style:none;padding-left:0px;"
            >
                <li>
                    <span 
                        style="text-decoration: underline; cursor:pointer"
                        @click="ok(item)"
                    >
                      {{item.address.address_name}}
                    </span>
                </li>
            </ul>
        </div>
        <div v-if="isShowNotSearch">
          <p>
            {{`"${failAddress}" 검색결과가 없습니다. 검색어에 잘못된 철자가 없는지, 정확한 주소인지 다시 한번 확인해 주세요. `}}
          </p>
        </div>
    </div>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between btn-group flex-nowrap">
        <b-btn variant="primary m-0 ml-1 py-3 w-50 btn-block" @click="close()"
          >취소</b-btn
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    data: null, //해당 data에는 모든 api를 조회한 값이 담겨옴 (비딩 등록 직전의 데이터)
  },
  data() {
    return {
      address: null,
      failAddress:null,
      kakaoMap: null,
      kakaoMaps: null,
      isShowNotSearch: false
    };
  },
  methods: {
    close(){
      this.$bvModal.hide('bv-modal-kakoMap');
    },
    ok(item){
      this.$emit("addressObj", {
        address : item.address_name,
        sido : item.address.region_1depth_name,
        jibunAddress: item.address_name,
        roadAddress : item.road_address 
          ? item.road_address.address_name 
            ? item.road_address.address_name 
            : item.road_address
          : null,
        zipNo : item.road_address && item.road_address.zone_no 
          ? item.road_address.zone_no 
          : null,

        addressDetailMeta: {
              bcode: item.address.b_code,
              bname: item.address.region_3depth_name.includes(" ") 
                ? item.address.region_3depth_name.substring(item.address.region_3depth_name.indexOf(" "))
                : item.address.region_3depth_name, 

              bname1: item.address.region_3depth_name.includes(item.address.region_3depth_h_name) 
                ? item.address.region_3depth_h_name
                : null,

              bun: item.address.main_address_no,
              ji: item.address.sub_address_no,
              sido: item.address.region_1depth_name,
              sigungu: item.address.region_2depth_name,
              sigunguCode: item.address.b_code.substring(0,5),

              buildingCode: null,
              buildingName: item.road_address && item.road_address.building_name 
                ? item.road_address.building_name
                : null,
            },
      });
      this.$bvModal.hide("bv-modal-kakoMap");
    },
    async search() {
        if(this.address){
          try {
              const { data } = await this.$axios.get("/test/kbPrice2",{params: { address: this.address }});
  
              if (data.status.code === "0000") {
                  console.log(data);
                  this.kakaoMaps = data.data;
                  if(this.kakaoMaps.documents.length ==0){
                    this.isShowNotSearch = true;
                    this.failAddress = this.address;
                  }else{
                    this.isShowNotSearch = false;
                    this.failAddress = null;
                  }
              } else {
                  window.alert(data.status.message);
              }
          } catch (error) {
              console.log(error);
          }
        }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  padding: 6px 12px;
  height: 38px;
}
</style>
