<template>
  <div class="quick-menu" @click="goToLimitCalc()">
    <b-btn>한도계산기</b-btn>
  </div>
</template>

<script>
export default {
  name: "Quickmenu",
  methods: {
    goToLimitCalc() {
      this.$router.push("/limit-calculator").catch(() => {});
    },
  },
};
</script>

<style></style>
