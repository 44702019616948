<template>
  <b-modal
    id="modal-rejected"
    title="부결"
    ok-title="확인"
    @ok="ok"
    cancelTitle="취소"
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="primary m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="sm"
  >
    <div class="text-center">
      <span>정말 부결하시겠습니까?</span>
      <!-- idBidder :{{ idBidder }} idBidding :{{ idBidding }} reason :{{ reason }} -->
      <b-textarea
        class="mt-3 rounded boorder border-light-gray"
        placeholder="부결 사유를 입력해주세요."
        v-model="reason"
        @input="$emit('input', $event)"
        rows="4"
      ></b-textarea>
    </div>
  </b-modal>
</template>

<script>
export default {
  computed: {
    idBidding() {
      return this.$route.params.matching_id;
    },
  },
  props: {
    value: {
      type: String,
    },
    idBidder: {
      type: Number,
    },
  },
  data() {
    return {
      reason: null,
    };
  },
  watch: {
    value(n) {
      this.reason = n;
    },
  },
  methods: {
    async ok() {
      if (!this.reason) {
        await window.alert("부결 사유는 필수적으로 입력해야 합니다.", {
          title: "필수 입력",
        });
      } else {
        try {
          const { data } = await this.$axios.put(
            `/bidding/${this.idBidding}/bidder/${this.idBidder}/update-reject`,
            {
              reason: this.reason
                ? this.reason
                : "단순 변심에 의한 진행 취소입니다.",
              rejected: true,
            }
          );
          if (data.status.code === "0000") {
            const bool = await window.alert(
              "정상적으로 진행이 취소되었습니다."
            );
            if (bool) {
              window.location.reload();
            }
          } else {
            window.alert(data.status.message);
          }
        } catch (error) {}
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
