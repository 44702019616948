<template>
  <b-container>
    <!-- input.profile: {{ input.profile }} -->
    <b-form-group class="radio-group-sqaure">
      <b-form-radio-group
        :options="types"
        v-model="input.role"
        buttons
        button-variant="light-gray rounded-0 w-25"
      />
    </b-form-group>
    @@formValid: {{ formValid }}

    <w-form
      @submit.prevent.stop="signup"
      class="mb-5 pb-5"
      v-model="formValid"
      ref="form"
    >
      <div :style="{ paddingBㅣottom: '60px' }">
        <article class="mt-3">
          <legend-custom text="프로필 사진 선택" />
          <input-image
            label-text="프로필 사진을 등록해주세요"
            :accept="'image/jpg, image/jpeg, image/png'"
            v-model="input.profile"
          />
        </article>
        <article>
          <input-image
            v-model="input.businessCard"
            :width="180"
            label-text="명함 사진을 등록해주세요"
          />
        </article>
      </div>
      <article class="mt-3 mb-5">
        <legend-custom text="계정 정보 입력" class="mb-3" />
        <template v-if="input.loginType === 'EMAIL'">
          <w-input
            type="email"
            placeholder="이메일"
            v-model="input.serviceId"
            :rules="rules.serviceId"
            :serverCheck="checkId"
          >
            <template #prepend> 이메일 </template>
          </w-input>
          <w-input
            type="password"
            placeholder="비밀번호"
            v-model="input.password"
            :rules="rules.password"
          >
            <template #prepend> 비밀번호 </template>
          </w-input>
          <w-input
            type="password"
            placeholder="비밀번호 확인"
            v-model="input.passwordConfirm"
            :rules="rules.passwordConfirm"
          >
            <template #prepend> 비밀번호 확인 </template>
          </w-input>
        </template>
        <div class="d-flex">
          <w-input
            ref="mobile"
            class="flex-grow-1"
            type="tel"
            placeholder="핸드폰 번호"
            v-model="input.mobile"
            :rules="rules.mobile"
            :readonly="serverVaild.certNumberConfirm ? 'readonly' : undefined"
            maxlength="11"
            @input.native="$numberOnly($event, 'mobile', 11)"
          >
            <template #prepend> 핸드폰 번호 </template>
            <template #append="{ validate }">
              <div class="ml-3">
                <b-btn
                  variant="outline-primary"
                  :disabled="!validate"
                  @click="validMobile"
                  >인증</b-btn
                >
              </div>
            </template>
          </w-input>
        </div>
        <div :class="serverVaild.certNumber ? 'd-flex' : 'd-none'">
          <w-input
            ref="certNumber"
            class="flex-grow-1"
            type="tel"
            :rules="rules.certNumber"
            maxlength="4"
            v-model="input.certNumber"
            placeholder="인증 번호 4자리"
            @input.native="
              $numberOnly($event, 'certNumber', 4),
                (serverVaild.certNumberConfirm = false)
            "
          >
            <template #prepend> 인증 번호 </template>
            <template #append>
              <small
                class="position-absolute text-error text-14 text-lg-15 mr-3"
                :style="{
                  top: '50%',
                  transform: 'translateY(-50%)',
                  right: '0',
                  zIndex: '1000',
                }"
                id="timer"
              >
              </small>
            </template>
          </w-input>
          <div class="ml-3 position-relative">
            <b-btn
              variant="primary"
              @click="certNumberConfirm"
              :disabled="serverVaild.certNumberConfirm"
              :style="{ width: '80px' }"
              >확인</b-btn
            >
          </div>
        </div>
        <template>
          <w-input
            type="text"
            placeholder="은행명"
            v-model="input.bankAccountMeta.bank"
            :rules="rules.bank"
          >
            <template #prepend> 은행명 </template>
          </w-input>
          <w-input
            type="text"
            placeholder="예금주"
            v-model="input.bankAccountMeta.accountHolder"
            :rules="rules.accountHolder"
          >
            <template #prepend> 예금주 </template>
          </w-input>
          <w-input
            type="text"
            placeholder="계좌번호"
            v-model="input.bankAccountMeta.accountNumber"
            :rules="rules.accountNumber"
            maxlength="12"
            @input.native="accountNumber($event)"
          >
            <template #prepend> 계좌번호 </template>
          </w-input>
        </template>
        <template
          v-if="
            input.role === 'LOANBROKER' ||
            input.role === 'FINANCE' ||
            input.role === 'REALTOR'
          "
        >
          <legend-custom text="업체 정보 입력" />
          <article class="mt-3 mb-5">
            <w-input
              type="text"
              placeholder="업체명"
              v-model="input.companyMeta.companyName"
              :rules="rules.companyName"
            >
              <template #prepend> 업체명 </template>
            </w-input>
            <input-file
              labelText="대부 등록증 업로드(PDF)"
              accept="file/pdf"
              v-model="input.companyMeta.loanLicenseFileMeta"
              :state="valid.companyMeta.loanLicenseFileMeta"
              :isInvalid="status.companyMeta.loanLicenseFileMeta"
              feedbackMsg="대부 등록증을 첨부해주세요."
            />
            <input-file
              labelText="사업자 등록증 업로드(PDF)"
              accept="file/pdf"
              v-model="input.companyMeta.businessLicenseFileMeta"
              :state="valid.companyMeta.businessLicenseFileMeta"
              :isInvalid="status.companyMeta.businessLicenseFileMeta"
              feedbackMsg="사업자 등록증을 첨부해주세요."
            />
          </article>
        </template>
        <template v-if="input.role === 'COUNSELOR'">
          <legend-custom text="상담사 정보 입력" />
          <article class="mt-3 mb-5">
            <b-form-radio-group
              stacked
              :options="counselGubun"
              v-model="input.counselGubun"
              class="fw-400 text-17"
              :disabled="counselCheck"
            ></b-form-radio-group>
            <w-input
              type="text"
              placeholder="상담원 코드"
              v-model="input.counselCode"
              :rules="rules.counselCode"
              :readonly="counselCheck ? 'readonly' : undefined"
              inputClass="prepend-size-lg"
              @input.native="$numberOnly($event, 'counselCode', 11)"
            >
              <template #prepend> 상담원 코드 </template>
            </w-input>
            <!-- 0811 상담자 조회 위해 추가 -->
            <w-input
              type="text"
              placeholder="상담원 이름"
              v-model="input.counselName"
              :rules="rules.counselName"
              :readonly="counselCheck ? 'readonly' : undefined"
              inputClass="prepend-size-lg"
            >
              <template #prepend> 상담원 이름 </template>
            </w-input>
              <b-btn
                variant="outline-primary"
                @click="counselSearch()"
                :disabled="counselCheck"
                >상담사 조회</b-btn
              >
          </article>
        </template>
      </article>

      <article class="mt-3 mb-5">
        <legend-custom text="약관 동의" class="mb-3" />
        <b-form-checkbox
          :checked="allAgree"
          class="text-14 text-lg-15 mb-3"
          @click.native.prevent="checkAll"
        >
          <span class="text-underline text-darkest"
            >전체 내용에 동의합니다</span
          >
        </b-form-checkbox>
        <b-form-group v-for="(item, i) in terms" :key="i" class="mb-3">
          <w-checkbox
            v-model="input[item.inputValue]"
            :require="item.required"
            class="w-100"
          >
            <div
              class="d-flex align-items-center justify-content-between w-100"
            >
              <span class="text-14 text-lg-15 fw-400 text-darkest">
                {{ item.text }}
              </span>
              <b-btn
                variant="link text-light-gray"
                class="p-0 text-14"
                @click="openTerm(item.url)"
                >자세히 보기</b-btn
              >
            </div>
          </w-checkbox>
        </b-form-group>
      </article>
      <section class="my-5">
        <b-btn variant="primary" class="py-3 w-100" @click="signup"
          >회원가입</b-btn
        >
      </section>
    </w-form>
  </b-container>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

export default {
  components: {
    LegendCustom: Form.LegendCustom,
    InputImage: Form.InputImage,
    InputFile: Form.InputFile,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";
    return {
      formValid: false,
      timeChecker: null,
      rules: {
        serviceId: [
          (v) =>
            !!v || { title: "이메일 입력", message: "이메일을 입력하세요." },
          (v) =>
            regex.serviceId.test(v) || {
              title: "이메일 형식",
              message: "이메일 형식이 올바르지 않습니다.",
            },
        ],
        password: [
          (v) =>
            !!v || {
              title: "비밀번호 입력",
              message: "비밀번호를 입력하세요.",
            },
          (v) =>
            regex.password.test(v) || {
              title: "비밀번호 형식",
              message: "8-16자 영문 대/소문자, 숫자, 특수문자를 사용하세요.",
            },
        ],
        passwordConfirm: [
          (v) =>
            !!v || {
              title: "비밀번호 확인",
              message: "비밀번호 확인을 입력하세요.",
            },
          (v) =>
            this.input.password === v || {
              title: "비밀번호 확인",
              message: "비밀번호 확인이 일치하지 않습니다",
            },
        ],
        mobile: [
          (v) =>
            !!v || {
              title: "핸드폰 번호 입력",
              message: "휴대폰 번호를 입력하세요.",
            },
          (v) =>
            v.length >= 10 || {
              title: "핸드폰 번호 입력",
              message: "휴대폰 번호 형식이 올바르지 않습니다.",
            },
          // (v) =>
          //   this.serverVaild.mobile || {
          //     title: "핸드폰 번호 인증",
          //     message: "핸드폰 번호를 인증해주세요.",
          //   },
        ],
        certNumber: [
          (v) =>
            !!v || {
              title: "핸드폰 번호 인증",
              message: "인증번호를 입력하세요.",
            },
          (v) =>
            v.length === 4 || {
              title: "핸드폰 번호 인증",
              message: "인증번호를 입력하세요.",
            },
          // (v) =>
          //   this.serverVaild.certNumber || {
          //     title: "핸드폰 번호 인증",
          //     message: "핸드폰 번호를 인증해주세요.",
          //   },
          (v) =>
            this.serverVaild.certNumberConfirm || {
              title: "핸드폰 번호 인증",
              message: "핸드폰 번호를 인증해주세요.",
            },
        ],
        bank: [
          (v) =>
            !!v || {
              title: "은행 정보 입력",
              message: "은행명을 입력하세요.",
            },
        ],
        accountHolder: [
          (v) =>
            !!v || {
              title: "은행 정보 입력",
              message: "예금주를 입력하세요.",
            },
        ],
        accountNumber: [
          (v) =>
            !!v || {
              title: "은행 정보 입력",
              message: "계좌번호를 입력하세요.",
            },
          (v) =>
            v.length >= 10 || {
              title: "은행 정보 입력",
              message: "계좌번호 형식이 올바르지 않습니다.",
            },
        ],
        counselCode: [
          (v) =>
            !!v || {
              title: "은행 정보 입력",
              message: "은행상담원 코드를 입력하세요.",
            },
        ],
        counselName: [
          (v) =>
            !!v || {
              title: "은행 정보 입력",
              message: "은행상담원 이름을 입력하세요.",
            },
        ],
        companyName: [
          (v) =>
            !!v || {
              title: "업체 정보 입력",
              message: "업체명을 입력하세요.",
            },
        ],
      },
      types: [
        {
          text: "공인 중개사",
          value: "REALTOR",
        },
        {
          text: "대출 중개인",
          value: "LOANBROKER",
        },
        {
          text: "상담사",
          value: "COUNSELOR",
        },
        {
          text: "금융사",
          value: "FINANCE",
        },
      ],
      terms: [
        {
          text: "(필수) 개인정보처리방침 동의",
          required: true,
          inputValue: "terms1",
          url: "/guidance/terms?type=PRIVACY",
        },
        {
          text: "(필수) 서비스 이용약관 동의",
          required: true,
          inputValue: "terms2",
          url: "/guidance/terms?type=SERVICE_TERMS",
        },
        {
          text: "(선택) 마케팅 동의",
          required: false,
          inputValue: "terms3",
          url: "/guidance/terms?type=MARKETING",
        },
      ],
      serverVaild: {
        serviceId: false,
        mobile: false,
        certNumber: false,
        certNumberConfirm: false,
      },
      input: {
        role: "REALTOR",
        loginType: "EMAIL",
        profile: {},
        businessCard: {},
        serviceId: null,
        password: null,
        passwordConfirm: null,
        mobile: null,
        certNumber: null,
        terms1: null,
        terms2: null,
        terms3: null,
        // 개인이 아닐 경우 은행정보 입력
        bankAccountMeta: {
          bank: null,
          accountHolder: null,
          accountNumber: null,
        },
        // 중개사,금융사 업체정보 입력
        companyMeta: {
          companyName: null,
          businessLicenseFileMeta: null,
          loanLicenseFileMeta: null,
        },
        // 상담사, 상담사정보입력
        counselCode: null,

        // 0811 상담사 조회 위해 추가 
        counselName: null,
        counselGubun: null,

        counselInfo: {
          name: null,
          lcNum: null,
          corpName: null,
          finInfoName: null,
          conDate: null,
          cancelDate: null,
          gubun: null
        },
      },
      status: {
        serviceId: null,
        password: null,
        passwordConfirm: null,
        mobile: null,
        certNumber: null,
        // terms1: false,
        // terms2: false,
        // terms3: false,
        bankAccountMeta: {
          bank: null,
          accountHolder: null,
          number: null,
        },
        companyMeta: {
          companyName: null,
          // certificate: null,
          // license: null,
          businessLicenseFileMeta: null,
          loanLicenseFileMeta: null,
        },
        
      },

      timerActive: false,

      //0811 상담사 조회 확인 여부 위해 추가
      counselCheck: false,
      //0811 상담사 조회 위해 추가 
      counselGubun: [
        {
          text: "개인",
          value: "P",
        },
        {
          text: "법인",
          value: "S",
        },
      ],
    };
  },
  methods: {
    //0811 상담사 조회위해 추가 
    async counselSearch(){

      let input = {
        ...this.input
      };

      try {
        const { data } = await this.$axios.post("/users/counsel", input);

        console.log(data)
        if (data.status.code === "0000") {

          this.input.counselInfo = data.data;
          this.input.counselInfo.gubun = this.input.counselGubun;
          this.counselCheck = true;

          console.log(this.input.counselInfo)
          alert("상담사 조회에 성공하였습니다.");
        }else{
          alert(data.status.message);
        }
      } catch (error) {
        console.error(error);
      }

    },
    checkAll() {
      this.input = {
        ...this.input,
        terms1: !this.allAgree,
        terms2: !this.allAgree,
        terms3: !this.allAgree,
      };
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.check();
        });
      });
    },
    accountNumber(e) {
      if (e && e.target) {
        let val = e.target.value.replace(/[^0-9]/g, "");
        val = val.substr(0, 11);
        this.input.bankAccountMeta.accountNumber = val;
      }
    },
    // 유저생성 - 회원가입
    async signup() {

      //0811 상담사조회 버튼 안눌렀을 경우
      if(this.input.role === "COUNSELOR" && !this.counselCheck){
        alert("상담사 조회는 필수입니다.");
        return;
      }

      let input = {
        ...this.input,
        termsAgree: {
          taPrivacy: this.input.terms1,
          taService: this.input.terms2,
          taMarketing: this.input.terms3,
        },
      };

      if (!this.formValid) {
        const children = this.$refs.form.$children;
        // console.log(children);
        // Input 중에서 valid === true가 아닌 제일 처음 엘리먼트
        const { taPrivacy, taService } = input.termsAgree;
        const firstError = children.find(
          (item) => item && item.valid && item.valid.state !== true
        );
        if (
          (!firstError || firstError === undefined) &&
          !(taPrivacy && taService)
        ) {
          await window.alert("필수 약관을 모두 동의해주세요", {
            title: "약관 동의",
          });
          return;
        } else if (firstError.valid.state === null) {
          /**
           * 해당 인풋에 값이 입력된 적이 없는 경우
           * rules[0]의 메시지 출력
           */

          firstError.value = "";
          await alert(firstError.valid.message, {
            title: firstError.valid.title,
          });
        } else if (firstError.valid.state === false) {
          await alert(firstError.valid.message, {
            title: firstError.valid.title,
          });
        }
        return;
      } else if (this.formValid) {
        if (
          input.role === "LOANBROKER" ||
          input.role === "FINANCE" ||
          input.role === "REALTOR"
        ) {
          if (!input.companyMeta.loanLicenseFileMeta) {
            await window.alert("대부등록증을 첨부해주세요.");
            this.status.companyMeta.loanLicenseFileMeta = true;
            return;
          } else if (!input.companyMeta.businessLicenseFileMeta) {
            await window.alert("사업자등록증을 첨부해주세요.");
            this.status.companyMeta.businessLicenseFileMeta = true;
            return;
          }
        }

        if (input.role === "COUNSELOR") {
          // 일반사용자일 경우 회사, 계좌 등 null 처리
          input = {
            ...input,
            bankAccountMeta: null,
            companyMeta: null,
            termsAgree: {
              taMarketing: true,
              taPrivacy: true,
              taService: true,
            },
          };
        }

        input.profile =
          typeof input.profile === "object" ? input.profile.filename : null;
        input.businessCard =
          typeof input.businessCard === "object"
            ? input.businessCard.filename
            : null;

        try {
          const { data } = await this.$axios.post("/users", input);
          if (data.status.code === "0000") {
            this.$router.push({ name: "SignUpComplete", params: input });
          } else {
            console.log(data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    // 유효성검사 - id
    async checkId(v) {
      try {
        const { data } = await this.$axios.post("/users/valid-id", {
          serviceId: v,
        });
        if (data.status.code === "0000") {
          return { state: true, message: "" };
        } else if (data.status.code === "1002") {
          return {
            state: false,
            title: "이메일 중복",
            message: "이미 존재하는 이메일입니다.",
          };
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        return { state: false, message: error.message };
      }
    },
    // 유효성검사 - mobile
    async validMobile(e) {
      try {
        const { data } = await this.$axios.post("/users/valid-mobile", {
          mobile: this.input.mobile,
        });
        if (data.status.code === "0000") {
          await window.alert("인증번호를 확인해주세요", {
            title: "핸드폰 번호 인증",
          });
          this.serverVaild.mobile = true;
          this.sendCertNumber();
        } else if (data.status.code === "1002") {
          await window.alert("이미 사용중인 핸드폰 번호입니다.", {
            title: "핸드폰 번호 중복",
          });
        } else {
          // console.log(data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 인증번호 요청 - 문자
    async sendCertNumber() {
      if (!this.rules.mobile) {
        window.alert("핸드폰번호 형식이 올바르지 않습니다.");
      } else {
        try {
          const { data } = await this.$axios.post("/cert/mobile", {
            email: this.input.serviceId,
            mobile: this.input.mobile,
            mode: "CERT_MOBILE",
          });
          if (data.status.code === "0000") {
            // console.log(data.data);
            this.serverVaild.certNumber = true;
            this.countdown("timer", 3, 0);
          } else if (data.status.code === "1002") {
            window.alert("이미 사용중인 핸드폰 번호입니다.");
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    countdown(element, minutes, seconds) {
      if (this.timerActive) clearInterval(this.timerActive);
      var time = minutes * 60 + seconds;
      this.timerActive = setInterval(function () {
        var el = document.getElementById(element);
        if (!el) return;
        if (time <= 0) {
          var text = "00:00";
          el.innerHTML = text;
          setTimeout(function () {
            countdown("clock", 0, 5);
          }, 2000);
          clearInterval(this.timerActive);
          this.timerActive = null;
          return;
        }
        var minutes = Math.floor(time / 60);
        if (minutes < 10) minutes = "0" + minutes;
        var seconds = time % 60;
        if (seconds < 10) seconds = "0" + seconds;
        var text = minutes + ":" + seconds;
        el.innerHTML = text;
        time--;
      }, 1000);
    },
    // 인증번호 확인 - 문자
    async certNumberConfirm() {
      // if (this.serverVaild.mobile && this.serverVaild.certNumber) {
      try {
        const { data } = await this.$axios.post("/cert/mobile/confirm", {
          code: this.input.certNumber,
          mobile: this.input.mobile,
          mode: "CERT_MOBILE",
        });
        if (data.status.code === "0000") {
          this.serverVaild.certNumberConfirm = true;
          window.alert("인증되었습니다.", {
            title: "핸드폰 번호 인증",
          });
          var el = document.getElementById("timer");
          el.style.display = "none";
        } else {
          window.alert(data.status.message);
        }
      } catch (error) {
        console.error(error);
      }
      // }
    },
    checkValue(type, val) {
      let value;

      switch (type) {
        case "password":
          value = regex.password.test(val)
            ? false
            : "8-16자 영문 대/소문자, 숫자, 특수문자를 사용하세요.";
          break;
        case "passwordConfirm":
          value =
            this.input.password !== this.input.passwordConfirm
              ? "비밀번호 확인 후 다시 입력해주세요."
              : false;
          break;
      }

      this.status[type] = value;
    },
    openTerm(url) {
      window.open(url, "_blank");
    },
  },
  mounted() {
    if (this.params.serviceId) {
      this.input.serviceId = this.params.serviceId;
      this.input.password = this.params.serviceId;
      this.input.loginType = this.params.loginType;
    }
  },
  beforeDestroy() {
    clearInterval(this.timerActive);
  },
  computed: {
    params() {
      return this.$route.params;
    },
    allAgree() {
      const { terms1, terms2, terms3 } = this.input;
      return ![terms1, terms2, terms3].some((val) => !val);
    },

    // ...지울예정
    valid() {
      const rules = {
        serviceId: [
          (v) => !!v || "이메일을 입력하세요",
          (v) => regex.serviceId.test(v) || "이메일 형식이 올바르지 않습니다.",
          (v) => this.serverVaild.serviceId || "이메일을 확인 중입니다.",
        ],
        mobile: [
          (v) => !!v || "핸드폰 번호를 입력하세요",
          (v) => v.length >= 10 || "핸드폰 번호 형식이 올바르지 않습니다.",
          // (v) => this.serverVaild.mobile || "핸드폰 번호를 인증해주세요.",
        ],
      };
      const ruleChecker = (rules, value) => {
        return rules.reduce(
          (result, rule) => {
            if (value === null) return { state: null, msg: null };
            if (result.state === true) {
              const r = rule(value);

              const state = r === true;
              const msg = r === true ? null : r;

              return { state, msg };
            } else if (result.state === false) {
              return result;
            } else {
              return { state: true, msg: null };
            }
          },
          { state: true, msg: null }
        );
      };

      const serviceId = ruleChecker(rules.serviceId, this.input.serviceId);
      const mobile = ruleChecker(rules.mobile, this.input.mobile);

      return {
        serviceId,
        mobile,
        companyMeta: {
          companyName:
            this.input.companyMeta === null
              ? null
              : this.input.companyMeta.companyName !== "" &&
                this.input.companyMeta.companyName &&
                this.input.companyMeta.companyName.length >= 0,
          // certificate: this.input.companyMeta === null ? false : true,
          // license: this.input.companyMeta === null ? false : true,
          terms: !this.input.terms1 || !this.input.terms2 ? false : true,
          loanLicenseFileMeta:
            this.input.companyMeta.loanLicenseFileMeta === null ? false : true,
          businessLicenseFileMeta:
            this.input.companyMeta.businessLicenseFileMeta === null
              ? false
              : true,
        },

        bankAccountMeta: {
          bank:
            this.input.bankAccountMeta.bank === null
              ? null
              : this.input.bankAccountMeta.bank !== "" &&
                this.input.bankAccountMeta.bank &&
                this.input.bankAccountMeta.bank.length >= 0,
          accountHolder:
            this.input.bankAccountMeta.accountHolder === null
              ? null
              : this.input.bankAccountMeta.accountHolder !== "",
          accountNumber:
            this.input.bankAccountMeta.accountNumber === null
              ? null
              : this.input.bankAccountMeta.accountNumber !== "" &&
                this.input.bankAccountMeta.accountNumber.length > 5,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
