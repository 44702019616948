<template>
  <b-container
    class="
      min-vh-100
      d-flex
      flex-column
      justify-content-center
      align-items-center
    "
  >
    <h1 class="mb-5 text-24 text-lg-32 text-primary fw-400">
      페이지를 찾을 수 없습니다
    </h1>
    <b-row class="w-100" align-h="center">
      <b-col cols="12" md="6" lg="4">
        <b-btn variant="primary" class="w-100" to="/"
          >메인화면으로 돌아가기</b-btn
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
