<template>
  <div class="" style="height: inherit;" ref="element" v-resize @resize="onResize">
    <b-container>
      <b-row>
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
            <span style="flex: 1;">검색조건</span>
            <b-form-select size="sm" style="flex: 1;" v-model="filterData.searchKeyType" @change="filterSet" :options="searchKeyOption"></b-form-select>
            <b-form-input 
              size="sm"
              v-model="filterData.searchKey"
              style="flex: 4;"
              @change="filterSet"
            ></b-form-input>
        </b-col>
        <b-col lg=6>
          
        </b-col>
      </b-row>
      <b-row>
        <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
          <span>전체 {{ gridFilterData.length }} 건</span>
        </b-col>
        <b-col lg=6  class="d-flex justify-content-end">
          <b-button class="mx-1 my-1 tab_btn" @click="DownloadExcel">엑셀다운</b-button>
        </b-col>
      </b-row>
      <b-row :style="{'height': page_grid_height + 'px'}">
        <div class="" style="width: -webkit-fill-available;">
          <b-table 
            id = "grid"
            striped hover 
            :items="gData"
            :fields="gridFields"
            small
          >
            <template #cell(No)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)">
                {{ row.index + 1 + ((currentPage - 1) * perPage) }}
              </div>
            </template>
            <template #cell(user_id)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)">
                {{ gData[row.index].user_id }}
              </div>
            </template>
            <template #cell(type)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)">
                {{ gData[row.index].type }}
              </div>
            </template>
            <template #cell(conn_ip)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)">
                {{ gData[row.index].conn_ip }}
              </div>
            </template>
            <template #cell(conn_date)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)">
                {{ gData[row.index].conn_date }}
              </div>
            </template>
          </b-table>
        </div>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="gridFilterData.length"
          :per-page="perPage"
          first-text="⏮"
          prev-text="⏪"
          next-text="⏩"
          last-text="⏭"
          class="mt-4"
          size="sm"
        ></b-pagination>
      </b-row>

      <!-- modals -->
    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    // Modals
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      formValid: false,
      salt: 0,
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      select_index: -1,
      select_rowData: {
        id : 0,
        user_id : "",
        conn_ip : "",
        browser_info : "",
        conn_date : "",
        type : "",
      },
      perPage: 1,
      currentPage: 1,
      gridDefaultData: [

      ],
      gridFilterData: [

      ],
      gData: [

      ],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "user_id",
          label: "회원 ID"
        },
        {
          key: "type",
          label: "종류"
        },
        {
          key: "conn_ip",
          label: "접속 IP"
        },
        {
          key: "conn_date",
          label: "접속일자"
        },
      ],
      filterData: {
        searchKeyType: "all",
        searchKey: ""
      },
      searchKeyOption: [
        { text: "전체", value: "all" },
        { text: "회원 ID", value: "user_id" },
        { text: "접속 IP", value: "conn_ip" }
      ],
      file_name: "adminConn_list",
      rules: {
        
      },
    };
  },
  computed: {
    
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      let items = this.gridFilterData;

      this.selectPage(newVal);
    },
  },
  methods: {
    async selectAdminConnLog() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectAdminConnLog", {
            
          }
        );
        if (data.length > 0) {
          this.gData = data;
          this.gridDefaultData = data;
          this.filterSet();
        } else if (data.length == 0) {
          this.gData = null;
          window.alert("데이터가 없습니다.");
        }

        this.pageSet();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    DownloadExcel() {
      var crt_date = this.getToday();
      var excel_name = this.file_name + "_" + crt_date;
      var workBook = XLSX.utils.book_new(); // 새로운 workbook 생성 
      var grid_list = XLSX.utils.json_to_sheet(this.gridFilterData);
      // var stdData = XLSX.utils.json_to_sheet(this.checkStdList); // json 객체를 sheet로 변환하여 워크시트 생성
      
      XLSX.utils.book_append_sheet(workBook, grid_list, this.file_name); // stdData 워크시트로 시트 생성, 해당 시트 이름 명명
      // XLSX.utils.book_append_sheet(workBook, uncheckData, '학생현황'); // 시트 추가
      XLSX.writeFile(workBook, excel_name + '.xlsx'); // 파일 생성;
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    filterSet() {
      let search_key = this.filterData.searchKey;
      let search_type = this.filterData.searchKeyType;
      let temp_data = [];

      //this.gridDefaultData.

      if (search_key != "") {
        if (search_type === "all") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.user_id.indexOf(search_key) != -1 || e.conn_ip.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "user_id") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.user_id.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "conn_ip") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.conn_ip.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        }

        this.gridFilterData = temp_data;
      } else {
        this.gridFilterData = this.gridDefaultData;
      }

      this.pageSet();
    },
    clipboardCopy(index) {
      var copy_data = JSON.stringify(this.gData[index]);
      window.navigator.clipboard.writeText(copy_data).then( function (res) {alert("복사되었습니다.");} );
    },
    onResize(e) {
      if (this.crt_width == 0 & this.crt_height == 0) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        //this.pageSet();
      } else if (this.crt_width != e.detail.width || this.crt_height != e.detail.height) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        this.pageSet();  
      }
    },
    pageSet() {
      var filter_height = 60;
      var button_height = 60;
      var pagination_height = 50;
      var grid_header_height = 60;
      var grid_row_height = 65;
      var grid_data_height = this.crt_height - filter_height - button_height - pagination_height - grid_header_height - 40;
      this.page_grid_height = grid_data_height;

      var page_row_count = Math.floor(grid_data_height / grid_row_height);
      var max_page_num = Math.ceil(this.gridFilterData.length / page_row_count);

      this.perPage = page_row_count;

      this.selectPage(1);
    },
    selectPage(page) {
      let temp_grid_data = this.gridFilterData;

      this.gData = temp_grid_data.slice(
        (page - 1) * this.perPage, page * this.perPage
      )
    }
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
  async mounted() {
    this.selectAdminConnLog();
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>