<template>
  <div>
    <header
      class="mb-3 pb-md-3 d-md-flex align-items-center justify-content-between"
    >
      <div class="ml-md-0">
        <h2 class="mt-2 mb-3 text-28 text-lg-38">회원정보 수정</h2>
        <span class="text-14 text-gray fw-400"
          >회원정보를 수정하실 수 있습니다.</span
        >
      </div>
      <div class="mt-4 ml-md-0 mr-md-5">
        <div class="d-flex align-items-center">
          <b-btn
            variant="outline-primary py-1"
            class="mr-2 mr-md-3 text-nowrap"
            v-b-modal.modal-change-password
          >
            <span class="ml-1 mr-n2"> 비밀번호 변경 </span>
            <i class="icon icon-next ml-1 mr-n2"></i>
          </b-btn>
          <b-btn variant="outline-primary py-1" @click="withdrawal()"
            >회원탈퇴
          </b-btn>
        </div>
      </div>
    </header>
    <b-form @submit.prevent.stop="edit">
      <b-row align-v="center" class="py-3 border-top mt-5 pt-4">
        <b-col
          cols="12"
          md="3"
          class="mb-2 mt-md-n5 text-md-center text-primary"
        >
          프로필
          <span v-if="user.role !== 'REALTOR'"> / 명함</span>
        </b-col>
        <b-col cols="12" md="9" class="text-center text-md-left">
          <div class="d-flex flex-column flex-md-row align-item-start">
            <div class="">
              <template v-if="input.profile">
                <figure
                  class="
                    bg-img
                    border border-primary
                    mx-auto mx-md-0
                    position-relative
                  "
                  :style="{
                    width: '7.5rem',
                    minHeight: '7.5rem',
                    maxHeight: '7.5rem',
                    backgroundImage: `url(${s3 + input.profile})`,
                  }"
                >
                  <b-btn
                    variant="primary rounded-circle p-0 d-flex align-items-center justify-content-center position-absolute"
                    :style="{
                      width: '32px',
                      height: '32px',
                      top: '0',
                      right: '0',
                      transform: 'translate(50%, -50%)',
                    }"
                    v-if="input.profile"
                    @click="reset('profile')"
                  >
                    <i class="icon icon-times text-14"></i>
                  </b-btn>
                </figure>
              </template>
              <template v-else-if="!input.profile">
                <div
                  :style="{
                    minHeight: '7.5rem',
                    maxHeight: '7.5rem',
                  }"
                >
                  <b-avatar square size="7.5rem" variant="primary"></b-avatar>
                </div>
              </template>

              <label class="btn btn-outline-primary mt-3 w-sm-100 py-1">
                <i class="icon icon-add-file"></i>
                파일 선택
                <b-form-file
                  class="d-none"
                  v-model="newProfile"
                  accept="image/png, image/jpg, image/jpeg"
                  @change="inputProfile"
                ></b-form-file>
              </label>
            </div>
            <div
              class="mt-3 mt-md-0 ml-0 ml-md-5 text-center"
              v-if="user.role !== 'REALTOR'"
            >
              <template v-if="input.businessCard">
                <figure
                  class="
                    bg-img
                    border border-primary
                    mx-auto mx-md-0
                    position-relative
                  "
                  :style="{
                    width: '11.25rem',
                    minWidth: '11.25rem',
                    maxWidth: '11.25rem',
                    maxHeight: '7.5rem',
                    minHeight: '7.5rem',
                    backgroundImage: `url(${s3 + input.businessCard})`,
                  }"
                >
                  <b-btn
                    variant="primary rounded-circle p-0 d-flex align-items-center justify-content-center position-absolute"
                    :style="{
                      width: '32px',
                      height: '32px',
                      top: '0',
                      right: '0',
                      transform: 'translate(50%, -50%)',
                    }"
                    v-if="input.businessCard"
                    @click="reset('businessCard')"
                  >
                    <i class="icon icon-times text-14"></i>
                  </b-btn>
                </figure>
              </template>
              <template v-else-if="!input.businessCard">
                <div
                  :style="{
                    minWidth: '11.25rem',
                    maxWidth: '11.25rem',
                    minHeight: '7.5rem',
                    maxHeight: '7.5rem',
                  }"
                >
                  <b-avatar
                    square
                    :style="{ width: '11.25rem', height: '7.5rem' }"
                    variant="primary"
                    icon="card-text"
                  ></b-avatar>
                </div>
              </template>

              <label class="btn btn-outline-primary mt-3 w-sm-100 py-1">
                <i class="icon icon-add-file"></i>
                파일 선택
                <b-form-file
                  class="d-none"
                  v-model="newBusinessCard"
                  accept="image/png, image/jpg, image/jpeg"
                  @change="inputbusinessCard"
                ></b-form-file>
              </label>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row align-v="center" class="py-3 border-top">
        <b-col
          cols="12"
          md="3"
          class="mb-2 mb-md-0 text-md-center text-primary"
        >
          이메일
        </b-col>
        <b-col cols="12" md="8" class="text-center text-md-left">
          <b-input-group hide-prepend class="mb-0">
            <b-input
              type="email"
              v-model="input.serviceId"
              :style="{ border: '2px solid #dedede' }"
              disabled
            ></b-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row align-v="center" class="pt-3 border-top">
        <b-col
          cols="12"
          md="3"
          class="mb-2 mb-md-3 text-md-center text-primary"
        >
          핸드폰 번호
        </b-col>
        <b-col cols="12" md="8" class="text-center text-md-left">
          <b-input-group class="mb-4" hide-prepend variant="outline">
            <b-form-input
              type="text"
              v-model="input.mobile"
              :state="valid.mobile.state"
              :readonly="serverVaild.certNumberConfirm ? 'readonly' : undefined"
              @input.native="$numberOnly($event, 'mobile', 11)"
              placeholder="핸드폰 번호"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="valid.mobile.state"
              :style="{ right: '80px' }"
              >{{ valid.mobile.msg }}</b-form-invalid-feedback
            >
            <template #append>
              <div class="ml-3">
                <b-btn variant="outline-primary" @click="validMobile"
                  >인증</b-btn
                >
              </div>
            </template>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row align-v="center" class="py-3" v-if="serverVaild.certNumber">
        <b-col
          cols="12"
          md="3"
          class="mb-2 mb-md-0 text-md-center text-primary"
        >
          인증번호
        </b-col>
        <b-col cols="12" md="8" class="text-center text-md-left">
          <b-input-group class="mb-0" hide-prepend variant="outline">
            <b-form-input
              type="text"
              v-model="input.certNumber"
              :style="{ paddingRight: '3.5rem' }"
              :disabled="serverVaild.certNumberConfirm"
              @input.native="$numberOnly($event, 'certNumber', 4)"
              placeholder="인증 번호"
            >
            </b-form-input>
            <template #append>
              <div class="ml-3 position-relative">
                <small
                  class="position-absolute text-error text-14 text-lg-15"
                  :style="{
                    top: '50%',
                    left: '-4rem',
                    transform: 'translateY(-50%)',
                    zIndex: '1000',
                  }"
                  id="timer"
                >
                </small>
                <b-btn
                  variant="primary"
                  @click="certNumberConfirm"
                  :disabled="serverVaild.certNumberConfirm"
                  >확인</b-btn
                >
              </div>
            </template>
          </b-input-group>
        </b-col>
      </b-row>
      <!-- 개인 회원이 아닐 경우 
      0816 user.role != 'COUNSELOR' 추가
      -->
      <template v-if="user.role !== 'REALTOR' && user.role != 'COUNSELOR'">
        <b-row align-v="center" class="py-3 border-top">
          <b-col
            cols="12"
            md="3"
            class="mb-2 mb-md-0 text-md-center text-primary"
          >
            은행명
          </b-col>
          <b-col cols="12" md="8" class="text-center text-md-left">
            <b-input-group hide-prepend class="mb-0" variant="outline">
              <b-input
                type="text"
                v-model="input.bankAccountMeta.bank"
              ></b-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row align-v="center" class="py-3 border-top">
          <b-col
            cols="12"
            md="3"
            class="mb-2 mb-md-0 text-md-center text-primary"
          >
            예금주
          </b-col>
          <b-col cols="12" md="8" class="text-center text-md-left">
            <b-input-group hide-prepend class="mb-0" variant="outline">
              <b-input
                type="text"
                v-model="input.bankAccountMeta.accountHolder"
              ></b-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row align-v="center" class="py-3 border-top">
          <b-col
            cols="12"
            md="3"
            class="mb-2 mb-md-0 text-md-center text-primary"
          >
            계좌번호
          </b-col>
          <b-col cols="12" md="8" class="text-center text-md-left">
            <b-input-group hide-prepend class="mb-0" variant="outline">
              <b-input
                type="text"
                v-model="input.bankAccountMeta.accountNumber"
              ></b-input>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
      <!-- 상담사일 경우 -->
      <template v-if="user.role == 'COUNSELOR'">
        <b-row align-v="center" class="py-3 border-top">
          <b-col
            cols="12"
            md="3"
            class="mb-2 mb-md-0 text-md-center text-primary"
          >
            상담원 코드
          </b-col>
          <b-col cols="12" md="8" class="text-center text-md-left">
            <b-input-group hide-prepend class="mb-0" variant="outline">
              <b-input type="text" v-model="input.counselCode"></b-input>
            </b-input-group>
          </b-col>
        </b-row>
        <div v-if="input.counselInfo">
          <b-row align-v="center" class="py-3 border-top">
            <b-col
              cols="12"
              md="3"
              class="mb-2 mb-md-0 text-md-center text-primary"
            >
              소속 유형
            </b-col>
            <b-col cols="12" md="8" class="text-center text-md-left">
              <b-input-group hide-prepend class="mb-0" variant="outline">
                <b-input type="text" v-model="input.counselInfo.gubun"></b-input>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row align-v="center" class="py-3 border-top">
            <b-col
              cols="12"
              md="3"
              class="mb-2 mb-md-0 text-md-center text-primary"
            >
              상담원 이름
            </b-col>
            <b-col cols="12" md="8" class="text-center text-md-left">
              <b-input-group hide-prepend class="mb-0" variant="outline">
                <b-input type="text" v-model="input.counselInfo.name"></b-input>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row align-v="center" class="py-3 border-top">
            <b-col
              cols="6"
              md="3"
              class="mb-2 mb-md-0 text-md-center text-primary"
            >
              계약 금융회사 
            </b-col>
            <b-col cols="6" md="3" class="text-center text-md-left">
              <b-input-group hide-prepend class="mb-0" variant="outline">
                <b-input type="text" v-model="input.counselInfo.finInfoName"></b-input>
              </b-input-group>
            </b-col>
            <b-col
              cols="6"
              md="2"
              class="mb-2 mb-md-0 text-md-center text-primary"
            >
              소속법인
            </b-col>
            <b-col cols="6" md="3" class="text-center text-md-left">
              <b-input-group hide-prepend class="mb-0" variant="outline">
                <b-input type="text" v-model="input.counselInfo.corpName"></b-input>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row align-v="center" class="py-3 border-top">
            <b-col
              cols="6"
              md="3"
              class="mb-2 mb-md-0 text-md-center text-primary"
            >
              계약 시작일
            </b-col>
            <b-col cols="6" md="3" class="text-center text-md-left">
              <b-input-group hide-prepend class="mb-0" variant="outline">
                <b-input type="text" v-model="input.counselInfo.conDate"></b-input>
              </b-input-group>
            </b-col>
            <b-col
              cols="6"
              md="2"
              class="mb-2 mb-md-0 text-md-center text-primary"
            >
              계약 종료일
            </b-col>
            <b-col cols="6" md="3" class="text-center text-md-left">
              <b-input-group hide-prepend class="mb-0" variant="outline">
                <b-input type="text" v-model="input.counselInfo.cancelDate"></b-input>
              </b-input-group>
            </b-col>
          </b-row>


        </div>
      </template>
      <!-- 중개사 혹은 금융사일 경우 -->
      <template
        v-if="
          user.role === 'LOANBROKER' ||
          user.role === 'FINANCE' ||
          user.role === 'REALTOR'
        "
      >
        <b-row align-v="center" class="py-3 border-top">
          <b-col
            cols="12"
            md="3"
            class="mb-2 mb-md-0 text-md-center text-primary"
          >
            대부 등록증(PDF)
          </b-col>
          <b-col cols="12" md="7" class="text-center text-md-left">
            <input-file
              labelText="대부 등록증 업로드(PDF)"
              accept=".pdf"
              class="pt-2"
              v-model="input.companyMeta.loanLicenseFileMeta"
              feedbackMsg="대부 등록증을 첨부해주세요."
            />
            <!-- <div
              class="
                my-2
                py-1
                px-2
                attached-file
                text-darkest text-left
                bg-light
                border border-light-gray
                d-flex
                align-items-center
                justify-content-between
              "
              v-if="input.companyMeta.loanLicenseFileMeta"
            >
              <i class="icon icon-attach text-24 mr-1"></i>
              <span class="text-13 text-gray mr-auto">{{
                input.companyMeta.loanLicenseFileMeta.originalFilename
              }}</span>
            </div> -->
          </b-col>
        </b-row>
        <b-row align-v="center" class="py-3 border-top">
          <b-col
            cols="12"
            md="3"
            class="mb-2 mb-md-0 text-md-center text-primary"
          >
            사업자 등록증(PDF)
          </b-col>
          <b-col cols="12" md="7" class="text-center text-md-left">
            <input-file
              labelText="사업자 등록증 업로드(PDF)"
              accept=".pdf"
              class="pt-2"
              v-model="input.companyMeta.businessLicenseFileMeta"
              feedbackMsg="사업자 등록증을 첨부해주세요."
            />
            <!-- <div
              class="
                my-2
                py-1
                px-2
                attached-file
                text-darkest text-left
                bg-light
                border border-light-gray
                d-flex
                align-items-center
                justify-content-between
              "
              v-if="input.companyMeta.businessLicenseFileMeta"
            >
              <i class="icon icon-attach text-24 mr-1"></i>
              <span class="text-13 text-gray mr-auto">{{
                input.companyMeta.businessLicenseFileMeta.originalFilename
              }}</span>
            </div> -->
          </b-col>
        </b-row>
      </template>
      <div class="pt-5 border-top">
        <b-row align-h="center" class="mt-5">
          <b-col cols="12" md="8">
            <b-btn
              variant="primary"
              class="w-100 py-4"
              @click="edit"
              :disabled="inprogress"
            >
              <span class="text-18">수정</span>
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-form>
    <!-- modals -->
    <modal-change-password />
    <!-- input : {{ input }} <br /><br />
    serverVaild : {{ serverVaild }} -->
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import Modals from "@/components/Modals/index";

export default {
  components: {
    InputFile: Form.InputFile,
    ModalChangePassword: Modals.ChangePassword,
  },
  data() {
    return {
      original: null,
      input: {
        id: null,
        serviceId: null,
        mobile: null,
        profile: null,
        businessCard: null,
        counselCode: null,
        bankAccountMeta: {
          bank: null,
          accountHolder: null,
          accountNumber: null,
        },
        counselInfo: {
          id: null,
          name: null,
          lcNum: null,
          corpName: null,
          finInfoName: null,
          gubun: null,
          conDate: null,
          cancelDate: null
        },
        companyMeta: {
          companyName: null,
          businessLicenseFileMeta: {
            url: null,
            originalFilename: null,
            filename: null,
            mimeType: null,
            timeCreated: null,
            size: 0,
            extension: null,
          },
          loanLicenseFileMeta: {
            url: null,
            originalFilename: null,
            filename: null,
            mimeType: null,
            timeCreated: null,
            size: 0,
            extension: null,
          },
        },
        temporaryPassword: false,
        termsAgree: { taService: true, taPrivacy: true, taMarketing: true },
        createdDate: null,
        updatedDate: null,
        role: null,
        loginType: null,
      },
      serverVaild: {
        // serviceId: false,
        mobile: true,
        certNumber: false,
        certNumberConfirm: false,
      },
      // 새 프로필, 명함
      newProfile: null,
      newBusinessCard: null,

      // 핸드폰번호변경중에 수정버튼 못누르게 함
      inprogress: false,

      timerActive: false,
    };
  },
  computed: {
    valid() {
      const rules = {
        mobile: [
          (v) => !!v || "휴대폰번호를 입력하세요",
          (v) => v.length >= 10 || "핸드폰 번호 양식을 확인해 주세요.",
          (v) =>
            this.serverVaild.mobile ||
            "이 핸드폰 번호로 변경하려면 인증을 진행해주세요.",
        ],
      };
      const ruleChecker = (rules, value) => {
        return rules.reduce(
          (result, rule) => {
            if (result.state === true) {
              const r = rule(value);

              const state = r === true;
              const msg = r === true ? null : r;

              return { state, msg };
            } else if (result.state === false) {
              return result;
            } else {
              return { state: true, msg: null };
            }
          },
          { state: true, msg: null }
        );
      };
      const mobile = ruleChecker(rules.mobile, this.input.mobile);

      return {
        mobile,
        // companyMeta: {
        //   companyName:
        //     this.input.companyMeta === null
        //       ? null
        //       : this.input.companyMeta.companyName !== "" &&
        //         this.input.companyMeta.companyName &&
        //         this.input.companyMeta.companyName.length >= 0,
        //   certificate: this.input.companyMeta === null ? false : true,
        //   license: this.input.companyMeta === null ? false : true,
        //   terms: !this.input.terms1 || !this.input.terms2 ? false : true,
        // },
        // bankAccountMeta: {
        //   bank:
        //     this.input.bankAccountMeta.bank === null
        //       ? null
        //       : this.input.bankAccountMeta.bank !== "",
        //   accountHolder:
        //     this.input.bankAccountMeta.accountHolder === null
        //       ? null
        //       : this.input.bankAccountMeta.accountHolder !== "",
        //   accountNumber:
        //     this.input.bankAccountMeta.accountNumber === null
        //       ? null
        //       : this.input.bankAccountMeta.accountNumber !== "" &&
        //         this.input.bankAccountMeta.accountNumber.length > 5,
        // },
      };
    },
  },
  methods: {
    async withdrawal() {
      const bool = await window.confirm("정말 탈퇴하시겠습니까?", {
        title: "회원탈퇴",
        bodyClass: "text-center",
        okTitle: "회원탈퇴",
      });
      if (bool) {
        try {
          const { data } = await this.$axios.delete(`/users/me`);
          if (data) {
            localStorage.removeItem("homeBank__token");
            sessionStorage.removeItem("homeBank__token");
            this.$store.commit("set", ["user", null]);
            axios.defaults.headers.common["Authorization"] = undefined;
            this.$router.push("/");
            // console.log(data);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async inputProfile(e) {
      const file = e.target.files[0];
      const data = await this.attachFile(file);
      // console.log(data);
      this.input.profile = data.filename;
      // this.$emit("input", data);
    },
    async inputbusinessCard(e) {
      const file = e.target.files[0];
      const data = await this.attachFile(file);
      // console.log(data);
      this.input.businessCard = data.filename;

      // this.$emit("input", data);
    },
    // 유효성검사 - mobile
    async validMobile(e) {
      const cb = async () => {
        try {
          const { data } = await this.$axios.post("/users/valid-mobile", {
            mobile: this.input.mobile,
          });
          if (data.status.code === "0000") {
            this.serverVaild.mobile = true;
            this.sendCertNumber();
            this.countdown("timer", 3, 0);
            this.inprogress = true;
          } else if (data.status.code === "1002") {
            window.alert(
              `기존 핸드폰번호와 동일합니다.<br>혹은 이미 사용중인 핸드폰번호입니다.`,
              {
                title: "핸드폰번호 중복",
              }
            );
          }
        } catch (error) {
          console.error(error);
        }
      };

      this.serverVaild.mobile = false;
      if (this.timeChecker) clearTimeout(this.timeChecker);
      if (
        this.valid.mobile.msg ===
        "이 핸드폰 번호로 변경하려면 인증을 진행해주세요."
      ) {
        this.timeChecker = setTimeout(cb, 500);
      }
    },
    countdown(element, minutes, seconds) {
      if (this.timerActive) clearInterval(this.timerActive);
      var time = minutes * 60 + seconds;
      this.timerActive = setInterval(function () {
        var el = document.getElementById(element);
        if (!el) return;
        if (time <= 0) {
          var text = "00:00";
          el.innerHTML = text;
          setTimeout(function () {
            countdown("clock", 0, 5);
          }, 2000);
          clearInterval(this.timerActive);
          this.timerActive = null;
          return;
        }
        var minutes = Math.floor(time / 60);
        if (minutes < 10) minutes = "0" + minutes;
        var seconds = time % 60;
        if (seconds < 10) seconds = "0" + seconds;
        var text = minutes + ":" + seconds;
        el.innerHTML = text;
        time--;
      }, 1000);
    },
    // 인증번호 요청 - 문자
    async sendCertNumber() {
      try {
        const { data } = await this.$axios.post("/cert/mobile", {
          email: this.input.serviceId,
          mobile: this.input.mobile,
          mode: "CERT_MOBILE",
        });
        if (data.status.code === "0000") {
          await window.alert("인증번호를 발송했습니다", {
            title: "핸드폰 번호 인증",
          });
          this.serverVaild.certNumber = true;
          this.countdown("timer", 3, 0);
          this.inprogress = true;

          // console.log(data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 인증번호 확인 - 문자
    async certNumberConfirm() {
      try {
        const { data } = await this.$axios.post("/cert/mobile/confirm", {
          code: this.input.certNumber,
          mobile: this.input.mobile,
          mode: "CERT_MOBILE",
        });
        if (data.status.code === "0000") {
          window.alert("인증이 완료되었습니다. 회원정보 수정을 완료해주세요.", {
            title: "핸드폰 번호 인증 완료",
          });
          this.inprogress = false;
          this.serverVaild.certNumberConfirm = true;
          var el = document.getElementById("timer");
          el.style.display = "none";
        } else if (data.status.code === "1003") {
          await window.alert(`인증 번호를 다시 확인해주세요.`, {
            title: "인증 오류",
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async edit() {
      if (
        this.original.mobile !== this.input.mobile &&
        (!this.serverVaild.mobile ||
          !this.serverVaild.certNumber ||
          !this.serverVaild.certNumberConfirm)
      ) {
        await window.alert(
          `핸드폰 번호를 변경하려면 인증이 필요합니다. <br> 핸드폰 인증을 끝까지 진행해주세요.`,
          { title: "핸드폰 인증 필요" }
        );
        // window.location.reload();
        return;
      } else {
        try {
          const { data } = await this.$axios.put("/users/me", this.input);
          if (data.status.code === "0000") {
            const bool = await window.alert(
              "회원정보가 정상적으로 변경되었습니다.",
              {
                title: "회원정보 변경 완료",
                okTitle: "확인",
              }
            );
            if (bool) {
              window.location.reload();
            }
          } else {
            window.alert(data.status.message);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    reset(item) {
      if (item === "profile") {
        this.input.profile = null;
      } else if (item === "businessCard") {
        this.input.businessCard = null;
      }
    },
  },
  mounted() {
    this.input = { ...this.user };
    this.original = { ...this.user };

    console.log(this.input);
    console.log(this.original);
  },
  beforeDestroy() {
    clearInterval(this.timerActive);
  },

};
</script>

<style lang="scss" scoped></style>
