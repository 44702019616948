<template>
  <div class="layout">
    <b-container>
      <b-row align-h="center">
        <b-col
          cols="12"
          md="3"
          lg="3"
          xl="2"
          class="py-3 py-md-5 border-right min-md-vh-100"
        >
          <b-row align-h="center">
            <b-col cols="12" md="12">
              <header class="px-lg-3 mb-md-5">
                <figure
                  class="
                    rounded-sm
                    d-flex
                    flex-row flex-md-column
                    align-items-center
                    justify-content-lg-center
                  "
                >
                  <div
                    class="
                      myphoto
                      p-1
                      border border-light-gray
                      rounded
                      mr-3 mr-md-0
                      position-relative
                    "
                    :style="{
                      minWidth: '8.5rem',
                      minHeight: '8.5rem',
                    }"
                  >
                    <template v-if="user.profile">
                      <div
                        class="bg-img w-100 ratio-100"
                        :style="{
                          backgroundImage: `url(${s3 + user.profile})`,
                        }"
                      ></div>
                    </template>
                    <template v-else-if="!user.profile">
                      <b-avatar
                        square
                        :style="{
                          width: '100%',
                          height: '100%',
                          minWidth: '8.5rem',
                          minHeight: '8.5rem',
                        }"
                        variant="primary"
                        :src="null"
                      ></b-avatar>
                    </template>
                    <template v-if="user.loginType !== 'EMAIL'">
                      <div
                        class="bg-img position-absolute rounded-circle p-1"
                        :class="`bg-${snsId(user.serviceId).variant}`"
                        :style="{
                          top: '-8px',
                          right: '-8px',
                          zIndex: '3',
                        }"
                      >
                        <div
                          class="bg-img"
                          :style="{
                            width: '24px',
                            minWidth: '24px',
                            paddingBottom: '24px',
                            backgroundImage: `url(${
                              snsId(user.serviceId).type
                            })`,
                          }"
                        ></div>
                      </div>
                    </template>
                  </div>
                  <figcaption
                    class="
                      d-flex
                      flex-column
                      align-items-md-center
                      overflow-hidden
                    "
                  >
                    <span
                      class="mt-2 mb-3 text-16 text-gray text-truncate"
                      :style="{ maxWidth: '180px' }"
                    >
                      <template v-if="user.loginType === 'EMAIL'">
                        <!-- {{
                          user.role === "REALTOR" || user.role === "FINANCE"
                            ? onlyId(user.serviceId)
                            : user.companyMeta.companyName
                        }} -->
                        {{
                          user.role === "COUNSELOR"
                            ? !user.counselInfo
                              ? onlyId(user.serviceId) 
                              : user.counselInfo.finInfoName +'_'+ user.counselInfo.name
                            : user.companyMeta.companyName
                        }}
                      </template>
                      <template v-if="user.loginType !== 'EMAIL'">
                        {{ snsId(user.serviceId).id }}
                      </template>
                    </span>

                    <!-- 마이페이지 _ 개인 토글버튼 (v-if="user.role !== 'USER'")-->
                    {{ $store.state.user.role }}
                    {{ $store.state.user.mypageStatus }}
                    <b-btn
                      variant="outline-primary"
                      class="px-3"
                      @click="userTypeToggle"
                    >
                      {{
                        userType === "bidder"
                          ? "의뢰자로 전환하기"
                          : "입찰자로 전환하기"
                      }}
                    </b-btn>
                  </figcaption>
                </figure>
              </header>
            </b-col>
            <b-col cols="12" md="12">
              <article>
                <header
                  class="pb-3"
                  :style="{ borderBottom: '3px solid #333' }"
                >
                  <h5 class="d-none d-md-block">마이페이지</h5>
                </header>
                <article>
                  <b-row
                    class="list-unstyled bg-light py-3 d-block d-md-none"
                    v-if="$route.path == '/mypage/history'"
                  >
                    <b-col cols="12" class="mb-md-3">
                      <div
                        block
                        class="
                          lnb-category
                          d-flex
                          align-items-cneter
                          justify-content-between
                          px-0
                          py-2
                          text-17
                        "
                      >
                        <span>나의 비딩매칭 내역</span>
                        <i class="icon icon-caret text-gray"></i>
                      </div>
                      <template
                        v-if="
                          sidebarLinks[0].list && sidebarLinks[0].list.length
                        "
                      >
                        <b-row class="list-unstyled bg-light py-1">
                          <b-col
                            cols="6"
                            class="my-1"
                            v-for="(link, l) in sidebarLinks[0].list"
                            :key="l"
                          >
                            <router-link
                              class="lnb-link text-14 text-nowrap px-3 px-md-0"
                              :to="{ query: { type: link.url } }"
                              >{{ link.name }} ({{
                                link && link.count ? getLength(link.count) : 0
                              }})
                              <i
                                class="icon icon-is-new icon-16 ml-1 mb-n1"
                                v-if="getState(link.state)"
                              ></i>
                            </router-link>
                          </b-col>
                        </b-row>
                      </template>
                    </b-col>
                  </b-row>
                  <section
                    class="mb-1 border-0 d-none d-md-block"
                    v-for="(item, i) in sidebarLinks"
                    :key="i"
                  >
                    <!-- 
                         프린트 내용대로 의뢰자 카테고리에 
                         수익정산 페이지 & 평점관리 메뉴 보이게 했으나, 
                         클릭시 의뢰자는 평점을 볼 수 없다고함 -->
                    <template v-if="item.userType === null">
                      <router-link
                        block
                        :to="`/mypage/${item.url}`"
                        class="
                          lnb-category
                          d-flex
                          align-items-cneter
                          justify-content-between
                          px-0
                          py-2
                          text-17
                        "
                      >
                        <span
                          >{{ item.category }}

                          <!-- 마이페이지 new 아이콘 -->
                          <template v-if="item.state">
                            <i
                              class="icon icon-is-new icon-16 ml-1 mb-n1"
                              v-if="getState(item.state) === true"
                            ></i>
                          </template>
                        </span>
                        <i
                          class="icon icon-caret text-gray"
                          v-if="item.list"
                        ></i>
                      </router-link>
                      <template
                        v-if="
                          sidebarLinks[0].list && sidebarLinks[0].list.length
                        "
                      >
                        <!-- -->
                        <b-row
                          class="list-unstyled bg-light py-3"
                          v-if="item.list && meta.showLnb"
                        >
                          <b-col
                            cols="12"
                            md="12"
                            class="mb-md-3"
                            v-for="(link, l) in item.list"
                            :key="l"
                          >
                            <router-link
                              class="lnb-link text-14 text-nowrap px-3 px-md-0"
                              :to="{ query: { type: link.url } }"
                              >{{ link.name }} ({{
                                link && link.count ? getLength(link.count) : 0
                              }})
                              <i
                                class="icon icon-is-new icon-16 ml-1 mb-n1"
                                v-if="getState(link.state)"
                              ></i>
                            </router-link>
                          </b-col>
                        </b-row>
                      </template>
                    </template>
                    <template v-else>
                      <router-link
                        block
                        :to="`/mypage/${item.url}`"
                        class="
                          lnb-category
                          d-flex
                          align-items-cneter
                          justify-content-between
                          px-0
                          py-2
                          text-17
                        "
                      >
                        <span>{{ item.category }}</span>
                        <i
                          class="icon icon-caret text-gray"
                          v-if="item.list"
                        ></i>
                      </router-link>
                    </template>
                  </section>
                </article>
              </article>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="9" lg="9" xl="10" class="py-5">
          <router-view :userType="userType" class="px-3" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  data() {
    return {
      userRole: this.$store.state.user.role,
      userType: this.$store.state.user.mypageStatus.toLowerCase(),
      path: this.$route.path,
      sidebarLinks: [
        {
          category: "나의 비딩매칭 내역",
          url: "history?type=all",
          list: [
            {
              name: "전체",
              url: "all",
              state: "totalUpdated",
              count: "totalCount",
            },
            {
              name: "진행중",
              url: "ING",
              state: "ingUpdated",
              count: "ingCount",
            },
            {
              name: "매칭 완료",
              url: "MATCHED",
              state: "matchedUpdated",
              count: "matchedCount",
            },
            {
              name: "승인 완료",
              url: "APPROVAL",
              state: "approvalUpdated",
              count: "approvalCount",
            },
            {
              name: "자서 완료",
              url: "AUTOGRAPH",
              state: "autographUpdated",
              count: "autographCount",
            },
            {
              name: "송금 완료",
              url: "TRANSFERRED",
              state: "transferredUpdated",
              count: "transferredCount",
            },
            {
              name: "대출 완료",
              url: "COMPLETE",
              state: "completeUpdated",
              count: "completeCount",
            },
            {
              name: "진행 취소",
              url: "CANCEL_PROGRESS",
              state: "cancelProgressUpdated",
              count: "cancelProgressCount",
            },
          ],
          userType: null,
        },
        {
          category: "평점 확인",
          url: "check-grade",
          userType: this.userType,
        },
        {
          category: "알림",
          url: "notification",
          userType: null,
          state: "notificationUpdated",
        },
        {
          category: "회원정보 수정",
          url: "edit-info",
          userType: null,
        },
        {
          category: "1:1 문의",
          url: "contact",
          userType: null,
        },
        {
          category: "수익/정산",
          url: "benefit",
          userType: null,
        },
      ],
      items: null,
      currentState: {
        totalCount: null,
        totalUpdated: null,
        ingCount: null,
        ingUpdated: null,
        matchedCount: null,
        matchedUpdated: null,
        approvalCount: null,
        approvalUpdated: null,
        autographCount: null,
        autographUpdated: null,
        transferredCount: null,
        transferredUpdated: null,
        completeCount: null,
        completeUpdated: null,
        cancelProgressCount: null,
        cancelProgressUpdated: null,
        notificationUpdated: null,
      },
    };
  },
  watch: {
    userType(e) {
      this.getCuurentState();

      if(this.path.includes('/history')){
        this.getBiddingHistory();
      }
    },
  },
  methods: {
    userTypeToggle() {
      // 3/31회의 결과를 토대로 승인되지 않은 계정은 입찰자, 의뢰자 전환 제한

      // 관리자 승인이 안된 경우
      if (this.$store.state.user.status === "WAITING") {
        alert(
          "아직 승인되지 않은 계정입니다. \n  승인 후 이용 가능한 서비스 입니다."
        );
        //this.$router.replace("/matching");
        return;

        //관리자 승인은 됐으나, 권한이 공인중개사 또는 금융사인 경우
      } else if (
        this.$store.state.user.role === "REALTOR" ||
        this.$store.state.user.role === "FINANCE"
      ) {
        alert(" 이용할 수 없는 서비스 입니다.");
        return;
      }

      if (this.$store.state.user.mypageStatus === "BIDDER") {
        this.userType = "client";
      } else if (this.$store.state.user.mypageStatus === "CLIENT") {
        this.userType = "bidder";
      }
    },
    // 3/31일 회의 결과를 토대로
    async setMypageStatus() {
      try {
        const { data } = await this.$axios.get(
          `/bidding-history/mypage-status`,
          {
            params: { status: this.userType.toUpperCase() },
          }
        );

        if (data.status.code === "0000") {
          this.getMe(data.data); //store에 값 변경
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getBiddingHistory() {
      try {
        const { data } = await this.$axios.get(
          `/bidding-history/${this.userType}`
        );
        if (data.status.code === "0000") {
          this.items = data.data;
          this.setMypageStatus();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCuurentState() {
      try {
        const { data } = await this.$axios.get(
          `/bidding-history/${this.userType}/current-state`
        );
        if (data.status.code === "0000") {
          this.currentState = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    getState(progress) {
      const states = {
        totalUpdated: this.currentState.totalUpdated,
        ingUpdated: this.currentState.ingUpdated,
        matchedUpdated: this.currentState.matchedUpdated,
        approvalUpdated: this.currentState.approvalUpdated,
        autographUpdated: this.currentState.autographUpdated,
        transferredUpdated: this.currentState.transferredUpdated,
        completeUpdated: this.currentState.completeUpdated,
        cancelProgressUpdated: this.currentState.cancelProgressUpdated,
        notificationUpdated: this.currentState.notificationUpdated,
      };
      return states[progress] || false;
    },
    getLength(length) {
      if (this.currentState.totalCount) {
        const updates = {
          totalCount: this.currentState.totalCount,
          ingCount: this.currentState.ingCount,
          matchedCount: this.currentState.matchedCount,
          approvalCount: this.currentState.approvalCount,
          autographCount: this.currentState.autographCount,
          transferredCount: this.currentState.transferredCount,
          completeCount: this.currentState.completeCount,
          cancelProgressCount: this.currentState.cancelProgressCount,
        };
        return updates[length] || 0;
      } else {
        return 0;
      }
    },
  },
  async mounted() {
    await this.getBiddingHistory();
    await this.getCuurentState();
  },
};
</script>
