<template>
  <b-container class="my-5 py-5">
    <!-- <b-btn @click="test()">테스트</b-btn> -->
    <b-btn @click="test2()">테스트</b-btn>
    <b-tabs
      content-class="border p-3 p-md-5"
      nav-class="text-14 text-lg-15 fw-700 text-nowrap"
      justified
    >
      <b-tab title="비딩 매칭" active>
        <b-row align-h="center">
          <b-col cols="10" md="8" lg="7">
            <header class="pb-4 pb-md-5 text-center">
              <h2 class="mb-3 text-18 text-lg-24">비딩매칭</h2>
            </header>
            <b-form @submit.prevent.stop="upload">
              <!-- ============================ 기본 정보 ============================ -->
              <fieldset>
                <legend class="mb-4 p-3 text-17 bg-primary text-white">
                  기본 정보
                </legend>
                {{ addressObj }}
                <!-- (필수)주소 -->
                <template>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <daum-address v-model="addressObj" />
                </template>
                <!-- (필수)동 SHOW -->
                <template v-if="isShowDong">
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-form-select
                    v-model="selectedDong"
                    class="mb-4"
                    @change="dongChange($event)"
                  >
                    <option :value="null" selected>동을 선택해주세요</option>
                    <option
                      v-for="(item, idx) in dongArr"
                      :value="item"
                      :key="idx"
                    >
                      {{ item }}
                    </option>
                  </b-form-select>
                </template>
                <!-- (필수)호수 SHOW -->
                <template v-if="isShowHo">
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-form-select
                    v-model="selectedHo"
                    class="mb-4"
                    v-if="isShowHo"
                    @change="hoChange($event, selectedDong)"
                  >
                    <option :value="null" selected>호를 선택해주세요</option>
                    <option
                      v-for="(item, idx) in hoArr"
                      :value="item"
                      :key="idx"
                    >
                      {{ item }}
                    </option>
                  </b-form-select>
                </template>
                <!-- (필수)물건종류 -->
                <template>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-form-select
                    v-model="input.productKind"
                    :options="kind"
                    @change="kindSelect"
                    class="mb-4"
                  ></b-form-select>
                </template>
                <!-- (필수)대출종류 SHOW -->
                <template v-if="input.productKind">
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-form-select
                    v-model="input.productKind2"
                    :options="kind2"
                    class="mb-4"
                  ></b-form-select>
                </template>
                <!-- 0810 진행종류가 매매자금인 경우 필수 입력 추가  -->
                <template v-if="input.productKind2==='KIND2_2'">
                  <fieldset>
                    <legend class="mb-4 p-3 text-17 bg-primary text-white">
                      매매내용
                    </legend>
                    <b-row>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <!-- 20230622 매도자 명칭 to 소유자 -->
                        <b-input-group class="mb-4" variant="outline" hide-prepend>
                          <b-form-input
                            type="text"
                            v-model="tradeDetailsMeta.seller"
                            placeholder="소유자*"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-input-group class="mb-4" variant="outline" hide-prepend>
                          <b-form-input
                            type="text"
                            v-model="tradeDetailsMeta.buyer"
                            placeholder="매수자*"
                          ></b-form-input>
                      </b-input-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-input-group class="mb-4" variant="outline" hide-prepend>
                          <b-form-input
                            type="text"
                            v-model="tradeDetailsMeta.saleAmount"
                            placeholder="매매금액*"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                      <b-col>
                        <span class="text-17 text-danger">
                          <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                        </span>
                        <b-input-group class="mb-4" variant="outline" hide-prepend>
                          <b-form-input
                            type="text"
                            v-model="tradeDetailsMeta.balanceDate"
                            placeholder="잔금일* ex) 2022-08-12"
                          ></b-form-input>
                      </b-input-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </template>
                <!-- (필수)소유자 -->
                <template>
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                  </span>
                  <b-input-group class="mb-4" variant="outline" hide-prepend>
                    <b-form-input
                      type="text"
                      v-model="input.holder"
                      placeholder="소유자*"
                    ></b-form-input>
                  </b-input-group>
                </template>
                <!-- ============================ 소유자 공동명의 ====================== -->
                <fieldset class="mt-5">
                  <legend class="mb-4 p-3 text-17 bg-primary text-white">
                    소유자 공동명의
                  </legend>
                  <!-- 공동명의 유무 -->
                  <template>
                    <b-form-radio-group v-model="input.jointName">
                      <b-form-radio
                        class="fw-400 d-inline-flex mr-5"
                        :value="true"
                      >
                        유 (2인 이상)
                      </b-form-radio>
                      <b-form-radio class="fw-400 d-inline-flex" :value="false">
                        무
                      </b-form-radio>
                    </b-form-radio-group>
                    <!-- 공동 소유자 / 지분 대출 SHOW -->
                    <template v-if="input.jointName">
                      <!-- 공동 소유자 입력 -->
                      <b-input-group
                        variant="outline"
                        class="mt-3"
                        hide-prepend
                        :style="{ gap: '1rem' }"
                      >
                        <w-input
                          class="m-0"
                          type="text"
                          ref="jointNameHolder"
                          v-model="jointNameHolder"
                          placeholder="공동 소유자명*"
                        ></w-input>
                        <b-form-select v-model="jointNameRelation">
                          <b-form-select-option
                            v-for="item in relation"
                            v-bind:key="item.id"
                            :value="item.value"
                            >{{ item.text }}
                          </b-form-select-option>
                        </b-form-select>
                        <b-btn
                          variant="primary"
                          v-on:click="add(jointNameHolder, jointNameRelation)"
                        >
                          추가
                        </b-btn>
                      </b-input-group>
                      <!-- 공동 소유자 출력 -->
                      <template v-if="input.jointName">
                        <div
                          v-for="(item, i) in input.biddingJointName"
                          :key="i"
                          class="align-items-center text-17"
                        >
                          <template v-if="!item.delete">
                            <template v-if="!item.edit">
                              <b-input-group
                                variant="outline"
                                class="mt-3 justify-content-between"
                                hide-prepend
                                :style="{ gap: '1rem' }"
                              >
                                <w-input
                                  readonly
                                  class="m-0"
                                  type="text"
                                  ref="jointNameHolder"
                                  v-model="item.jointNameHolder"
                                ></w-input>
                                <b-form-select v-model="item.relation" disabled>
                                  <b-form-select-option
                                    v-for="item in relation"
                                    v-bind:key="item.id"
                                    :value="item.value"
                                    >{{ item.text }}
                                  </b-form-select-option>
                                </b-form-select>
                                <b-btn
                                  size="sm"
                                  variant="outline-dark"
                                  @click="editBtn(i)"
                                  >수정</b-btn
                                >
                                <b-btn
                                  size="sm"
                                  variant="outline-danger"
                                  @click="deleteBtn(i)"
                                  >삭제</b-btn
                                >
                              </b-input-group>
                            </template>
                            <template v-else>
                              <b-input-group
                                variant="outline"
                                class="mt-3"
                                hide-prepend
                                :style="{ gap: '1rem' }"
                              >
                                <w-input
                                  class="m-0"
                                  type="text"
                                  ref="jointNameHolder"
                                  v-model="item.jointNameHolder"
                                  placeholder="공동 소유자명"
                                ></w-input>
                                <b-form-select v-model="item.relation">
                                  <b-form-select-option
                                    v-for="item in relation"
                                    v-bind:key="item.id"
                                    :value="item.value"
                                    >{{ item.text }}
                                  </b-form-select-option>
                                </b-form-select>
                                <b-btn
                                  size="sm"
                                  variant="outline-dark"
                                  @click="jointNameSave(item, i)"
                                  >저장</b-btn
                                >
                                <b-btn
                                  size="sm"
                                  variant="outline-danger"
                                  @click="cancelBtn(item, i)"
                                  >취소</b-btn
                                >
                              </b-input-group>
                            </template>
                          </template>
                        </div>
                      </template>
                      <!-- 지분 대출 -->
                      <div>
                        <legend class="mb-3 p-3 text-17 bg-lighter">
                          지분 대출
                        </legend>
                        <b-form-radio-group v-model="input.shareLoan">
                          <b-form-radio
                            class="fw-400 d-inline-flex mr-5"
                            :value="false"
                          >
                            본인지분
                          </b-form-radio>
                          <b-form-radio
                            class="fw-400 d-inline-flex"
                            :value="true"
                          >
                            공동지분
                          </b-form-radio>
                        </b-form-radio-group>
                         <!-- 0810 공동소유자명이 있고, 본인 지분인 경우 필수 입력 추가  -->
                        <w-input
                          class="m-0"
                          type="text"
                          ref="jointNameHolder"
                          v-model="input.selfRatio"
                          placeholder="본인 지분율*"
                          v-if="!input.shareLoan"
                        ></w-input>
                      </div>
                    </template>
                  </template>
                </fieldset>
                <div class="mt-5">
                  <legend class="mb-4 p-3 text-17 bg-lighter">
                    소유자 연락처 / 요청금액
                  </legend>
                  <!-- 전화번호 -->
                  <template>
                    <!-- 0810 진행종류가 무설정론인 경우 필수 표시 추가  -->
                    <span class="text-17 text-danger" v-if="input.productKind2==='KIND2_4'">
                      <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                    </span>
                    <b-input-group class="mb-4" variant="outline" hide-prepend>
                      <w-input
                        class="w-100 m-0"
                        type="tel"
                        v-model="input.phone"
                        @input.native="$numberOnly($event, 'phone', 11)"
                        placeholder="소유자 전화번호"
                      ></w-input>
                    </b-input-group>
                    <!-- 0810 진행종류가 무설정론인 경우 필수 입력 추가  -->
                    <b-input-group v-if="input.productKind2==='KIND2_4'" class="mb-4" variant="outline" hide-prepend>
                      <span class="text-17 text-danger">
                        <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                      </span>
                      <w-input
                        class="w-100 m-0"
                        type="tel"
                        v-model="input.birthDate"
                        @input.native="$numberOnly($event, 'birthDate', 8)"
                        placeholder="생년월일* ex) 19910812"
                      ></w-input>
                    </b-input-group>
                  </template>
                  <!-- 요청금액 -->
                  <template>
                    <b-input-group class="mb-0" variant="outline" hide-prepend>
                      <w-input
                        class="w-100 m-0"
                        type="text"
                        v-model="input.amount"
                        @input.native="$inputPrice($event, 'amount')"
                        placeholder="요청금액(만원)"
                      ></w-input>
                    </b-input-group>
                  </template>
                </div>
              </fieldset>
              <!-- ============================ 재직 정보 ============================ -->
              <fieldset class="mt-5">
                <legend class="mb-4 p-3 text-17 bg-primary text-white">
                  재직 정보
                </legend>
                <!-- (필수)재직정보 -->
                <template>
                  <p class="text-17">
                    <span class="text-17 text-danger">
                      <font-awesome-icon icon="fa-solid fa-asterisk" /> 필수
                    </span>
                    소유자 재직상태를 선택해주세요.
                    <span class="fw-900" v-if="input.work"
                      >( {{ input.work }} )
                    </span>
                  </p>
                  <b-form-radio-group
                    stacked
                    :options="work"
                    v-model="input.work"
                    class="fw-400 text-17"
                  ></b-form-radio-group>
                  <!-- 신용결여 SHOW -->
                  <template v-if="input.work === 'BADCREDIT'">
                    <legend class="my-3 p-3 text-17 bg-lighter">
                      신용 현황
                    </legend>
                    <b-row>
                      <b-col>
                        <b-form-input
                          type="text"
                          v-model="input.creditScore"
                          placeholder="신용점수"
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <b-form-input
                          type="text"
                          v-model="input.totalTimes"
                          placeholder="총 납기회차"
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <b-form-input
                          type="text"
                          v-model="input.currentTimes"
                          placeholder="현재 회차"
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <b-form-input
                          type="text"
                          v-model="input.defaultTimes"
                          placeholder="미납 회차"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </template>
                </template>
              </fieldset>

              <!-- ============================ 세입자 정보 ========================== -->
              <fieldset class="mt-5">
                <legend class="mb-4 p-3 text-17 bg-primary text-white">
                  세입자 정보
                </legend>
                <!-- 세입자 유무 -->
                <b-form-radio-group v-model="input.tenant">
                  <b-form-radio class="fw-400 d-inline-flex mr-5" :value="true">
                    유
                  </b-form-radio>
                  <b-form-radio class="fw-400 d-inline-flex" :value="false">
                    무
                  </b-form-radio>
                </b-form-radio-group>
                <!-- 세입자 상세정보 SHOW -->
                <template v-if="input.tenant">
                  <legend class="my-3 p-3 text-17 bg-lighter">상세 정보</legend>
                  <b-row>
                    <b-col>
                      <b-input-group class="m-0" variant="outline" hide-prepend>
                        <w-input
                          class="w-100 m-0"
                          type="text"
                          v-model="input.deposit"
                          @input.native="$inputPrice($event, 'deposit')"
                          placeholder="보증금(만원)*"
                        ></w-input>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <b-input-group class="m-0" variant="outline" hide-prepend>
                        <w-input
                          class="w-100 m-0"
                          type="text"
                          v-model="input.monthlyRent"
                          @input.native="$inputPrice($event, 'monthlyRent')"
                          placeholder="월세(만원)*"
                        ></w-input>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <b-form-radio-group v-model="input.tenantAgree">
                        <b-form-radio
                          class="fw-400 d-inline-flex mr-5"
                          :value="true"
                        >
                          대출 동의
                        </b-form-radio>
                        <b-form-radio
                          class="fw-400 d-inline-flex"
                          :value="false"
                        >
                          대출 미동의
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-col>
                  </b-row>
                </template>
              </fieldset>
              <!-- ============================ 등기부등본 업로드 ==================== -->
              <fieldset class="mt-5">
                <!-- <legend class="mb-4 p-3 text-17 bg-primary text-white">
                  등기부등본 업로드
                </legend> -->
                <!-- 업로드 버튼 -->
                <input-file
                  labelText="등기부등본 업로드(PDF)"
                  accept=".pdf"
                  v-model="input.file"
                  :holder="input.holder"
                  @owners="owners"
                  @fileDelete="fileDelete()"
                />
                <!-- 확인용 -->
                <div>
                  <strong> 주 의 사 항 </strong>
                  <!-- <p v-if="registerCountMeta">registerCountMeta : {{ registerCountMeta }}</p> -->
                  <p v-if="owner">owner : {{ owner }}</p>
                  <hr />
                </div>
              </fieldset>
              <!-- ============================ 의뢰등록 버튼 ======================== -->
              <div class="my-5 text-center">
                <b-btn
                  variant="primary"
                  class="w-75 py-3"
                  @click="upload"
                  :disabled="submitted"
                >
                  {{ submitted ? "등록중입니다..." : "비딩매칭 의뢰등록" }}
                </b-btn>
              </div>
            </b-form>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="비딩 매칭이란?">
        <header class="text-28 text-lg-32 text-center pt-5">
          <strong
            class="text-primary pb-1 border-primary"
            :style="{ borderBottom: '3px solid black' }"
            >비딩 매칭</strong
          >이란?
        </header>
        <section class="mb-5 p-5">
          <p class="text-14 text-lg-16 lh-180 text-center text-gray lh-188">
            비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나
            그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽
            요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종
            결과물에 들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인
            프로젝트 모형의 채움 글로도 이용된다. 이런 용도로 사용할 때 로렘
            입숨을 그리킹(greeking)이라고도 부르며, 때로 로렘 입숨은 공간만
            차지하는 무언가를 지칭하는 용어로도 사용된다. 로렘 입숨은 전통
            라틴어와 닮은 점 때문에 종종 호기심을 유발하기도 하지만 그 이상의
            의미를 담지는 않는다. 문서에서 텍스트가 보이면 사람들은 전체적인
            프레젠테이션보다는 텍스트에 담긴 뜻에 집중하는 경향이 있어서
            출판사들은 서체나 디자인을 보일 때는 프레젠테이션 자체에 초점을
            맞추기 위해 로렘 입숨을 사용한다. 로렘 입숨은 영어에서 사용하는
            문자들의 전형적인 분포에 근접하다고도 하는데, 이 점 때문에
            프레젠테이션으로 초점을 이동하는 데에도 도움을 준다.
          </p>
        </section>
      </b-tab>
      <b-tab title="비딩 매칭이 좋은 이유">
        <ul class="list-unstyled pb-5 m-3 m-md-0">
          <li class="mb-5 d-flex flex-column">
            <section class="my-2">
              <h5 class="text-18 text-lg-20 text-primary fw-700">
                Q. 비딩매칭이 좋은 이유는?
              </h5>
            </section>
            <section class="my-2">
              <h5 class="text-18 text-lg-20 text-secondary fw-700">
                A. 비딩매칭이 좋은 이유입니다.
              </h5>
              <p class="mt-3 pl-4 pr-md-5 text-14 lh-188 text-gray">
                비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은
                출판이나 그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃
                같은 그래픽 요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기
                텍스트로, 최종 결과물에 들어가는 실제적인 문장 내용이 채워지기
                전에 시각 디자인 프로젝트 모형의 채움 글로도 이용된다. 이런
                용도로 사용할 때 로렘 입숨을 그리킹(greeking)이라고도 부르며,
                때로 로렘 입숨은 공간만 차지하는 무언가를 지칭하는 용어로도
                사용된다. 로렘 입숨은 전통 라틴어와 닮은 점 때문에 종종 호기심을
                유발하기도 하지만 그 이상의 의미를 담지는 않는다. 문서에서
                텍스트가 보이면 사람들은 전체적인 프레젠테이션보다는 텍스트에
                담긴 뜻에 집중하는 경향이 있어서 출판사들은 서체나 디자인을 보일
                때는 프레젠테이션 자체에 초점을 맞추기 위해 로렘 입숨을
                사용한다. 로렘 입숨은 영어에서 사용하는 문자들의 전형적인 분포에
                근접하다고도 하는데, 이 점 때문에 프레젠테이션으로 초점을
                이동하는 데에도 도움을 준다.
              </p>
            </section>
          </li>
        </ul>
      </b-tab>
    </b-tabs>

    <template v-if="loadingInfo.show">
      <div
        class="text-center"
        style="
          position: fixed;
          left: 0px;
          top: 0px;
          background: rgba(0, 0, 0, 0.5);
          width: 100%;
          height: 100%;
          z-index: 300;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div>
          <b-spinner variant="white" class="my-3" />
          <p class="text-18 text-white m-0">{{ loadingInfo.text }}</p>
        </div>
      </div>
    </template>
  </b-container>
</template>

<script>
import Product from "@/lib/product";
import Form from "@/components/Form/index";
import DaumAddress from "@/components/DaumAddress.vue";
import WInput from "../../components/common/WInput.vue";
// import { asyncLoading } from "vuejs-loading-plugin";

export default {
  components: {
    InputFile: Form.InputFile,
    DaumAddress,
  },
  data() {
    return {
      result: [],
      kind: [
        {
          text: "주소 조회후 물건 종류를 선택할 수 있습니다.*",
          value: null,
        },
      ],
      kind2: null,
      addressObj: {},
      owner: null,
      registerCountMeta: null,
      input: {
        file: null,
        amount: null,
        deposit: null,
        holder: null,
        monthlyRent: null,
        phone: null,
        productKind: null,
        productKind2: null,
        registerFile: null,
        tenant: false,
        tenantAgree: true,
        work: null,
        modalShow: true,
        biddingJointName: [],
        jointName: false,
        creditScore: null,
        shareLoan: false,
        totalTimes: null,
        currentTimes: null,
        defaultTimes: null,
        selfRatio: null,    //0810 공동소유자 & 본인지분의 지분율 변수
        birthDate: null,    //0810 무설정론인 경우 생년월일 변수
      },
        tradeDetailsMeta: {},//0810 매매자금인 경우 변수map
      formValid: false,
      jointNameHolder: null,
      jointNameRelation: null,
      rules: {
        holder: [(v) => !!v || "소유자를 입력하세요."],
        //phone: [(v) => !!v || "소유자 전화번호를 입력하세요."],
      },
      work: [
        {
          text: "직장인",
          value: "EMPLOYEE",
        },
        {
          text: "주부(무직)",
          value: "HOUSEWIFE_N",
        },
        {
          text: "주부(생활비 내역 있음)",
          value: "HOUSEWIFE_Y",
        },
        {
          text: "실사업자",
          value: "BUSINESSMAN_Y",
        },
        {
          text: "가라사업자(매출 증빙 X)",
          value: "BUSINESSMAN_N",
        },
        {
          text: "프리랜서(통장증빙 가능)",
          value: "FREELANCER",
        },
        {
          text: "신용결여(회복/회생/파산/워크아웃 등)",
          value: "BADCREDIT",
        },
        {
          text: "외국인 거소증 유",
          value: "FOREIGNER_RESIDENT_Y",
        },
        {
          text: "외국인 거소증 무",
          value: "FOREIGNER_RESIDENT_N",
        },
      ],

      relation: [
        {
          text: "관계를 선택하세요. *",
          value: null,
        },
        {
          text: "부부",
          value: "MARRIED",
        },
        {
          text: "부모",
          value: "PARENT",
        },
        {
          text: "형제",
          value: "BROTHRES",
        },
        {
          text: "제 3자",
          value: "OTHER_PERSON",
        },
      ],
      submitted: false,
      selectedDong: null, //selectBox에서 선택한 동이 담길 변수
      selectedHo: null, //selectBox에서 선택한 호수가 담길 변수
      apiDongHo: [], //서버에서 조회해 동, 호수 보낸 값
      hoArr: null, //동 선택시 또는 동이 없는 경우 ho값이 담기는 변수
      dongArr: null, //동이 list로 담길 변수
      isShowDong: false,
      isShowHo: false,
      dateOfBirth: null,
      regValidFail: false,
      failId: null,
      text: null,
      apiExistJointName:false,  //api등본 조회시 공동 소유자 존재 체크
      loadingInfo: {
        show: false,
        text: null,
      },
      caution: null,
      //register: null,
      // kind1: [
      //   {
      //     text: "진행 물건 종류를 선택하세요.*",
      //     value: null,
      //   },
      // ]
    };
  },
  // 0809 테스트위해 추가
  // computed: {
  //   kind2() {
  //     return [
  //       {
  //         text: "진행 물건 종류를 선택하세요.*",
  //         value: null,
  //       },
  //       ...Product.kind2,
  //     ];
  //   },
  // },
  //==================================================
  watch: {
    //주소 검색시
    addressObj: function () {
      if (this.addressObj.addressDetailMeta) {
        this.findDongHo();
      }

      if (this.owner) {
        this.owner = null;
      }
    },
    //서버에서 조회한 값이 변수에 담기자마자
    apiDongHo: function () {
      if (this.input.productKind) {
        this.input.productKind = null;
        this.input.productKind2 = null;
      }

      if (this.hoArr || this.dongArr) {
        this.dongArr = [];
        this.isShowDong = false;

        this.hoArr = [];
        this.isShowHo = false;
      }

      this.selectedDong = null;
      this.selectedHo = null;

      //서버에서 받아온 값이 있을 경우 ( 조건문을 달지 않으면 콘솔watch err)
      if (this.apiDongHo && this.apiDongHo.length > 0) {
        this.isShowDong = this.apiDongHo[0].dongNm === "" ? false : true;
        this.isShowHo =
          this.apiDongHo[0].dongNm === "" && this.apiDongHo[0].hoNm !== ""
            ? true
            : false;

        //동만 따로 list에 담기
        const dongArray = this.apiDongHo.map((x) => {
          return x.dongNm;
        });
        this.dongArr = dongArray.sort((a, b) =>
          a.localeCompare(b, undefined, { numeric: true })
        );

        //호수만 있는 경우
        if (this.isShowHo) {
          const hoArray = this.apiDongHo.find(function (item) {
            return item.dongNm === "";
          });
          this.hoArr = hoArray.hoNm.sort((a, b) =>
            a.localeCompare(b, undefined, { numeric: true })
          );
        }
      } else {
        let kind = [];

        Product.kind.forEach(function (obj) {
          if (obj.text === "토지" || obj.text === "지분") {
            kind.push(obj);
          }
        });

        kind.unshift({
          text: "진행 물건 종류를 선택하세요.*",
          value: null,
        });
        this.kind = kind;
      }
    },
  },
  methods: {
    
    fileDelete() {
      this.input.file = null;
      this.registerCountMeta = null;
      this.owner = null;
      this.register = null;
    },
    async test2() {
      try {
        const { data } = await this.$axios.get("/test/kbPrice");

        if (data.status.code === "0000") {
          console.log("성공");
          console.log(data);
        } else {
          window.alert(data.status.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    owners(e) {
      if (this.input.file) {
        this.caution = e.caution;

        //this.registerCountMeta = e.registerCountMeta;
        this.owner = e.owners;
        //this.register = e.registerCountMeta.regsiterResBody;

        const owner = e.owners.map((x) => {
          return x.owner;
        });

        const ownership = e.owners.map((x) => {
          return x.ownership;
        });

        if (owner.includes(this.input.holder)) {
          if (
            ownership.includes("공유자") &&
            this.input.biddingJointName.length == 0
          ) {
            alert("공동소유자가 존재합니다. 소유자 공동명의를 입력해주세요. ");
          }
        } else {
          alert(
            "입력하신 소유자명과 등기부등본의 소유자명이 일치하지 않습니다. "
          );
        }
      } else {
        this.owner = null;
        //this.registerCountMeta = null;
        //this.regsiter = null;
      }
    },
    kindSelect(n) {
      let kind2 = [];

      Product.kind2.forEach(function (data) {
        data.key.forEach(function (obj) {
          if (obj === n) {
            kind2.push(data);
          }
        });
      });

      if (this.input.productKind2) {
        this.input.productKind2 = null;
      }

      this.kind2 = [
        {
          text: "진행 물건 종류를 선택하세요.*",
          value: null,
        },
        ...kind2,
      ];
    },
    // add(jointNameHolder, jointNameRelation){

    //   if(jointNameRelation !== null && jointNameHolder !== null){

    //     this.input.biddingJointName.push({  //배열에 추가
    //       jointNameHolder: jointNameHolder,
    //       relation: jointNameRelation
    //     })

    //     this.jointNameHolder = null
    //     this.jointNameRelation = null

    //   }else if(jointNameHolder === null){
    //     alert('공동 소유자명을 입력해주세요.');
    //   }else if(jointNameRelation === null){
    //     alert('관계를 선택해주세요.');
    //   }

    // },
    jointNameSave(item, i) {
      this.$set(this.input.biddingJointName, i, item);
      item.edit = !item.edit;
    },
    editBtn(index) {
      this.input.biddingJointName.forEach(function (el, idx) {
        if (index === idx) {
          el.edit = true;
        } else {
          el.edit = false;
        }
      }, this);
    },
    add(jointNameHolder, jointNameRelation) {
      if (jointNameRelation !== null && jointNameHolder !== null) {
        this.input.biddingJointName.push({
          jointNameHolder: jointNameHolder,
          relation: jointNameRelation,
          edit: false,
        });

        this.jointNameHolder = null;
        this.jointNameRelation = null;
      } else if (jointNameHolder === null) {
        alert("공동 소유자명을 입력해주세요.");
      } else if (jointNameRelation === null) {
        alert("관계를 선택해주세요.");
      }
    },
    cancelBtn(item, i) {
      item.edit = !item.edit;
    },
    deleteBtn(i) {
      this.input.biddingJointName.splice(i);
    },
    //============================================
    //동 selectBox 선택시
    dongChange(dongNm) {
      //선택한 동의 호수 list가져오기
      const hoArray = this.apiDongHo.find(function (item) {
        return item.dongNm === dongNm;
      });

      //가져온 호수 list 정렬 & 호수 selectBox 보여주기
      this.hoArr = hoArray.hoNm.sort((a, b) =>
        a.localeCompare(b, undefined, { numeric: true })
      );

      this.isShowHo = true;
    },

    //호 selectBox 선택시
    hoChange(hoNm, dongNm) {
      if (this.input.productKind) {
        this.input.productKind = null;
        this.input.productKind2 = null;
      }

      let arr;
      let mainPurps;

      if (!dongNm) {
        arr = this.apiDongHo.find(function (item) {
          return item.dongNm === "";
        });
      } else {
        arr = this.apiDongHo.find(function (item) {
          return item.dongNm === dongNm;
        });
      }

      Object.entries(arr.mainPurpsCdNm).map(([key, value]) => {
        if (key === hoNm) {
          mainPurps = value;
        }
      });

      let kind = [];

      // Product.kind.forEach(function(obj){
      //   if(obj.text.indexOf(mainPurps) > -1){
      //     kind.push(obj);
      //   }
      // });
      this.kind = [
        {
          text: "진행 물건 종류를 선택하세요.*",
          value: null,
        },
        ...Product.kind,
      ];
      // if(kind.length > 0 ){
      //   this.kind = [
      //     {
      //       text: "진행 물건 종류를 선택하세요.*",
      //       value: null,
      //     },
      //     ...kind
      //   ];
      // }else{
      //   this.kind = [
      //     {
      //       text: `"${mainPurps}"는(은) 진행할 수 없는 물건입니다.*`,
      //       value: null,
      //     },
      //   ];
      // }
    },
    findDongHo() {
      try{
        const login = new Promise(async (resolve, reject) => {
  
          this.loadingInfo = {
            show: true,
            text: "주소를 조회중입니다.",
          };
  
          const { data } = await this.$axios.post(
            "/bidding/test",
            this.addressObj.addressDetailMeta
          );
  
          this.loadingInfo = {
            show: false,
            text: null,
          };
  
          if (data.status.code === "0000") {
            await window.alert(`주소 조회가 완료되었습니다.`, {
              title: "주소 조회완료",
            });
  
            this.apiDongHo = data.data !== undefined ? data.data.item : [];
          } else {
            window.alert(data.status.message);
          }
        });

      }catch(e){
        console.log(e);
      }
      // asyncLoading(login).then().catch();
    },

    //==========================================
    async upload() {

      // 소유자명 미 일치
      if (this.owner && this.input.holder) {
        const owner = this.owner.map((x) => {
          return x.owner;
        });

        if (!owner.includes(this.input.holder)) {
          window.alert("정확한 소유자명을 입력해주세요.", {
            title: "소유자명 불일치",
          });
          return;
        }
      }

      //0810 무설정론인 경우 등본첨부 필수 알림
      if(this.input.productKind2 === 'KIND2_4' & !this.input.file){
        window.alert(`무설정론을 원하실 경우 </br> 등기부등본 첨부는 필수입니다.`, {
            title: "등기부등본 미첨부",
          });
          return;
      }

      if (this.selectedDong && this.selectedHo) {
        this.addressObj.addressDetail =
          this.selectedDong + " " + this.selectedHo;
      } else {
        this.addressObj.addressDetail = this.selectedHo;
      }

      this.submitted = true;

      if (this.input.work !== "BADCREDIT") {
        this.input.creditScore = null;
        this.input.totalTimes = null;
        this.input.currentTimes = null;
        this.input.defaultTimes = null;
      }
      const {
        address,
        addressDetail,
        addressDetailMeta,
        jibunAddress,
        roadAddress,
      } = this.addressObj;

      //-----------

      const form = {
        ...this.input,
        //registerCountMeta: this.registerCountMeta,
        address: roadAddress,
        addressDetail: addressDetail || "",
        jibunAddress: jibunAddress,
        roadAddress: roadAddress,
        addressDetailMeta: {
          dongNm: addressDetail ? this.selectedDong : "",
          hoNm: addressDetail ? this.selectedHo : "",
          ...addressDetailMeta,
        },
        dateOfBirth: "",
        amount: this.input.amount
          ? this.priceToNumber(this.input.amount)
          : null,
        deposit: this.input.tenant
          ? this.priceToNumber(this.input.deposit)
          : null,
        monthlyRent: this.input.tenant
          ? this.priceToNumber(this.input.monthlyRent)
          : null,
        regValidFail: this.regValidFail,
        failId: this.failId,
        text: this.text,
        caution: this.caution,
        apiExistJointName: this.apiExistJointName,
        //register: this.register
        tradeDetailsMeta: {
          ...this.tradeDetailsMeta
        }
      };

      //등본파싱 후 가져온 생년월일 값 할당
      if (this.owner) {
        this.owner.some(function (e) {
          if (e.owner === form.holder) {
            form.dateOfBirth = e.dateOfBirth;
            return;
          }
        });
      }

      if (
        // 동: 셀렉트 보이고 선택 안했을 경우
        (this.isShowDong && !this.selectedDong) ||
        // 호수: 셀렉트 보이고 선택 안했을 경우
        (this.isShowHo && !this.selectedHo) ||
        // 주소: 없거나 빈 값
        !form.address ||
        form.address === "" ||
        // 직장: 선택 안했을 때
        !form.work ||
        // 물건종류, 대출종류: 없을 때
        !form.productKind ||
        !form.productKind2 ||
        // 소유자: 이름 없거나 빈 값
        !form.holder ||
        form.holder === "" ||
        // 세입자: 있을때 보증금과 월세 빈 값
        (form.tenant && !form.deposit) ||
        //(form.tenant && !form.monthlyRent) ||
        //0810 물건 징행종류가 매매자금인 경우 매도자 등을 입력하지 않은 경우
        (form.productKind2 === 'KIND2_2' && !form.tradeDetailsMeta.seller) || 
        (form.productKind2 === 'KIND2_2' && !form.tradeDetailsMeta.buyer) ||
        (form.productKind2 === 'KIND2_2' && !form.tradeDetailsMeta.saleAmount) ||
        (form.productKind2 === 'KIND2_2' && !form.tradeDetailsMeta.balanceDate) ||

        //0810 무설정론인 경우 전화번호와 생년월일 
        (form.productKind2 === 'KIND2_4' && !form.birthDate) ||
        (form.productKind2 === 'KIND2_4' && !form.phone) ||

        //0810 공동소유자가 있고 본인지분인 경우 지분율
        (form.jointName && !form.shareLoan && !form.selfRatio)
        
      ) {
        window.alert("필수 값들을 모두 입력해주세요.", { title: "필수 값 미입력"} );
        this.submitted = false;
      } else if (this.submitted) {
        if (!form.amount || form.amount === "") {
          form.amount = "0";
        }

        // 등기부 등본 직접등록시 aws파일 업로드
        if (this.input.file) {
          try {
            const formData = new FormData();
            formData.append("file", this.input.file);
            formData.append("type", "D");

            const { data } = await this.$axios.post(
              "/files/image",
              formData
              // config
            );

            if (data.status.code === "0000") {
              form.registerFile = data.data;
              form.text = data.data.pdfText;
              // this.input.registerFile = data.data;
              // this.text = data.data.pdfText;
            }
          } catch (error) {
            window.alert('비딩매칭 등록에 실패하였습니다.');
            this.submitted = false;
            console.log(error);
          }
        }

        try {
          const login = new Promise(async (resolve, reject) => {
            // this.$loading(true);
            console.log("progress start");

            this.loadingInfo = {
              show: true,
              text: "비딩매칭 등록중입니다.",
            };

            const { data } = await this.$axios.post("/bidding", form);
            
            console.log(data.data)

            if (data.status.code === "0000") {

              //등기부 등본 api발급시 공동소유자명이 존재하는 경우               
              if(data.data.apiExistJointName){
                this.regValidFail = data.data.regValidFail;     //실패 여부
                this.failId = data.data.failId;                 //실패한 비딩 id
                this.owner = data.data.pdfCheckResBody.owners;  //등기부 등본상의 소유자명과 생년월일
                this.text = data.data.pdfCheckResBody.text;     //api로 받은 등기부 등본 파일 정보
                this.apiExistJointName = data.data.apiExistJointName;
                this.caution = data.data.caution

                const bool = await window.confirm("공동 소유자가 존재합니다. </br> 추가 하시겠습니까 ?", {
                  title: "공동 소유자 존재",
                  bodyClass: "text-center",
                  okTitle: "추가",
                });

                if(bool){
                  this.submitted = false;
                  this.loadingInfo = {
                    show: false,
                    text: null,
                  };
                }else{
                  this.upload();
                  return;
                }
              //등기부 등본 api발급시 입력한 소유자명과 등기부등본상의 소유자명이 일치하지 않을 경우   
              }else if(data.data.regValidFail){
                this.regValidFail = data.data.regValidFail;     //실패 여부
                this.failId = data.data.failId;                 //실패한 비딩 id
                this.owner = data.data.pdfCheckResBody.owners;  //등기부 등본상의 소유자명과 생년월일
                this.text = data.data.pdfCheckResBody.text;     //api로 받은 등기부 등본 파일 정보
                this.caution = data.data.caution;
                
                await window.alert(
                  `입력하신 소유자명과, 등기부등본상의 소유자명이 일치하지 않습니다.</br> 소유자명 수정 후 다시 등록 해주세요.`,
                  { title: "소유자명 불일치" }
                );
                this.submitted = false;
              }else {
                const bool = await window.alert(`비딩매칭이 등록되었습니다.`, {
                  title: "비딩 매칭 업로드 완료",
                });

                if (bool) {
                  this.$router.push(`/matching/${data.data.id}`);
                }
              }
            } else {
              window.alert(data.status.message);
            }

            // this.$loading(false);
            this.loadingInfo = {
              show: false,
              text: null,
            };
          }).catch((error) => {
            console.log("promise cathch");
            console.error(error);
          });

          // asyncLoading(login).then().catch();
        } catch (error) {
          // this.$loading(false);
          console.log("upload err - progress quit");
          this.loadingInfo = {
            show: false,
            text: null,
          };
          this.submitted = false;
          window.alert('비딩매칭 등록에 실패하였습니다.');
          console.log(error);
        }
      }
    },
  },
  mounted() {
    //0816 upload파일이 아닌 list파일에 설정
    //금융사인 경우 입찰만 가능
    // if (this.$store.state.user && this.$store.state.user.status === "WAITING") {
    //   window.alert(
    //     "아직 승인되지 않은 계정입니다. 승인 후 이용 가능한 서비스입니다."
    //   );
    //   this.$router.go(-1);
    //   return;
    // } else if (
    //   this.$store.state.user &&
    //   this.$store.state.user.role === "FINANCE"
    // ) {
    //   window.alert("입찰만 가능한 계정입니다. ");
    //   this.$router.go(-1);
    //   return;
    // }else{
    //   this.$router.push("/matching/upload");
    // }
  },
};
</script>

<style lang="scss" scoped></style>