<template>
  <div class="mt-3 mb-5 py-5">
    <b-container>
      <guide-header text="서비스 이용안내" class="text-white" />
      <section class="mb-5 pb-5 overflow-hidden">
        <div class="position-relative">
          <b-carousel
            class="position-relative"
            :perPage="1"
            :paginationEnabled="false"
            :autoplay="true"
            v-model="currentSlide"
            :loop="true"
          >
            <b-carousel-slide
              v-for="(item, i) in guides"
              :key="i"
              :img-src="item.src"
            />
          </b-carousel>
          <b-form-radio-group
            class="position-absolute radio-group-pagination d-flex radio-page"
            :style="{
              zIndex: '3',
              bottom: '16px',
              left: '50%',
              transform: 'translateX(-50%)',
            }"
            buttons
            button-variant="rounded-circle"
            ref="radioPagination"
            :options="guides"
            v-model="currentSlide"
          >
          </b-form-radio-group>
        </div>
        <p class="text-16 fw-400 text-center pt-4 mb-md-5 pb-md-5">
          비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나
          그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽 요소나
          시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종 결과물에
          들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인 프로젝트
          모형의 채움 글로도 이용된다. 이런 용도로 사용할 때 로렘 입숨을
          그리킹(greeking)이라고도 부르며, 때로 로렘 입숨은 공간만 차지하는
          무언가를 지칭하는 용어로도 사용된다.
        </p>
      </section>

      <guide-header text="이용 방법" class="text-white" />
      <b-row class="mx-lg-n4 pt-4 mt-3">
        <b-col
          cols="12"
          lg="4"
          class="px-lg-4"
          v-for="(item, i) in howToUse"
          :key="i"
        >
          <header
            class="px-4 py-2 border border-primary rounded-pill d-flex align-item-center justify-content-between w-100 text-20 text-lg-23 text-nowrap"
          >
            <strong class="text-primary text-score-black"
              >STEP {{ i + 1 }}</strong
            >
            <span class="mt-1 text-20 text-score-bold">{{ item.title }}</span>
          </header>
          <section class="py-4">
            <img :src="item.src" :alt="item.title" class="w-100 h-auto" />
            <p class="mt-3 text-center text-14 text-lg-16 fw-400 lh-175">
              {{ item.content }}
            </p>
          </section>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import GuideHeader from "@/components/Items/GuideHeader.vue";
export default {
  components: { GuideHeader },
  data() {
    return {
      currentSlide: 0,

      guides: [
        {
          text: "1",
          value: 0,
          src: require("@/assets/images/Guidance/guide-slide-1.png"),
        },
        {
          text: "2",
          value: 1,
          src: require("@/assets/images/Guidance/guide-slide-1.png"),
        },
        {
          text: "3",
          value: 2,
          src: require("@/assets/images/Guidance/guide-slide-1.png"),
        },
        {
          text: "4",
          value: 3,
          src: require("@/assets/images/Guidance/guide-slide-1.png"),
        },
        {
          text: "5",
          value: 4,
          src: require("@/assets/images/Guidance/guide-slide-1.png"),
        },
      ],
      howToUse: [
        {
          title: "절차명",
          src: require("@/assets/images/Guidance/step-1.png"),
          content:
            "비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나 그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽 요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종 결과물에 들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인 프로젝트 모형의 채움 글로도 이용된다.",
        },
        {
          title: "절차명",
          src: require("@/assets/images/Guidance/step-2.png"),
          content:
            "비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나 그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽 요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종 결과물에 들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인 프로젝트 모형의 채움 글로도 이용된다.",
        },
        {
          title: "절차명",
          src: require("@/assets/images/Guidance/step-3.png"),
          content:
            "비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나 그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽 요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종 결과물에 들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인 프로젝트 모형의 채움 글로도 이용된다.",
        },
        {
          title: "절차명",
          src: require("@/assets/images/Guidance/step-4.png"),
          content:
            "비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나 그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽 요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종 결과물에 들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인 프로젝트 모형의 채움 글로도 이용된다.",
        },
        {
          title: "절차명",
          src: require("@/assets/images/Guidance/step-5.png"),
          content:
            "비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나 그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽 요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종 결과물에 들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인 프로젝트 모형의 채움 글로도 이용된다.",
        },
        {
          title: "절차명",
          src: require("@/assets/images/Guidance/step-6.png"),
          content:
            "비딩매칭이란, 로렘 입숨(lorem ipsum; 줄여서 립숨, lipsum)은 출판이나 그래픽 디자인 분야에서 폰트, 타이포그래피, 레이아웃 같은 그래픽 요소나 시각적 연출을 보여줄 때 사용하는 표준 채우기 텍스트로, 최종 결과물에 들어가는 실제적인 문장 내용이 채워지기 전에 시각 디자인 프로젝트 모형의 채움 글로도 이용된다.",
        },
      ],
    };
  },
  mounted() {
    // this.$refs.radioPagination.$children[0].$el.classList.add("active");
    // this.$refs.radioPagination.$children[0].$el.classList.add("focus");
  },
};
</script>

<style lang="scss" scoped></style>
