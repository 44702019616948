<template>
  <div>
    <b-row class="mt-5 mb-3">
      <b-col cols="12" md="5">
        <b-form-group class="radio-group-sqaure">
          <b-form-radio-group
            :options="types"
            v-model="selected"
            buttons
            button-variant="light-gray rounded-0"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="table-wrapper py-3">
      <table class="px-3">
        <tr class="">
          <th class="pt-1 pb-3 px-3" :style="{ minWidth: '9rem' }">
            카테고리
          </th>
          <th class="pt-1 pb-3" :style="{ minWidth: '13rem' }">
            제목
          </th>
          <th class="pt-1 pb-3" :style="{ minWidth: '8rem' }">
            등록일시
          </th>
          <th class="pt-1 pb-3" :style="{ minWidth: '8rem' }">
            <!-- a11y read text -->
            <span :style="{ opacity: '0.00000001' }">자세히보기 버튼</span>
          </th>
        </tr>

        <template v-if="allItems && items && items.length">
          <tr
            v-for="(item, i) in items"
            :key="i"
            :class="
              i == 0
                ? 'border-top border-2 border-gray'
                : 'border-top border-light-gray'
            "
          >
            <td class="text-nowrap px-3 text-center text-primary">
              <div class="text-nowrap">
                {{ getText(item.type) }}
              </div>
            </td>
            <td class="text-nowrap px-3">
              <div class="text-nowrap">
                {{ item.title }}
              </div>
            </td>
            <td class="text-center">
              {{ toLocaleDate(item.createdDate) }}
            </td>
            <td class="text-center">
              <b-btn
                variant="outline-primary"
                :to="'/customer-center/' + item.id"
              >
                자세히 보기
              </b-btn>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="4" class="border-top text-center">
              데이터가 없습니다.
            </td>
          </tr>
        </template>
      </table>
    </div>
    <div
      class="mt-5 d-flex flex-column"
      v-if="allItems && items && items.length"
    >
      <b-pagination
        @change="getList($event)"
        :value="page.number + 1"
        :total-rows="page.totalElements"
        :per-page="page.size"
        class="mx-auto"
        first-class="d-none"
        last-class="d-none"
        hide-ellipsis
      >
        <template #prev-text>
          <i class="icon icon-prev ml-1"></i>
        </template>
        <template #next-text>
          <i class="icon icon-next ml-1"></i>
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allItems: null,
      types: [
        {
          text: "전체",
          value: null,
        },
        {
          text: "공지사항",
          value: "NOTICE",
        },
        {
          text: "대출시 안내사항",
          value: "GUIDE",
        },
      ],
      selected: null,
      page: {
        page: 1,
      },
    };
  },

  computed: {
    items() {
      const type = this.selected;
      if (type === null) {
        return this.allItems;
      } else if (type) {
        return this.allItems.filter((r) => {
          return r.type === type;
        });
      }
    },
  },
  methods: {
    getText(progress) {
      const text = {
        GUIDE: "대출시 안내사항",
        NOTICE: "공지사항",
        ERROR: "에러",
      };
      return text[progress] || null;
    },
    async getList(n = 1) {
      try {
        const { data } = await this.$axios.get("/customer-center", {
          params: { page: n - 1 },
        });
        if (data.status.code === "0000") {
          this.allItems = data.data.content;
          this.page = data.data.page;
        }
      } catch (error) {}
    },
  },

  async mounted() {
    await this.getList();
  },
};
</script>

<style lang="scss" scoped></style>
