<template>
  <!-- 비딩매칭 리스트 페이지 -->
  <b-container>
    <b-row align-h="center">
      <!-- 필터 영역 -->
      <b-col
        cols="12"
        md="3"
        lg="3"
        xl="2"
        class="py-3 py-md-5 border-right min-md-vh-100"
      >
        <div>
          <b-form>
            <fieldset class="form-filter">
              <legend class="mb-2 text-20 fw-400 position-relative">
                물건 종류
              </legend>
              <checkbox-group-custom
                v-model="filter.kind"
                :options="kind"
                class="skew"
              />
            </fieldset>
            <fieldset class="form-filter">
              <legend class="mb-2 text-20 fw-400 position-relative">
                대출 종류
              </legend>
              <checkbox-group-custom
                v-model="filter.kind2"
                :options="kind2"
                class="skew"
              />
            </fieldset>
            <fieldset class="form-filter">
              <legend class="mb-2 text-20 fw-400 position-relative">
                지역
              </legend>
              <checkbox-group-custom
                title="지역"
                :selected="filter.location"
                :options="location"
                v-model="filter.location"
                class="skew"
              />
            </fieldset>
          </b-form>
          <b-btn
            variant="outline-lightest"
            class="px-4"
            @click="reset"
            :style="{
              border: ' solid 1px #dedede;',
            }"
            >필터 초기화</b-btn
          >
        </div>
      </b-col>
      <!-- 리스트 영역 -->
      <b-col cols="12" md="9" lg="9" xl="10" class="py-5">
        <div class="px-3">
          <!-- 정렬 -->
          <!-- <b-dropdown
            variant="white border"
            split-variant="outline-dark-gray text-nowrap"
            split-class="border-right border-dark-gray"
            class="dropdown-badge dropdown-sm mx-0 p-0"
            menu-class="rounded-0 my-0 py-0"
            no-flip
          >
            <template #button-content>
              <div class="w-100 px-2">
                {{ getText(progressSelected) }}
              </div>
            </template>
            <b-dropdown-item-button
              @click="progressSelected = item.value"
              v-for="(item, i) in progress"
              :key="`${i}-${item.id}`"
            >
              {{ item.text }}
            </b-dropdown-item-button>
          </b-dropdown> -->
          <div class="d-flex justify-content-between">
            <b-button-group size="sm" class="skew">
              <b-btn
                @click="
                  (progressSelected = item.value), (activeBtn = item.value)
                "
                v-for="(item, i) in progress"
                :key="`${i}-${item.id}`"
                squared
                variant="outline-primary"
                :class="{ active: activeBtn === item.value }"
              >
                {{ item.text }}
              </b-btn>
            </b-button-group>
            <b-btn @click="upload()" squared variant="primary">
              비딩매칭 요청하기
            </b-btn>
          </div>

          <!-- 리스트 로딩 -->
          <template v-if="!items || progressLoad">
            <div class="text-center">
              <b-spinner variant="primary" class="my-5" />
            </div>
          </template>
          <!-- 리스트 있음 -->
          <template v-if="items && items.length && !progressLoad">
            <matching-items :items="items" />
            <div class="mt-5 d-flex flex-column">
              <b-pagination
                @change="paginationEvent"
                :value="page.number + 1"
                :total-rows="page.totalElements"
                :per-page="page.size"
                class="mx-auto"
                first-class="d-none"
                last-class="d-none"
                hide-ellipsis
              >
                <template #prev-text>
                  <i class="icon icon-prev ml-1"></i>
                </template>
                <template #next-text>
                  <i class="icon icon-next ml-1"></i>
                </template>
              </b-pagination>
            </div>
          </template>
          <!-- 리스트 없음 -->
          <template v-if="items && !items.length && !progressLoad">
            <div class="mt-4 bg-light text-primary skew text-center py-5">
              매칭 데이터가 없습니다.
            </div>
          </template>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Form from "@/components/Form/index";
import Items from "@/components/Items/index";
import Product from "@/lib/product";

export default {
  components: {
    CheckboxGroupCustom: Form.CheckboxGroup,
    // RadioGroupCustom: Form.RadioGroup,
    MatchingItems: Items.Matching,
  },
  data() {
    return {
      kind: Product.kind,
      kind2: Product.kind2,
      location: [
        // {
        //   text: "전국",
        //   value: null,
        // },
        ...Product.location,
      ],
      progressSelected: null,
      filter: { kind: [], kind2: [], location: [] },
      progress: [
        { text: "전체", value: null },
        { text: "진행중", value: "ING" },
        { text: "매칭 완료", value: "MATCHED" },
        { text: "승인 완료", value: "APPROVAL" },
        { text: "자서 완료", value: "AUTOGRAPH" },
        { text: "송금 완료", value: "TRANSFERRED" },
        { text: "대출 완료", value: "COMPLETE" },
        { text: "진행 취소", value: "CANCEL_PROGRESS" },
      ],
      page: {
        page: 1,
      },
      items: null,
      activeBtn: null,
      progressLoad: false,
    };
  },
  watch: {
    // // 토지, 오피스텔 선택 시 대출종류 필터링
    // "filter.kind"(newVal, oldVal) {
    //   if (oldVal !== undefined) {
    //     console.log(
    //       "newVal: ",
    //       newVal[0] ? newVal[0].text : "-",
    //       "oldVal: ",
    //       oldVal[0] ? oldVal[0].text : "-"
    //     );
    //     if (oldVal[0]) {
    //       this.kind2.forEach(function (data) {
    //         // console.log(data.key, newVal[0].value);
    //         data.disabled = true;
    //         document.getElementById(data.value).checked = false;
    //         data.key.forEach(function (obj) {
    //           if (newVal[0] == null) {
    //             data.disabled = false;
    //           } else {
    //             if (obj === newVal[0].value) {
    //               data.disabled = false;
    //             }
    //           }
    //         });
    //       });
    //     }
    //   }
    // },
    // "filter.kind2"(newVal, oldVal) {
    //   if (oldVal !== undefined) {
    //     console.log(
    //       "newVal: ",
    //       newVal[0] ? newVal[0].text : "-",
    //       "oldVal: ",
    //       oldVal[0] ? oldVal[0].text : "-"
    //     );
    //     this.kind.forEach(function (data) {
    //       // console.log(data.text, newVal[0].key, newVal[0].text);
    //       data.disabled = true;
    //       if (newVal[0] == null) {
    //         data.disabled = false;
    //       } else {
    //         newVal[0].key.forEach(function (obj) {
    //           if (obj === data.value) {
    //             data.disabled = false;
    //           }
    //         });
    //       }
    //     });
    //   }
    // },

    query(n) {
      // 종류1, 종류2 체크박스 관련
      // if(this.filter.kind2.length > 0 && this.filter.kind.length > 0){
      //   if( this.filter.kind[0] !== null && this.filter.kind2[0] !== null
      //     && this.filter.kind[0].value !== this.filter.kind2[0].key ){
      //       this.filter.kind2 = [];
      //   }
      // }

      this.getBidding(n);

      // if (n.locationString && n.locationString.includes("전국")) {
      //   this.getBidding({ ...n, locationString: null });
      // } else {
      //   this.getBidding(n);
      // }
    },
    progressSelected() {
      this.progressLoad = true;
    },
  },
  computed: {
    query() {
      const { kind, kind2, location } = this.filter;
      // 라디오박스 체크 기능
      if (kind.length > 1) {
        // this.filter.kind.shift();
        kind.shift();
      }
      // if (kind2.length > 1) {
      //   // this.filter.kind.shift();
      //   kind2.shift();
      // }

      // console.log("kind", kind, "this.kind", this.kind);
      // console.log("kind2", kind2, "this.kind2", this.kind2);

      if (
        (kind[0] && kind[0].productKind === "KIND_3") ||
        (kind[0] && kind[0].productKind === "KIND_4")
      ) {
        // console.log("KIND_3");
        this.kind2.forEach(function (el) {
          if (el.value === "KIND2_4") {
            // console.log(el);
            el.disabled = true;
            document.getElementById(el.value).checked = false;
          }
        }, this);
      } else {
        // console.log("All KIND");
        this.kind2.forEach(function (el) {
          el.disabled = false;
        }, this);
      }

      return {
        productKindString: kind.length
          ? kind.map((item) => item.productKind)
          : null,
        productKindString2: kind2.length
          ? kind2.map((item) => item.value)
          : null,
        locationString: location.length
          ? location.map((item) => item.text)
          : null,
        progress: this.progressSelected,
      };
    },
  },

  methods: {
    upload() {
      //0816 승인되지 않은 계정인 경우 페이지 이동 없이 버튼 클릭후 바로 제한걸기 위해 추가 upload to list
      //금융사인 경우 입찰만 가능
      if (
        this.$store.state.user &&
        this.$store.state.user.status === "WAITING"
      ) {
        window.alert(
          "아직 승인되지 않은 계정입니다. 승인 후 이용 가능한 서비스입니다."
        );
        return;
      } else if (
        this.$store.state.user &&
        this.$store.state.user.role === "FINANCE"
      ) {
        window.alert("입찰만 가능한 계정입니다. ");
        return;
      } else {
        this.$router.push("/matching/upload2");
      }
    },
    // 필터 초기화
    reset() {
      this.filter = {
        kind: [],
        kind2: [],
        location: [],
      };
    },
    // 진행현황 텍스트
    getText(progress) {
      const text = {
        null: "전체",
        ING: "진행중",
        MATCHED: "매칭 완료",
        APPROVAL: "승인 완료",
        AUTOGRAPH: "자서 완료",
        TRANSFERRED: "송금 완료",
        COMPLETE: "대출 완료",
        CANCEL_PROGRESS: "진행 취소",
        ERROR: "에러",
      };
      return text[progress] || text.error;
    },
    // 리스트 불러오기 + 필터링
    async getBidding(options) {
      try {
        const makeString = (obj) => {
          const result = Object.keys(obj)
            .filter((key) => null !== obj[key])
            .map((key) => {
              const val = obj[key];
              const value = Array.isArray(val)
                ? `%5B"${val.join('","')}"%5D`
                : val;
              return `${key}=${value}`;
            })
            .join("&");
          return result;
        };

        const { data } = await this.$axios.get("/bidding", {
          params: { ...options },
          paramsSerializer: (params) => makeString(params),
        });

        // console.log(options);

        if (data.status.code === "0000") {
          this.items = data.data.content;
          this.page = data.data.page;
          this.progressLoad = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 페이지 처리
    paginationEvent(page) {
      this.getBidding({ ...this.query, page: page - 1 });
      window.scrollTo(0, 0);
    },
    // changekind(e) {
    //   console.log(e);
    // },

    // 빠른검색으로 들어왔을 때
    isParams() {
      if (this.$route.params.kind?.length != 0) {
        this.filter.kind = [this.$route.params.kind];
      } else {
        this.filter.kind = [];
      }
      if (this.$route.params.kind2?.length != 0) {
        this.filter.kind2 = [this.$route.params.kind2];
      } else {
        this.filter.kind2 = [];
      }
      if (this.$route.params.location?.length != 0) {
        this.filter.location = [this.$route.params.location];
      } else {
        this.filter.location = [];
      }
      // console.log("params", this.$route.params);
      // console.log("filter", this.filter);
    },
  },
  mounted() {
    // this.getBidding(this.query);
    // console.log("list.vue", this.$store.state.user.mypageStatus);
  },
  created() {
    if (
      !this.$route.params.kind ||
      !this.$route.params.kind2 ||
      !this.$route.params.location
    ) {
      console.log("빠른검색 파라미터 없음", this.$route.params);
      this.getBidding();
      window.scrollTo(0, 0);
    } else {
      console.log("빠른검색 파라미터 있음", this.$route.params);
      this.isParams();
      window.scrollTo(0, 0);
    }
    // this.getBidding(this.query);
  },
};
</script>

<style lang="scss" scoped></style>
