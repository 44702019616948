import { render, staticRenderFns } from "./AccountService.vue?vue&type=template&id=4cc68816&scoped=true&"
import script from "./AccountService.vue?vue&type=script&lang=js&"
export * from "./AccountService.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc68816",
  null
  
)

export default component.exports