<template>
  <b-modal
    id="modal-change-password"
    title="비밀번호 변경"
    ok-variant="primary m-0 ml-1 p-3"
    ok-title="변경"
    @ok="ok"
    cancelTitle="취소"
    cancelVariant="outline-primary m-0 p-3"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    contentClass="confirm border-0 fw-400"
    footerClass="w-100 d-flex align-items-center justify-content-between p-1 border-0 btn-group"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="md"
  >
    <div class="text-center">
      <w-form
        @submit.prevent.stop="ChangePassword"
        ref="form"
        v-model="formValid"
      >
        <w-input
          type="password"
          placeholder="기존 비밀번호"
          v-model="input.originalPassword"
          :rules="rules.password"
        >
          <template #prepend>
            기존 비밀번호
          </template>
        </w-input>
        <w-input
          type="password"
          placeholder="새 비밀번호"
          v-model="input.newPassword"
          :rules="rules.password"
        >
          <template #prepend>
            새 비밀번호
          </template>
        </w-input>
        <w-input
          type="password"
          placeholder="비밀번호 확인"
          v-model="input.passwordConfirm"
          :rules="rules.passwordConfirm"
        >
          <template #prepend>
            비밀번호 확인
          </template>
        </w-input>
      </w-form>
      <!-- <b-input-group class="mb-4">
        <template #prepend>
          <b-input-group-text>기존 비밀번호</b-input-group-text>
        </template>
        <b-form-input
          type="text"
          v-model="input.originalPassword"
          placeholder="기존 비밀번호"
        ></b-form-input>
      </b-input-group>
      <b-input-group class="mb-4">
        <template #prepend>
          <b-input-group-text
            :class="valid.newPassword.state ? '' : 'is-invalid'"
            >새 비밀번호</b-input-group-text
          >
        </template>
        <b-form-input
          type="text"
          v-model="input.newPassword"
          :state="valid.newPassword.state"
          placeholder="새로운 비밀번호"
        ></b-form-input>
        <b-form-invalid-feedback :state="valid.newPassword.state">
          {{ valid.newPassword.msg }}</b-form-invalid-feedback
        >
      </b-input-group>
      <b-input-group class="mb-4">
        <template #prepend>
          <b-input-group-text
            :class="valid.passwordConfirm.state ? '' : 'is-invalid'"
            >비밀번호 확인</b-input-group-text
          >
        </template>
        <b-form-input
          type="text"
          v-model="input.passwordConfirm"
          :state="valid.passwordConfirm.state"
          placeholder="비밀번호 확인"
        ></b-form-input>
        <b-form-invalid-feedback :state="valid.passwordConfirm.state">
          {{ valid.passwordConfirm.msg }}</b-form-invalid-feedback
        >
      </b-input-group> -->
    </div>
  </b-modal>
</template>

<script>
import regex from "@/lib/regex";

export default {
  data() {
    return {
      formValid: false,
      input: {
        originalPassword: null,
        newPassword: null,
        passwordConfirm: null,
      },
      status: {
        originalPassword: null,
        newPassword: null,
        passwordConfirm: null,
      },
      rules: {
        password: [
          (v) =>
            !!v || {
              title: "비밀번호 입력",
              message: "비밀번호를 입력하세요.",
            },
          (v) =>
            regex.password.test(v) || {
              title: "비밀번호 형식",
              message: "8-16자 영문 대/소문자, 숫자, 특수문자를 사용하세요.",
            },
        ],
        passwordConfirm: [
          (v) =>
            !!v || {
              title: "비밀번호 확인",
              message: "비밀번호 확인을 입력하세요.",
            },
          (v) =>
            this.input.newPassword === v || {
              title: "비밀번호 확인",
              message: "비밀번호 확인이 일치하지 않습니다",
            },
        ],
      },
    };
  },
  computed: {
    valid() {
      const rules = {
        newPassword: [
          (v) =>
            regex.password.test(v) ||
            "비밀번호는 영어, 숫자 포함 8글자 이상 입력하세요.",
        ],
        passwordConfirm: [
          (v) =>
            v === this.input.newPassword || "새 비밀번호와 일치하지 않습니다.",
        ],
      };

      const ruleChecker = (rules, value) => {
        return rules.reduce(
          (result, rule) => {
            if (result.state === true) {
              const r = rule(value);

              const state = r === true;
              const msg = r === true ? null : r;

              return { state, msg };
            } else if (result.state === false) {
              return result;
            } else {
              return { state: true, msg: null };
            }
          },
          { state: true, msg: null }
        );
      };

      const newPassword = ruleChecker(
        rules.newPassword,
        this.input.newPassword
      );
      const passwordConfirm = ruleChecker(
        rules.passwordConfirm,
        this.input.passwordConfirm
      );

      return { newPassword, passwordConfirm };
    },
  },
  methods: {
    async ok() {
      if (!this.formValid) {
        const children = this.$refs.form.$children;
        // console.log(children);
        // Input 중에서 valid === true가 아닌 제일 처음 엘리먼트
        const firstError = children.find(
          (item) => item && item.valid && item.valid.state !== true
        );
        if (firstError.valid.state === null) {
          firstError.value = "";
          await alert(firstError.valid.message, {
            title: firstError.valid.title,
          });
          window.location.reload();
        } else if (firstError.valid.state === false) {
          await alert(firstError.valid.message, {
            title: firstError.valid.title,
          });
          window.location.reload();
        }
        return;
      } else if (this.formValid) {
        try {
          const { data } = await this.$axios.put(`/users/reset-password`, {
            newPassword: this.input.newPassword,
            originalPassword: this.input.originalPassword,
          });
          if (data.status.code === "0000") {
            const bool = await window.alert("비밀번호가 변경되었습니다.");
            if (bool) {
              window.location.reload();
            }
          } else if (data.status.code === "1003") {
            const bool = await window.alert(
              "기존비밀번호가 일치하지 않습니다."
            );
            if (bool) {
              window.location.reload();
            }
          } else {
            window.alert(data.status.message);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
