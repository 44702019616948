<template>
  <div class="pt-md-5">
    <header class="py-4 py-md-5 text-center">
      <h2 class="mb-3 text-18 text-lg-24">
        {{ !findPasswordComplete ? "아이디/비밀번호 찾기" : "비밀번호 재설정" }}
      </h2>
    </header>
    <!-- {{ `/users/find/${this.path}` }} {{ input.type }}
    <div class="">@@input: {{ input }}</div>
    <div class="">@@resetPassword: {{ resetPassword }}</div>
    <div class="">@@valid :{{ valid }}</div>
    <div class="">@@serverValid : {{ serverValid }}</div>
    <div class="">@@foundedId: {{ foundedId }}</div> -->
    <!-- @@serverValid : {{ serverValid }} @@formValid {{ formValid }} -->

    <b-container v-if="!findidComplete && !findPasswordComplete">
      <b-form-group class="radio-group-sqaure">
        <b-form-radio-group
          :options="types"
          v-model="input.type"
          buttons
          button-variant="light-gray rounded-0 w-50"
        />
      </b-form-group>
      <w-form
        @submit.prevent.stop="findAccount"
        class="mb-5 pb-5"
        ref="form"
        :salt="salt"
        v-model="formValid"
      >
        <ul
          class="
            list-unstyled
            my-2
            px-3
            bg-light
            border-top border-bottom border-light-gray
            text-12
          "
          :style="{ color: '#a5a5a5' }"
        >
          <template v-if="input.type === 'find-id'">
            <li class="my-3">
              * 핸드폰 번호 문자 인증 후 가입하신 아이디를 확인하실 수 있습니다.
            </li>
          </template>
          <template v-if="input.type === 'find-password'">
            <li class="my-3">
              * 회원가입 시 등록했던 이메일을 입력하시고 핸드폰 번호 문자 인증
              진행 후 [비밀번호 찾기] 버튼을 클릭하여 주십시오.
            </li>
            <li class="my-3">
              * 입력하신 아이디가 일치할 경우 비밀번호를 재설정 하실 수
              있습니다.
            </li>
          </template>
        </ul>

        <article class="mt-3 mb-5" ref="form">
          <w-input
            type="email"
            placeholder="이메일"
            v-model="input.email"
            :rules="input.type === 'find-password' ? rules.email : undefined"
            v-show="input.type === 'find-password'"
          >
            <template #prepend> 이메일 </template>
          </w-input>
          <div class="d-flex">
            <w-input
              ref="mobile"
              class="flex-grow-1"
              type="tel"
              placeholder="핸드폰 번호"
              v-model="input.mobile"
              :rules="rules.mobile"
              maxlength="11"
              @input.native="$numberOnly($event, 'mobile', 11)"
            >
              <template #prepend> 핸드폰 번호 </template>
              <template #append="{ validate }">
                <div class="ml-3">
                  <b-btn
                    variant="outline-primary"
                    :disabled="!validate"
                    @click="sendCertNumber"
                    style="width: 80px"
                    >인증</b-btn
                  >
                </div>
              </template>
            </w-input>
          </div>
          <div :class="serverValid.mobile ? 'd-flex' : 'd-none'">
            <w-input
              ref="certNumber"
              class="flex-grow-1"
              type="tel"
              maxlength="4"
              v-model="input.code"
              placeholder="인증 번호 4자리"
              @input.native="
                $numberOnly($event, 'code', 4), (serverValid.code = false)
              "
            >
              <template #prepend> 인증 번호 </template>
              <template #append>
                <small
                  class="position-absolute text-error text-14 text-lg-15 mr-3"
                  :style="{
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '0',
                    zIndex: '1000',
                  }"
                  id="timer"
                >
                </small>
              </template>
            </w-input>
            <div class="ml-3 position-relative">
              <b-btn
                variant="primary"
                @click="codeConfirm"
                style="width: 80px"
                :disabled="serverValid.code"
                >확인</b-btn
              >
            </div>
          </div>
        </article>
        <section class="my-5">
          <b-btn
            variant="primary"
            class="py-3 w-100"
            @click="findAccount"
            v-if="this.input.type === 'find-id'"
          >
            <span>아이디 찾기</span>
          </b-btn>
          <b-btn
            variant="primary"
            class="py-3 w-100"
            @click="findAccount"
            v-if="this.input.type === 'find-password'"
          >
            <span>비밀번호 찾기</span>
          </b-btn>
        </section>
      </w-form>
    </b-container>
    <b-container class="text-center" v-if="findidComplete">
      <article
        class="
          my-5
          py-5
          border-top border-bottom border-light-gray
          bg-light
          text-center
        "
      >
        <h3 class="text-24 text-lg-26 text-primary fw-400 text-nowrap">
          {{
            idHidden(
              foundedId && foundedId.serviceId.length ? foundedId.serviceId : ""
            )
          }}
        </h3>
        <small class="text-primary fw-400"
          >가입일 : {{ toLocaleDate(foundedId.createdDate) }}</small
        >
      </article>
      <div class="pt-3 pb-5 d-flex flex-column">
        <b-btn variant="primary" class="w-100 py-3 mb-2" to="/account/login"
          >로그인 하러 가기</b-btn
        >
        <b-btn
          variant="outline-primary"
          class="w-100 py-3 mb-2"
          @click="resetInput"
          >비밀번호 찾기</b-btn
        >
      </div>
    </b-container>
    <b-container v-if="findPasswordComplete">
      <w-form
        class="mb-5 pb-5"
        @submit.prevent.stop="passwordReset"
        v-model="formValid"
        ref="form"
      >
        <w-input
          type="email"
          placeholder="이메일"
          v-model="resetPassword.serviceId"
          :rules="rules.email"
          readonly
        >
          <template #prepend> 이메일 </template>
        </w-input>
        <w-input
          type="password"
          placeholder="새 비밀번호"
          v-model="resetPassword.newPassword"
          :rules="rules.password"
        >
          <template #prepend> 비밀번호 </template>
        </w-input>
        <w-input
          type="password"
          placeholder="비밀번호 확인"
          v-model="resetPassword.passwordConfirm"
          :rules="rules.passwordConfirm"
        >
          <template #prepend> 비밀번호 확인 </template>
        </w-input>
        <section class="my-5">
          <b-btn variant="primary" class="py-4 w-100" @click="passwordReset"
            >비밀번호 변경</b-btn
          >
        </section>
      </w-form>
    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

export default {
  components: {
    LegendCustom: Form.LegendCustom,
    InputImage: Form.InputImage,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      formValid: false,
      salt: 0,

      types: [
        {
          text: "아이디 찾기",
          value: "find-id",
        },
        {
          text: "비밀번호 찾기",
          value: "find-password",
        },
      ],

      input: {
        type: "find-id",
        email: null,
        mobile: null,
        code: null,
      },

      rules: {
        email: [
          (v) =>
            !!v || { title: "이메일 입력", message: "이메일을 입력하세요." },
          (v) =>
            regex.serviceId.test(v) || {
              title: "이메일 형식",
              message: "이메일 형식이 올바르지 않습니다.",
            },
        ],
        mobile: [
          (v) =>
            !!v || {
              title: "핸드폰 번호 입력",
              message: "휴대폰 번호를 입력하세요.",
            },
          (v) =>
            v.length >= 10 || {
              title: "핸드폰 번호 입력",
              message: "휴대폰 번호 형식이 올바르지 않습니다.",
            },
        ],
        code: [
          (v) =>
            !!v || {
              title: "핸드폰 번호 인증",
              message: "인증번호를 입력하세요.",
            },
          (v) =>
            v.length === 4 || {
              title: "핸드폰 번호 인증",
              message: "인증번호를 입력하세요.",
            },
          (v) =>
            (v.length === 4 && this.serverValid.code) || {
              title: "핸드폰 번호 인증",
              message: "핸드폰 번호를 인증해주세요.",
            },
        ],
        password: [
          (v) =>
            !!v || {
              title: "비밀번호 입력",
              message: "비밀번호를 입력하세요.",
            },
          (v) =>
            regex.password.test(v) || {
              title: "비밀번호 형식",
              message: "8-16자 영문 대/소문자, 숫자, 특수문자를 사용하세요.",
            },
        ],
        passwordConfirm: [
          (v) =>
            !!v || {
              title: "비밀번호 확인",
              message: "비밀번호 확인을 입력하세요.",
            },
          (v) =>
            this.resetPassword.newPassword === v || {
              title: "비밀번호 확인",
              message: "비밀번호 확인이 일치하지 않습니다",
            },
        ],
      },

      resetPassword: {
        serviceId: null,
        newPassword: null,
        passwordConfirm: null,
      },

      serverValid: {
        email: false,
        mobile: false,
        code: false,
      },

      findidComplete: false,
      foundedId: null,
      findPasswordComplete: false,
      timerActive: false,
    };
  },
  computed: {
    path() {
      return this.input.type === "find-id" ? "me" : "password";
    },
    meta() {
      return this.$route.meta;
    },
    valid() {
      const email = this.input.email === null ? null : `${this.input.email}`;
      const serviceId =
        this.resetPassword.serviceId === null
          ? null
          : `${this.resetPassword.serviceId}`;

      const rules = {
        newPassword: [
          (v) =>
            regex.password.test(v) ||
            "비밀번호는 영어, 숫자 포함 8글자 이상 입력하세요.",
        ],
        passwordConfirm: [
          (v) =>
            v === this.resetPassword.newPassword ||
            "새 비밀번호와 일치하지 않습니다.",
        ],
      };

      const ruleChecker = (rules, value) => {
        return rules.reduce(
          (result, rule) => {
            if (result.state === true) {
              const r = rule(value);

              const state = r === true;
              const msg = r === true ? null : r;

              return { state, msg };
            } else if (result.state === false) {
              return result;
            } else {
              return { state: true, msg: null };
            }
          },
          { state: true, msg: null }
        );
      };

      const newPassword = ruleChecker(
        rules.newPassword,
        this.resetPassword.newPassword
      );
      const passwordConfirm = ruleChecker(
        rules.passwordConfirm,
        this.resetPassword.passwordConfirm
      );

      return {
        email:
          email === null ? null : email !== "@" && regex.serviceId.test(email),
        mobile:
          this.input.mobile !== "" &&
          this.input.mobile &&
          this.input.mobile.length >= 10,
        serviceId:
          serviceId === null
            ? null
            : serviceId !== "@" && regex.serviceId.test(serviceId),
        newPassword,
        passwordConfirm,
      };
    },
    typeSelected() {
      return this.input.type;
    },
  },
  watch: {
    typeSelected(n) {
      this.input = {
        type: n,
        email: null,
        phone: null,
        code: null,
      };
      this.serverValid = {
        email: false,
        mobile: false,
        code: false,
      };
      this.foundedId = null;
    },
  },

  methods: {
    // 최종, 계정 찾기
    async findAccount() {
      if (!this.formValid) {
        const children = this.$refs.form.$children;
        // console.log(children);
        // Input 중에서 valid === true가 아닌 제일 처음 엘리먼트
        const firstError = children.find(
          (item) => item && item.valid && item.valid.state !== true
        );
        if (firstError.valid.state === null) {
          firstError.value = "";
          await alert(firstError.valid.message, {
            title: firstError.valid.title,
          });
        } else if (firstError.valid.state === false) {
          await alert(firstError.valid.message, {
            title: firstError.valid.title,
          });
        }
        return;
      } else {
        if (!this.serverValid.code) {
          return window.alert("핸드폰 인증을 끝까지 완료해주세요", {
            title: "인증 미완료",
          });
        }
        if (this.input.type === "find-id") {
          this.findidComplete = true;
        } else if (this.input.type === "find-password") {
          this.findPasswordComplete = true;
        }
      }
    },
    // 문자인증
    async sendCertNumber() {
      try {
        const { data } = await this.$axios.post(`/users/find/${this.path}`, {
          ...this.input,
          mode: "CERT_MOBILE",
        });

        if (data.status.code === "0000") {
          await window.alert("인증번호를 발송했습니다", {
            title: "핸드폰 번호 인증",
          });
          this.serverValid.mobile = true;
          this.countdown("timer", 3, 0);

          if (this.valid.email === true) {
            this.serverValid.email = true;
          }
          // console.log(data);
        } else if (data.status.code === "4040") {
          window.alert("입력하신 휴대폰 번호로 가입된 계정이 없습니다.", {
            title: "핸드폰 번호 인증",
          });
        } else if (data.status.code === "1002") {
          window.alert("잘못된 요청입니다.", {
            title: "잘못된 요청",
          });
        } else {
          window.alert("잘못된 요청입니다.", {
            title: "잘못된 요청",
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    countdown(element, minutes, seconds) {
      if (this.timerActive) clearInterval(this.timerActive);
      var time = minutes * 60 + seconds;
      this.timerActive = setInterval(function () {
        var el = document.getElementById(element);
        if (!el) return;
        if (time <= 0) {
          var text = "00:00";
          el.innerHTML = text;
          setTimeout(() => {
            this.countdown("clock", 0, 5);
          }, 2000);
          clearInterval(this.timerActive);
          this.timerActive = null;
          return;
        }
        var minutes = Math.floor(time / 60);
        if (minutes < 10) minutes = "0" + minutes;
        var seconds = time % 60;
        if (seconds < 10) seconds = "0" + seconds;
        var text = minutes + ":" + seconds;
        el.innerHTML = text;
        time--;
      }, 1000);
    },
    // 인증번호 확인 - 문자
    async codeConfirm() {
      try {
        const { data } = await this.$axios.post(
          `/users/find/${this.path}/confirm`,
          {
            mobile: this.input.mobile,
            code: this.input.code,
            mode: "CERT_MOBILE",
          }
        );
        if (data.status.code === "0000") {
          const bool = await window.alert(
            `인증되었습니다.<br> 아래 ${
              this.input.type === "find-id" ? "아이디찾기" : "비밀번호 찾기"
            } 버튼을 눌러 진행해주세요.`,
            {
              title: "핸드폰 번호 인증",
            }
          );
          this.serverValid.code = true;
          this.salt++;
          var el = document.getElementById("timer");
          el.style.display = "none";
          if (this.input.type === "find-id") {
            this.foundedId = data.data;
          } else if (this.input.type === "find-password") {
            this.resetPassword.serviceId = this.input.email;
          }
        } else if (data.status.code === "1003") {
          await window.alert(`만료되었거나 잘못된 인증번호입니다.`, {
            title: "인증 오류",
          });
        } else {
          window.alert(data.status.message);
        }
      } catch (error) {
        // 인증번호가 맞지 않을 때
        window.alert("인증 번호가 올바르지 않습니다.", {
          title: "핸드폰 번호 인증",
        });
      }
    },
    resetInput() {
      this.findidComplete = false;
      this.findPasswordComplete = false;

      this.serverValid = {
        email: false,
        mobile: false,
        code: false,
      };

      this.input.code = null;
      this.input.type = "find-password";
      // console.log(this.input.type);
    },
    // 비밀번호 재설정
    async passwordReset() {
      if (!this.formValid) {
        const children = this.$refs.form.$children;
        const firstError = children.find(
          (item) => item && item.valid && item.valid.state !== true
        );
        if (firstError.valid.state === null) {
          firstError.value = "";
          await alert(firstError.valid.message, {
            title: firstError.valid.title,
          });
        } else if (firstError.valid.state === false) {
          await alert(firstError.valid.message, {
            title: firstError.valid.title,
          });
        }
        return;
      }
      if (this.formValid) {
        try {
          const { data } = await this.$axios.put(
            "/users/find/password/reset",
            this.resetPassword
          );
          if (data.status.code === "0000") {
            const bool = await window.alert(
              "설정된 비밀번호로 다시 로그인 해주세요.",
              {
                title: "비밀번호 재설정 완료",
                okTitle: "로그인",
              }
            );
            if (bool) {
              this.$router.push("/account/login");
            }
          } else {
            window.alert(data.status.message);
          }
        } catch (error) {
          // console.log(7);
          console.log(error);
        }
      }
    },
    idHidden(val) {
      const id = val.split("@")[0];
      const domain = val.split("@")[1];
      const isHidden = id.slice(0, id.length - 2);

      return `${isHidden}**@${domain}`;
    },
  },
  beforeDestroy() {
    clearInterval(this.timerActive);
  },
};
</script>

<style lang="scss" scoped></style>
