<template>
  <b-container class="mb-5 pb-5">
    <article class="d-flex flex-column mb-1" ref="temp">
      <b-btn
        variant="outline-gray"
        class="w-100 btn-sns btn-email my-2"
        to="/account/sign-up-form"
        >이메일로 가입하기</b-btn
      >
      <b-btn class="w-100 btn-sns btn-kakao my-2" @click="kakao"
        >카카오 가입하기</b-btn
      >
      <b-btn
        id="naverIdLogin"
        variant="naver"
        class="w-100 btn-sns btn-naver my-2 naverIdSignin"
        >네이버 가입하기</b-btn
      >
      <b-btn
        variant="white"
        id="btn-google"
        class="g-signin2 btn-sns my-2"
        @click="google"
      >
        <span class="">구글 가입하기</span>
      </b-btn>
    </article>
  </b-container>
</template>

<script>
export default {
  methods: {
    async snsLogin(id, type) {
      try {
        const serviceId = `${type}_${id}`;
        const body = {
          serviceId,
          password: serviceId,
          loginType: type,
        };
        console.log("body", body);
        // 이메일 중복체크
        const { data: isSigned } = await this.$axios.post("/auth/token", body);
        if (isSigned.status.code === "40411") {
          // 중복이 아니면? 회원가입
          this.$router.push({
            name: "SignUpForm",
            params: { serviceId, loginType: type },
          });
        } else if (isSigned.status.code === "0000") {
          const loginData = {
            token: `${isSigned.data.token_type} ${isSigned.data.access_token}`,
            autoLogin: false,
          };
          this.getMe(loginData);
          this.$router.push("/");
        }
      } catch (error) {
        console.error(error);
      }
    },
    kakao() {
      Kakao.Auth.login({
        scope: "account_email",
        success: async (obj) => {
          const me = await Kakao.API.request({
            url: "/v2/user/me",
          });
          // console.log("@@kakao me :", me);
          const { id } = me;
          this.snsLogin(id, "KAKAO");
        },
        fail: (err) => {
          window.alert("에러로 인해 회원가입에 실패했습니다.");
        },
      });
    },
    google() {
      this.$gAuth
        .signIn()
        .then((GoogleUser) => {
          //on success do something
          //console.log('GoogleUser',GoogleUser)
          //console.log('GoogleUser id', GoogleUser.Av.mw)
          //const id = GoogleUser.it.Tt;

          const id = GoogleUser.Av.mw;

          this.snsLogin(id, "GOOGLE");
        })
        .catch((error) => {
          console.log(error);

          window.alert("에러로 인해 회원가입에 실패했습니다.");
        });
    },
    async initNaver() {
      console.log(window.location.origin);

      // var naverLogin = new window.naver.LoginWithNaverId({
      //   clientId: "5gCMcDWRMuH2EsvuO97O",
      //   callbackUrl: `${window.location.origin}/account/naver/`,

      //   // isPopup: false, /* 팝업을 통한 연동처리 여부 */
      // });
      // /* 설정정보를 초기화하고 연동을 준비 */
      // naverLogin.init();

      // naverLogin.getLoginStatus(async (status) => {
      //   // console.log("status", status);
      //   const url = naverLogin.generateAuthorizeUrl();
      //   const link = document.createElement("a");
      //   link.href = url;
      //   this.$refs.temp.append(link);
      //   link.click();
      //   this.$refs.temp.removeChild(link);

      //   let user = naverLogin.user;
      //   if (status) {
      //     let id = user.email;
      //     // let { accessToken } = naverLogin;
      //     this.snsLogin(id, "NAVER");
      //   } else {
      //     // console.log("callback 처리에 실패하였습니다.");
      //   }
      // });
    },
  },
  mounted() {
    var naverLogin = new window.naver.LoginWithNaverId({
      clientId: "62LknMd92KuRBoC3s95T",
      callbackUrl: `${window.location.origin}/account/naver/`,
      loginButton: {
        color: "green",
        type: 3,
        height: 60,
      } /* 로그인 버튼의 타입을 지정 */,
      // isPopup: false, /* 팝업을 통한 연동처리 여부 */
    });
    /* 설정정보를 초기화하고 연동을 준비 */
    naverLogin.init();
  },
};
</script>

<style lang="scss" scoped></style>
