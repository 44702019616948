<template>
  <div class="mt-3 mb-5 py-5">
    <b-container>
      <guide-header text="파트너 기업" variant="primary text-white" />
      <b-row class="mt-5 pt-3 pt-lg-5" align-h="center">
        <b-col cols="12" lg="8" class="pt-1">
          <div class="mb-3" v-for="(item, i) in partners" :key="i">
            <b-row align-h="center" align-v="center">
              <b-col cols="12" lg="8">
                <div class="px-5 py-lg-3 border">
                  <div class="mx-4 mx-lg-5 px-lg-5">
                    <b-btn
                      variant="text"
                      class="bg-img ratio-35 ratio-45 w-100 px-0 pt-0 rounded-0"
                      :style="{
                        backgroundImage: `url(${item.src})`,
                        backgroundSize: 'contain',
                      }"
                      :href="item.url"
                      target="_blank"
                    />
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                lg="3"
                offset-lg="1"
                class="text-center text-lg-left pt-2 pt-lg-0"
              >
                <span class="text-14 text-lg-24">
                  {{ item.name }}
                </span>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import GuideHeader from "@/components/Items/GuideHeader.vue";
export default {
  components: { GuideHeader },
  data() {
    return {
      partners: [
        {
          name: "신용회복위원회",
          src: require("@/assets/images/Home/partners/partner-ccrs.svg"),
          url: "https://www.ccrs.or.kr/main.do",
        },
        {
          name: "한국대부금융협회",
          src: require("@/assets/images/Home/partners/partner-clfa.png"),
          url: "https://www.clfa.or.kr/",
        },
        {
          name: "금융위원회",
          src: require("@/assets/images/Home/partners/partner-fsc.svg"),
          url: "https://www.fsc.go.kr/index",
        },
        {
          name: "YUN Communication",
          src: require("@/assets/images/Home/partners/partner-yun.svg"),
          url: "http://www.yuncom.co.kr/",
        },
        {
          name: "금융감독원",
          src: require("@/assets/images/Home/partners/partner-fss.png"),
          url: "https://www.fss.or.kr/fss/kr/main.jsp",
        },
        {
          name: "한국핀테크지원센터",
          src: require("@/assets/images/Home/partners/partner-fintech.svg"),
          url: "https://fintech.or.kr/web/user/main.do",
        },
        {
          name: "한국핀테크협회",
          src: require("@/assets/images/Home/partners/partner-korfin.gif"),
          url: "http://korfin.kr/",
        },
        {
          name: "유진투자증권",
          src: require("@/assets/images/Home/partners/partner-eugenefn.svg"),
          url: "https://www.eugenefn.com/cpkr/main/cpmain.do",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
