<template>
  <div class="d-flex flex-column align-items-center">
    <label
      class="mt-3 input-image-label bg-img cursor-pointer position-relative"
      :class="thumb ? 'border border-primary' : ''"
      :style="{ backgroundImage: `url(${s3 + thumb})`, width: `${width}px` }"
    >
      <b-form-file
        class="d-none"
        v-model="file"
        :accept="accept"
        @change="fileChanged"
      ></b-form-file>
      <span class="position-center" v-if="!thumb">
        <span class="text-48 text-white">+</span>
      </span>
      <b-btn
        variant="primary rounded-circle p-0 d-flex align-items-center justify-content-center position-absolute"
        :style="{
          width: '32px',
          height: '32px',
          top: '0',
          right: '0',
          transform: 'translate(50%, -50%)',
        }"
        v-if="thumb"
        @click="reset()"
      >
        <i class="icon icon-times text-17"></i>
      </b-btn>
    </label>
    <div class="text-center" v-if="!thumb">
      <span class="text-gray text-17 text-lg-15 fw-400">
        {{ labelText }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labelText: {
      default: "이미지를 등록해주세요.",
      type: String,
    },
    width: { default: 120, type: Number },
    accept: {
      type: String,
    },
  },
  data() {
    return {
      file: null,
      thumb: null,
    };
  },
  methods: {
    async fileChanged(e) {
      const file = e.target.files[0];
      const data = await this.attachFile(file);
      // console.log(("url::", data.filename);
      this.thumb = data.filename;
      this.$emit("input", data);
    },
    reset() {
      this.file = null;
      this.thumb = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
