<template>
  <div class="mt-3 mb-5 py-5">
    <b-container>
      <guide-header
        :text="getText(type)"
        variant="white"
        class="border border-primary text-primary"
      />
      <template v-if="!currentTerm">
        <div class="text-center">
          <b-spinner variant="primary" class="my-5" />
        </div>
      </template>
      <template v-else-if="currentTerm">
        <article class="py-5" v-html="currentTerm.content">
          <!-- {{ currentTerm }} -->
        </article>
      </template>
    </b-container>
  </div>
</template>

<script>
import GuideHeader from "@/components/Items/GuideHeader.vue";
export default {
  components: { GuideHeader },
  data() {
    return {
      currentTerm: null,
    };
  },
  computed: {
    type() {
      return this.$route.query.type;
    },
  },
  methods: {
    getText(type) {
      const text = {
        SERVICE_TERMS: "이용약관",
        PRIVACY: "개인정보처리방침",
        MARKETING: "마케팅 활용동의",
        PRIVACY_CONSIGNMENT: "제 3자 개인정보 위탁 공유 약관",
        LOCATION: "위치정보서비스 및 위치기반서비스 이용약관",
        LEAVE: "서비스 탈퇴약관",
        NON_MEMBER_SERVICE_TERMS: "비회원 구매 이용약관",
        NON_MEMBER_PRIVACY: "비회원 구매 개인정보 수집",
      };
      return text[type] || text.error;
    },
    async getTerm() {
      try {
        const { data } = await this.$axios.get(`/documents/${this.type}`);
        this.currentTerm = data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    await this.getTerm();
  },
};
</script>

<style lang="scss" scoped></style>
