<template>
  <div
    class="
      p-3
      d-flex
      flex-column
      justify-content-between
      align-items-center
      w-100
      text-center
      rounded-xl
      border-dark-3
      h-100
    "
  >
    <header class="pt-3 text-primary text-20 text-lg-26">
      {{ item.heading }}
    </header>
    <hr
      class="
        mx-5
        mt-1
        mb-4
        w-75
        border border-bottom border-top-0 border-3 border-primary
      "
    />
    <span class="text-blacktext-17 text-lg-15">
      <div v-html="item.text" class="opacity-7">
        {{ item.text }}
      </div>
    </span>
    <div
      class="bg-img w-100 ratio-35 ratio-sm-50 my-3"
      :style="{
        backgroundImage: `url(${item.icon})`,
        backgroundSize: 'contain',
      }"
    ></div>
    <b-btn
      variant="text text-black fw-700 mt-3"
      class="p-0"
      @click="$router.push(item.url)"
    >
      {{ item.more }}
      <i class="icon icon-next ml-0"></i>
    </b-btn>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped></style>
