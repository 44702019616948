<template>
  <b-container class="my-5 py-5">
    <fieldset class="mt-5">
        {{bankInfo}}
        <!-- 은행 ID -->
        <legend class="mb-4 my-3 p-3 text-17 bg-lighter"> 은행 - BANK DB ID </legend>
        <template>
            <b-row v-if="bankInfo">
                    <b-col>
                        <h5>{{bankInfo.relativeUser.companyMeta.companyName}}</h5>
                    </b-col>
            </b-row>
        </template>
        <template>
            <b-row>
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend>
                        <w-input
                            class="w-100 m-0"
                            type="text"
                            v-model="idBank"
                            placeholder="BANK DB ID*"
                        ></w-input>
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-btn @click="getBankInfo">조회</b-btn>
                </b-col>
            </b-row>
        </template>

        <!-- 기본 조건 -->
        <legend class="mb-4 my-3 p-3 text-17 bg-lighter"> 기본 조건 - CondtionChart </legend>
        <template>
            <b-row class="mb-4 my-3 p-3">
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend>
                        <w-input
                            class="w-100 m-0"
                            type="text"
                            v-model="conditionChart.work"
                            placeholder="직업*"
                        ></w-input>
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend>
                        <w-input
                            class="w-100 m-0"
                            type="text"
                            v-model="conditionChart.age"
                            placeholder="만 나이*"
                        ></w-input>
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend>
                        <w-input
                            class="w-100 m-0"
                            type="text"
                            v-model="conditionChart.city"
                            placeholder="city*"
                        ></w-input>
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend>
                        <w-input
                            class="w-100 m-0"
                            type="text"
                            v-model="conditionChart.buildingKind"
                            placeholder="물건 종류*"
                        ></w-input>
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row class="mb-4 my-3 p-3">
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend>
                        <w-input
                            class="w-100 m-0"
                            type="text"
                            v-model="conditionChart.minHhldCnt"
                            placeholder="최소 세대수*"
                        ></w-input>
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row class="mb-4 my-3 p-3">
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend>
                        <w-input
                            class="w-100 m-0"
                            type="text"
                            v-model="conditionChart.area"
                            placeholder="전용면적*"
                        ></w-input>
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend>
                        <w-input
                            class="w-100 m-0"
                            type="text"
                            v-model="conditionChart.criteriaKbPrice"
                            placeholder="KB시세*"
                        ></w-input>
                    </b-input-group>
                </b-col>
            </b-row>
        </template>

        <!-- 금리표 -->
        <legend class="mb-4 my-3 p-3 text-17 bg-lighter">금리표 - InterestRateChart</legend>
        <template>
            <b-form-radio-group v-model="interestRateChart.first">
                <b-form-radio class="fw-400 d-inline-flex mr-5" :value="true">선순위</b-form-radio>
                <b-form-radio class="fw-400 d-inline-flex" :value="false">후순위</b-form-radio>
            </b-form-radio-group>
        </template>
        <template>
            <legend class="mb-4 my-3 p-3 text-17 bg-lighter">신용등급</legend>
            <b-row>
                <b-col>
                    <b-form-radio-group
                    stacked
                    :options="tierCode"
                    v-model="interestRateChart.tierCode"
                    class="fw-400 text-17 fw-400
                            d-flex
                            justify-content-between
                            flex-wrap
                            work-radio"
                  ></b-form-radio-group>
                </b-col>
            </b-row>
        </template>
        <template>
            <b-row class="mb-4 my-3 p-3">
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend>
                    <w-input
                        class="w-100 m-0"
                        type="text"
                        v-model="interestRateChart.minLtvPersent"
                        placeholder="최소 LTV*"
                    ></w-input>
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend>
                    <w-input
                        class="w-100 m-0"
                        type="text"
                        v-model="interestRateChart.maxLtvPersent"
                        placeholder="최대 LTV*"
                    ></w-input>
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend @keyup.enter="add('I')">
                    <w-input
                        class="w-100 m-0"
                        type="text"
                        v-model="interestRateChart.interestRate"
                        placeholder="금리*"
                        
                    ></w-input>
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-btn @click="add('I')">추가</b-btn>
                </b-col>
            </b-row>
        </template>
        <template>
            <legend class="mb-4 my-3 p-3 text-17 bg-lighter">InterestRateChart Data</legend>
            <div v-if="interestRateCharts && interestRateCharts.length > 0" style="height: 200px; overflow: scroll; ">
                <ul style="list-style: none;"
                    v-for="(item, i) in interestRateCharts"
                    :key="i"
                >
                    <li>
                        <span>순위 : {{item.first ? '선순위' : '후순위'}} <br></span>
                        <span>신용등급 : {{item.tierCode}} <br></span>
                        <span>금리 : {{item.interestRate}} <br> </span>
                        <span>최소 LTV 한도 : {{item.minLtvPersent}}<br> </span>
                        <span>최대 LTV 한도 : {{item.maxLtvPersent}}<br></span>
                        <hr>
                    </li>
                </ul>
            </div>
            <div v-else>
                <span>No add Data</span>
            </div>
        </template>
        <hr>
        <!-- 한도표 -->
        <template>
            <legend class="mb-4 my-3 p-3 text-17 bg-lighter">한도표 - LimitChart </legend>
        </template>

        <!-- 한도표가 있는 경우 -->
        <template>
            <b-form-radio-group v-model="limitChart.first" class="mb-4 my-3 p-3">
                <b-form-radio class="fw-400 d-inline-flex mr-5" :value="true">선순위</b-form-radio>
                <b-form-radio class="fw-400 d-inline-flex" :value="false">후순위</b-form-radio>
            </b-form-radio-group>

            <template>
            <legend class="mb-4 my-3 p-3 text-17 bg-lighter">신용등급</legend>
                <b-row>
                    <b-col>
                        <b-form-radio-group
                        stacked
                        :options="tierCode"
                        v-model="limitChart.tierCode"
                        class="fw-400 text-17 fw-400
                                d-flex
                                justify-content-between
                                flex-wrap
                                work-radio"
                    ></b-form-radio-group>
                    </b-col>
                </b-row>
            </template>

            <b-row v-if="bankInfo && bankInfo.exclusiveAreas">
            <legend class="mb-4 my-3 p-3 text-17 bg-lighter">면적구간</legend>
                <b-col>
                    <b-form-select
                        v-model="limitChart.exclusiveArea"
                        class="mb-4"
                        @change="dongChange2($event)"
                        >
                        <option
                            v-for="(item, idx) in bankInfo.exclusiveAreas"
                            :value="item"
                            :key="idx"
                        >
                            {{ `${item.minArea == null ? 0 : item.minArea} ~ ${item.maxArea == null ? 10000 : item.maxArea}` }}
                        </option>
                    </b-form-select>
                </b-col>
            </b-row>

            <legend class="mb-4 my-3 p-3 text-17 bg-lighter">급지</legend>
            <b-row>
                <b-col>
                    <b-form-radio-group
                    stacked
                    :options="landGubun"
                    v-model="limitChart.landCode"
                    class="fw-400 text-17 fw-400
                            d-flex
                            justify-content-between
                            flex-wrap
                            work-radio"
                  ></b-form-radio-group>
                </b-col>
            </b-row>
            <b-row class="mb-4 my-3 p-3">
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend>
                    <w-input
                        class="w-100 m-0"
                        type="text"
                        v-model="limitChart.exclusiveArea.minArea"
                        placeholder="최소 면적*"
                    ></w-input>
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend>
                    <w-input
                        class="w-100 m-0"
                        type="text"
                        v-model="limitChart.exclusiveArea.maxArea"
                        placeholder="최대 면적*"
                    ></w-input>
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group class="m-0" variant="outline" hide-prepend @keyup.enter="add('L')">
                    <w-input
                        class="w-100 m-0"
                        type="text"
                        v-model="limitChart.ltvPersent"
                        placeholder="LTV 한도*"
                    ></w-input>
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-btn @click="add('L')">추가</b-btn>
                </b-col>
            </b-row>
        </template>
        <template>
            <legend class="mb-4 my-3 p-3 text-17 bg-lighter">LimitChart Data</legend>
            <div v-if="limitCharts && limitCharts.length > 0" style="height: 200px; overflow: scroll; ">
                <ul style="list-style: none;"
                    v-for="(limitChart, i) in limitCharts"
                    :key="i"
                >
                    <li>
                        <span>순위 : {{limitChart.first ? '선순위' : '후순위'}} <br></span>
                        <span>급지 : {{limitChart.landCode}} <br> </span>
                        <span>LTV 한도 : {{limitChart.ltvPersent}}<br> </span>
                        <span>최소 면적 : {{limitChart.exclusiveArea.minArea}}<br></span>
                        <span>최대 면적 : {{limitChart.exclusiveArea.maxArea}}<br></span>
                        <hr>
                    </li>
                </ul>
            </div>
            <div v-else>
                <span>No add Data</span>
            </div>
            <b-btn @click="allRemove()">전체삭제</b-btn>
        </template>
    </fieldset>
    <div class="my-5 text-center">
        <b-btn
            variant="primary"
            class="w-75 py-3"
            @click="upload"
        >
            {{ "등록" }}
        </b-btn>
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
        idBank: null,
        existLimit:false,
        submitted :false,

        limitCharts:[],
        interestRateCharts:[],

        bankInfo: null,
        /* 기본 조건 */
        conditionChart:{
            age: null,
            work: null,
            city: null,
            area: null,
            minHhldCnt: null,
            buildingKind: null,
            criteriaKbPrice: null,
        },

        /* 금리 */ 
        interestRateChart: {
            minLtvPersent: null,
            maxLtvPersent: null,
            interestRate : null,
            tierCode: null,
            first : true
        },

         /* 한도 */ 
        limitChart:{
            first: true,       //선순위 여부 
            landCode: null,     //급지코드
            ltvPersent: null,   //ltv한도
            tierCode: null,
            exclusiveArea: {    //면적조건
                id: null,
                maxArea : null,
                minArea : null,
            },
        },

        tierCode: [
            {
                text: "1등급",
                value: "ONE"
            },
            {
                text: "2등급",
                value: "TWO"
            },
            {
                text: "3등급",
                value: "THREE"
            },
            {
                text: "4등급",
                value: "FOUR"
            },
            {
                text: "5등급",
                value: "FIVE"
            },
            {
                text: "6등급",
                value: "SIX"
            },
            {
                text: "7등급",
                value: "SEVEN"
            },
            {
                text: "8등급",
                value: "EIGHT"
            },

        ],
        landGubun: [
            {
                text: "1급지",
                value: "FIRST",
            },
            {
                text: "2급지",
                value: "SECOND",
            },
            {
                text: "3급지",
                value: "THIRD",
            },
            {
                text: "4급지",
                value: "FOURTH",
            },
            {
                text: "5급지",
                value: "FIFTH",
            }
        ],
      }
    },

  methods: {
    allRemove(){
        this.limitCharts = [];
    },
    add(type){
        //금리표
        if(type ==='I'){

            if(!this.interestRateChart.interestRate){
                alert("금리 입력은 필수입니다.");
                return;
            }

            if(this.interestRateChart){
                if(!this.interestRateChart.minLtvPersent){
                    this.interestRateChart.minLtvPersent = 0;
                }else if(!this.interestRateChart.maxLtvPersent){
                    this.interestRateChart.maxLtvPersent = 10000;
                }
            }

            this.interestRateCharts.push(this.interestRateChart);
            
            let code = null;

            this.tierCode.forEach((e,i) => {
                if(e.value === this.interestRateChart.tierCode){
                    if(i >= this.tierCode.length -1){
                        code = this.tierCode[0].value;
                        return;
                    }
                    code = this.tierCode[i+1].value;
                    return;
                }
            });

            this.interestRateChart = {
                tierCode :code,
                minLtvPersent: this.interestRateChart.minLtvPersent,
                maxLtvPersent: this.interestRateChart.maxLtvPersent,
                interestRate : null,
                first: this.interestRateChart.first
            }; 

        //한도표
        }else if(type === 'L'){

            if(!this.limitChart.ltvPersent){
                alert("한도 입력은 필수입니다.");
                return;
            }

            if(this.limitChart.exclusiveArea){
                if(!this.limitChart.exclusiveArea.minArea){
                    this.interestRateChart.minLtvPersent = 0;
                }else if(!this.limitChart.exclusiveArea.maxArea){
                    this.limitChart.exclusiveArea.maxArea = 10000;
                }
            }

            this.limitCharts.push(this.limitChart);

            let code = null;

            this.tierCode.forEach((e,i) => {
                if(e.value === this.limitChart.tierCode){
                    if(i >= this.tierCode.length -1){
                        code = this.tierCode[0].value;
                        return;
                    }
                    code = this.tierCode[i+1].value;
                    return;
                }
            });

            this.limitChart = {
                tierCode: code,
                first: this.limitChart.first,
                ltvPersent: null,   //ltv한도
                landCode: this.limitChart.landCode,     //급지코드
                exclusiveArea: this.limitChart.exclusiveArea
            };
        }
    },

    async getBankInfo() {

        if(!this.idBank){
            alert("은행 아이디를 입력해주세요.");
                return;
        }
        
        if(this.bankInfo){
            this.bankInfo = null;
        }
      try{
        
        this.loadingInfo = {
          show: true,
          text: null,
        };

        const { data } = await this.$axios.get(`/bank/${this.idBank}` );

        if (data.status.code === "0000") {

            this.bankInfo = data.data;

            const bool = await window.alert(`은행 조회가 완료되었습니다.`, {
            title: "은행 조회 완료",
          });
        }else{
            const bool = await window.alert(data.status.message, {
                title: "은행 조회 실패",
        });
        }
      }catch(e){
        console.log(e);
        const bool = await window.alert(`은행 조회에 실패하였습니다.`, {
                title: "은행 조회 실패",
        });
      }finally{
        this.loadingInfo = {
          show: false,
          text: null,
        };
      }
    },

    async upload() {

        this.submitted = true;

        let form = {
            idBank : this.idBank,
            conditionChart: {...this.conditionChart},
            interestRateCharts: this.interestRateCharts,
            limitCharts: this.limitCharts
        };

      try{
        
        this.loadingInfo = {
          show: true,
          text: "조견표 등록 중",
        };

        const { data } = await this.$axios.post("/condition", form);

        if (data.status.code === "0000") {
          const bool = await window.alert(`조견표 등록이 완료되었습니다.`, {
            title: "조견표 등록 완료",
          });

            if(bool){
                this.submitted = false;
                this.$router.go();  //새로 고침
            }
        }
      }catch(e){
        console.log(e);
      }finally{
        this.loadingInfo = {
          show: false,
          text: null,
        };
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
