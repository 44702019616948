<template>
  <div class="" style="height: inherit;" ref="element">
    <b-container>
      <b-row style="margin-bottom: 10px">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span style="flex: 1;">법인명</span>
          <b-form-select size="sm" style="flex: 5;" v-model="searchCompany" @change="changeCompany" :options="searchCompanyOption"></b-form-select>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-end flex-row align-items-center">
          <b-button class="mx-1 my-1 tab_btn" @click="add">등록</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg=6 class="d-flex justify-content-end flex-row">
          <div class="" style="width: -webkit-fill-available;">
            <b-table 
              id = "grid1"
              striped hover 
              :items="first_gData"
              :fields="first_gridFields"
              small
            >
              <template #cell(No)="row">
                <div style="cursor: pointer;" @click="secondMenuChange(row.index)">
                  {{ row.index + 1 }}
                </div>
              </template>
              <template #cell(code)="row">
                <div style="cursor: pointer;" @click="secondMenuChange(row.index)">
                  {{ first_gData[row.index].code }}
                </div>
              </template>
              <template #cell(name)="row">
                <div style="cursor: pointer;" @click="secondMenuChange(row.index)">
                  {{ first_gData[row.index].name }}
                </div>
              </template>
              <template #cell(authority)="row">
                <div style="cursor: pointer;" @click="secondMenuChange(row.index)">
                  {{ first_gData[row.index].authority }}
                </div>
              </template>
              <template #cell(use_yn)="row">
                <div style="cursor: pointer;" @click="secondMenuChange(row.index)">
                  {{ first_gData[row.index].use_yn }}
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-end flex-row">
          <div class="" style="width: -webkit-fill-available;">
            <b-table 
              id = "grid2"
              striped hover 
              :items="second_gData"
              :fields="second_gridFields"
              small
            >
              <template #cell(No)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ row.index + 1 }}
                </div>
              </template>
              <template #cell(type_name)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].type_name }}
                </div>
              </template>
              <template #cell(code)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].code }}
                </div>
              </template>
              <template #cell(name)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].name }}
                </div>
              </template>
              <template #cell(url)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].url }}
                </div>
              </template>
              <template #cell(authority)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].authority }}
                </div>
              </template>
              <template #cell(use_yn)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].use_yn }}
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>

      <!-- modals -->
      <modal-menu-add ref="modal_menuAdd" :obj="obj" />
      <modal-menu-edit ref="modal_menuEdit" :obj="obj" />

    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    
    // Modals
    ModalMenuAdd: Modals.MenuAdd,
    ModalMenuEdit: Modals.MenuEdit,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      formValid: false,
      salt: 0,
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      select_index: -1,
      select_rowData: {
        id : 0,
        type : "",
        type_name : "",
        code : "",
        code_name : "",
        title : "",
        descript : "",
        reg_user : "",
        reg_date : "",
        update_date : "",
        post_date : "",
        use_yn : "",
        status_name : "",
      },
      first_menu: [],
      first_gData: [],
      first_gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "code",
          label: "메뉴ID"
        },
        {
          key: "name",
          label: "메뉴이름"
        },
        {
          key: "authority",
          label: "권한"
        },
        {
          key: "use_yn",
          label: "사용여부"
        },
      ],
      second_menu: [],
      second_gData: [],
      second_gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "code",
          label: "메뉴ID"
        },
        {
          key: "name",
          label: "메뉴이름"
        },
        {
          key: "url",
          label: "메뉴경로"
        },
        {
          key: "authority",
          label: "권한설정"
        },
        {
          key: "use_yn",
          label: "사용여부"
        },
      ],
      searchCompany: "CPS00002",
      searchCompanyOption: [],
      rules: {
        
      },
      obj: {
        edit_type: "QNA",
        title: "메뉴 등록",
        ok_title: "등록",
        param_data: {
          id : 0,
          type : "",
          type_name : "",
          code : "",
          code_name : "",
          title : "",
          descript : "",
          reg_user : "",
          reg_date : "",
          update_date : "",
          post_date : "",
          use_yn : "",
          status_name : "",
        },
      },
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  methods: {
    async selectCompany() {
      console.log("메뉴 관리 페이지");

      try {
        const { data } = await this.$axios.post(
          "/api/selectCompanyList", {
            use_yn : "Y",
            type : "C"
          }
        );
        if (data.length > 0) {
          let options = [];

          data.forEach((e,i) => {
            let add_option = { value: e.company_id, text: e.name }
            options.push(add_option);
          });

          this.searchCompanyOption = options;
        } else if (data.length == 0) {
          this.searchCompanyOption = null;
          window.alert("데이터가 없습니다.");
        }

        this.selectMenu(this.searchCompany);
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectMenu(companyId) {
      if (companyId === "CPS00002") {
        companyId = "EV";
      }

      try {
        const { data } = await this.$axios.post(
          "/api/selectMenu", {
            company_id: companyId
          }
        );
        if (data.length > 0) {
          let f_data = [];
          let s_data = [];

          data.forEach((e,i) => {
            if (e.level === "1") {
              f_data.push(e);
            } else if (e.level === "2") {
              s_data.push(e);
            }
          });

          this.first_menu = f_data;
          this.first_gData = f_data;
          this.second_menu = s_data;

          let temp_data = [];

          s_data.forEach((e,i) => {
            if (e.r_menu_id === this.first_menu[0].code) {
              temp_data.push(e);
            }
          });

          this.second_gData = temp_data;
        } else if (data.length == 0) {
          this.first_gData = null;
          this.second_gData = null;
          window.alert("데이터가 없습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    changeCompany() {
      this.selectMenu(this.searchCompany);
    },
    secondMenuChange(row_index) {
      let temp_data = [];

      this.second_menu.forEach((e,i) => {
        if (e.r_menu_id === this.first_menu[row_index].code) {
          temp_data.push(e);
        }
      });

      this.second_gData = temp_data;
    },
    selectRow(row_index) {
      this.obj.edit_type = "QNA";
      this.obj.title = "메뉴 수정";
      this.obj.ok_title = "수정";
      this.obj.param_data = this.second_gData[row_index];

      this.$refs.modal_menuEdit.setData(this.obj.param_data);
      this.$bvModal.show("modal-menu-edit");
    },
    add() {debugger;
      this.obj.edit_type = "QNA";
      this.obj.title = "메뉴 등록";
      this.obj.ok_title = "등록";
      this.obj.param_data = null;

      this.$bvModal.show("modal-menu-add");
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
  async mounted() {
    this.selectCompany();
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>