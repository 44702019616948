import Vue from "vue";
import "@babel/polyfill";
import "mutationobserver-shim";
import "./plugins/axios";
import "./plugins/bootstrap-vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
// 샌드버드 오류창 숨기려고 주석처리
import "./plugins/sendbird";
import "./plugins/weblab";
import App from "./App.vue";
import router from "./router";
import "./styles/index.scss";
import store from "./store";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import xlsx from "xlsx";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);
Vue.use(xlsx);

library.add(fas, far, fab);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import commons from "./plugins/commons";
Vue.use(commons);

Vue.config.productionTip = false;

import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);

import resize from "vue-element-resize-detector";
Vue.use(resize);

// import VueLoading from "vuejs-loading-plugin";

// overwrite defaults
// Vue.use(VueLoading, {
//   dark: true, // default false
//   text: 'Loading', // default 'Loading'
//   loading: true, // default false
//   //customLoader: myVueComponent, // replaces the spinner and text with your own
//   background: 'rgb(255,255,255)', // set custom background
//   classes: ['myclass'] // array, object or string
// })

// google map
// import * as VueGoogleMaps from "vue2-google-maps";

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: "AIzaSyDnzPQU27TFRKsCr_A1DoBNf21xROXwy1g",
//     libraries: "places",
//   },
// });

import GAuth from "vue-google-oauth2";

Vue.use(GAuth, {
  clientId:
    "212226073186-4kgm83c0plpfvd2ieiju60ks2cblrnrf.apps.googleusercontent.com",
  scope: "profile email https://www.googleapis.com/auth/plus.login",
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
