<template>
  <div class="py-5">
    <b-container>
      <header class="mb-5">
        <h2 class="mb-2 text-28 text-lg-38">고객센터</h2>
        <span class="text-14 text-gray fw-400"
          >공지사항, 대출시 안내사항을 확인하실 수 있습니다.</span
        >
      </header>
      <section>
        <hr bold class="border-gray mb-0" />
        <b-row class="border-bottom border-5 border-light mx-0 py-3 py-md-4">
          <b-col cols="12" md="2" class="mb-2 mb-md-0">
            <div class="text-md-center text-primary">
              <strong class="text-14 text-lg-16">
                {{ getText(item.type) }}
              </strong>
            </div>
          </b-col>
          <b-col cols="12" md="8" class="mb-2 mb-md-0">
            {{ item.title }}
          </b-col>
          <b-col cols="12" md="2" class="mb-2 mb-md-0 text-md-right">
            <small class="text-14 text-lg-15 text-gray">
              {{ toLocaleDate(item.createdDate) }}
            </small>
          </b-col>
        </b-row>
        <div
          class="text-17 fw-400 lh-180 pr-3 pr-md-5 py-4"
          :style="{ minHeight: '15rem' }"
        >
          <div v-html="item.content"></div>
        </div>
        <hr bold class="border-gray" />
      </section>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: null,
    };
  },
  computed: {
    idx() {
      return this.$route.params.id;
    },
  },
  methods: {
    getText(progress) {
      const text = {
        GUIDE: "대출시 안내사항",
        NOTICE: "공지사항",
        ERROR: "에러",
      };
      return text[progress] || null;
    },
    async getCurrentItem() {
      try {
        const { data } = await this.$axios.get(`/customer-center/${this.idx}`);
        if (data.status.code === "0000") {
          this.item = data.data;
        }
        // console.log(data);
      } catch (error) {
        // console.log(data);
      }
    },
  },
  async mounted() {
    await this.getCurrentItem();
  },
};
</script>

<style lang="scss" scoped></style>
