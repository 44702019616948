<template>
  <div>
    <header class="mb-3">
      <h2 v-if="userType === 'bidder'" class="mb-2 text-28 text-lg-38">
        입찰 수익/정산
      </h2>
      <h2 v-else-if="userType === 'client'" class="mb-2 text-28 text-lg-38">
        의뢰 수익/정산
      </h2>
      <span class="text-14 text-gray fw-400"
        >수익/정산 내역을 확인하실 수 있습니다.</span
      >
      store : {{ this.$store.state.user.mypageStatus }} userType :
      {{ userType }}
    </header>
    <b-row align-v="start" class="mt-4">
      <b-col
        cols="12"
        md="12"
        xl="2"
        class="pt-lg-4 mb-2 mb-md-5 mb-xl-0"
        :style="{ height: '36px' }"
      >
        <b-dropdown
          variant="white border border-dark-gray"
          split-variant="outline-dark-gray text-nowrap"
          split-class="border-right border-dark-gray"
          class="dropdown-badge dropdown-sm mx-0 p-0"
          menu-class="w-100 rounded-0 my-0 py-0"
          no-flip
          :style="{ height: '36px' }"
        >
          <template #button-content>
            <div class="w-100 px-2">
              {{ getText(filterSelected) }}
            </div>
          </template>
          <b-dropdown-item-button
            @click="filterSelected = item.value"
            v-for="(item, i) in progress"
            :key="`${i}-${item.id}`"
          >
            {{ item.text }}
          </b-dropdown-item-button>
        </b-dropdown>
      </b-col>
      <b-col cols="12" md="6" xl="3">
        <label class="mb-0 text-13 text-dark-gray font-weight-normal w-100"
          >시작일
          <b-input-group
            class="mt-1 custom-datepicker"
            :style="{ height: '36px' }"
          >
            <div
              class="
                date-string
                h-100
                bg-white
                text-gray
                d-flex
                align-items-center
                pl-2
                font-weight-bold
              "
              :style="{ width: 'calc(100% - 20px)' }"
            >
              {{ toLocaleDateString(dateSelected.start) }}
            </div>

            <b-input-group-append>
              <b-form-datepicker
                v-model="dateSelected.start"
                right
                button-only
                is-text
                button-variant="dark-gray"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  weekday: 'narrow',
                }"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </label>
      </b-col>
      <b-col cols="12" md="6" xl="3">
        <label class="mb-0 text-13 text-dark-gray font-weight-normal w-100"
          >종료일
          <b-input-group
            class="mt-1 custom-datepicker"
            :style="{ height: '36px' }"
          >
            <div
              class="
                date-string
                h-100
                bg-white
                text-gray
                d-flex
                align-items-center
                pl-2
                font-weight-bold
              "
              :style="{ width: 'calc(100% - 20px)' }"
            >
              {{ toLocaleDateString(dateSelected.end) }}
            </div>

            <b-input-group-append>
              <b-form-datepicker
                v-model="dateSelected.end"
                right
                button-only
                is-text
                button-variant="dark-gray"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  weekday: 'narrow',
                }"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </label>
      </b-col>
      {{ status }}
      <b-col cols="12" xl="4" class="pt-4">
        <div class="px-3 py-4 border border-light-gray rounded-lg text-right">
          <header class="mb-2">
            <strong class="text-16"> 선택 기간 수익/정산내역 </strong>
            <!-- <h4 class="mb-0 text-24 text-lg-30 text-primary">
              {{ items && items.length && status ? items.length : 0 }}건 /
              {{
                toLocale(
                  items && items.length && status
                    ? status.doneAmount + status.waitAmount
                    : 0
                )
              }}만원
            </h4> -->
            <h4 class="mb-0 text-24 text-lg-30 text-primary">
              {{ count }}건 / {{ toLocale(total) }}원
            </h4>
          </header>
          <template>
            <ul class="mb-0 list-unstyled text-gray text-17">
              <li class="">
                정산 완료 :
                {{ items && items.length && status ? status.doneCount : 0 }}건 /
                {{
                  items && items.length && status
                    ? toLocale(status.doneAmount)
                    : 0
                }}만원
              </li>
              <li class="">
                정산 대기 :
                {{ items && items.length && status ? status.waitCount : 0 }}건 /
                {{
                  items && items.length && status
                    ? toLocale(status.waitAmount)
                    : 0
                }}만원
              </li>
            </ul>
          </template>
        </div>
      </b-col>
      <div v-if="items && items.length">
        <b-btn variant="outline-primary" @click="getExcelDown()">
          수익/정산 엑셀 다운
        </b-btn>
      </div>
    </b-row>
    <hr />
    <template v-if="items && items.length">
      <div class="table-wrapper mt-3 py-3">
        <table class="px-3 text-14 text-lg-16">
          <tr>
            <th class="pt-1 pb-3" :style="{ width: '20%', minWidth: '3rem' }">
              입찰자
            </th>
            <th class="pt-1 pb-3" :style="{ width: '35%', minWidth: '12rem' }">
              대출 정보
            </th>
            <th class="pt-1 pb-3" :style="{ width: '30%', minWidth: '12rem' }">
              수익
            </th>
            <th class="pt-1 pb-3" :style="{ width: '15%' }">진행 상태</th>
          </tr>
          <tr
            v-for="(item, i) in items"
            :key="i"
            :class="
              i == 0
                ? 'border-top border-2 border-gray'
                : 'border-top border-light-gray'
            "
          >
            <td>
              {{
                item.relativeUser.companyMeta === null
                  ? onlyId(item.relativeUser.serviceId)
                  : item.relativeUser.companyMeta.companyName
              }}
            </td>

            <td>
              <ul class="list-unstyled m-0 text-center fw-400 text-gray">
                <li>
                  대출 종류 : {{ getProduct(item.relativeBidding.productKind) }}
                </li>
                <li class="text-truncate">
                  물건명 : {{ item.relativeBidding.address }}
                </li>
                <li>
                  대출 금액 :{{
                    item.relativeBidding.loanAmount
                      ? `${toLocale(item.relativeBidding.loanAmount)}만원`
                      : "-"
                  }}
                </li>
                <li>
                  대출종료일:
                  {{ getDate(item.relativeBidding.lastProgressUpdatedDate) }}
                </li>
              </ul>
            </td>
            <td>
              <ul class="list-unstyled m-0 text-center fw-400 text-gray">
                <li>수당 : {{ item.benefit }}%</li>
                <li v-if="userType === 'bidder'">
                  지급금액 :
                  <!-- {{
                    toLocale(
                      item.relativeBidding.resultAmount * (item.benefit / 100)
                    )
                  }}만원 -->
                  {{ toLocale(item.relativeBidding.resultAmount) }}원
                </li>
                <li v-else-if="userType === 'client'">
                  수취금액 :
                  <!-- {{
                    toLocale(
                      item.relativeBidding.resultAmount * (item.benefit / 100)
                    )
                  }}만원 -->
                  {{ toLocale(item.relativeBidding.resultAmount) }}원
                </li>
              </ul>
            </td>
            <td class="text-center">
              <template v-if="item.benefitStatus === 'DONE'">
                <strong class="d-block text-secondary">정산 완료</strong>
                <span class="fw-400 text-gray"
                  >정산일 : {{ getDate(item.benefitDate) }}</span
                >
              </template>
              <template v-else>
                <strong class="d-block text-dark">입금 대기</strong>
              </template>
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-5 d-flex flex-column">
        <b-pagination
          @change="paginationEvent"
          :value="page.number + 1"
          :total-rows="page.totalElements"
          :per-page="page.size"
          class="mx-auto"
          first-class="d-none"
          last-class="d-none"
          hide-ellipsis
        >
          <template #prev-text>
            <i class="icon icon-prev ml-1"></i>
          </template>
          <template #next-text>
            <i class="icon icon-next ml-1"></i>
          </template>
        </b-pagination>
      </div>
    </template>
    <template v-else>
      <div
        class="
          mt-3
          bg-light
          text-gray text-center
          py-5
          border-top border-bottom border-3 border-darkest
        "
      >
        매칭 데이터가 없습니다.
      </div>
    </template>
  </div>
</template>
<script>
// import Product from "@/lib/product";
import xlsx from "xlsx";

export default {
  props: {
    userType: {
      type: String,
      default: "client",
    },
  },
  data() {
    return {
      items: [],
      count: 0,
      total: 0,
      filter: [
        {
          text: "전체",
          value: null,
        },
        {
          text: "정산완료",
          value: "DONE",
        },
        {
          text: "입금대기",
          value: "WAIT",
        },
      ],
      page: {
        page: 1,
      },
      filterSelected: null,
      dateSelected: {
        start: new Date("2021-04-01T00:00:00.000Z"),
        end: new Date(),
      },
      status: null,
      progress: [
        { text: "전체", value: null },
        { text: "정산완료", value: "DONE" },
        { text: "입금대기", value: "WAIT" },
      ],
    };
  },
  computed: {
    query() {
      const benefitStatus = this.filterSelected;
      const start = new Date(this.dateSelected.start);
      const end = new Date(this.dateSelected.end).setHours(0, 0, 0, 0);
      const startDate = new Date(start).toISOString();
      const endDate = this.getCalcDate(end, 1).toISOString();
      return {
        benefitStatus,
        startDate,
        endDate,
      };
    },
  },

  methods: {
    getExcelDown() {
      
      alert("엑셀");
      const item = this.items;
      
      let array = [];
      let total = 0;
      let loanEndDate;
      let benefitDate;

      //foreach to for
      for(let i=0; i<item.length; i++){

        loanEndDate = new Date(item[i].relativeBidding.updatedDate);
        loanEndDate.setHours(loanEndDate.getHours() + 9);
        loanEndDate = loanEndDate
          .toISOString()
          .split("T")
          .join(" ")
          .substr(0, 19);

        if(item[i].benefitDate){
          benefitDate = new Date(item[i].benefitDate);
          benefitDate.setHours(benefitDate.getHours() + 9);
          benefitDate = benefitDate
            .toISOString()
            .split("T")
            .join(" ")
            .substr(0, 19);
        }

        let bidding = item[i].relativeBidding;

        const data = {
          "물건 주소": bidding.roadAddress,
          "물건 종류": this.getProduct(bidding.productKind),
          "대출 종류": this.getProduct2(bidding.productKind2),

          "대출 요청금액": `${(bidding.amount*10000).toLocaleString("en")}`,
          "실제 대출금액": `${bidding.loanAmount ? (bidding.loanAmount*10000).toLocaleString("en") : '' }`,

          "수당 (%)": item[i].benefit,
          "수익": `${bidding.resultAmount ? bidding.resultAmount.toLocaleString("en") : '' }`,

          "정산일": benefitDate,
          "대출 종료일": loanEndDate,
        };

        array.push(data);

        total += bidding.resultAmount;
      }

      array.push({});

      const wb = xlsx.utils.book_new();

      //엑셀 워크시트 형식으로 json을 보내준다. (데이터의 형식은 배열의 형식만 가능)
      const ws = xlsx.utils.json_to_sheet(array);
      ws["!cols"] = [
        { wpx: 60 }, // A열
        { wch: 40 }, // B열
        { wpx: 60 }, // C열
        { wpx: 80 }, // D열
        { wpx: 100 }, // E열
        { wpx: 130 }, // F열
        { wpx: 80 }, // G열
        { wpx: 80 }, // H열
        { wpx: 130 }, // I열
      ];

      const addWs = [
        {
          "총 건수": `${array.length - 1}건`,
          "총 수익 합계": `${total.toLocaleString("en")}원`,
        },
      ];
      const dataWS = xlsx.utils.sheet_add_json(ws, addWs, {
        origin: `H${array.length + 2}`,
      });

      //엑셀의 workbook생성, 엑셀에서 요구하는 이름형식
      //만든 workbook에 워크시크 추가, 시트명은 nameData
      xlsx.utils.book_append_sheet(wb, dataWS, "수익정산");
      
      const fileName = `${this.toLocaleDateString(this.dateSelected.start)}~${this.toLocaleDateString(this.dateSelected.end)}`+ 
        `홈뱅크 ${this.userType === 'bidder' ? '입찰' : '의뢰'} 수익정산.xlsx`;
      //엑셀 파일을 내보낸다. wb에 담긴 data배열을 엑셀형태로 다운 받게 된다.
      xlsx.writeFile(wb, fileName);
    },
    async getItem(options) {
      try {
        const { data } = await this.$axios.get(
          `/benefit/list-${this.userType}`,
          {
            params: {
              ...options,
            },
          }
        );
        if (data.status.code === "0000") {
          this.items = data.data.content;
          this.page = data.data.page;

          this.count = this.items.length;

          let total = 0;

          this.items.forEach(function (e) {
            console.log(e.relativeBidding.resultAmount);
            total += e.relativeBidding.resultAmount;
          });

          this.total = total;
        }
        console.log("@@items:", this.items);
      } catch (error) {
        console.log(error);
      }
    },
    async getStatus(options) {
      try {
        const { data } = await this.$axios.get(`/benefit/current-state`, {
          params: {
            ...options,
          },
        });
        if (data.status.code === "0000") {
          this.status = data.data;
          // console.log("@@status: ", this.status);
        }
      } catch (error) {
        console.log(error);
      }
    },
    getText(progress) {
      // 전체, 정산완료, 입금대기
      const text = {
        null: "전체",
        DONE: "정산완료",
        WAIT: "입금대기",
      };
      return text[progress] || text.error;
    },
    getProduct(kind) {
      console.log('getProcu');
      const products = {
        KIND_1: "아파트",
        KIND_2: "빌라/다세대/도시형/연립주택",
        KIND_3: "단독/다가구/타운하우스",
        KIND_4: "오피스텔",
        KIND_5: "토지",

        // KIND_1: "아파트",
        // KIND_2: "빌라",
        // KIND_3: "오피스텔/도시형생활주택",
        // KIND_4: "단독/다가구주택/타운하우스",
        // KIND_5: "부동산지분대출",
        // KIND_6: "상가건물/상가주택/집합상가",
        // KIND_7: "토지/건물(준공)대환대출",
        // KIND_8: "PF건축자금대출/미준공건축자금대출",
        // KIND_9: "무설정아파트론",
        // KIND_10: "전세자금대출",
        // KIND_11: "임대아파트대출/보증금담보대출",
        // KIND_12: "기타/공장 등 특수물건",
      };
      return products[kind];
    },
    getProduct2(kind) {
      const products = {
        KIND2_1: "담보대출",
        KIND2_2: "매매자금",
        KIND2_3: "퇴거자금",
        KIND2_4: "무설정론",
        KIND2_5: "지분",

        // KIND_1: "아파트",
        // KIND_2: "빌라",
        // KIND_3: "오피스텔/도시형생활주택",
        // KIND_4: "단독/다가구주택/타운하우스",
        // KIND_5: "부동산지분대출",
        // KIND_6: "상가건물/상가주택/집합상가",
        // KIND_7: "토지/건물(준공)대환대출",
        // KIND_8: "PF건축자금대출/미준공건축자금대출",
        // KIND_9: "무설정아파트론",
        // KIND_10: "전세자금대출",
        // KIND_11: "임대아파트대출/보증금담보대출",
        // KIND_12: "기타/공장 등 특수물건",
      };
      return products[kind];
    },
    paginationEvent(page) {
      console.log("paginationEvent");
      this.getItem({ ...this.query, page: page - 1 });
      window.scrollTo(0, 0);
    },
    toLocaleDateString(value) {
      const date = new Date(value);
      this.getCalcDate(date, 1);
      return date.toISOString().split("T")[0];
    },
  },
  watch: {
    async userType(n) {
      // this.$router.push("/mypage/matching-history?type=all");
      // if (n === "client") {
      //   this.$router.push("/mypage/matching-history?type=all");
      // }

      await this.getItem();
    },
    query(n) {
      console.log("query");
      this.getItem(n);
      this.getStatus(n);
    },
  },
  async mounted() {
    await this.getItem();
    //await this.getStatus();
    // if (this.userType === "client") {
    //   this.$router.go(-1);
    // }
  },
};
</script>
<style lang="scss" scoped></style>
