<template>
  <!-- 등기부등본 -->
  <article class="px-4 px-md-5">
    <header class="text-14 text-lg-16 text-primary">
      추후 업데이트
    </header>
    <section class="my-3">
      <b-btn
        variant="outline-light-gray bg-transparent w-100 py-3 rounded-0 text-darkest d-flex align-items-center"
        v-b-toggle="`collapse-${0}`"
        @click="toggle(0)"
      >
        <span class="text-13 text-lg-15">추후 업데이트</span>
        <i
          class="icon icon-16 icon-caret-collapse ml-auto"
          :class="{ active: current === 0 }"
        />
      </b-btn>
      <b-collapse id="collapse-0" class="bg-gray-fa px-4">
        <div class="py-3">
          <ul class="list-unstyled p-0">
            <li
              class="py-2 mb-2 border-bottom border-dark-gray d-flex align-items-center justify-content-between"
            >
              <span
                class="text-gray text-13 text-lg-14 ml-0 mr-auto text-nowrap"
              >
                추후 업데이트
              </span>
              <span
                class="text-darkest fw-600 text-13 text-lg-14 ml-auto mr-0 pl-3 text-right"
              >
                -
              </span>
            </li>
          </ul>
        </div>
      </b-collapse>
    </section>
  </article>
</template>

<script>
export default {
  data() {
    return {
      current: null,
    };
  },
  methods: {
    toggle(idx) {
      if (this.current !== null && this.current === idx) {
        this.current = null;
      } else {
        this.current = idx;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
