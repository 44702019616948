<template>
  <aside>
    <b-modal
      id="finCompany"
      scrollable
      centered
      hide-header-close
      ok-only
      ok-title="확인"
      ok-variant="facebook"
      footer-bg-variant="white"
      no-close-on-backdrop
      size="lg"
    >
      <template #modal-header>
        <h5 class="fw-900">{{ finCompany.name }}</h5>
      </template>
      <template #default>
        <h5>{{ finCompany.name }}</h5>
      </template>
    </b-modal>
  </aside>
</template>

<script>
export default {
  name: "BankList",
  props: {
    finCompany: null,
  },
};
</script>

<style lang="scss"></style>
