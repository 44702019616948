<template>
  <b-container class="py-5 border-right position-relative">
    <b-btn @click="gradeTest()">테스트</b-btn>
    <template v-if="!item">
      <div class="text-center">
        <b-spinner variant="primary" class="my-5" />
      </div>
    </template>
    <template v-if="item">
      <floating-button v-b-toggle.more-info />
      <header class="mb-5">
        <div
          class="
            mb-2
            d-flex
            flex-column-reverse flex-md-row
            align-items-md-center
          "
        >
          <h1 class="mt-3 mb-0 mt-md-0 mr-md-4 text-24 text-lg-38"></h1>
          <!-- 비회원 경우 -->
          <template v-if="!user && item">
            <b-badge
              :variant="getVariant(item.progress)"
              class="ml-md-2 my-1 my-md-0 py-3 rounded-0 text-white"
              :style="{ maxWidth: '100px' }"
            >
              <span class="text-16"> {{ getText(item.progress) }}</span>
            </b-badge>
          </template>

          <!-- 의뢰자(글쓴이) 경우, 진행취소가 버튼이 아니고 뱃지였다 -->
          <!-- <template v-else-if="isMine(item.relativeUser)">
          <b-btn
            variant="outline-primary"
            class="py-2"
            :style="{ maxWidth: '120px' }"
            v-b-modal.modal-cancel-progress
            :disabled="item.progress === 'CANCEL_PROGRESS'"
            >진행 취소</b-btn
          >
        </template> -->
          <!-- 입찰자bidder 일 경우  -->

          <template
            v-else-if="
              user &&
              item.bidders &&
              item.bidders.some(
                (bid) => bid.relativeUser && bid.relativeUser.serviceId === user.serviceId
              ) &&
              currentMatchedBidder
            "
          >
            <b-dropdown
              variant="white text-gray border"
              split-variant="outline-dark-gray text-nowrap"
              split-class="border-right border-dark-gray"
              class="dropdown-badge mx-0 p-0"
              :menu-class="
                item.progress !== 'COMPLETE'
                  ? 'w-100 rounded-0 my-0 py-0 min-w-0'
                  : 'd-none'
              "
              :disabled="!bidderStatus.possibleChange"
            >
              <template #button-content>
                <div class="w-100 px-2">
                  {{ getText(item.progress) }}
                </div>
              </template>
              <template
                v-if="
                  item.progress !== 'TRANSFERRED' &&
                  item.progress !== 'COMPLETE'
                "
              >
                <b-dropdown-item-button
                  @click="changeProgress('MATCHED')"
                  v-if="item.progress !== 'MATCHED'"
                  >매칭 완료</b-dropdown-item-button
                >
                <b-dropdown-item-button
                  @click="changeProgress('APPROVAL')"
                  v-if="item.progress !== 'APPROVAL'"
                  >승인 완료</b-dropdown-item-button
                >
                <b-dropdown-item-button
                  @click="changeProgress('AUTOGRAPH')"
                  v-if="item.progress !== 'AUTOGRAPH'"
                  >자서 완료</b-dropdown-item-button
                >
              </template>

              <b-dropdown-item-button
                v-b-modal.modal-result-amount
                v-if="
                  item.progress !== 'TRANSFERRED' &&
                  item.progress !== 'COMPLETE'
                "
              >
                송금 완료
              </b-dropdown-item-button>
              <b-dropdown-item-button
                @click="complete()"
                v-if="item.progress !== 'COMPLETE'"
              >
                대출 완료</b-dropdown-item-button
              >
              <template
                v-if="
                  item.progress !== 'TRANSFERRED' &&
                  item.progress !== 'COMPLETE'
                "
              >
                <b-dropdown-item-button v-b-modal.modal-rejected
                  >부결</b-dropdown-item-button
                >
              </template>
            </b-dropdown>
            <!-- ... 송금완료, 대출완료시에 나온다고 돼있는데 일반사용자, 의뢰자, 입찰자 모두 나와야 하는 건지 -->
            <template
              v-if="
                item.progress === 'TRANSFERRED' || item.progress === 'COMPLETE'
              "
            >
              <span class="ml-4 px-3 py-2 border-gray-light border text-nowrap"
                >실제 대출 금액 :
                <strong class="text-secondary">{{
                  toLocale(item.loanAmount)
                }}</strong>
                만원</span
              >
              <span class="ml-4 px-3 py-2 border-gray-light border text-nowrap"
                >실제 송금 금액(수수료) :
                <strong class="text-secondary">{{
                  toLocale(item.resultAmount)
                }}</strong>
                원</span
              >
            </template>
          </template>

          <template v-else-if="isMine(item.relativeUser)">
            <template
              v-if="
                item.progress === 'TRANSFERRED' || item.progress === 'COMPLETE'
              "
            >
              <b-badge
                :variant="getVariant(item.progress)"
                class="ml-md-2 my-1 my-md-0 py-3 rounded-0 text-white"
                :style="{ maxWidth: '100px' }"
              >
                <span class="text-16"> {{ getText(item.progress) }}</span>
              </b-badge>
              <template
                v-if="
                  item.progress === 'TRANSFERRED' ||
                  item.progress === 'COMPLETE'
                "
              >
                <span
                  class="ml-4 px-3 py-2 border-gray-light border text-nowrap"
                  >실제 대출 금액 :
                  <strong class="text-secondary">{{
                    toLocale(item.loanAmount)
                  }}</strong>
                  만원</span
                >
                <span
                  class="ml-4 px-3 py-2 border-gray-light border text-nowrap"
                  >실제 송금 금액(수수료) :
                  <strong class="text-secondary">{{
                    toLocale(item.resultAmount)
                  }}</strong>
                  원</span
                >
                <span
                  class="ml-4 px-3 py-2 border-gray-light border text-nowrap"
                  v-if="
                    currentMatchedBidder &&
                    currentMatchedBidder.relativeUser.bankAccountMeta
                  "
                  >송금계좌 정보
                  <div
                    v-if="
                      currentMatchedBidder.relativeUser.bankAccountMeta.bank
                    "
                  >
                    <strong class="text-secondary">은행명 : </strong>
                    <span>{{
                      `${currentMatchedBidder.relativeUser.bankAccountMeta.bank}은행`
                    }}</span>
                  </div>
                  <div
                    v-if="
                      currentMatchedBidder.relativeUser.bankAccountMeta
                        .accountHolder
                    "
                  >
                    <strong class="text-secondary">예금주 : </strong>
                    <span>{{
                      `${currentMatchedBidder.relativeUser.bankAccountMeta.accountHolder}`
                    }}</span>
                  </div>
                  <div
                    v-if="
                      currentMatchedBidder.relativeUser.bankAccountMeta
                        .accountNumber
                    "
                  >
                    <strong class="text-secondary">계좌번호 : </strong>
                    <span>{{
                      `${currentMatchedBidder.relativeUser.bankAccountMeta.accountNumber}`
                    }}</span>
                  </div>
                </span>
              </template>
            </template>
            <template v-else>
              <b-badge
                :variant="getVariant(item.progress)"
                class="ml-md-2 my-1 my-md-0 py-3 rounded-0 text-white"
                :style="{ maxWidth: '100px' }"
              >
                <span class="text-16"> {{ getText(item.progress) }}</span>
              </b-badge>
            </template>
          </template>

          <!-- 일반사용자 COMMON 일 경우 -->
          <template v-else>
            <b-badge
              :variant="getVariant(item.progress)"
              class="ml-md-2 my-1 my-md-0 py-3 rounded-0 text-white"
              :style="{ maxWidth: '100px' }"
            >
              <span class="text-16"> {{ getText(item.progress) }}</span>
            </b-badge>
          </template>
        </div>
        <div class="mt-3">
          <span class="text-gray text-14 fw-400"
            >비딩 매칭 상세를 확인하실 수 있습니다.</span
          >
          <div class="mt-1" v-if="isMine(item.relativeUser)">
            <b-badge
              variant="white"
              class="px-4 py-1 border-light-gray border rounded-pill"
            >
              <span class="text-13 text-gray">
                남은 수정 횟수
                <strong class="text-error">{{ item.possibleUpdate }}</strong
                >회
              </span>
            </b-badge>
          </div>
          <div
            class="mt-1"
            v-else-if="
              user &&
              item.bidders &&
              item.bidders.some(
                (bid) => bid.relativeUser && bid.relativeUser.serviceId === user.serviceId
              )
            "
          >
            <b-badge
              variant="white"
              class="px-4 py-1 border-light-gray border rounded-pill"
            >
              <span class="text-13 text-gray">
                남은 입찰 횟수
                <strong class="text-error"
                  >{{ bidderStatus.possibleBidding }} </strong
                >회
              </span>
            </b-badge>
          </div>
        </div>
      </header>
      <template v-if="user">
        <strong v-if="item.caution && item.caution != ''">
          {{ item.caution }}
          <hr />
        </strong>
        <h5>공시가격 알리미 - 공동주택 가격</h5>
        <div v-if="item.apartmentPrice" style="margin-bottom: 20px">
          <div>{{`전용면적 : ${item.apartmentPrice.privArea }㎡`}}</div>
          <div>공동주택 가격 : {{ toLocale(item.apartmentPrice.price) }}원</div>
          <div>데이터 업데이트 일자 : {{ item.apartmentPrice.noticeDate }}</div>
        </div>
        <div v-else style="margin-bottom: 20px">
          <div>
            <strong>조회 내용 없음</strong>
          </div>
        </div>

        <h5>등기부 등본 요약 내역</h5>
        <div v-if="item.registerCountMeta" style="margin-bottom: 20px">
          <div>압류 건수 : {{ item.registerCountMeta.seizure }}</div>
          <div>가압류 건수 : {{ item.registerCountMeta.proSeizure }}</div>
          
          <div>가처분 건수 : {{ item.registerCountMeta.proInjunction }}</div>
          <div>가등기 건수 : {{ item.registerCountMeta.proDisposition }}</div>
          
          <div>예고등기 건수 : {{ item.registerCountMeta.noticeRegistration }}</div>
          <div>환매등기 건수 : {{ item.registerCountMeta.resaleRegistration }}</div>

          <div>강제 or 임의 경매 개시결정 건수 : {{ item.registerCountMeta.auction }}</div>
        </div>
        <div v-else style="margin-bottom: 20px">
          <div>
            <strong>조회 내용 없음</strong>
          </div>
        </div>

        <h5>압류/가압류 요약 내용</h5>
        <div v-if="regsiter && regsiter.length" style="margin-bottom: 20px">
          <ul style="list-style: none; display: flex">
            <div
              class="fw-700 text-gray text-truncate"
              style="margin-right: 50px"
              v-for="(item, i) in regsiter"
              :key="i"
            >
              <li>{{ `항목 : ${item.code == 'P1' ? '가압류' : '압류'}` }}</li>
              <li>{{ `대상 소유자 : ${item.owner}` }}</li>
              <li>{{ `청구 금액 : ${item.billPrice ? toLocale(item.billPrice) : '-'}` }}</li>
              <li>{{ `채권자 : ${item.creditor}` }}</li>
              <li>{{ `접수정보 : ${item.receiveInfo}` }}</li>
              <li>{{ `접수일자 : ${item.receiveDate}` }}</li>
            </div>
          </ul>
        </div>
        <div v-else style="margin-bottom: 20px">
          <div>
            <strong>조회 내용 없음</strong>
          </div>
        </div>
        <h5>근저당권 상세 내용</h5>
        <div v-if="mortgage && mortgage.length" style="margin-bottom: 20px">
          <ul style="list-style: none; display: flex">
            <div
              class="fw-700 text-gray text-truncate"
              style="margin-right: 50px"
              v-for="(item, i) in mortgage"
              :key="i"
            >
            
              <li>{{ `말소여부 : ${item.cancellation ? 'O' : 'X'}` }}</li>
              <li>{{ `순위번호 : ${item.ranking ? item.ranking : ""}` }}</li>
              <li>{{ `등기목적 : ${item.cont ? item.cont : ""}` }}</li>
              <li>{{ `대출 원금 : ${toLocale(item.bondAmount)}` }}</li>
              <li>{{ `채권 최고액 : ${toLocale(item.highBondAmount)}` }}</li>
              <li>{{ `설정율: ${item.persent ? item.persent : ""}%` }}</li>
              <li>{{ `근저당권자 : ${item.mortgageHolder ? item.mortgageHolder : ""}` }}</li>
              <li>{{ `채무자 : ${item.owner ? item.owner : ""}` }}</li>
            </div>
          </ul>
        </div>
        <div v-else style="margin-bottom: 20px">
          <div>
            <strong>조회 내용 없음</strong>
          </div>
        </div>
        <div>
          {{
            `채권 원금 합계 : ${
              item.totalBondAmount == null
                ? "없음"
                : item.totalBondAmount == -1
                ? "직접 확인 요망"
                : `${toLocale(item.totalBondAmount)}원`
            }`
          }}
        </div>
        <div>
          {{
            `채권 최고액 합계 : ${
              item.totalHighBondAmount == null
                ? "없음"
                : item.totalHighBondAmount == -1
                ? "직접 확인 요망"
                : `${toLocale(item.totalHighBondAmount)}원`
            }`
          }}
        </div>
        <hr>
        <div v-if="item.ltvMeta">
          <div v-if="item.productKind === 'KIND_5'">
            <span
              >(등기부 등본상) 공지시가별 토지 면적대비 :
              {{
                item.ltvMeta.indvdLandPrice
                  ? item.ltvMeta.indvdLandPrice
                  : "개별 공시지가 최근 기록 없음"
              }}
            </span>
          </div>
          <div v-else>
            <div>
              <div v-if="item.ltvMeta.reson && !item.ltvMeta.kbPrice">
                {{ `KB 시세 : ${item.ltvMeta.reson}` }}
              </div>
              <div v-else>
                {{
                  `KB 시세 : ${
                    item.ltvMeta.kbPrice == null || item.ltvMeta.kbPrice == ""
                      ? "KB시세 없음"
                      : `${toLocale(item.ltvMeta.kbPrice)}원`
                  }`
                }}
              </div>
            </div>
            <div>
              {{
                `실거래가 : ${
                  !item.ltvMeta ||
                  item.ltvMeta.marketPrice == null ||
                  item.ltvMeta.marketPrice == ""
                    ? "실거래가 1년 내 없음"
                    : `${toLocale(item.ltvMeta.marketPrice)}원`
                }`
              }}
            </div>
          </div>
          <div>
            {{
              `경매 낙찰가 (최근 2년) : ${
                (!item.ltvMeta && item.ltvMeta.auctionPrice == null) || item.ltvMeta.auctionPrice == 0
                  ? "경매낙찰가 2년 내 없음"
                  : item.ltvMeta.auctionPrice == -1 
                    ? "직접 확인 요망"
                    : `${toLocale(item.ltvMeta.auctionPrice)}원`
              }`
            }}
          </div>

          <br />

          <h5>경매낙찰가 상세 내용</h5>
          <div
            v-if="
              item.auctionDetail &&
              item.auctionDetail.auctionPrice != '0' &&
              item.auctionDetail.auctionPrice != '-1'
            "
            style="margin-bottom: 20px"
          >
            <ul>
              <li>{{ `접수일 : ${item.auctionDetail.registerDate}` }}</li>
              <li>{{ `주소 : ${item.auctionDetail.address}` }}</li>
              <li>{{ `용도 : ${item.auctionDetail.yongDo}` }}</li>
              <li>{{ `대지 : ${item.auctionDetail.area}` }}</li>
              <li>{{ `건물내역 : ${item.auctionDetail.platArea}` }}</li>
              <li>{{ `매각방법 : ${item.auctionDetail.salesMethod}` }}</li>
              <li>
                {{ `유찰 / 진행 : ${item.auctionDetail.outbidProgressCount}` }}
              </li>
              <li>{{ `최저가 : ${item.auctionDetail.lowestPrice}` }}</li>
              <li>{{ `감정가 : ${item.auctionDetail.appraisedPrice}` }}</li>
              <li>{{ `경매 낙찰가 : ${item.auctionDetail.auctionPrice}` }}</li>
              <li>{{ `최종 결과 : ${item.auctionDetail.finalResult}` }}</li>
            </ul>
          </div>
          <div v-else style="margin-bottom: 20px">
            <span>조회된 내용 없음.</span>
          </div>

          <br />

          <h5>원금 합계 대비 LTV</h5>
          <div v-if="item.ltvMeta.bondAmount">
            <div v-if="item.productKind === 'KIND_5'">
              <span
                >개별 공시지가 대비 LTV :
                {{
                  item.ltvMeta.bondAmount.indvdLandPriceLtv
                    ? item.ltvMeta.bondAmount.indvdLandPriceLtv
                    : "개별 공시지가 대비 LTV 최근 기록 없음"
                }}
              </span>
            </div>
            <div v-else>
              <div>
                <span
                  >KB시세 대비 LTV :
                  {{
                    item.ltvMeta.bondAmount.kbPriceLtv
                      ? item.ltvMeta.bondAmount.kbPriceLtv
                      : "KB시세 대비 LTV 최근 기록 없음"
                  }}</span
                >
              </div>
              <div>
                <span
                  >실거래가 대비 LTV :
                  {{
                    item.ltvMeta.bondAmount.marketPriceLtv
                      ? item.ltvMeta.bondAmount.marketPriceLtv
                      : "실거래가 대비 LTV 최근 기록 없음"
                  }}</span
                >
              </div>
            </div>
            <div>
              <span
                >경매 낙찰가 대비 LTV :
                {{
                  item.ltvMeta.bondAmount.auctionLtv
                    ? item.ltvMeta.bondAmount.auctionLtv
                    : "경매낙찰가 대비 LTV 최근 기록 없음"
                }}</span
              >
            </div>
          </div>
          <div v-else>없음</div>

          <br />

          <h5>채권최고액 합계 대비 LTV</h5>
          <!-- 0810 값이 없는 경우 대체 텍스트 변경 -->
          <div v-if="item.ltvMeta.highBondAmount">
            <div v-if="item.productKind === 'KIND_5'">
              <span
                >개별 공시지가 대비 LTV :
                {{
                  item.ltvMeta.highBondAmount.hindvdLandPriceLtv
                    ? item.ltvMeta.highBondAmount.hindvdLandPriceLtv
                    : "개별 공시지가 대비 LTV 최근 기록 없음"
                }}</span
              >
            </div>
            <div v-else>
              <div>
                <span
                  >KB시세 대비 LTV :
                  {{
                    item.ltvMeta.highBondAmount.hkbPriceLtv
                      ? item.ltvMeta.highBondAmount.hkbPriceLtv
                      : "KB시세 대비 LTV 최근 기록 없음"
                  }}</span
                >
              </div>
              <div>
                <span
                  >실거래가 대비 LTV :
                  {{
                    item.ltvMeta.highBondAmount.hmarketPriceLtv
                      ? item.ltvMeta.highBondAmount.hmarketPriceLtv
                      : "실거래가 대비 LTV 최근 기록 없음"
                  }}</span
                >
              </div>
            </div>
            <div>
              <span
                >경매 낙찰가 대비 LTV :
                {{
                  item.ltvMeta.highBondAmount.hauctionLtv
                    ? item.ltvMeta.highBondAmount.hauctionLtv
                    : "경매낙찰가 대비 LTV 최근 기록 없음"
                }}</span
              >
            </div>
          </div>
          <div v-else>없음</div>
        </div>
        <div v-else>
          <div>
            <strong> LTV 조회 결과 없음. </strong>
          </div>
        </div>
      </template>
      <article class="mb-5">
        <header
          class="mb-4 d-md-flex align-items-center jusitfy-content-between"
        >
          <h2 class="mb-2 mb-md-0 text-24 text-lg-28 fw-400 mr-auto">
            의뢰 정보
          </h2>
          <!-- 의뢰자(글쓴이)인 경우 -->
          <!-- :disabled="item.bidders.find((r) => r.grade !== null)" -->
          <template v-if="isMine(item.relativeUser)">
            <template v-if="item.progress === 'COMPLETE'">
              <b-btn
                variant="outline-primary"
                class="mx-2"
                v-if="item"
                :disabled="item.bidders.find((r) => r.grade2 !== null)"
                @click="inputGrade()"
              >
                <i class="icon icon-star text-20 mr-1"></i>평점 작성
              </b-btn>
            </template>
            <template v-else>
              <b-btn
                variant="primary"
                class="mr-2"
                style="min-width: 137px"
                :to="`${this.$route.path}/edit`"
                :disabled="
                  item.possibleUpdate === 0 ||
                  item.progress === 'CANCEL_PROGRESS'
                "
              >
                수정
              </b-btn>
              <b-btn
                variant="outline-primary"
                class="mr-0"
                style="min-width: 137px"
                v-b-modal.modal-cancel-progress
                :disabled="item.progress === 'CANCEL_PROGRESS'"
              >
                진행 취소
              </b-btn>
            </template>
          </template>
          <template
            v-else-if="
              user &&
              item.bidders &&
              item.bidders.some(
                (bid) => bid.relativeUser && bid.relativeUser.serviceId === user.serviceId
              ) &&
              currentMatchedBidder
            "
          >
            <template v-if="item.progress === 'COMPLETE'">
              <b-btn
                variant="outline-primary"
                class="mx-2"
                @click="inputGrade()"
                v-if="item && item.bidders"
                :disabled="item.grade2"
              >
                <i class="icon icon-star text-20 mr-1"></i>평점 작성
              </b-btn>
            </template>
          </template>
          <!-- 그 외의 경우 -->
          <!-- <template
        채팅을 특정하기 어려워 없어지게 됨
          v-else-if="
            user &&
              !isMine(item.relativeUser) &&
              item.bidders &&
              item.bidders.some(
                (bid) => bid.relativeUser.serviceId === user.serviceId
              )
          "
        >
          <b-btn
            variant="outline-primary position-relative"
            class="mr-2"
            :replace="{ path: $route.path, query: { chat: 1 } }"
          >
            <div
              class="bg-error position-absolute text-white d-flex align-items-center justify-content-center"
              :style="{
                width: '30px',
                height: '30px',
                maxWidth: '30px',
                borderRadius: '50%',
                top: '0',
                left: '0',
                transform: 'translate(-50%,-50%)',
              }"
            >
              12
            </div>
            <i class="icon icon-chatting text-20 mr-1"></i>채팅하기
          </b-btn>
        </template> -->
          <template
            v-if="
              item.progress !== 'TRANSFERRED' &&
              item.progress !== 'COMPLETE' &&
              user &&
              !isMine(item.relativeUser) &&
              user.role !== 'REALTOR'
            "
          >
            <b-btn
              variant="primary"
              class="mr-0 ml-2 py-2"
              :to="'/matching/' + item.id + '/bidding'"
              :disabled="
                bidderStatus.possibleBidding === 0 ||
                item.progress === 'CANCEL_PROGRESS'
              "
            >
              <!-- || item.progress !== 'ING' -->
              <!-- item.progress === 'CANCEL_PROGRESS' || -->
              <!-- item.progress === 'MATCHED' -->
              <i class="icon icon-bid text-20 mr-1"></i>입찰하기
            </b-btn>
          </template>
          <!-- 비회원의 경우 -->
          <template v-else> </template>
        </header>
        <hr class="my-2" bold />

        <b-row class="p-3" align-v="start">
          <b-col cols="12" md="2" class="pr-4">
            <figure
              class="mb-0 bg-img w-100 ratio-100 mb-3"
              :style="
                item.productKind
                  ? [
                      {
                        backgroundImage: `url(${getImage(item.productKind)})`,
                      },
                    ]
                  : [
                      {
                        backgroundColor: '#f2f2f2',
                      },
                    ]
              "
            ></figure>
          </b-col>
          <b-col cols="12" md="10" class="d-flex">
            <ul class="list-unstyled w-50">
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-start
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '100px' }" class="text-secondary"
                  >소유자명</strong
                >
                <span class="fw-700 text-gray text-truncate">
                  {{ item.holder }}
                </span>
              </li>
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-start
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '100px' }" class="text-secondary"
                  >공동명의자</strong
                >
                <span v-if="biddingJointName && biddingJointName.length >= 1">
                  <span
                    class="fw-700 text-gray text-truncate"
                    v-for="(item, i) in biddingJointName"
                    :key="i"
                  >
                    {{ `${item.jointNameHolder} (${getJoint(item.relation)})` }}
                    <br />
                  </span>
                </span>
                <span v-else>-</span>
              </li>

              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-start
                  text-17 text-nowrap
                "
              >
                <!-- 07/04 세입자 동의여부 상세 내용 표기  -->
                <strong :style="{ minWidth: '100px' }" class="text-secondary"
                  >지분 소유현황</strong
                >
                {{item.landShare}}
                <span class="fw-700 text-gray text-truncate">
                  {{
                    !item.landShare 
                      ? item.share 
                        ? item.share 
                        : "-"
                      : item.landShare
                  }}
                </span>
              </li>
              
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-start
                  text-17 text-nowrap
                "
              >
                <!-- 07/04 세입자 동의여부 상세 내용 표기  -->
                <strong :style="{ minWidth: '100px' }" class="text-secondary"
                  >대출 종류</strong
                >
                {{ getProductKind2(item.productKind2) }}
                <span class="fw-700 text-gray text-truncate">
                  
                </span>
              </li>

              <!-- <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-start
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '100px' }" class="text-secondary"
                  >세입자</strong
                >
                <span class="fw-700 text-gray text-truncate">
                  {{
                    item.tenant
                      ? `${toLocale(item.deposit)}만원 / ${toLocale(
                          item.monthlyRent
                        )}만원 / ${item.tenantAgree ? "동의" : "미동의"}`
                      : "본인거주"
                  }}
                </span>
              </li> -->
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-start
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '100px' }" class="text-secondary"
                  >의뢰일자</strong
                >
                <span class="fw-700 text-gray text-truncate">
                  {{ item.createdDate ? toLocaleDate(item.createdDate) : "-" }}
                </span>
              </li>
            </ul>
            <ul class="list-unstyled w-50">
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-start
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '100px' }" class="text-secondary"
                  >주소</strong
                >

                <!-- 건축물대장 데이터가 없을 경우 무한로딩으로 item.buildingMeta && item.buildingMeta.brTitleInfo 조건 추가 -->
                <span class="fw-700 text-gray">
                  <span v-if="item.roadAddress">
                    {{
                      ` ${item.roadAddress} 
                          ${(item.buildingMeta &&
                              item.buildingMeta.brTitleInfo &&
                              item.buildingMeta.brTitleInfo.bldNm) ||
                            "" } 
                          ${item.addressDetail || ""}`
                    }}
                  </span>
                  <span v-else> 
                    {{ ` ${item.address} `}}
                  </span>
                </span>
              </li>
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-start
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '100px' }" class="text-secondary"
                  >토지 담보 여부</strong
                >
                <span class="fw-700 text-gray text-truncate">
                  {{item.landColl ? "O" : "X"}}
                </span>
              </li>
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-start
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '100px' }" class="text-secondary"
                  >필요금액</strong
                >
                <span class="fw-700 text-gray text-truncate">
                  {{ item.amount ? `${toLocale(item.amount)}만원` : "-" }}
                </span>
              </li>
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-start
                  text-17 text-nowrap
                "
              >
                <strong :style="{ minWidth: '100px' }" class="text-secondary"
                  >재직정보</strong
                >
                <span class="fw-700 text-gray text-truncate">
                  {{ getWork(item.work) }}
                </span>
              </li>
              <!-- 단독주택 또는 토지인 경우 의뢰자가 입력한 내용 -->
              <div v-if="item && (item.productKind === 'KIND_3' || item.productKind === 'KIND_5') && item.goingOrAppraisedPrice">
                <li
                  class="
                    mb-1 mb-md-2
                    d-flex
                    align-items-start
                    text-17 text-nowrap
                  "
                >
                  <strong :style="{ minWidth: '100px' }" class="text-secondary"
                    >건축물 감정가 또는 시세</strong
                  >
                  <span class="fw-700 text-gray text-truncate">{{item.goingOrAppraisedPrice}}원</span>
                </li>
              </div>
              <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-start
                  text-17 text-nowrap
                "
                v-if="item.work === 'BADCREDIT'"
              >
                <strong :style="{ minWidth: '100px' }" class="text-secondary"
                  >신용현황</strong
                >
                <div class="">
                  <div>
                    <span class="text-dark-gray">신용점수 : </span>
                    <span>{{ `${item.creditScore ? item.creditScore + "점" : "-"}` }}</span>
                  </div>
                  <div>
                    <span class="text-dark-gray">총 납기회차 : </span>
                    <span>{{ `${item.totalTimes ? item.totalTimes + "회" : "-"}` }}</span>
                  </div>
                  <div>
                    <span class="text-dark-gray">현재 회차 : </span>
                    <span>{{ `${item.currentTimes ? item.currentTimes + "회" : "-"}` }}</span>
                  </div>
                  <div>
                    <span class="text-dark-gray">미납 회차 : </span>
                    <span>{{ `${item.defaultTimes ? item.defaultTimes + "회" : "-"}` }}</span>
                  </div>
                </div>
              </li>
              <!-- <li
                class="
                  mb-1 mb-md-2
                  d-flex
                  align-items-start
                  text-17 text-nowrap
                "
                v-if="item.jointName"
              >
                <strong :style="{ minWidth: '100px' }" class="text-secondary"
                  >지분대출</strong
                >
                <span class="fw-700 text-gray text-truncate">
                  <span>
                    {{ `${
                        item.shareLoan
                          ? "공동지분"
                          : "본인지분 - " + item.selfRatio + "%"
                      } ` }}
                  </span>
                </span>
              </li> -->
            </ul>
          </b-col>
        </b-row>
        <!-- 0810 진행종류가 매매자금인 경우 필수 입력 추가  -->
        <!-- 20230622 매도자 명칭 to 소유자 -->
        <div v-if="item.productKind2 ==='KIND2_2' && item.tradingFunds">
          <h4 class="mb-2 mb-md-0 text-24 text-lg-28 fw-400 mr-auto">
            매매내용
          </h4>
          <b-row>
            <b-col>
              <strong :style="{ minWidth: '100px' }" class="text-secondary">
                소유자
              </strong>
              <span class="fw-700 text-gray text-truncate">
                <span> {{ `${item.tradingFunds.seller}` }} </span>
              </span>
              <strong :style="{ minWidth: '100px' }" class="text-secondary">
                매수자
              </strong>
              <span class="fw-700 text-gray text-truncate">
                <span>
                  {{ `${item.tradingFunds.buyer}` }}
                </span>
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong :style="{ minWidth: '100px' }" class="text-secondary">
                매매금액
              </strong>
              <span class="fw-700 text-gray text-truncate">
                <span>
                  {{ `${ toLocale(item.tradingFunds.saleAmount) }만원` }}
                </span>
              </span>
              <strong :style="{ minWidth: '100px' }" class="text-secondary">
                잔금일
              </strong>
              <span class="fw-700 text-gray text-truncate">
                <span>
                  {{ `${item.tradingFunds.balanceDate}` }}
                </span>
              </span>
            </b-col>
          </b-row>
          <b-row v-if="item.tradingFunds.withTenant">
            
            <span>세입자 끼고 진행</span>
            <b-col>
              <strong :style="{ minWidth: '100px' }" class="text-secondary">
                보증금
              </strong>
              <span class="fw-700 text-gray text-truncate">
                <span>
                  {{ `${ toLocale(item.tradingFunds.deposit) }만원` }}
                </span>
              </span>
              <strong :style="{ minWidth: '100px' }" class="text-secondary">
                월세
              </strong>
              <span class="fw-700 text-gray text-truncate">
                <span>
                  {{ `${ toLocale(item.tradingFunds.monthlyRent) }만원` }}
                </span>
              </span>
            </b-col>
          </b-row>
          <b-row v-else>
            <span>선순위 은행 동시진행</span>
            <b-col>
              <strong :style="{ minWidth: '100px' }" class="text-secondary">
                은행명
              </strong>
              <span class="fw-700 text-gray text-truncate">
                <span>
                  {{ `${item.tradingFunds.bankName}` }}
                </span>
              </span>
              <strong :style="{ minWidth: '100px' }" class="text-secondary">
                원금
              </strong>
              <span class="fw-700 text-gray text-truncate">
                <span>
                  {{ `${ toLocale(item.tradingFunds.principal) }만원` }}
                </span>
              </span>
              <strong :style="{ minWidth: '100px' }" class="text-secondary">
                채권최고액
              </strong>
              <span class="fw-700 text-gray text-truncate">
                <span>
                  {{ `${ toLocale(item.tradingFunds.bondMaximum) }만원` }}
                </span>
              </span>
            </b-col>
          </b-row>
        </div>
        <hr />
        <!-- 0816 진행종류가 무설정론인 경우  -->
        <!-- 20230704 주석처리  -->
        <!-- <div v-if="item.productKind2 === 'KIND2_4'">
          <h4 class="mb-2 mb-md-0 text-24 text-lg-28 fw-400 mr-auto">
            무설정론 내용
          </h4>
          <b-row>
            <span>
              * 정상등록 확인을 위해 표기 추후 삭제 예정
            </span>
            <b-col>
              <strong :style="{ minWidth: '100px' }" class="text-secondary">
                생년월일
              </strong>
              <span class="fw-700 text-gray text-truncate">
                <span>
                  {{ `${item.birthDate}` }}
                </span>
              </span>
              <strong :style="{ minWidth: '100px' }" class="text-secondary">
                전화번호
              </strong>
              <span class="fw-700 text-gray text-truncate">
                <span>
                  {{ `${item.phone}` }}
                </span>
              </span>
            </b-col>
          </b-row>
        </div> -->
        <h4>세입자 여부</h4>     
        <div v-if="tenants && tenants.length">
          <div
            class="fw-700 text-gray text-truncate"
            style="margin-right: 50px"
            v-for="tenant in tenants"
            :key="tenant.index"
          >
            <span class="fw-700 text-gray text-truncate">
              {{`${toLocale(tenant.deposit)}만원 / 
                ${toLocale(tenant.monthlyRent)}만원 / 
                ${tenant.tenantAgree ? "동의" : "미동의"}`
              }}
            </span>
          </div>
        </div>
        <div v-else-if="item.tradingFunds && item.tradingFunds.withTenant">
          <span
            class="fw-700 text-gray text-truncate"
          >있음</span>
        </div>
        <div v-else>
          <span
            class="fw-700 text-gray text-truncate"
          >본인거주</span>
        </div>
      </article>
      <article class="mb-5">
        <header
          class="mb-4 d-md-flex align-items-center jusitfy-content-between"
        >
          <h2 class="mb-2 mb-md-0 text-24 text-lg-28 fw-400 mr-auto">코멘트</h2>
          <!-- 의뢰자(글쓴이)인 경우 -->
          <template
            v-if="isMine(item.relativeUser) && item.progress !== 'COMPLETE'"
          >
            <b-btn
              variant="outline-primary"
              class="mr-0"
              v-b-modal.modal-add-comment
              :disabled="item.progress === 'CANCEL_PROGRESS'"
            >
              코멘트 등록
            </b-btn>
          </template>
          <!-- 입찰자인 경우 -->
          <template
            v-else-if="
              item.progress !== 'TRANSFERRED' &&
              item.progress !== 'COMPLETE' &&
              user &&
              item.bidders &&
              item.bidders.some(
                (bid) => bid.relativeUser && bid.relativeUser.serviceId === user.serviceId
              )
            "
          >
          </template>
          <!-- 비회원의 경우 -->
          <template v-else> </template>
        </header>
        <hr class="mt-2 mb-0" bold />

        <section class="mb-5">
          <template
            v-if="
              item &&
              biddingComments &&
              biddingComments.content &&
              biddingComments.content.length"
          >
            <div
              class="px-4 py-3 border-bottom"
              v-for="(comment, i) in biddingComments.content"
              :key="i"
            >
              <comment :item="comment" />
            </div>
            <hr class="mt-n1 mb-2" bold />
            <div class="mt-5 d-flex flex-column">
              <b-pagination
                @change="getComments($event)"
                :value="biddingComments.page.number + 1"
                :total-rows="biddingComments.page.totalElements"
                :per-page="biddingComments.page.size"
                class="mx-auto"
                first-class="d-none"
                last-class="d-none"
                hide-ellipsis
              >
                <template #prev-text>
                  <i class="icon icon-prev ml-1"></i>
                </template>
                <template #next-text>
                  <i class="icon icon-next ml-1"></i>
                </template>
              </b-pagination>
            </div>
          </template>
          <template v-if="!biddingComments || !biddingComments.content.length">
            <div class="p-4 bg-light">
              <span class="text-gray text-17 fw-400"
                >등록된 코멘트가 없습니다.</span
              >
            </div>
            <hr class="mt-n1 mb-2" bold />
          </template>
        </section>
      </article>
      <!--  -->
      <article class="mb-5">
        <header class="mb-2">
          <b-row>
            <b-col>
              <h2 class="text-24 text-lg-28 fw-400">
                입찰 내역
                <template v-if="user && item.bidders">
                  ({{ item.bidders.length ? item.bidders.length : "0" }})
                </template>
              </h2>
            </b-col>
            <!-- 의뢰자에게만 보이는 정렬 기준 셀렉트,버튼 -->
            <b-col class="text-right" v-if="isMine(item.relativeUser)">
              <select
                @change="onChange($event)"
                class="text-17 d-inline-block px-3 py-1 w-30"
                :style="{ borderRadius: '10px' }"
              >
                <option value="createdDate" selected="true">기본정렬</option>
                <!-- <option value="rank">순위</option> -->
                <option value="limitAmount">한도</option>
                <option value="interestRate">금리</option>
                <option value="benefit">수당</option>
                <option value="platformCommission">플랫폼수수료</option>
                <option value="prepaymentCommission">중도상환수수료</option>
              </select>
              <b-btn
                @click="onChange2('up')"
                :class="{ active: value2 === 'up' }"
                :disabled="value === null || value === 'createdDate'"
                class="onChangeBtn text-primary border-primary bg-white
                  fw-900 text-17 px-3 py-1 ml-2"
              >
                높은순
              </b-btn>
              <b-btn
                @click="onChange2('down')"
                :class="{ active: value2 === 'down' }"
                :disabled="value === null || value === 'createdDate'"
                class="
                  onChangeBtn
                  text-primary
                  border-primary
                  bg-white
                  fw-900
                  text-17
                  px-3
                  py-1
                  ml-2
                "
              >
                낮은순
              </b-btn>
            </b-col>
          </b-row>
        </header>
        <hr class="my-2" bold />
        <template v-if="!user">
          <div class="py-5 text-center bg-light">
            아직 비회원이시라면, 입찰내역을 확인하실 수 없습니다.
            <div class="mt-3 d-flex align-items-center justify-content-center">
              <b-btn variant="outline-primary mx-2" to="/account/login"
                >로그인</b-btn
              >
              <b-btn variant="primary mx-2" to="/account/sign-up"
                >회원가입</b-btn
              >
            </div>
          </div>
        </template>
        <template v-else-if="item.bidders && item.bidders.length">
          <section class="mb-5">
            <b-row>
              <b-col
                cols="6"
                md="12"
                class="pl-md-4 py-3 bidding-item"
                v-for="(bidding, i) in item.bidders"
                :key="i"
              >
                <!-- {{ bidding.createdDate }} -->
                <b-row
                  class="
                    text-18
                    fw-900
                    border-bottom border-dark
                    py-3
                    mb-3
                    border-top
                  "
                >
                  <!-- <b-col>
                    순위 <b class="text-danger">( {{ bidding.rank }} )</b>
                  </b-col> -->
                  <b-col>
                    한도
                    <b class="text-danger"
                      >( {{ bidding.limitAmount }} )
                    </b></b-col
                  >
                  <b-col>
                    금리
                    <b class="text-danger">( {{ bidding.interestRate }} )</b>
                  </b-col>
                  <b-col>
                    수당
                    <b class="text-danger">( {{ bidding.benefit }} )</b></b-col
                  >
                  <b-col>
                    플랫폼수수료
                    <b class="text-danger"
                      >( {{ bidding.platformCommission }} )</b
                    ></b-col
                  >
                  <b-col>
                    중도상환수수료
                    <b class="text-danger"
                      >( {{ bidding.prepaymentCommission }} )</b
                    ></b-col
                  >
                </b-row>
                <bidding :user="user" :item="bidding" :bidding="item" />
              </b-col>
            </b-row>
            <hr class="mt-n1 mb-2" bold />
            <!-- <div class="mt-5 d-flex flex-column">
            <b-pagination
              @change="getBidder($event)"
              :value="biddingPage.number + 1"
              :total-rows="biddingPage.totalElements"
              :per-page="biddingPage.size"
              class="mx-auto"
              first-class="d-none"
              last-class="d-none"
              hide-ellipsis
            >
              <template #prev-text>
                <i class="icon icon-prev ml-1"></i>
              </template>
              <template #next-text>
                <i class="icon icon-next ml-1"></i>
              </template>
            </b-pagination>
          </div> -->
          </section>
        </template>
        <template v-else-if="(user && !item.bidders) || !item.bidders.length">
          <div class="text-center py-3 bg-light">
            아직 입찰 내역이 없습니다.
          </div>
        </template>
      </article>

      <!--  사이드바 -->
      <b-sidebar
        id="more-info"
        right
        width="582px"
        body-class="py-5 px-3 pt-md-0 px-md-0 overflow-x-hidden scrollbar-hidden"
        no-header
        v-model="sidebarShown"
      >
        <article class="mb-2 p-2 py-md-4 bg-white">
          <b-row align-h="center">
            <b-col cols="10">
              <header class="mb-3 text-18">지도</header>

              <map-geocoder
                class="w-100 mb-3"
                v-if="sidebarShown"
                :address="item.address"
                :addressDetail="item.addressDetail ? item.addressDetail : ''"
              />
            </b-col>
            <b-col cols="12">
              <template v-if="item.documentGenerated">
                <b-tabs
                  nav-wrapper-class="px-md-5"
                  justified
                  class="tab-bidding-detail"
                  v-model="tabIdx"
                >
                  <b-tab
                    class="py-4"
                    title-link-class="text-12 text-lg-16"
                    v-for="(tab, i) in tabs"
                    :key="i"
                    :title="tab.title"
                  >
                    <component :is="tab.component" :itemMeta="item.itemMeta" />
                  </b-tab>
                  <b-tab
                    title-link-class="text-12 text-lg-16"
                    @click="showPdf()"
                    title="등기부등본"
                    class="py-4"
                  >
                    <p
                      class="
                        m-0
                        py-3
                        text-center text-gray-light text-14
                        fw-300
                      "
                      v-if="!pdfAttached"
                    >
                      등록된 등기부등본이 없습니다.
                    </p>
                  </b-tab>
                  <b-tab
                    title-link-class="text-12 text-lg-16"
                    @click="showImage()"
                    title="매매자금"
                    class="py-4"
                  >
                    <p
                      class="
                        m-0
                        py-3
                        text-center text-gray-light text-14
                        fw-300
                      "
                      v-if="!imageAttached"
                    >
                      등록된 매매자금 이미지가 없습니다.
                    </p>
                  </b-tab>
                </b-tabs>
              </template>
              <template v-else-if="!item.documentGenerated">
                <p class="m-0 py-3 text-center text-gray-light text-14 fw-300">
                  데이터 조회중입니다.
                </p>
              </template>
            </b-col>
          </b-row>
        </article>

        <!-- 토글버튼 -->
        <aside
          class="position-fixed d-none d-md-block"
          :style="{ top: '50%', left: '0', height: '200px', zIndex: '100' }"
        >
          <b-btn
            variant="primary"
            class="pl-2 pr-1 h-100"
            v-b-toggle.more-info
            :style="{ borderRadius: '0 4rem 4rem 0 ' }"
          >
            <i
              class="icon icon-24 mt-2 icon-sidebar-toggler"
              :style="{ transform: 'rotate(180deg)' }"
            ></i>
          </b-btn>
        </aside>
        <aside
          class="position-fixed d-block d-md-none"
          :style="{ top: '50%', left: '0', height: '48px', zIndex: '100' }"
        >
          <b-btn
            variant="primary"
            class="py-3 px-1 w-100"
            v-b-toggle.more-info
            :style="{ borderRadius: '0 2rem 2rem 0 ' }"
          >
            <i
              class="icon icon-sidebar-toggler"
              :style="{ transform: 'rotate(180deg)' }"
            ></i>
          </b-btn>
        </aside>
      </b-sidebar>
      <!-- modals -->
      <modal-cancel-progress />
      <template v-if="currentMatchedBidder">
        <modal-grade2
          :idBidding="item.id"
          :idBidder="currentMatchedBidder.id"
          :type="type"
          v-model="grade2"
        />
        <modal-rejected
          :idBidder="currentMatchedBidder.id"
          v-model="rejectReason"
        />
        <modal-grade
          :idBidding="item.id"
          :idBidder="currentMatchedBidder.id"
          :type="type"
          v-model="grade"
        />
      </template>
      <modal-result-amount
        v-model="resultAmount"
        :bidder="currentMatchedBidder"
      />
      <modal-add-comment v-model="comment.content" />
    </template>
    <modal-image v-if="imageUrl" :url="imageUrl" />
    <modal-pdf v-if="pdfUrl" :url="pdfUrl" />
    <modal-pdf2 v-if="pdfUrl2" :url="pdfUrl2" />
  </b-container>
</template>

<script>
import Items from "@/components/Items/index.js";
import FloatingButton from "@/components/FloatingButton.vue";
// import GmapCustomMarker from "vue2-gmap-custom-marker";
import Modals from "@/components/Modals/index";
import Product from "@/lib/product.js";

import Tabs from "@/components/tabs/index";

export default {
  components: {
    Comment: Items.Comment,
    Bidding: Items.Bidding,
    FloatingButton,
    // GmapCustomMarker,
    MapGeocoder: Items.MapGeocoder,

    //test
    ModalGrade2: Modals.Grade2,

    // Modals
    ModalCancelProgress: Modals.CancelProgress,
    ModalRejected: Modals.Rejected,
    ModalGrade: Modals.Grade,
    ModalResultAmount: Modals.ResultAmount,
    ModalAddComment: Modals.AddComment,
    ModalPdf: Modals.ShowPdf,
    ModalPdf2: Modals.ShowPdf,
    ModalImage: Modals.ShowImage,
    ModalBidderCancel: Modals.BidderCancel,
  },
  data() {
    return {

      grade2: null,
      
      value: null,
      value2: null,
      type: null,
      sidebarShown: false,
      // 지도 센터
      center: {
        lng: 127.04454,
        lat: 37.55101,
      },
      item: null,
      // 실제 송금금액
      resultAmount: null,
      // 코멘트 추가
      comment: {
        type: "CLIENT",
        contnet: null,
      },
      // 진행취소, 사유
      cancelProgress: {
        reseon: null,
      },
      // 내 입찰정보
      bidderStatus: {
        isMine: false,
        possibleBidding: true,
        possibleChange: true,
      },
      // 코멘트들
      biddingComments: null,
      biddingJointName: null,

      tenants: null,      //세입자 정보 list

      biddingPage: null, // 비딩매칭 페이지네이션
      rejectReason: null, // 부결사유
      grade: null, // 평점
      kind: Product.kind, // 이미지를 얻기위한 종류
      test123: null,
      // 사이드바 탭
      tabs: [
        {
          title: "건축물대장",
          component: Tabs.BiddingBuilding,
        },
        {
          title: "토지대장",
          component: Tabs.BiddingPlant,
        },
        // {
        //   title: "등기부등본",
        //   component: Tabs.BiddingPaper,
        // },
      ],
      tabIdx: 0,
      // pdf의 유무
      pdfAttached: false,
      imageAttached: false,
      regsiter: null,
      mortgage: null, //근저당권
    };
  },
  computed: {
    idBidding() {
      return this.$route.params.matching_id;
    },
    position() {
      const lng = this.item.longitude || 127.04454;
      const lat = this.item.latitude || 37.55101;
      return { lng, lat };
    },
    currentMatchedBidder() {
      if (this.item.bidders) {
        const current = this.item.bidders.find((r) => {
          return r.matched === true;
        });
        return current;
      } else {
        return null;
      }
    },
    // 등기부등본 URL
    imageUrl(){
      if (!this.item) return null;
      else if (!this.item.imageUrl && !this.item.imageFile) {
        return null;
      } else {
        const url = this.item.imageUrl || this.item.imageFile.filename;
        return url ? `${this.s3}${url}` : null;
      }
    },
    pdfUrl() {
      if (!this.item) return null;
      else if (!this.item.registerPdfUrl && !this.item.registerFile) {
        return null;
      } else {
        const url = this.item.registerPdfUrl || this.item.registerFile.filename;
        return url ? `${this.s3}${url}` : null;
      }
    },

    pdfUrl2() {
      if (!this.item) return null;
      else if (!this.item.landRegisterPdfUrl && !this.item.landRegisterFile) {
        return null;
      } else {
        const url = this.item.landRegisterPdfUrl || this.item.landRegisterFile.filename;
        return url ? `${this.s3}${url}` : null;
      }
    },

  },
  methods: {
    gradeTest(){
      this.$bvModal.show("modal-grade2");
    },
    getProductKind2(){
      let kind2 = this.item.productKind2;
      let obj = Product.kind2.find(e => e.value === kind2);
      return obj.text;
    },
    onChange(event) {
      this.value = event.target.value;
      this.value2 = null;
      console.log("onChange:", this.value);
      if (this.value == "createdDate") {
        this.item.bidders.sort(function (a, b) {
          return new Date(b.createdDate) - new Date(a.createdDate);
        });
      }
    },
    onChange2(val) {
      this.value2 = val;
      console.log("onChange2:", this.value2);

      if (this.value == "rank") {
        if (this.value2 == "up") {
          this.sortListUp(this.value);
        } else if (this.value2 == "down") {
          this.sortListDown(this.value);
        }
      } else {
        if (this.value2 == "up") {
          this.sortListDown(this.value);
        } else if (this.value2 == "down") {
          this.sortListUp(this.value);
        }
      }
    },
    sortListUp(val) {
      // 오름차순 1,2,3
      this.item.bidders.sort(function (a, b) {
        let value = val;
        // console.log(value, a[value], b[value]);
        return a[value] - b[value];
      });
    },
    sortListDown(val) {
      // 내림차순 3,2,1
      this.item.bidders.sort(function (a, b) {
        let value = val;
        // console.log(value, a[value], b[value]);
        return b[value] - a[value];
      });
    },
    async getItem() {
      try {
        const { data } = await this.$axios.get(`/bidding/${this.idBidding}`);

        console.log(data);

        if (data.status.code === "0000") {
          this.item = {
            itemMeta: {
              buildingMeta: data.data.buildingMeta,
              landMeta: data.data.landMeta,
            },
            ...data.data,
          };

          //console.log(this.item.registerFile.pdfText);
        }
        /**
         * else if(data.status.code === "1005"){
          window.alert(data.status.message);
          this.$router.go(-1);
        }
         *
         */
      } catch (error) {
        console.log(error);
      }
    },

    //공동 소유자
    async getJointName() {
      try {
        const { data } = await this.$axios.get(
          `/bidding/${this.idBidding}/jointName`
        );
        if (data.status.code === "0000") {
          this.biddingJointName = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //가압류 내용
    async getRegsiter() {
      try {
        const { data } = await this.$axios.get(
          `/bidding/${this.idBidding}/regsiter`
        );
        if (data.status.code === "0000") {
          this.regsiter = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //근저당권 상세내용
    async getmortgage() {
      try {
        const { data } = await this.$axios.get( `/bidding/${this.idBidding}/mortgage` );
        if (data.status.code === "0000") {
          this.mortgage = data.data.sort(function(a,b){
            return a.ranking < b.ranking ? -1 : a.ranking > b.ranking ? 1 : 0;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    //입찰자 내역
    async getBidder() {
      //n = 1
      try {
        const { data } = await this.$axios.get(
          `/bidding/${this.idBidding}/bidder`
        );
        if (data.status.code === "0000") {
          this.item.bidders = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //입찰자 상세
    async getBidderDetail() {
      if (this.user) {
        try {
          const { data } = await this.$axios.get(
            `/bidding/${this.idBidding}/bidder-detail`
          );
          if (data && data.data.length) {
            // 입찰내역에 현재 유저가 있음
            this.bidderStatus.isMine = true;
            this.bidderStatus.possibleBidding = Math.abs(data.data.length - 3);
          } else if (data.status.code === "4041") {
            // 비회원상태
            // console.log("내 입찰정보:", data);
          } else if (data.status.code === "4042") {
            // 입찰내역에 현재 유저가 없음
            // console.log("내 입찰정보:", data);
            this.bidderStatus.isMine = false;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },

    
    //세입자 정보 
    async getTenants(){
      try {
          const { data } = await this.$axios.get(`/tenant/bidding/${this.idBidding}` );
          if (data.status.code == '0000') {
            this.tenants = data.data;
          }
        } catch (error) {
          console.log(error);
        }
    },
    getVariant(progress) {
      const variant = {
        beforeBidding: "darkest",
        ING: "darkest",
        MATCHED: "error",
        APPROVAL: "success",
        AUTOGRAPH: "third",
        TRANSFERRED: "secondary",
        COMPLETE: "primary",
        // 부결, 입찰마감 색은?
        REJECTED: "primary",
        END_BIDDING: "light-gray",
        CANCEL_PROGRESS: "outline-primary",
        ERROR: "error",
      };
      return variant[progress] || variant.error;
    },
    getText(progress) {
      const text = {
        beforeBidding: "매칭 전",
        ING: "진행중",
        MATCHED: "매칭 완료",
        APPROVAL: "승인 완료",
        AUTOGRAPH: "자서 완료",
        TRANSFERRED: "송금 완료",
        COMPLETE: "대출 완료",
        REJECTED: "부결",
        END_BIDDING: "입찰 마감",
        CANCEL_PROGRESS: "진행 취소",
        ERROR: "에러",
      };
      return text[progress] || text.error;
    },
    // 비딩매칭 상태값 변경
    async changeProgress(progress) {
      try {
        const { data } = await this.$axios.put(
          `/bidding/${this.idBidding}/update-progress`,
          {
            progress: progress,
          }
        );
        if (data.status.code === "0000") {
          window.alert(
            `${this.getText(progress)}로 정상적으로 처리되었습니다.`
          );
          this.item.progress = progress;
        } else {
          window.alert(data.status.message);
        }
      } catch (error) {
        window.alert(error.message || error.status.message);
        console.log(error);
      }
    },
    getImage(kind) {
      return this.kind.find((r) => r.value === kind).image;
    },


    getWork(work) {
      const text = {
        EMPLOYEE: "직장인",
        HOUSEWIFE_N: "주부(무직)",
        HOUSEWIFE_Y: "주부(생활비 내역 있음)",
        BUSINESSMAN_Y: "실사업자",
        BUSINESSMAN_N: "가라사업자(매출증빙 X)",
        FREELANCER: "프리랜서(소득증빙가능)",
        BADCREDIT: "신용결여(회복/회생/파산/워크아웃 등)",
        FOREIGNER_RESIDENT_Y: "외국인 거소증 유",
        FOREIGNER_RESIDENT_N: "외국인 거소증 무",

        ERROR: "에러",
      };
      return text[work] || text.error;
    },

    getJoint(jointNameRelation) {
      const text = {
        MARRIED: "부부",
        PARENT: "부모",
        BROTHRES: "형제",
        OTHER_PERSON: "제3자",
        ERROR: "에러",
      };
      return text[jointNameRelation] || text.error;
    },

    //********************************************************************** */
    async changeAcceptCondition(bidders) {
      if (this.user) {
        const myBidder = bidders.filter(
          (bid) => bid.relativeUser.serviceId === this.user.serviceId
        );
        if (myBidder) {
          try {
            const { data } = await this.$axios.put(
              "/bidding/" +
                this.idBidding +
                "/bidder/" +
                bidders.id +
                "/update-condition?acceptCondition=true"
            );
            if (data.status.code === "0000") {
              const arr = myBidder;
              arr.forEach((el) => {
                el.acceptCondition = true;
              });
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          window.alert("아직 입찰을 진행하지 않았습니다.");
        }
      }
    },
    async getComments(n = 1) {
      try {
        const { data } = await this.$axios.get(
          `/bidding/${this.idBidding}/comments`,
          {
            params: { page: n - 1 },
          }
        );
        if (data.status.code === "0000") {
          this.biddingComments = data.data;
        }
      } catch (error) {
        window.alert(error);
      }
    },
    // 대출 완료
    async complete() {
      if (!this.item.resultAmount) {
        const bool = await window.confirm(
          "실제 송금금액을 입력해 송금완료를 진행해야 대출 완료를 진행할 수 있습니다.",
          {
            okTitle: "송금완료 진행",
          }
        );
        if (bool) {
          this.$bvModal.show("modal-result-amount");
        }
      } else {
        //if (this.item.resultAmount)
        const bool = await window.confirm(
          `대출완료를 진행하면 더 이상 상태를 변경하실 수 없습니다. <br>
          변경하시겠습니까?`,
          {
            bodyClass: "text-center",
            okTitle: "대출완료 진행",
          }
        );
        if (bool) {
          this.changeProgress("COMPLETE");
          this.$bvModal.show("modal-grade");
          window.location.reload();
        }
      }
    },
    async inputGrade() {
      console.log(this.item);
      console.log(this.currentMatchedBidder);
      if (
        this.currentMatchedBidder && 
        (this.item.grade && this.currentMatchedBidder.grade) ||
        (this.currentMatchedBidder.grade && !this.currentMatchedBidder.grade) ||
        (!this.currentMatchedBidder.grade && this.currentMatchedBidder.grade)
      ) {
        await window.alert("이미 평점 작성을 완료하였습니다.");
      } else if (this.item.progress === "COMPLETE") {
        this.$bvModal.show("modal-grade2");
      }
    },
    showImage(){
       if (this.imageUrl) {
          this.imageAttached = true;
          this.$bvModal.show("modal-image");
        } else {
          this.imageAttached = false;
        }
    },
    showPdf(type) {

        if (this.pdfUrl || this.pdfUrl2) {
          this.pdfAttached = true;
          this.$bvModal.show("modal-pdf");
        } else {
          this.pdfAttached = false;
        }

        // if (this.pdfUrl2) {
        //   this.pdfAttached = true;
        //   this.$bvModal.show("modal-pdf2");
        // } else {
        //   this.pdfAttached = false;
        // }

    },
    hiddenPdf() {
      this.tabIdx = 0;
    },
  },
  async mounted() {

    // 3/31일 회의 결과를 토대로 관리자가 승인하지 않은 계정인 경우 비딩상세보기 X
    //입찰 권한이 없는 경우엔 본인이 올린 물건을 확인하기 위해 비딩 상세보기 데이터를 먼저 받아온 후 비교 해야함
    await this.getItem();
    
    /**
     * 상담사 or 대출 중개인 or 금융사
       본인이 작성한 의뢰건이 아니면서, 승인되지 않은 경우 비딩 리스트로 페이지 이동
      */
    if (this.$store.state.user.status !== "OK") {
      if (
        // 상담사, 중개사 -> 대출 중개사 - 입찰 & 의뢰
        // 금융사 - 입찰만 가능
        this.$store.state.user.role === "COUNSELOR" ||
        this.$store.state.user.role === "LOANBROKER" ||
        this.$store.state.user.role === "FINANCE"
      ) {
        alert(
          "아직 승인되지 않은 계정입니다. \n  승인 후 이용 가능한 서비스입니다."
        );
        this.$router.replace("/matching");
        return;
      }
    }

    // 3/31일 회의 결과를 토대로 관리자가 승인하지 않은 계정인 경우 비딩상세보기 X
    //입찰 권한이 없는 경우엔 본인이 올린 물건을 확인하기 위해 비딩 상세보기 데이터를 먼저 받아온 후 비교 해야함
    await this.getItem();

    // 본인이 작성한 의뢰건이 아니면서 승인되지 않은 경우 or 권한이 안 되는 경우
    if (this.$store.state.user.id !== this.item.relativeUser.id) {
      /**
       * 개인 -> 공인중개사로 변경 (의뢰만 가능)
       * 본인이 작성한 의뢰건이 아니면서 공인중개사인 경우 비딩리스트 페이지로 이동
       */
      if (this.$store.state.user.role === "REALTOR") {
        window.alert("매칭 의뢰만 가능한 계정입니다. ");
        this.$router.replace("/matching");
        return;
      }
    }

    await this.getTenants();
    await this.getRegsiter();
    await this.getmortgage();
    await this.getJointName();
    await this.getComments();
    await this.getBidder();
    await this.getBidderDetail();

    // 등기부등본 닫기
    this.$root.$on("hb::pdf::hidden", (bool) => {
      if (bool) {
        this.hiddenPdf();
      }
    });
    this.$root.$on("hb::comment:added", () => {
      this.getComments();
    });

    if (this.user.serviceId == this.item.relativeUser.serviceId) {
      this.type = "bidder";
    } else if (
      this.user.serviceId == this.currentMatchedBidder &&
      this.currentMatchedBidder.relativeUser.serviceId
    ) {
      this.type = "client";
    }
  },
};
</script>

<style lang="scss" scoped>
.onChangeBtn {
  &:hover,
  &:active,
  &:focus,
  &.active {
    background: #5465b1 !important;
    color: #fff !important;
  }
  &[disabled] {
    background-color: #dcdcdc !important;
    border-color: #dcdcdc !important;
  }
}
</style>
