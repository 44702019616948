<template>
  <b-checkbox
    v-model="value_"
    @input.native.prevent="$emit('input', $event.target.checked)"
  >
    <slot />
  </b-checkbox>
</template>

<script>
export default {
  props: {
    value: { default: null },
    type: {
      type: String,
      default: () => "text",
    },
    inputClass: { default: () => undefined, type: String },
    placeholder: { default: () => undefined, type: String },
    readonly: { default: () => undefined, type: String },
    successMessage: { default: () => null },
    require: { type: Boolean },
  },
  data() {
    return {
      valid_: null,
      value_: null,
    };
  },
  watch: {
    value(n) {
      this.value_ = n;
    },
    value_(n) {
      this.value_ = n;
      this.inputListener({ target: { checked: n } });
      this.$emit("input", n);
    },
  },
  computed: {
    valid() {
      return this.valid_;
    },
  },
  mounted() {
    if (this.require)
      this.valid_ = {
        state: false,
        title: "필수 약관 동의여부",
        message: "필수 약관 동의 여부 확인",
      };
  },
  methods: {
    inputListener(e) {
      this.valid_ = this.require
        ? {
            state: e.target.checked,
            title: "필수 약관 동의여부",
            message: "필수 약관 동의 여부 확인",
          }
        : undefined;
    },
  },
};
</script>

<style></style>
