<template>
  <b-container>
    <b-form-group class="radio-group-sqaure">
      <b-form-radio-group
        :options="types"
        v-model="input.role"
        buttons
        button-variant="light-gray rounded-0"
      />
    </b-form-group>
    <form @submit.prevent.stop="signup" class="mb-5 pb-5">
      <div :style="{ paddingBottom: '60px' }">
        <article class="mt-3">
          <legend-custom text="프로필 사진 선택" />
          <input-image
            label-text="프로필 사진을 등록해주세요"
            :accept="'image/jpg, image/jpeg, image/png'"
            v-model="input.profile"
          />
        </article>
        <template v-if="input.role !== 'USER'">
          <article>
            <input-image
              v-model="input.businessCard"
              :width="180"
              label-text="명함 사진을 등록해주세요"
            />
          </article>
        </template>
      </div>

      <article class="mt-3 mb-5">
        <legend-custom text="계정 정보 입력" class="mb-3" />
        <template v-if="input.loginType === 'EMAIL'">
          <b-input-group class="mb-4">
            <template #prepend>
              <b-input-group-text
                :class="
                  input.serviceId === null || valid.serviceId.state
                    ? ''
                    : 'is-invalid'
                "
                >이메일
              </b-input-group-text>
            </template>
            <b-form-input
              type="email"
              v-model="input.serviceId"
              placeholder="이메일"
              :state="valid.serviceId.state"
              @input="checkId"
              autocomplete="off"
            ></b-form-input>
            <b-form-invalid-feedback :state="valid.serviceId.state">
              {{ valid.serviceId.msg }}
            </b-form-invalid-feedback>
            <b-form-valid-feedback class="text-right"
              >사용 가능한 아이디 입니다.</b-form-valid-feedback
            >
          </b-input-group>
          <b-input-group class="mb-4">
            <template #prepend>
              <b-input-group-text
                :class="
                  status.passwordConfirm || status.password ? 'is-invalid' : ''
                "
                >비밀번호</b-input-group-text
              >
            </template>
            <b-form-input
              type="password"
              v-model="input.password"
              @input="checkValue('password', $event)"
              :state="!status.password"
              placeholder="비밀번호"
              autocomplete="off"
            ></b-form-input>
          </b-input-group>
          <b-input-group class="mb-4">
            <template #prepend>
              <b-input-group-text
                :class="status.passwordConfirm ? 'is-invalid' : ''"
                >비밀번호 확인</b-input-group-text
              >
            </template>
            <b-form-input
              type="password"
              v-model="input.passwordConfirm"
              @input="checkValue('passwordConfirm', $event)"
              :state="!status.passwordConfirm"
              placeholder="비밀번호 확인"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="
                status.password ? !status.password : !status.passwordConfirm
              "
            >
              {{
                status.password ? status.password : status.passwordConfirm
              }}</b-form-invalid-feedback
            >
          </b-input-group>
        </template>
        <!-- {{ status.password }} -->
        <!-- {{ status.passwordConfirm }} -->
        <b-input-group class="mb-4">
          <template #prepend>
            <b-input-group-text
              :class="
                input.mobile === null || valid.mobile.state ? '' : 'is-invalid'
              "
              >핸드폰 번호</b-input-group-text
            >
          </template>
          <b-form-input
            type="number"
            v-model="input.mobile"
            :state="valid.mobile.state"
            placeholder="핸드폰 번호"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="valid.mobile.state"
            :style="{ right: '80px' }"
            >{{ valid.mobile.msg }}</b-form-invalid-feedback
          >
          <template #append>
            <div class="ml-3">
              <b-btn variant="outline-primary" @click="validMobile">인증</b-btn>
            </div>
          </template>
        </b-input-group>
        <b-input-group class="mb-4" v-if="valid.mobile.state">
          <template #prepend>
            <b-input-group-text>인증 번호</b-input-group-text>
          </template>
          <b-form-input
            type="number"
            v-model="input.certNumber"
            :style="{ paddingRight: '3.5rem' }"
            placeholder="인증 번호"
          >
          </b-form-input>
          <template #append>
            <div class="ml-3 position-relative">
              <small
                class="position-absolute text-error text-14 text-lg-15"
                :style="{
                  top: '50%',
                  left: '-4rem',
                  transform: 'translateY(-50%)',
                  zIndex: '1000',
                }"
              >
                3:00
              </small>
              <b-btn variant="primary" @click="certNumberConfirm">확인</b-btn>
            </div>
          </template>
        </b-input-group>
        <template
          v-if="
            input.role === 'LOANBROKER' ||
              input.role === 'COUNSELOR' ||
              input.role === 'FINANCE'
          "
        >
          <b-input-group class="mb-4">
            <template #prepend>
              <b-input-group-text
                :class="
                  input.bankAccountMeta.bank === null ||
                  valid.bankAccountMeta.bank
                    ? ''
                    : 'is-invalid'
                "
                >은행명</b-input-group-text
              >
            </template>
            <b-form-input
              type="text"
              v-model="input.bankAccountMeta.bank"
              :state="valid.bankAccountMeta.bank"
              placeholder="은행명"
            ></b-form-input>
            <b-form-invalid-feedback :state="valid.bankAccountMeta.bank">
              은행명을 입력해주세요
            </b-form-invalid-feedback>
          </b-input-group>
          <b-input-group class="mb-4">
            <template #prepend>
              <b-input-group-text
                :class="
                  input.bankAccountMeta.accountHolder === null ||
                  valid.bankAccountMeta.accountHolder
                    ? ''
                    : 'is-invalid'
                "
                >예금주</b-input-group-text
              >
            </template>
            <b-form-input
              type="text"
              v-model="input.bankAccountMeta.accountHolder"
              placeholder="예금주"
              :state="valid.bankAccountMeta.accountHolder"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="
                input.bankAccountMeta.accountHolder === null ||
                  valid.bankAccountMeta.accountHolder
              "
            >
              예금주를 입력해주세요
            </b-form-invalid-feedback>
          </b-input-group>
          <b-input-group class="mb-4">
            <template #prepend>
              <b-input-group-text
                :class="
                  input.bankAccountMeta.accountNumber === null ||
                  valid.bankAccountMeta.accountNumber
                    ? ''
                    : 'is-invalid'
                "
                >계좌번호</b-input-group-text
              >
            </template>
            <b-form-input
              type="text"
              v-model="input.bankAccountMeta.accountNumber"
              :state="valid.bankAccountMeta.accountNumber"
              placeholder="계좌번호"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="
                input.bankAccountMeta.accountNumber === null ||
                  valid.bankAccountMeta.accountNumber
              "
            >
              계좌번호를 입력해주세요
            </b-form-invalid-feedback>
          </b-input-group>
        </template>
        <template v-if="input.role === 'LOANBROKER' || input.role === 'FINANCE' || input.role === 'REALTOR'">
          <legend-custom text="업체 정보 입력" />
          <article class="mt-3 mb-5">
            <b-input-group class="mb-4">
              <template #prepend>
                <b-input-group-text
                  :class="
                    input.companyMeta.companyName === null ||
                    valid.companyMeta.companyName
                      ? ''
                      : 'is-invalid'
                  "
                  >업체명</b-input-group-text
                >
              </template>
              <b-form-input
                type="text"
                v-model="input.companyMeta.companyName"
                :state="valid.companyMeta.companyName"
                placeholder="업체명"
              ></b-form-input>
              <b-form-invalid-feedback :state="valid.companyMeta.companyName"
                >업체명을 입력해주세요.</b-form-invalid-feedback
              >
            </b-input-group>
            <input-file
              labelText="대부 등록증 업로드(PDF)"
              accept="file/pdf"
              v-model="input.companyMeta.loanLicenseFileMeta"
              :state="valid.companyMeta.loanLicenseFileMeta"
              :isInvalid="status.companyMeta.loanLicenseFileMeta"
              :registerFile="input.loanLicenseFileMeta"
              feedbackMsg="대부 등록증을 첨부해주세요."
            />
            <input-file
              labelText="사업자 등록증 업로드(PDF)"
              accept="file/pdf"
              v-model="input.companyMeta.businessLicenseFileMeta"
              :state="valid.companyMeta.businessLicenseFileMeta"
              :isInvalid="status.companyMeta.businessLicenseFileMeta"
              feedbackMsg="사업자 등록증을 첨부해주세요."
            />
          </article>
        </template>
        <template v-if="input.role === 'COUNSELOR'">
          <legend-custom text="상담사 정보 입력" />
          <article class="mt-3 mb-5">
            <b-input-group class="mb-4">
              <template #prepend>
                <b-input-group-text
                  :class="valid.counselCode ? '' : 'is-invalid'"
                  >은행 상담원 코드</b-input-group-text
                >
              </template>
              <b-form-input
                type="text"
                v-model="input.counselCode"
                placeholder="은행 상담원 코드"
                prepend-size="lg"
              ></b-form-input>
              <b-form-invalid-feedback :state="valid.serviceId"
                >이메일 형식이 올바르지 않습니다.</b-form-invalid-feedback
              >
            </b-input-group>
          </article>
        </template>
      </article>

      <article class="mt-3 mb-5">
        <legend-custom text="약관 동의" />
        <b-form-checkbox
          :checked="allAgree"
          class="text-14 text-lg-15 mb-3"
          @click.native.prevent="checkAll"
        >
          <span class="text-underline text-darkest"
            >전체 내용에 동의합니다</span
          >
        </b-form-checkbox>
        <b-form-group v-for="(item, i) in terms" :key="i" class="mb-3">
          <b-form-checkbox v-model="input[item.inputValue]" class="w-100">
            <div
              class="d-flex align-items-center justify-content-between w-100"
            >
              <span class="text-14 text-lg-15 fw-400 text-darkest">
                {{ item.text }}
              </span>
              <b-btn
                variant="link text-light-gray"
                class="p-0 text-14"
                :to="item.url"
                >자세히 보기</b-btn
              >
            </div>
          </b-form-checkbox>
        </b-form-group>
      </article>
      <section class="my-5">
        <b-btn variant="primary" class="py-4 w-100" @click="signup"
          >회원가입</b-btn
        >
      </section>
    </form>
  </b-container>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

export default {
  components: {
    LegendCustom: Form.LegendCustom,
    InputImage: Form.InputImage,
    InputFile: Form.InputFile,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";
    return {
      timeChecker: null,
      types: [
        {
          text: "공인 중개사",
          value: "REALTOR",
        },
        {
          text: "대출 중개인",
          value: "LOANBROKER",
        },
        {
          text: "상담사",
          value: "COUNSELOR",
        },
        {
          text: "금융사",
          value: "FINANCE",
        },
      ],
      terms: [
        {
          text: "(필수) 개인정보처리방침 동의",
          required: true,
          inputValue: "terms1",
          url: "/",
        },
        {
          text: "(필수) 서비스 이용약관 동의",
          required: true,
          inputValue: "terms2",
          url: "/",
        },
        {
          text: "(선택) 마케팅 동의",
          required: false,
          inputValue: "terms3",
          url: "/",
        },
      ],
      serverVaild: {
        serviceId: false,
        mobile: false,
        certNumber: false,
        certNumberConfirm: false,
      },
      input: {
        role: "USER",
        loginType: "EMAIL",
        profile: {},
        businessCard: {},
        serviceId: null,
        password: null,
        passwordConfirm: null,
        mobile: null,
        certNumber: null,
        terms1: null,
        terms2: null,
        terms3: null,
        // 개인이 아닐 경우 은행정보 입력
        bankAccountMeta: {
          bank: null,
          accountHolder: null,
          accountNumber: null,
        },
        // 중개사,금융사 업체정보 입력
        companyMeta: {
          companyName: null,
          businessLicenseFileMeta: null,
          loanLicenseFileMeta: null,
        },
        // 상담사, 상담사정보입력
        counselCode: null,
      },
      status: {
        serviceId: null,
        password: null,
        passwordConfirm: null,
        // mobile: null,
        // certNumber: null,
        // terms1: false,
        // terms2: false,
        // terms3: false,
        bankAccountMeta: {
          bank: null,
          accountHolder: null,
          number: null,
        },
        companyMeta: {
          companyName: null,
          // certificate: null,
          // license: null,
          businessLicenseFileMeta: null,
          loanLicenseFileMeta: null,
        },
        counselCode: null,
      },
    };
  },
  methods: {
    checkAll() {
      this.input = {
        ...this.input,
        terms1: !this.allAgree,
        terms2: !this.allAgree,
        terms3: !this.allAgree,
      };
    },
    // 유저생성 - 회원가입
    async signup() {
      // 약관동의표시
      let input = {
        ...this.input,
        termsAgree: {
          taPrivacy: this.input.terms1,
          taService: this.input.terms2,
          taMarketing: this.input.terms3,
        },
      };

      const {
        serviceId,
        password,
        passwordConfirm,
        mobile,
        certNumber,
        terms1: taPrivacy,
        terms2: taService,
        terms3: taMarketing,
      } = input;

      const {
        serviceId: serverServiceId,
        mobile: serverMobile,
        certNumber: serverCertNumber,
        certNumberConfirm: serverCertNumberConfirm,
      } = this.serverVaild;

      // serviceId
      if (!this.serviceId) {
        return alert("");
      }

      // 필수값 미입력
      if (this.status.passwordConfirm === "비밀번호가 다릅니다.") {
        await window.alert("비밀번호 확인이 일치하지 않습니다", {
          title: "비밀번호 확인",
        });
      } else if (
        !serviceId ||
        !mobile ||
        !certNumber ||
        !taPrivacy ||
        !taService
      ) {
        if (serverServiceId === "이미 사용중인 아이디입니다.") {
          // 이메일 중복
          await window.alert("이미 존재하는 이메일입니다.", {
            title: "이메일 중복",
          });
        } else if (serverMobile === "이미 사용중인 핸드폰 번호입니다.") {
          // 핸드폰 번호 중복
          await window.alert("이미 존재하는 핸드폰번호입니다.", {
            title: "핸드폰 번호 중복",
          });
        } else if (serverMobile === false) {
          // 핸드폰 번호 인증 미완료
          await window.alert("핸드폰 번호를 인증해주세요.", {
            title: "핸드폰 번호 인증",
          });
        } else if (serverCertNumberConfirm === false) {
          // 핸드폰 번호 인증 미완료
          await window.alert("인증번호를 제대로 입력하여 확인해주세요.", {
            title: "핸드폰 번호 인증",
          });
        } else if (input.role === "LOANBROKER" || input.role === "FINANCE" || input.role === "REALTOR") {
          if (!input.companyMeta.loanLicenseFileMeta) {
            this.status.companyMeta.loanLicenseFileMeta = true;
            await window.alert("대부등록증을 첨부해주세요.", {
              title: "대부등록증 미첨부",
            });
          } else if (!input.companyMeta.businessLicenseFileMeta) {
            this.status.companyMeta.businessLicenseFileMeta = true;
            await window.alert("사업자등록증을 첨부해주세요.", {
              title: "사업자등록증 미첨부",
            });
          }
        } else {
          // 필수값 미입력
          await window.alert("필수값들을 모두 입력해주세요.", {
            title: "필수 값 미입력",
          });
        }
      } else if (serviceId && mobile && certNumber && taPrivacy && taService) {
        // 일반사용자일 경우 회사, 계좌 등 null 처리
        if (input.role === "USER" || input.role === "COUNSELOR") {
          input = {
            ...input,
            bankAccountMeta: null,
            companyMeta: null,
            termsAgree: {
              taMarketing: true,
              taPrivacy: true,
              taService: true,
            },
          };
        }
        input.profile =
          typeof input.profile === "object" ? input.profile.url : null;
        input.businessCard =
          typeof input.businessCard === "object"
            ? input.businessCard.url
            : null;
        try {
          const { data } = await this.$axios.post("/users", input);
          if (data.status.code === "0000") {
            this.$router.push("sign-up-complete");
          } else {
            // console.log(data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    // 유효성검사 - id
    async checkId(e) {
      const cb = async () => {
        try {
          const { data } = await this.$axios.post("/users/valid-id", {
            serviceId: this.input.serviceId,
          });
          if (data.status.code === "0000") {
            this.serverVaild.serviceId = true;
          } else if (data.status.code === "1002") {
            this.serverVaild.serviceId = "이미 사용중인 아이디입니다.";
          } else {
            this.serverVaild.serviceId = false;
          }
        } catch (error) {
          console.error(error);
        }
      };

      this.serverVaild.serviceId = false;
      if (this.timeChecker) clearTimeout(this.timeChecker);
      if (this.valid.serviceId.msg === "이메일을 확인 중입니다.") {
        this.timeChecker = setTimeout(cb, 500);
      }
    },
    // 유효성검사 - mobile
    async validMobile(e) {
      const cb = async () => {
        try {
          const { data } = await this.$axios.post("/users/valid-mobile", {
            mobile: this.input.mobile,
          });
          if (data.status.code === "0000") {
            this.serverVaild.mobile = true;
            this.sendCertNumber();
            // console.log(data.status.message);
          } else if (data.status.code === "1002") {
            this.serverVaild.mobile = "이미 사용중인 핸드폰 번호입니다.";
          }
        } catch (error) {
          console.error(error);
        }
      };

      this.serverVaild.mobile = false;
      if (this.timeChecker) clearTimeout(this.timeChecker);
      if (this.valid.mobile.msg === "핸드폰 번호를 인증해주세요.") {
        this.timeChecker = setTimeout(cb, 500);
      }
    },
    // 인증번호 요청 - 문자
    async sendCertNumber() {
      // if (this.serverVaild.serviceId && this.input.mobile) {
      try {
        const { data } = await this.$axios.post("/cert/mobile", {
          email: this.input.serviceId,
          mobile: this.input.mobile,
          mode: "CERT_MOBILE",
        });
        if (data.status.code === "0000") {
          // console.log(data.data);
          this.serverVaild.certNumber = true;
        }
      } catch (error) {
        console.error(error);
      }
      // }
    },
    // 인증번호 확인 - 문자
    async certNumberConfirm() {
      // if (this.serverVaild.mobile && this.serverVaild.certNumber) {
      try {
        const { data } = await this.$axios.post("/cert/mobile/confirm", {
          code: this.input.certNumber,
          mobile: this.input.mobile,
          mode: "CERT_MOBILE",
        });
        if (data.status.code === "0000") {
          this.serverVaild.certNumberConfirm = true;
          window.alert("인증이 완료되었습니다. 회원가입을 계속 진행해주세요.", {
            title: "핸드폰 번호 인증 완료",
          });
        }
      } catch (error) {
        console.error(error);
      }
      // }
    },
    checkValue(type, val) {
      let value;

      switch (type) {
        case "password":
          value = regex.password.test(val)
            ? false
            : "8-16자 영문 대/소문자, 숫자, 특수문자를 사용하세요.";
          break;
        case "passwordConfirm":
          value =
            this.input.password !== this.input.passwordConfirm
              ? "비밀번호 확인 후 다시 입력해주세요."
              : false;
          break;
      }

      this.status[type] = value;
    },
  },
  mounted() {
    if (this.params.serviceId) {
      this.input.serviceId = this.params.serviceId;
      this.input.password = this.params.serviceId;
      this.input.loginType = this.params.loginType;
    }
  },
  computed: {
    params() {
      return this.$route.params;
    },
    allAgree() {
      const { terms1, terms2, terms3 } = this.input;
      return ![terms1, terms2, terms3].some((val) => !val);
    },
    valid() {
      const rules = {
        serviceId: [
          (v) => !!v || "이메일을 입력하세요",
          (v) => regex.serviceId.test(v) || "이메일 형식이 올바르지 않습니다.",
          (v) => this.serverVaild.serviceId || "이메일을 확인 중입니다.",
        ],
        mobile: [
          (v) => !!v || "핸드폰 번호를 입력하세요",
          (v) => v.length >= 10 || "핸드폰 번호 형식이 올바르지 않습니다.",
          (v) => this.serverVaild.mobile || "핸드폰 번호를 인증해주세요.",
        ],
      };
      const ruleChecker = (rules, value) => {
        return rules.reduce(
          (result, rule) => {
            if (value === null) return { state: null, msg: null };
            if (result.state === true) {
              const r = rule(value);

              const state = r === true;
              const msg = r === true ? null : r;

              return { state, msg };
            } else if (result.state === false) {
              return result;
            } else {
              return { state: true, msg: null };
            }
          },
          { state: true, msg: null }
        );
      };

      const serviceId = ruleChecker(rules.serviceId, this.input.serviceId);
      const mobile = ruleChecker(rules.mobile, this.input.mobile);

      return {
        serviceId,
        mobile,
        companyMeta: {
          companyName:
            this.input.companyMeta === null
              ? null
              : this.input.companyMeta.companyName !== "" &&
                this.input.companyMeta.companyName &&
                this.input.companyMeta.companyName.length >= 0,
          // certificate: this.input.companyMeta === null ? false : true,
          // license: this.input.companyMeta === null ? false : true,
          terms: !this.input.terms1 || !this.input.terms2 ? false : true,
          loanLicenseFileMeta:
            this.input.companyMeta.loanLicenseFileMeta === null ? false : true,
          businessLicenseFileMeta:
            this.input.companyMeta.businessLicenseFileMeta === null
              ? false
              : true,
        },

        bankAccountMeta: {
          bank:
            this.input.bankAccountMeta.bank === null
              ? null
              : this.input.bankAccountMeta.bank !== "" &&
                this.input.bankAccountMeta.bank &&
                this.input.bankAccountMeta.bank.length >= 0,
          accountHolder:
            this.input.bankAccountMeta.accountHolder === null
              ? null
              : this.input.bankAccountMeta.accountHolder !== "",
          accountNumber:
            this.input.bankAccountMeta.accountNumber === null
              ? null
              : this.input.bankAccountMeta.accountNumber !== "" &&
                this.input.bankAccountMeta.accountNumber.length > 5,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
