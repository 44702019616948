var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.notifications)?[_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"my-5",attrs:{"variant":"primary"}})],1)]:_vm._e(),(_vm.notifications && !_vm.notifications.content.length)?[_c('div',{staticClass:"py-3 bg-light text-center",style:({ borderTop: '3px solid #333', borderBottom: '3px solid #333' })},[_vm._v(" 알림이 없습니다. ")])]:_vm._e(),(_vm.notifications && _vm.notifications.content.length)?[_c('b-row',{staticClass:"py-3",style:({ borderTop: '3px solid #333', borderBottom: '3px solid #333' })},_vm._l((_vm.notifications.content),function(item,i){return _c('b-col',{key:i,staticClass:"py-3",class:i == 0 ? '' : 'border-md-top border-light-gray',attrs:{"cols":"12"}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","md":"9"}},[_c('article',{staticClass:"d-flex align-items-start pr-md-4"},[_c('i',{staticClass:"icon icon-28 icon-alarm mr-3"}),_c('section',{staticClass:"w-100"},[_c('header',{staticClass:"text-18"},[_c('h3',[(
                        item.type === 'CLIENT' ||
                        item.type === 'BIDDING_HISTORY' ||
                        item.type === 'CANCEL_PROGRESS' ||
                        item.type === 'CANCEL_MATCHING' ||
                        item.type === 'MATCHED' 
                      )?[_vm._v("내 입찰건 ")]:_vm._e(),(
                        item.type === 'BIDDER_HISTORY' ||
                        item.type === 'CANCEL_BIDDER' ||
                        item.type === 'UPDATE_CONDICTION' ||
                        item.type === 'REJECT'
                      )?[_vm._v("내 의뢰건 ")]:_vm._e()],2),_c('h5',[_vm._v(_vm._s(item.address))])]),_c('div',{staticClass:"text-18"},[_c('p',[(
                        item.type === 'CLIENT' ||
                        item.type === 'BIDDING_HISTORY' ||
                        item.type === 'CANCEL_PROGRESS' ||
                        item.type === 'CANCEL_MATCHING' || 
                        item.type === 'MATCHED' 
                      )?[_vm._v("입찰하신 "),(item.type === 'CLIENT')?_c('span',[_vm._v("물건에")]):_vm._e(),(item.type === 'BIDDING_HISTORY')?_c('span',[_vm._v("물건의 내용이")]):_vm._e(),(
                          item.type === 'CANCEL_PROGRESS' ||
                          item.type === 'CANCEL_MATCHING' ||
                          item.type === 'MATCHED'
                        )?_c('span',[_vm._v("물건이")]):_vm._e()]:_vm._e(),(
                        item.type === 'BIDDER_HISTORY' ||
                        item.type === 'CANCEL_BIDDER'
                      )?[_vm._v("입찰된 "),(item.type === 'BIDDER_HISTORY')?_c('span',[_vm._v("물건의 입찰 내용이")]):_vm._e(),(item.type === 'CANCEL_BIDDER')?_c('span',[_vm._v("물건이")]):_vm._e()]:_vm._e(),(
                        item.type === 'REJECT' ||
                        item.type === 'UPDATE_CONDICTION' ||
                        item.type === 'BID'
                      )?[_vm._v("의뢰하신 "),(item.type === 'REJECT' || item.type === 'BID')?_c('span',[_vm._v("물건이")]):_vm._e(),(item.type === 'UPDATE_CONDICTION' )?_c('span',[_vm._v("물건에")]):_vm._e()]:_vm._e(),_c('strong',{staticClass:"text-third"},[_vm._v(" "+_vm._s(_vm.getText(item.type)))]),_vm._v(" 되었습니다. ")],2),(item.categorys && item.beforeUpdateDatas !== null)?[_c('ul',{staticClass:"d-flex p-0 m-0 text-17",style:({ gap: '5rem', listStyle: 'none' })},[_c('li',[_c('span',[_vm._v("==category==")]),_vm._l((item.categorys),function(item2,i){return _c('p',{key:i,staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.getText2(item2))+" ")])})],2),_c('li',[_vm._v(" ==before== "),_vm._l((item.beforeUpdateDatas),function(item3,i){return _c('p',{key:i,staticClass:"m-0"},[_vm._v(" "+_vm._s(item3)+" ")])})],2),_c('li',[_vm._v(" ==after== "),_vm._l((item.afterUpdateDatas),function(item4,i){return _c('p',{key:i,staticClass:"m-0"},[_vm._v(" "+_vm._s(item4)+" ")])})],2)])]:_vm._e()],2),_c('span',[_vm._v(" "+_vm._s(item.createdDate)+" ")]),_c('div',{staticClass:"mt-3 d-md-flex align-items-center"},[(
                      item.type === 'CANCEL_MATCHING' ||
                      item.type === 'REJECT' ||
                      item.type === 'CANCEL_PROGRESS'
                    )?_c('b-badge',{staticClass:"py-2",attrs:{"variant":"gray"}},[_c('span',{staticClass:"text-13"},[_vm._v(" "+_vm._s(_vm.getText(item.type))+" 사유")])]):_vm._e(),_c('span',{staticClass:"\n                      ml-2 ml-md-3\n                      text-gray\n                      text-underline\n                      text-17\n                      text-lg-16\n                      text-underline\n                    ",domProps:{"innerHTML":_vm._s(
                      item.type === 'CANCEL_MATCHING' ||
                      item.type === 'REJECT' ||
                      item.type === 'CLIENT' ||
                      item.type === 'CANCEL_BIDDER' ||
                      item.type === 'CANCEL_PROGRESS' ||
                      item.type === 'UPDATE_CONDICTION' ||
                      item.type === 'UPDATE_BIDDING' ||
                      item.type === 'UPDATE_BIDDER' ||
                      item.type === 'MATCHED' ||
                      item.type === 'BID'
                        ? item.content
                        : ''
                    )}})],1),(
                    item.type === 'CANCEL_MATCHING' || item.type === 'REJECT'
                  )?[_c('hr'),_c('div',{staticClass:"pb-2"},[_c('small',{staticClass:"text-light-gray text-13"},[_vm._v(_vm._s(_vm.getText(item.type))+" 사유 확인 후 수용 가능 여부를 선택해주세요.")])])]:_vm._e()],2)])]),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"px-md-3"},[_c('b-btn',{staticClass:"w-100",attrs:{"variant":"outline-primary","to":("/matching/" + (item.idBidding))}},[_vm._v(" 자세히보기 ")])],1)])],1)],1)}),1),_c('div',{staticClass:"mt-5 d-flex flex-column"},[_c('b-pagination',{staticClass:"mx-auto",attrs:{"value":_vm.notifications.page.number + 1,"total-rows":_vm.notifications.page.totalElements,"per-page":_vm.notifications.page.size,"first-class":"d-none","last-class":"d-none","hide-ellipsis":""},on:{"change":function($event){return _vm.getNotifications($event)}},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('i',{staticClass:"icon icon-prev ml-1"})]},proxy:true},{key:"next-text",fn:function(){return [_c('i',{staticClass:"icon icon-next ml-1"})]},proxy:true}],null,false,972309129)})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }